import { TranslatedLanguageStringRecord, Translator } from '@sparelabs/translate'
import en from './en'
import { ILocaleTranslationKeys } from './keys'
import { LanguageHelper } from './LanguageHelper'

const translatedLanguages: TranslatedLanguageStringRecord<ILocaleTranslationKeys> = {
  /* eslint-disable @typescript-eslint/no-require-imports */
  ja: require('./files/ja.json'),
  nb: require('./files/nb.json'),
  nn: require('./files/nn.json'),
  de: require('./files/de.json'),
  es: require('./files/es.json'),
  ca: require('./files/ca.json'),
  is: require('./files/is.json'),
  sv: require('./files/sv.json'),
  fr: require('./files/fr.json'),
  pt: require('./files/pt.json'),

  /* eslint-enable @typescript-eslint/no-require-imports */
}

export const translate = new Translator<ILocaleTranslationKeys>(
  en,
  translatedLanguages,
  LanguageHelper.getCurrentLanguageCode(),
  (error) => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
)

export const st = translate.st
