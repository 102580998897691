import { useIsFocused } from '@react-navigation/native'
import { GroupMembershipStatus, IGroupMembershipResponse } from '@sparelabs/api-client'
import { IListResponse } from '@sparelabs/http-client-utils'
import React from 'react'
import { useAsync } from 'react-async-hook'
import { ActivityIndicator, Platform, SectionList, StyleSheet, Text, View } from 'react-native'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import { colors } from 'src/assets/colors'
import { SettingsListItem } from 'src/components/settings/SettingsListItem'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { ISection } from 'src/types/section'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  list: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  title: {
    fontSize: 14,
    lineHeight: 16,
    color: colors.textMedium,
    paddingTop: 24,
    paddingHorizontal: 16,
    paddingBottom: 8,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  separator: {
    backgroundColor: colors.gray3,
    height: 1,
  },
})

type Props = ParamsListAccount[ScreenName.GroupMemberships] & {
  isFocused: boolean
  handleNavigateJoinGroup: () => void
  handleNavigateGroupDetails: (params: ParamsListAccount[ScreenName.GroupDetails]) => void
}

export const refreshGroupMemberships = async (): Promise<IListResponse<IGroupMembershipResponse> | undefined> => {
  try {
    if (AuthenticatorHelper.userOrgToken) {
      // Get all active group memberships associated with the user
      const res = await ApiClientBuilder.build().groupMemberships.list(
        {
          userId: AuthenticatorHelper.userId ?? undefined,
          status: GroupMembershipStatus.Active,
        },
        true
      )
      return res
    }
  } catch (error) {
    handleError({ error: error as Error })
  }
}

export const GroupMembershipsView = ({ handleNavigateGroupDetails, handleNavigateJoinGroup, isFocused }: Props) => {
  const groupMemberships = useAsync(refreshGroupMemberships, [isFocused])

  const renderGroupMembershipsSection = (): ISection[] => {
    if (!groupMemberships.result || groupMemberships.result.data.length === 0) {
      return []
    }

    const groupMembershipsListItems: JSX.Element[] = []

    for (const membership of groupMemberships.result.data) {
      const joinDate = moment.unix(membership.createdAt).format('LL')
      const expiryDateTime =
        membership.expiryTs === null ? st.screens.group.noExpiry() : moment.unix(membership.expiryTs).format('ll LT')
      const groupItem = (
        <SettingsListItem
          key={membership.id}
          id={membership.id}
          title={membership.group.name}
          onPressItem={async () =>
            handleNavigateGroupDetails({
              group: { id: membership.groupId, ...membership.group },
              joinDate,
              expiryDateTime,
            })
          }
        />
      )
      groupMembershipsListItems.push(groupItem)
    }

    return [
      {
        title: st.screens.group.joinedTitle(),
        data: groupMembershipsListItems,
      },
    ]
  }

  return groupMemberships.loading ? (
    <ActivityIndicator style={{ marginTop: 32 }} color='black' />
  ) : (
    <View style={{ flex: 1, backgroundColor: colors.gray4 }}>
      <SectionList
        ListHeaderComponent={() => (
          <SettingsListItem
            key='join'
            id='join'
            title={st.screens.group.joinGroup()}
            onPressItem={async () => handleNavigateJoinGroup()}
          />
        )}
        ItemSeparatorComponent={Platform.OS === OsType.iOS ? () => <View style={[styles.separator]} /> : null}
        style={styles.list}
        sections={renderGroupMembershipsSection()}
        renderItem={({ item }) => item}
        renderSectionHeader={({ section: { title } }) => <Text style={styles.title}>{title}</Text>}
        stickySectionHeadersEnabled={false}
      />
    </View>
  )
}

export const GroupMemberships = (props: ScreenPropsAccount<ScreenName.GroupMemberships>) => {
  const isFocused = useIsFocused()
  return (
    <GroupMembershipsView
      {...props.route.params}
      isFocused={isFocused}
      handleNavigateJoinGroup={() => {
        props.navigation.navigate(ScreenName.JoinGroup, {})
      }}
      handleNavigateGroupDetails={(params) => {
        props.navigation.navigate(ScreenName.GroupDetails, params)
      }}
    />
  )
}
