import { LocalizationPhoneHelper } from '@sparelabs/localization'
import { PhoneParser } from '@sparelabs/phone-parser'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { PhoneInput } from 'src/components/phoneInput/PhoneInput'
import { ReCaptcha } from 'src/components/recaptcha/ReCaptcha'
import { Constants } from 'src/consts/Constants'
import { RecaptchaConstants } from 'src/consts/Recaptcha'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { st } from 'src/locales'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { LoginStyle } from 'src/screens/login/LoginStyle'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  cellContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  description: {
    fontSize: 15,
    color: colors.textDark,
    paddingVertical: 20,
  },
})

interface IPhoneNumberFormProps {
  submitButtonTestId: string
  handleValidatePhoneNumber: (parsedPhoneNumber: string, reCaptchaToken?: string) => Promise<void>
  handleInvalidPhoneNumber: () => void
  inputDescription?: string
  submitButtonText: string
}

export const PhoneNumberForm = observer(
  ({
    handleValidatePhoneNumber,
    handleInvalidPhoneNumber,
    submitButtonText,
    inputDescription,
    submitButtonTestId = 'phoneConfirmButton',
  }: IPhoneNumberFormProps): JSX.Element => {
    const [getCaptcha, setGetCaptcha] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState(
      LocalizationPhoneHelper.getCallingCode(LanguageHelper.getCurrentLanguageCode())
    )
    const [loading, setLoading] = useState(false)

    const getInitialCountry = () => LocalizationPhoneHelper.guessCountry(LanguageHelper.getCurrentLanguageCode())

    const handlePressConfirm = () => {
      setLoading(true)
      const parsedPhoneNumber = PhoneParser.parsePhoneNumber(phoneNumber)
      if (parsedPhoneNumber) {
        setGetCaptcha(true)
      } else {
        setLoading(false)
        handleInvalidPhoneNumber()
      }
    }

    const onCaptchaError = () => {
      setLoading(false)
      setGetCaptcha(false)
      AlertHelper.alert(
        st.helpers.errorHelpers.generalAlertTitle(),
        st.recaptcha.captchaError({ appName: Constants.APP_NAME }),
        [{ text: st.common.alertOk() }]
      )
    }

    // We only execute the "onSubmit" after we have received a recaptcha token,
    // this is to prevent SMS attacks
    const onReceiveToken = async (token: string) => {
      setGetCaptcha(false)
      await handleSubmit(phoneNumber, token)
    }

    const handleSubmit = async (phoneNumber: string, reCaptchaToken: string) => {
      const parsedPhoneNumber = PhoneParser.parsePhoneNumber(phoneNumber)
      if (parsedPhoneNumber) {
        await handleValidatePhoneNumber(parsedPhoneNumber, reCaptchaToken)
      } else {
        handleInvalidPhoneNumber()
      }
      setLoading(false)
    }

    return (
      <View style={styles.cellContainer}>
        {getCaptcha && (
          <ReCaptcha
            siteKey={RecaptchaConstants.SITE_KEY}
            captchaDomain={RecaptchaConstants.BASE_URL}
            onReceiveToken={onReceiveToken}
            onError={onCaptchaError}
          />
        )}
        <View style={LoginStyle.contentContainer}>
          <Text style={styles.description}>{inputDescription}</Text>
          <PhoneInput
            phoneNumber={phoneNumber}
            handleChangePhoneNumber={(phoneNumber: string) => setPhoneNumber(phoneNumber)}
            initialCountry={getInitialCountry()}
            textProps={{ autoFocus: true, accessibilityLabel: inputDescription }}
          />
          <PrimaryButton
            testID={submitButtonTestId}
            title={submitButtonText}
            onPress={handlePressConfirm}
            loading={loading}
          />
        </View>
      </View>
    )
  }
)
