import { IFixedRouteLeg, IJourney, ILeg, IOnDemandLeg, IWalkingLeg, LegMode } from '@sparelabs/api-client'
import { MINUTE } from '@sparelabs/time'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { JourneyFixedRouteItem } from 'src/components/journey/JourneyFixedRouteItem'
import { IOnDemandItemEstimateInfo } from 'src/components/journey/JourneyHelper'
import { IOnDemandItem, JourneyOnDemandItem } from 'src/components/journey/JourneyOnDemandItem'
import { JourneyWalkingItem } from 'src/components/journey/JourneyWalkingItem'
import { getRouteColour, getRouteTextColour } from 'src/screens/rideOptions/JourneyMapHelper'

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
  },
  // Used to ensure that the dashed line does not collide with next journey item
  journeyItemContainer: {
    paddingTop: 4,
  },
})

interface IProps {
  journey: IJourney
  walkingDestinationAddress: string
  estimateInfo?: IOnDemandItemEstimateInfo
  handleMakeJourneyEstimate: () => Promise<void>
}

@observer
export class JourneyCardContent extends Component<IProps> {
  public render() {
    return <View style={styles.container}>{this.renderContent(this.props.journey.legs)}</View>
  }

  private readonly renderContent = (legs: ILeg[]) =>
    legs.map((leg, index) => {
      const { estimateInfo } = this.props
      if (leg.mode === LegMode.Walk) {
        if (index === legs.length - 1) {
          return this.renderJourneyWalkingItem(leg, this.props.walkingDestinationAddress, true)
        }

        const nextLeg = legs[index + 1]
        if (nextLeg.mode === LegMode.OnDemand) {
          return estimateInfo
            ? this.renderJourneyWalkingItem(leg, estimateInfo.pickupLocationName, false, estimateInfo.pickupTs)
            : null
        } else if (nextLeg.mode !== LegMode.Walk) {
          return this.renderJourneyWalkingItem(leg, nextLeg.stops[0].name, false, nextLeg.startTime.ts)
        }

        return null
      } else if (leg.mode === LegMode.OnDemand) {
        if (estimateInfo) {
          return this.renderJourneyOnDemandItem(leg, index === legs.length - 1, estimateInfo)
        }
        return null
      }
      return this.renderJourneyFixedRouteItem(leg, index === legs.length - 1)
    })

  private readonly renderJourneyWalkingItem = (
    leg: IWalkingLeg,
    destinationAddress: string,
    isLastLeg: boolean,
    nextLegStartTs?: number
  ) => {
    const walkMinutes = Math.ceil((leg.endTime.ts - leg.startTime.ts) / MINUTE)
    const waitMinutes = nextLegStartTs ? Math.ceil((nextLegStartTs - leg.endTime.ts) / MINUTE) : undefined
    const walkingDetails = {
      destinationAddress,
      walkMinutes,
      waitMinutes,
      startTimestamp: leg.startTime.ts,
      isLastLeg,
    }
    return (
      <View
        key={`${leg.mode}-${leg.startTime.ts}-${leg.polyline}-${leg.endTime.ts}`}
        style={styles.journeyItemContainer}
      >
        <JourneyWalkingItem {...walkingDetails} />
      </View>
    )
  }

  private readonly renderJourneyOnDemandItem = (
    leg: IOnDemandLeg,
    isLastLeg: boolean,
    estimateInfo: IOnDemandItemEstimateInfo
  ) => {
    const { mode, serviceId, serviceName, serviceBrand, fare, fareRedemptions } = leg
    const {
      organizationName,
      dropoffLocationName,
      dropoffTs: dropoffTimestamp,
      pickupTs: pickupTimestamp,
      latestDropoffTs: latestDropoffTimestamp,
      pickupLocationName,
    } = estimateInfo

    const onDemandDetails: IOnDemandItem = {
      legMode: mode,
      serviceId,
      serviceName,
      serviceBrandUrl: serviceBrand.photoUrl,
      organizationName,
      rideDurationMinutes: Math.ceil((dropoffTimestamp - pickupTimestamp) / MINUTE),
      latestDropoffTimestamp,
      pickupTimestamp,
      pickupLocationName,
      dropoffTimestamp,
      dropoffLocationName,
      fare,
      fareRedemptions,
      isLastLeg,
    }

    return (
      <View
        key={`${leg.mode}-${leg.startTime.ts}-${leg.polyline}-${leg.endTime.ts}`}
        style={styles.journeyItemContainer}
      >
        <JourneyOnDemandItem
          onDemandDetails={onDemandDetails}
          handleMakeJourneyEstimate={this.props.handleMakeJourneyEstimate}
        />
      </View>
    )
  }

  private readonly renderJourneyFixedRouteItem = (leg: IFixedRouteLeg, isLastLeg: boolean) => {
    const { mode, routeName, stops, startTime, endTime, routeColor, routeTextColor } = leg
    const lastStopName = stops[stops.length - 1].name
    const secondLastStopName = stops[stops.length - 2] ? stops[stops.length - 2].name : undefined
    const rideDurationMinutes = Math.ceil((leg.endTime.ts - leg.startTime.ts) / MINUTE)
    const fixedRouteDetails = {
      legMode: mode,
      routeName,
      lastStopName,
      secondLastStopName,
      // Does not count the current stop and the last stop
      numberOfStops: stops.length - 2,
      rideDurationMinutes,
      startTimestamp: startTime.ts,
      // TODO: This is NOT actually the lastStopTimestamp.
      // This is the end time of this leg, but each individual stop
      // does not have its own timestamp because it has not been implemented
      lastStopTimestamp: endTime.ts,
      isLastLeg,
      routeColor: getRouteColour(routeColor),
      routeTextColor: getRouteTextColour(routeTextColor),
    }
    return (
      <View
        key={`${leg.mode}-${leg.startTime.ts}-${leg.polyline}-${leg.endTime.ts}`}
        style={styles.journeyItemContainer}
      >
        <JourneyFixedRouteItem fixedRouteDetails={fixedRouteDetails} />
      </View>
    )
  }
}
