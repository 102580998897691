import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { AutoSuggestTextInput } from './AutoSuggestTextInput'
import { CloseButton } from './buttons/CloseButton'

/**
 * This is necessary to do here. Originally attempted with SafeAreaView
 * but did not work. Possibly due to the fact that this screen does not have a header
 */
const paddingTop = Platform.select({
  android: 24,
  ios: 0,
})

const styles = StyleSheet.create({
  closeButton: {
    marginRight: 10,
    marginVertical: 10,
    flex: 0.1,
  },
  searchBarContainer: {
    marginRight: 10,
    marginVertical: 10,
    flex: 0.9,
  },
  safeContainer: {
    zIndex: 3,
    backgroundColor: 'white',
    borderBottomColor: colors.borderGray,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop,
  },
})

interface IProps {
  isShowingMap: boolean
  onPressBack: () => void
  placeholder: string
  setSearchTerm: (text: string) => void
  onFocus: () => void
  searchTerm: string
}

@observer
export class SetLocationHeader extends Component<IProps> {
  public render() {
    return (
      <View style={styles.safeContainer}>
        <View style={styles.closeButton}>
          <CloseButton onPress={this.props.onPressBack} />
        </View>
        <View style={styles.searchBarContainer}>
          <AutoSuggestTextInput
            autoFocus={true}
            placeholder={this.props.placeholder}
            onChangeText={(text) => this.props.setSearchTerm(text)}
            onFocus={this.props.onFocus}
            value={this.props.searchTerm}
          />
        </View>
      </View>
    )
  }
}
