import { FarePassType } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ActivityIndicator, FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TextButton } from 'src/components/buttons/TextButton'
import { FarePassCard } from 'src/components/farePasses/FarePassCard'
import { FarePassIcon } from 'src/components/farePasses/FarePassIcon'
import { IFontAwesomeWrapperProps } from 'src/components/FontAwesomeIcon'
import { ISettingsListItem, SettingsList, SettingsListItemType } from 'src/components/settings/SettingsList'
import { st } from 'src/locales'
import { FarePassScreenStateType, ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { FarePassStore } from 'src/stores/FarePassStore'
import { LoadingStore } from 'src/stores/LoadingStore'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  farePassContainer: {
    marginHorizontal: 16,
  },
  topHeaderContainer: {
    marginHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginVertical: 16,
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.gray6,
  },
  historyButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  historyText: {
    color: colors.primaryColor,
    fontSize: 18,
    paddingRight: 8,
  },
  emptyText: {
    fontSize: 18,
    marginBottom: 16,
    color: colors.textMedium,
  },
  listWrapper: {
    borderTopWidth: 1,
    borderTopColor: colors.borderGray,
    borderBottomWidth: 1,
    borderBottomColor: colors.borderGray,
  },
})

type Props = ParamsListAccount[ScreenName.FarePassList] & {
  handleNavigateFarePassHistoryList: () => void
  handleNavigateViewFarePass: (params: ParamsListAccount[ScreenName.ViewFarePass]) => void
}

@observer
export class FarePassListView extends Component<Props> {
  private readonly loadingStore = new LoadingStore()

  public async componentDidMount() {
    await this.loadingStore.execute(FarePassStore.updateActiveFarePassAllocations())
    await this.loadingStore.execute(FarePassStore.updateFarePasses())
  }

  public renderEmptyList(emptyText: string) {
    return (
      <View style={{ paddingHorizontal: 16 }}>
        <Text style={styles.emptyText}>{emptyText}</Text>
      </View>
    )
  }

  public farePassesList = () => {
    const settingsList: ISettingsListItem[] = []

    // Order so that stored value passes are shown first
    // The store already provides the fare passes in ascending order of cost
    const storedValueFarePasses = Array.from(FarePassStore.farePasses.values()).filter(
      (item) => item.type === FarePassType.StoredValue
    )
    const timeBasedFarePasses = Array.from(FarePassStore.farePasses.values()).filter(
      (item) => item.type === FarePassType.TimeBased
    )

    // Create a settings list for the fare passes
    for (const item of storedValueFarePasses.concat(timeBasedFarePasses)) {
      const farePass = item

      if (FarePassStore.isFarePassPurchasable(farePass)) {
        settingsList.push({
          id: farePass.id,
          title: farePass.name,
          subTitle: CurrencyHelper.format(farePass.cost, farePass.currency),
          iconComponent: <FarePassIcon color={farePass.color} />,
          type: SettingsListItemType.List,
        })
      }
    }

    return settingsList
  }

  public handleBuyPass = async (farePassId) => {
    const farePass = FarePassStore.getFarePass(farePassId)
    this.props.handleNavigateViewFarePass({
      farePassName: farePass.name,
      state: { type: FarePassScreenStateType.FarePass, farePassId },
    })
  }

  public handleViewAllocation = async (allocationId) => {
    const allocation = FarePassStore.getFarePassAllocation(allocationId)
    this.props.handleNavigateViewFarePass({
      farePassName: allocation.farePass.name,
      state: { type: FarePassScreenStateType.FarePassAllocation, allocationId },
    })
  }

  public handleViewHistory = async () => {
    this.props.handleNavigateFarePassHistoryList()
  }

  public renderFarePassAllocation(farePassAllocation) {
    if (farePassAllocation) {
      return (
        <TouchableOpacity
          style={styles.farePassContainer}
          key={farePassAllocation.id}
          onPress={() => this.handleViewAllocation(farePassAllocation.id)}
        >
          <FarePassCard farePass={farePassAllocation.farePass} />
        </TouchableOpacity>
      )
    }
    return null
  }

  public renderFarePassTitleAndHistory() {
    const iconProps: IFontAwesomeWrapperProps = {
      color: colors.primaryColor,
      icon: 'chevron-right',
      size: 16,
    }
    return (
      <View style={styles.topHeaderContainer}>
        <Text style={styles.title}>{st.screens.farePasses.yourPasses()}</Text>
        <TextButton
          containerStyle={styles.historyButton}
          onPress={this.handleViewHistory}
          text={st.screens.farePasses.passHistory()}
          textStyle={styles.historyText}
          rightIconProps={iconProps}
        />
      </View>
    )
  }

  public renderPurchasableFarePasses() {
    const farePassesList = this.farePassesList()

    return (
      <View>
        <Text style={[styles.title, { marginHorizontal: 16 }]}>{st.screens.farePasses.purchasePass()}</Text>

        {!farePassesList.length ? (
          <View style={{ paddingHorizontal: 16 }}>
            <Text style={styles.emptyText}>{st.screens.farePasses.noPassesToPurchase()}</Text>
          </View>
        ) : (
          <View style={styles.listWrapper}>
            <SettingsList data={farePassesList} onPressItem={this.handleBuyPass} />
          </View>
        )}
      </View>
    )
  }

  public render() {
    return (
      <View style={styles.container} testID='farePassesView'>
        {this.loadingStore.isLoading() ? (
          <ActivityIndicator style={{ marginTop: 32 }} color='black' />
        ) : (
          <FlatList
            data={Array.from(FarePassStore.activeFarePassAllocations.values())}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => this.renderFarePassAllocation(item)}
            ListEmptyComponent={this.renderEmptyList(st.screens.farePasses.noPasses())}
            ListHeaderComponent={this.renderFarePassTitleAndHistory()}
            ListFooterComponent={this.renderPurchasableFarePasses()}
          />
        )}
      </View>
    )
  }
}

export const FarePassList = (props: ScreenPropsAccount<ScreenName.FarePassList>) => (
  <FarePassListView
    {...props.route.params}
    handleNavigateFarePassHistoryList={() => props.navigation.navigate(ScreenName.FarePassHistoryList, {})}
    handleNavigateViewFarePass={(params) => props.navigation.navigate(ScreenName.ViewFarePass, params)}
  />
)
