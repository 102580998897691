import React from 'react'
import { Platform } from 'react-native'
import { Region } from 'react-native-maps'
import { MapServiceAreas } from 'src/components/mapMarkers/MapServiceAreas'
import { SearchScreenStore } from 'src/stores/SearchScreenStore'
import { OsType } from 'src/util/types'

export const SearchMap = ({ region }: { region: Region | undefined }) => (
  <MapServiceAreas
    mapRegion={region}
    hideStops={Platform.OS === OsType.Web ? false : SearchScreenStore.areStopsHidden}
  />
)
