import { useEffect } from 'react'
import useScript from 'src/hooks/useScript'
import { IReCaptchaProps } from './ReCaptchaTypes'

// Why is this a component and not a helper function ?

// This logic is turned into a component so that we can have one seamless "hybrid" native and web component
// the native version of this logic requires us to be a component so rather than have a bunch of code to handle
// executing various logic on the web vs native, we can now just use the <Recaptcha/> component and it will work
// on both native and web

export const ReCaptcha = ({ siteKey, onError, onReceiveToken }: Omit<IReCaptchaProps, 'captchaDomain'>): null => {
  // We need to HIDE and not remove the captcha script entirely because if the user's navigates back to page this is used on
  // the component won't retrigger the hook to download the script again which would result in a error
  const hideCaptchaBadge = () => {
    const recaptchaElements = document.getElementsByClassName('grecaptcha-badge')

    if (recaptchaElements.length) {
      // @ts-expect-error: Typescript will complain that 'style' does not exist on Element, but it does
      // and using .setAttribute which makes ts happy overwrites all the existing styles so we can't use

      // eslint-disable-next-line no-restricted-properties, @typescript-eslint/no-unsafe-member-access
      Array.from(recaptchaElements).forEach((captchaBadge) => (captchaBadge.style.visibility = 'hidden'))
    }
  }

  // Removes the script / badge on component unmount
  useEffect(() => () => hideCaptchaBadge(), [])

  const recaptchaScriptStatus = useScript(
    `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`,
    'recaptcha-script'
  )
  if (recaptchaScriptStatus === 'ready') {
    // @ts-expect-error: Typescript doesn't know if grecaptcha is appended to the window or not
    // we don't want to use the 'declare' ts syntax for fear of somebody accidentally accessing without first
    // loading the script

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.grecaptcha.enterprise.ready(async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        // @ts-expect-error: see above ts-expect-error comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
        const token = await grecaptcha.enterprise.execute(`${siteKey}`)
        onReceiveToken(token)
      } catch (error) {
        onError()
      }
    })
  }

  return null
}
