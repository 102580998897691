import { Constants } from 'src/consts/Constants'
import { ClientIdStore } from 'src/stores/ClientIdStore'
import { ExpoMixpanelAnalytics, IEventProps } from '../lib/ExpoMixpanelAnalytics'
import { AuthenticatorHelper } from './AuthenticatorHelper'

/**
 * Token for mixpanel Spare Rider project
 * https://mixpanel.com/report/2628539/settings/#project/2628539
 */
const MIXPANEL_TOKEN = '11301b73cc069bdfca9c07981fadd09a'

const ExpoMixpanel = new ExpoMixpanelAnalytics(MIXPANEL_TOKEN, ClientIdStore.getId)

export class Mixpanel {
  private static readonly shouldTrack: boolean = false

  public static track(name: string, properties: IEventProps = {}): void {
    if (this.shouldTrack && Constants.TRACK_MIXPANEL_EVENTS) {
      ExpoMixpanel.track(name, {
        ...properties,
        userId: AuthenticatorHelper.userId,
        organizationId: AuthenticatorHelper.organization?.id,
        organizationName: AuthenticatorHelper.organization?.name,
        testOrganization: AuthenticatorHelper.organization?.isTestOnly.toString(),
        appVersion: Constants.APP_VERSION,
      })
    }
  }
}
