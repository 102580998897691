import { IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { roundSecondsToMinutesMinOne } from 'src/helpers/TimeHelper'
import { st } from 'src/locales'
import { PRE_PICKUP_REQUEST_STATUSES } from 'src/stores/RequestStore'
import { BaseCardHeader } from '../cards/BaseCardHeader'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingVertical: 16,
    paddingHorizontal: 16,
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  wrapper: {
    flex: 1,
    flexGrow: 12,
  },
  walkingTitle: {
    color: colors.gray70,
    fontSize: 16,
  },
  walkingAddress: {
    color: colors.gray90,
    fontWeight: 'bold',
    fontSize: 21,
  },
  icon: {
    flex: 1,
    flexGrow: 1,
  },
})

export interface IProps {
  request: IRequestResponse
}

@observer
export class WalkingDirections extends Component<IProps> {
  public renderWalkingDirections(duration: number, address: string) {
    const walkingDuration = roundSecondsToMinutesMinOne(duration)

    return (
      <BaseCardHeader>
        <View style={styles.container}>
          <View style={styles.wrapper}>
            <Text style={styles.walkingTitle}>{st.components.walkingDirections.info({ walkingDuration })}</Text>
            <Text style={styles.walkingAddress}>{address}</Text>
          </View>
          <View style={styles.icon}>
            <FontAwesomeIconWrapper icon='walking' size={32} color={colors.gray70} />
          </View>
        </View>
      </BaseCardHeader>
    )
  }

  public render() {
    const request: IRequestResponse = this.props.request

    if (
      PRE_PICKUP_REQUEST_STATUSES.includes(request.status) &&
      MapMarkerHelper.showStartLocation(request) &&
      request.scheduledPickupAddress
    ) {
      return this.renderWalkingDirections(request.pickupWalkingDuration, request.scheduledPickupAddress)
    } else if (
      request.status === RequestStatus.Completed &&
      MapMarkerHelper.showEndLocation(request) &&
      request.requestedDropoffAddress
    ) {
      return this.renderWalkingDirections(request.dropoffWalkingDuration, request.requestedDropoffAddress)
    }
    return null
  }
}
