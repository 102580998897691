import React, { Component } from 'react'
import { AlertButton, StyleSheet, Text, View } from 'react-native'
import { unassignGroup } from 'src/api'
import { colors } from 'src/assets/colors'
import { CancelButton } from 'src/components/buttons/CancelButton'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { AlertButtonStyle } from 'src/util/types'

const UNASSIGN_GROUP_KEY = 'unassignGroupKey'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  containerCell: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  contentContainer: {
    paddingBottom: 32,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 8,
    color: colors.gray70,
    textTransform: 'uppercase',
  },
  sectionText: {
    fontSize: 16,
    color: colors.gray6,
  },
})

type Props = ParamsListAccount[ScreenName.GroupDetails] & {
  handleDismiss: () => void
}

export class GroupDetailsView extends Component<Props> {
  private readonly loadingStore: LoadingStore = new LoadingStore()
  public render() {
    const { group, joinDate, expiryDateTime } = this.props
    return (
      <View style={styles.container}>
        <View style={styles.containerCell}>
          {group.description && (
            <View style={styles.contentContainer}>
              <Text style={styles.sectionText}>{group.description}</Text>
            </View>
          )}
          <View style={styles.contentContainer}>
            <Text style={styles.sectionTitle}>{st.screens.group.joinDate()}</Text>
            <Text style={styles.sectionText}>{joinDate}</Text>
          </View>
          <View style={styles.contentContainer}>
            <Text style={styles.sectionTitle}>{st.screens.group.expiryDateTime()}</Text>
            <Text style={styles.sectionText}>{expiryDateTime}</Text>
          </View>
          <CancelButton
            loading={this.loadingStore.isLoading(UNASSIGN_GROUP_KEY)}
            onPress={this.handlePressLeaveGroup}
            title={st.screens.group.leaveButton()}
          />
        </View>
      </View>
    )
  }

  public leaveGroup = async () => {
    await this.loadingStore.execute(this.unassignGroup(), UNASSIGN_GROUP_KEY)
    this.props.handleDismiss()
  }

  private readonly handlePressLeaveGroup = () => {
    AlertHelper.alert(
      st.screens.group.leaveAlertTitle(),
      st.screens.group.leaveAlertText({ groupName: this.props.group.name }),
      [this.cancelAlertButton(), this.leaveAlertButton()],
      {
        cancelable: false,
      }
    )
  }

  private readonly unassignGroup = async () => {
    try {
      if (AuthenticatorHelper.userOrgToken) {
        await unassignGroup({ token: AuthenticatorHelper.userOrgToken, id: this.props.group.id })
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    }
  }

  private readonly leaveAlertButton = (): AlertButton => ({
    text: st.screens.group.leaveButton(),
    onPress: async () => {
      await this.leaveGroup()
    },
    style: AlertButtonStyle.Destructive,
  })

  private readonly cancelAlertButton = (): AlertButton => ({
    text: st.common.buttonCancel(),
    style: AlertButtonStyle.Cancel,
  })
}

export const GroupDetails = (props: ScreenPropsAccount<ScreenName.GroupDetails>) => (
  <GroupDetailsView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
