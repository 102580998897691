import {
  IPaymentMethodResponse,
  IPostStripeSessionBody,
  Resources,
  StripePaymentMethodType,
} from '@sparelabs/api-client'
import { remove } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { LegacyApiClient, postNimoca } from 'src/api'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'

export interface IPaymentMethodStore {
  paymentMethods: IPaymentMethodResponse[]
  isLoading: boolean
  defaultPayment: IPaymentMethodResponse | undefined
  getPaymentMethodById: (id: string) => IPaymentMethodResponse | undefined
  update: (newPayment: IPaymentMethodResponse) => void
  create: (paymentMethod: IPaymentMethodResponse) => string
  delete: (paymentMethodId: string) => void
  getPaymentMethods: () => Promise<void>
  createStripeSession: (paymentMethodType: StripePaymentMethodType) => Promise<string>
  createNimoca: (nimocaId: string) => Promise<string | null>
  deletePaymentMethod: (paymentMethodId: string) => Promise<void>
  setDefault: (id: string | null) => Promise<void>
  clear: () => void
}

class PaymentMethodStoreClass implements IPaymentMethodStore {
  public name = 'PaymentMethodStore'

  @observable
  @persist('object')
  public paymentMethods: IPaymentMethodResponse[] = []

  @observable public isLoading: boolean = false

  constructor() {
    makeObservable(this)
  }

  @action
  private setLoading(loading: boolean) {
    this.isLoading = loading
  }

  @computed
  public get defaultPayment(): IPaymentMethodResponse | undefined {
    return this.paymentMethods.find((payment) => payment.default)
  }

  public getPaymentMethodById(id: string): IPaymentMethodResponse | undefined {
    return this.paymentMethods.find((payment) => payment.id === id)
  }

  @action
  public update = (newPayment: IPaymentMethodResponse) => {
    this.paymentMethods = this.paymentMethods.map((payment: IPaymentMethodResponse) => {
      if (payment.id === newPayment.id) {
        return newPayment
      }
      return payment
    })
  }

  @action
  public create = (paymentMethod: IPaymentMethodResponse): string => {
    this.paymentMethods.push(paymentMethod)

    return paymentMethod.id
  }

  @action
  public delete = (paymentMethodId: string) => {
    this.paymentMethods = remove(this.paymentMethods, (paymentMethod) => paymentMethod.id !== paymentMethodId)
  }

  @action
  public getPaymentMethods = async () => {
    try {
      this.isLoading = true
      if (AuthenticatorHelper.userOrgToken) {
        const res = await LegacyApiClient.get(AuthenticatorHelper.userOrgToken, Resources.PaymentMethods, {
          status: 'valid',
        })
        if (res) {
          runInAction(() => {
            this.paymentMethods = res.body.data
          })
        }
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    } finally {
      this.setLoading(false)
    }
  }

  @action
  public createStripeSession = async (paymentMethodType: StripePaymentMethodType): Promise<string> => {
    const body: IPostStripeSessionBody = {
      paymentMethodTypes: [paymentMethodType],
      successUrl: 'https://spare-rider.web.app/success?sc_checkout=success%26sc_sid={CHECKOUT_SESSION_ID}',
      cancelUrl: 'https://spare-rider.web.app/cancel?sc_checkout=cancel',
    }
    const response = await ApiClientBuilder.build().connectedAccounts.postStripeSession(body)
    return response.sessionId
  }

  @action
  public createNimoca = async (nimocaId: string): Promise<string | null> => {
    let paymentMethodId: string | null = null
    try {
      const res = await postNimoca(AuthenticatorHelper.getUserOrgToken(), nimocaId)
      // We refetch all payment methods
      await this.getPaymentMethods()

      paymentMethodId = res && res.body.id
    } catch (error) {
      handleError({ error: error as Error })
    }

    return paymentMethodId
  }

  @action
  public deletePaymentMethod = async (paymentMethodId: string) => {
    try {
      this.isLoading = true
      if (AuthenticatorHelper.userOrgToken) {
        await LegacyApiClient.delete(AuthenticatorHelper.userOrgToken, `${Resources.PaymentMethods}/${paymentMethodId}`)
        this.delete(paymentMethodId)
      }
    } catch (error) {
      handleError({ error: error as Error })
    } finally {
      this.setLoading(false)
    }
  }

  @action
  public setDefault = async (id: string | null) => {
    try {
      this.isLoading = true
      if (AuthenticatorHelper.userOrgToken) {
        const res = await LegacyApiClient.patch(AuthenticatorHelper.userOrgToken, 'users/me/rider', {
          defaultPaymentMethodId: id,
        })
        if (res) {
          AuthenticatorHelper.optimisticallyUpdateRider(res.body)
          this.updateDefaultPaymentMethod(id)
        }
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    } finally {
      this.setLoading(false)
    }
  }

  @action
  public clear() {
    this.paymentMethods = []
    this.setLoading(false)
  }

  @action
  private readonly updateDefaultPaymentMethod = (newDefaultPaymentId: string | null) => {
    this.paymentMethods = this.paymentMethods.map((paymentMethod: IPaymentMethodResponse) => ({
      ...paymentMethod,
      default: paymentMethod.id === newDefaultPaymentId,
    }))
  }
}

export const PaymentMethodStore = new PaymentMethodStoreClass()
