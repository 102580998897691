import Slider from '@react-native-community/slider'
import { times } from 'lodash'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { UIStateStore } from 'src/stores/UIStore'

const HORIZONTAL_PADDING = 16
const THUMB_SIZE = 20
const SLIDER_BUBBLE_WIDTH = 50
const TRACK_DOT_SIZE = 8

const SLIDER_MIN_VALUE = 0
const SLIDER_MAX_VALUE = 10

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingHorizontal: HORIZONTAL_PADDING,
    marginBottom: 16,
  },
  title: {
    paddingVertical: 20,
    color: colors.gray90,
    fontSize: 21,
    lineHeight: 24,
    fontWeight: 'bold',
  },
  sliderContainer: {
    top: -60,
  },
  thumb: {
    height: THUMB_SIZE,
    width: THUMB_SIZE,
    backgroundColor: colors.white,
    borderColor: colors.blue50,
    borderWidth: 2,
  },
  numberRangeContainer: {
    top: -60,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  numberRangeText: {
    color: colors.blueAlt50,
    fontSize: 12,
    lineHeight: 16,
  },
  trackDotsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    top: -27,
  },
  trackDot: {
    borderWidth: 5,
    borderRadius: 8,
    height: TRACK_DOT_SIZE,
    width: TRACK_DOT_SIZE,
    borderColor: colors.blue20,
  },
  bubble: {
    position: 'relative',
    borderRadius: 5,
    width: SLIDER_BUBBLE_WIDTH,
    backgroundColor: colors.blue50,
  },
  bubbleText: {
    paddingVertical: 4,
    fontSize: 16,
    lineHeight: 20,
    color: colors.white,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  triangle: {
    width: 0,
    height: 0,
    top: 40,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderBottomWidth: 0,
    borderRightWidth: SLIDER_BUBBLE_WIDTH / 3,
    borderTopWidth: SLIDER_BUBBLE_WIDTH / 2,
    borderLeftWidth: SLIDER_BUBBLE_WIDTH / 3,
    borderTopColor: colors.blue50,
    borderBottomColor: 'transparent',
    borderRightColor: 'transparent',
    borderLeftColor: 'transparent',
  },
})

interface IState {
  isActive: boolean
  touched: boolean
  value: number
}

@observer
export class SliderField extends Component<IFormFieldProps<number>, IState> {
  constructor(props: IFormFieldProps<number>) {
    super(props)
    this.state = {
      isActive: false,
      touched: false,
      value: SLIDER_MIN_VALUE,
    }
  }

  public render() {
    const { label, isRequired } = this.props
    return (
      <View style={styles.container}>
        <Text style={styles.title}>
          {label}
          {isRequired && <Text style={[styles.title, { color: colors.red50 }]}> *</Text>}
        </Text>
        <View style={{ height: 100 }}>
          <View style={{ left: this.getBubbleLeftPadding(), top: -40 }}>
            <View
              style={[
                styles.triangle,
                { left: SLIDER_BUBBLE_WIDTH / 6, borderTopColor: this.state.touched ? colors.blue50 : colors.white },
              ]}
            />
            <View style={[styles.bubble, { backgroundColor: this.state.touched ? colors.blue50 : colors.white }]}>
              <Text style={styles.bubbleText}>{this.state.value}</Text>
            </View>
          </View>
          <View style={styles.trackDotsContainer}>
            {times(SLIDER_MAX_VALUE - SLIDER_MIN_VALUE + 1, (index) => (
              <View
                style={[
                  styles.trackDot,
                  index < this.state.value ? { borderColor: colors.white } : { borderColor: colors.blue20 },
                ]}
              />
            ))}
          </View>
          <View style={styles.sliderContainer}>
            {/* TODO check that styling still looks god since we switched from react-native-slider */}
            <Slider
              value={SLIDER_MIN_VALUE}
              minimumValue={SLIDER_MIN_VALUE}
              maximumValue={SLIDER_MAX_VALUE}
              step={1}
              maximumTrackTintColor={colors.blue20}
              minimumTrackTintColor={colors.blue50}
              // trackStyle={{ height: 4 }}
              // thumbStyle={[styles.thumb, { backgroundColor: this.state.isActive ? colors.white : colors.blue50 }]}
              onValueChange={this.handleValueChange}
              onSlidingStart={this.handleSlidingStart}
              onSlidingComplete={this.handleSlidingComplete}
            />
          </View>
          <View style={styles.numberRangeContainer}>
            <Text style={styles.numberRangeText}>{SLIDER_MIN_VALUE}</Text>
            <Text style={styles.numberRangeText}>{SLIDER_MAX_VALUE}</Text>
          </View>
        </View>
      </View>
    )
  }

  private readonly getBubbleLeftPadding = () =>
    HORIZONTAL_PADDING / 2 -
    SLIDER_BUBBLE_WIDTH / 2 +
    (this.state.value / SLIDER_MAX_VALUE) * (UIStateStore.screenWidth - 3 * HORIZONTAL_PADDING)

  private readonly handleValueChange = (value: number) => {
    // Prevent from updating too many times. Dragging slowly causes many updates.
    if (this.state.value !== value) {
      this.setState({ value })
    }
  }

  private readonly handleSlidingStart = () => {
    this.setState({ isActive: true, touched: true })
  }

  private readonly handleSlidingComplete = () => {
    this.setState({ isActive: false })
    this.props.onChange(this.state.value)
  }
}
