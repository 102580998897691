import React, { Component, ReactNode } from 'react'
import { TouchableWithoutFeedback } from 'react-native'
import { moment } from 'src/helpers/Moment'

const DEV_MENU_PRESS_TIMEOUT_MS = 300
const DEV_MENU_PRESS_COUNT = 10

interface IProps {
  handleNavigateDeveloperMenu: () => void
  children?: ReactNode
}

interface IState {
  pressCount: number
  lastPress: number
}

export class DeveloperMenuTouchable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      pressCount: 0,
      lastPress: 0,
    }
  }

  public render() {
    return <TouchableWithoutFeedback onPress={this.onPress}>{this.props.children}</TouchableWithoutFeedback>
  }

  private readonly onPress = async () => {
    const nowTs = moment().valueOf()
    if (nowTs - this.state.lastPress <= DEV_MENU_PRESS_TIMEOUT_MS) {
      /* Pressed recently, keep bringing the press count up */
      this.setState({
        pressCount: this.state.pressCount + 1,
        lastPress: nowTs,
      })
    } else {
      this.setState({
        pressCount: 0,
        lastPress: nowTs,
      })
    }
    if (this.state.pressCount >= DEV_MENU_PRESS_COUNT) {
      this.props.handleNavigateDeveloperMenu()
    }
  }
}
