import { IEstimateService, IFleetStub, IUserFleetAgreementResponse } from '@sparelabs/api-client'
import { DAY } from '@sparelabs/time'
import { makeObservable, observable, runInAction } from 'mobx'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { moment } from 'src/helpers/Moment'
import { LoadingStore } from 'src/stores/LoadingStore'

export interface IUserFleetAgreementStore {
  userFleetAgreements: IUserFleetAgreementResponse[]
  loadingStore: LoadingStore
  refreshStoreData: () => Promise<void>
  fleetRequiresAgreement: (fleetId: string) => boolean
  restrictedFleets: (estimateServices: IEstimateService[] | null) => Record<string, IFleetStub>
}

export class UserFleetAgreementStore implements IUserFleetAgreementStore {
  public static readonly ASK_AGAIN_INTERVAL = 30 * DAY

  @observable
  public userFleetAgreements: IUserFleetAgreementResponse[] = []

  @observable
  public loadingStore = new LoadingStore()

  public refreshStoreData = async (): Promise<void> => {
    try {
      await this.loadingStore.execute(this.fetchData())
    } catch (error) {
      handleError({ error: error as Error })
    }
  }

  private readonly fetchData = async (): Promise<void> => {
    try {
      if (AuthenticatorHelper.userId) {
        // Get all active fleet agreements associated with the user
        const userFleetAgreements = await ApiClientBuilder.build().userFleetAgreements.list(
          { userId: AuthenticatorHelper.userId },
          true
        )
        if (userFleetAgreements) {
          runInAction(() => {
            this.userFleetAgreements = userFleetAgreements.data
          })
        }
      }
    } catch (error) {
      handleError({ error: error as Error })
    }
  }

  constructor() {
    makeObservable(this)
  }

  public fleetRequiresAgreement(fleetId: string): boolean {
    const nowTs = Math.round(moment.now() / 1000)

    const existingAgreement = this.userFleetAgreements.find((agreement) => agreement.fleetStub.id === fleetId)

    return (
      !existingAgreement ||
      (!existingAgreement.agreed && nowTs - existingAgreement.lastAskedTs > UserFleetAgreementStore.ASK_AGAIN_INTERVAL)
    )
  }

  public restrictedFleets = (estimateServices: IEstimateService[] | null): Record<string, IFleetStub> => {
    const restrictedFleetMap = {}
    if (!estimateServices) {
      return restrictedFleetMap
    }
    for (const service of estimateServices) {
      for (const fleet of service.serviceLinkedFleets) {
        if (fleet.agreementRequired && !(fleet.id in restrictedFleetMap) && this.fleetRequiresAgreement(fleet.id)) {
          restrictedFleetMap[fleet.id] = fleet
        }
      }
    }

    return restrictedFleetMap
  }
}
