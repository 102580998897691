import { ApiClient, IGlobalLinkedOrganization } from '@sparelabs/api-client'
import { maybeCompleteAuthSession } from 'expo-web-browser'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ActivityIndicator, Image, Platform, SafeAreaView, StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { LoginButtons } from 'src/components/login/LoginButtons'
import { LoginTerms } from 'src/components/login/LoginTerms'
import { RetryButton } from 'src/components/login/RetryButton'
import { Constants } from 'src/consts/Constants'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { SSOHelper } from 'src/helpers/SSOHelper'
import { WhitelabelHelper } from 'src/helpers/WhitelabelHelper'
import { usePreLoginChecks } from 'src/hooks/usePreLoginChecks'
import { st } from 'src/locales'
import { ScreenName, ScreenPropsRoot } from 'src/navigation'
import { DeveloperMenuTouchable } from 'src/screens/developer/components/DeveloperMenuTouchable'
import { AuthType } from 'src/types/constants'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loginContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  contentContainer: {
    flex: 1,
    maxWidth: 500,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  bottomContainer: {
    width: '100%',
    paddingVertical: 16,
    display: 'flex',
    justifyContent: Platform.OS === OsType.Web ? 'flex-start' : 'flex-end',
    alignItems: 'center',
    marginBottom: 16,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
  },
  logo: {
    width: 250,
    height: 200,
  },
})

interface ILoginProps {
  handleSsoLoginPress: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void
  handleLoginPress: () => void
  handleRegisterPress: () => void
  handleNavigateDeveloperMenu: () => void
  authType: AuthType
  authOrganization: IGlobalLinkedOrganization
}

export const LoginView = observer(
  ({
    handleSsoLoginPress,
    handleLoginPress,
    handleRegisterPress,
    handleNavigateDeveloperMenu,
    authType,
    authOrganization,
  }: ILoginProps) => {
    const { isLoading, isConnected, updateAppAndLogout } = usePreLoginChecks()

    // This line is needed to automatically close the popup sso window on rider web
    maybeCompleteAuthSession()

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.loginContainer}>
          <View style={styles.contentContainer}>
            <View style={styles.logoContainer}>
              <DeveloperMenuTouchable handleNavigateDeveloperMenu={handleNavigateDeveloperMenu}>
                <Image resizeMode='contain' style={styles.logo} source={WhitelabelHelper.getLogoSource()} />
              </DeveloperMenuTouchable>
            </View>

            <View style={styles.bottomContainer}>
              {isLoading ? (
                <ActivityIndicator color={colors.black} size='small' />
              ) : (
                <View>
                  {isConnected ? (
                    <View>
                      <LoginTerms terms={{ ...authOrganization }} />
                      <LoginButtons
                        authType={authType}
                        handleSsoLoginPress={handleSsoLoginPress}
                        handleLoginPress={handleLoginPress}
                        handleRegisterPress={handleRegisterPress}
                      />
                    </View>
                  ) : (
                    <RetryButton onRetryPress={updateAppAndLogout} isLoading={isLoading} />
                  )}
                </View>
              )}
            </View>
          </View>
        </View>
      </SafeAreaView>
    )
  }
)

// This component acts as wrapper so that navigation is only controlled at the "Screen" level,
// this makes testing easier and why this pattern is required
export const Login = observer(({ route, navigation }: ScreenPropsRoot<ScreenName.RootLogin>): JSX.Element => {
  const { authOrganization } = route.params
  const loginApiClient = new ApiClient({ host: authOrganization.apiHost })

  const loginWithSSO = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const { code, ssoSessionStatus } = await SSOHelper.getWorkosCode(loginApiClient, authOrganization.apiHost)

    // If the user manually closes the SSO browser while its loading do not show an error
    if (ssoSessionStatus !== 'success') {
      setLoading(false)
      return
    }

    if (!code) {
      throw new Error('Unable to get SSO code required for login.')
    }

    const { token, userId } = await loginApiClient.workos.callbackRider({
      code,
      organizationId: authOrganization.id,
    })
    AuthenticatorHelper.setUserOrgToken(token)
    AuthenticatorHelper.setUserId(userId)
    AuthenticatorHelper.setRegionalHost(authOrganization.apiHost)
  }

  const redirectToNextScreen = () => {
    if (AuthenticatorHelper.getUser().phoneNumber) {
      navigation.reset({ routes: [{ name: ScreenName.RootHome, params: {} }] })
    } else {
      navigation.navigate(ScreenName.GroupEditPhoneNumber, {
        screen: ScreenName.EditPhoneNumber,
        params: { title: st.screens.setPhoneNumber.titleLoginSso() },
      })
    }
  }

  const handleSsoLoginPress = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    try {
      setLoading(true)
      await loginWithSSO(setLoading)
      await AuthenticatorHelper.fetchUserData()
      await AuthenticatorHelper.fetchOrganizationData(authOrganization.id)
      redirectToNextScreen()
    } catch (error) {
      setLoading(false)
      handleError({ error: error as IErrorWithResponse, silent: true })
      AlertHelper.alert(st.helpers.errorHelpers.generalAlertTitle(), st.errors.ssoLoginError(), [
        { text: st.common.alertOk() },
      ])
      return
    }

    setLoading(false)
  }

  return (
    <LoginView
      authType={Constants.AUTH_TYPE}
      authOrganization={authOrganization}
      handleSsoLoginPress={handleSsoLoginPress}
      handleLoginPress={() =>
        navigation.navigate(ScreenName.GroupSetPhoneNumber, {
          screen: ScreenName.SetPhoneNumber,
          params: { title: st.screens.setPhoneNumber.titleLogin(), authOrganization },
        })
      }
      handleRegisterPress={() =>
        navigation.navigate(ScreenName.GroupSetPhoneNumber, {
          screen: ScreenName.SetPhoneNumber,
          params: { title: st.screens.setPhoneNumber.titleLogin(), authOrganization },
        })
      }
      handleNavigateDeveloperMenu={() =>
        navigation.navigate(ScreenName.GroupDeveloperMenu, {
          screen: ScreenName.DeveloperMenu,
          params: {},
        })
      }
    />
  )
})
