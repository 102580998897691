import { Currency } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import React, { Component } from 'react'
import { OptionButton, OptionButtonPosition } from 'src/components/buttons/OptionButton'
import { TipButtonKey } from './AddTipScreen'

export interface ITipOptionButtonProps {
  onHandleSelected: (key: TipButtonKey, amount: number) => void
  position: OptionButtonPosition
  tipAmount: number | null
  percentage: number | null
  buttonKey: TipButtonKey
  currency: Currency
  selected: boolean
}

export class TipOptionButton extends Component<ITipOptionButtonProps> {
  public render() {
    const { position, buttonKey, tipAmount, percentage, currency, selected } = this.props

    if (tipAmount !== null) {
      return (
        <OptionButton
          title={CurrencyHelper.format(tipAmount, currency)}
          subtitle={percentage ? `${percentage}%` : undefined}
          onHandleSelected={() => this.props.onHandleSelected(buttonKey, tipAmount)}
          position={position}
          buttonKey={buttonKey}
          selected={selected}
        />
      )
    }

    return null
  }
}
