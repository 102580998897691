import { IStopResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import { colors } from 'src/assets/colors'
import { IMapServiceAreasProps } from 'src/components/mapMarkers/MapMarkerProps'
import { Polygon } from 'src/components/mapMarkers/polygon/Polygon'
import { StopMarkers } from 'src/components/mapMarkers/stopMarker/StopMarkers'
import { ServiceStore } from 'src/stores/ServiceStore'
import { OsType } from 'src/util/types'
import { areIndividualMarkersVisible, isPointVisible } from '../../helpers/MapHelper'

const visibleStops = new Map()

export const MapServiceAreas = observer((props: IMapServiceAreasProps) => {
  useEffect(() => {
    visibleStops.clear()

    return () => {
      visibleStops.clear()
    }
  }, [])

  const getVisibleStops = (stops: IStopResponse[]) => {
    if (Platform.OS === OsType.Web || stops.length <= 20) {
      return stops
    }
    if (props.mapRegion) {
      for (const stop of stops) {
        if (!visibleStops.has(stop.id)) {
          const stopCoordinate = stop.location.coordinates
          if (isPointVisible(stopCoordinate, props.mapRegion)) {
            visibleStops.set(stop.id, stop)
          }
        }
      }
      return Array.from(visibleStops.values())
    }
    return []
  }

  function renderZones() {
    return (
      <Polygon
        strokeWidth={3}
        fillColor={colors.zoneFill}
        strokeColor={colors.zoneBorder}
        zoneMap={ServiceStore.zonesMap}
      />
    )
  }

  function renderStops() {
    if (props.mapRegion && areIndividualMarkersVisible(props.mapRegion) && !props.hideStops) {
      return <StopMarkers stops={getVisibleStops(ServiceStore.stops)} />
    }
    return null
  }

  return (
    <>
      {renderZones()}
      {renderStops()}
    </>
  )
})
