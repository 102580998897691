import { GeoJsonCoordinate, GeometryType, ILineString, IPoint, IPolygon } from '@sparelabs/geography'
import { CompassDirection, getCompassDirection, getDistance, PositionAsDecimal } from 'geolib'
import { isEqual } from 'lodash'
import { Dimensions } from 'react-native'
import { LatLng, Region } from 'react-native-maps'
import { PICKUP_DROPOFF_PIN_SIZE } from 'src/helpers/MapMarkerHelper'
import { ICompassDirection, IMarker, IMarkerCoordinates } from 'src/types'

// Need an overlay for the entire world?
// WorldPolygon can be found https://gist.github.com/joshandrews/5ecf3676dd9939941e93366f275efee5
export const MAP_FOOTER_HEIGHT = 60

enum CompassBearingDirection {
  North = 'north',
  South = 'south',
  East = 'east',
  West = 'west',
}

export const geoJsonToCoords = (geoJson: IPoint): IMarkerCoordinates => ({
  latitude: geoJson.coordinates[1],
  longitude: geoJson.coordinates[0],
})

export const lineStringToCoords = (lineString: ILineString): IMarkerCoordinates[] =>
  lineString.coordinates.map(([lon, lat]) => ({ longitude: lon, latitude: lat }))

export const coordinatesToLineString = (coordinates: LatLng[]): ILineString => ({
  type: GeometryType.LineString,
  coordinates: coordinates.map((coordinate) => [coordinate.longitude, coordinate.latitude]),
})

export const geoJsonPolygonToCoordList = (polygon: IPolygon): IMarkerCoordinates[] | undefined => {
  if (polygon.coordinates && polygon.coordinates.length > 0) {
    return polygon.coordinates[0].map(([lon, lat]) => ({ longitude: lon, latitude: lat }))
  }

  return undefined
}

export const areIndividualMarkersVisible = (mapRegion: Region): boolean =>
  !(mapRegion.longitudeDelta >= 1.5 || mapRegion.latitudeDelta >= 1.5)

export const markersToCoordinates = (markers: IMarker[]): IMarkerCoordinates[] => {
  const coordinates: IMarkerCoordinates[] = []
  markers.map((marker) => coordinates.push(geoJsonToCoords(marker.coordinate)))
  return coordinates
}

export const compassDirectionToClosestLocation = (
  location: PositionAsDecimal,
  listOfLocations: PositionAsDecimal[]
): CompassDirection => {
  const sortedLocations = listOfLocations.sort((a, b) => getDistance(a, location) - getDistance(b, location))
  return getCompassDirection(location, sortedLocations[0])
}

const compassDirectionToClosestMarker = (
  markerLocations: IMarkerCoordinates[],
  location: IPoint
): ICompassDirection => {
  const filteredMarkers = markerLocations.filter((loc) => !isEqual(geoJsonToCoords(location), loc))
  if (filteredMarkers.length > 0) {
    return compassDirectionToClosestLocation(geoJsonToCoords(location), filteredMarkers) as ICompassDirection
  }
  return { rough: 'N', exact: 'N', bearing: 0 }
}

const getCompassBearingDirection = (compassBearing: number): CompassBearingDirection => {
  if (compassBearing >= 0 && compassBearing < 90) {
    return CompassBearingDirection.East
  }
  if (compassBearing >= 90 && compassBearing < 180) {
    return CompassBearingDirection.South
  }
  if (compassBearing >= 180 && compassBearing < 270) {
    return CompassBearingDirection.West
  }
  return CompassBearingDirection.North
}

export interface IXYOffset {
  x: number
  y: number
}

export const getCenterOffsetForMarker = (
  markerLocations: IMarkerCoordinates[],
  location: IPoint,
  markerWidth: number
): IXYOffset => {
  const compassDirection = compassDirectionToClosestMarker(markerLocations, location)
  const compassBearingDirection = getCompassBearingDirection(compassDirection.bearing)
  const yDisplacement = 35
  if (compassBearingDirection === CompassBearingDirection.East) {
    return { x: -markerWidth, y: yDisplacement }
  }
  if (compassBearingDirection === CompassBearingDirection.South) {
    return { x: -markerWidth, y: -yDisplacement }
  }
  if (compassBearingDirection === CompassBearingDirection.West) {
    return { x: markerWidth, y: -yDisplacement }
  }
  return { x: markerWidth, y: yDisplacement }
}

export const getAnchorForMarker = (
  markerLocations: IMarkerCoordinates[],
  location: IPoint,
  markerWidth: number
): IXYOffset => {
  const compassDirection = compassDirectionToClosestMarker(markerLocations, location)
  const compassBearingDirection = getCompassBearingDirection(compassDirection.bearing)
  const normalizedX = (0.1 * 64) / markerWidth

  if (compassBearingDirection === CompassBearingDirection.East) {
    return { x: 1 + normalizedX, y: -0.3 }
  }
  if (compassBearingDirection === CompassBearingDirection.South) {
    return { x: 1 + normalizedX, y: 1.3 }
  }
  if (compassBearingDirection === CompassBearingDirection.West) {
    return { x: -normalizedX, y: 1.3 }
  }
  return { x: -normalizedX, y: -0.3 }
}

const { width, height } = Dimensions.get('window')

export const getLatitudeLongitudeDelta = (latitudeDelta: number = 0.0222) => {
  // This method determines the zoom level of the map
  const aspectRatio = width / height
  const longitudeDelta = latitudeDelta * aspectRatio
  return {
    latitudeDelta,
    longitudeDelta,
  }
}

// for react-map-gl implementation
export interface ILabelOffsets {
  offsetLeft: number
  offsetTop: number
}

export interface IWebLabelOffsetParams {
  markerLocations: IMarkerCoordinates[]
  location: IPoint
  labelWidth: number
  labelHeight?: number
  pinSize?: number
}

export const getWebCenterOffset = ({
  markerLocations,
  location,
  labelWidth,
  labelHeight = 40,
  pinSize = PICKUP_DROPOFF_PIN_SIZE,
}: IWebLabelOffsetParams): ILabelOffsets => {
  const compassDirection = compassDirectionToClosestMarker(markerLocations, location)
  const compassBearingDirection = getCompassBearingDirection(compassDirection.bearing)

  if (compassBearingDirection === CompassBearingDirection.East) {
    return {
      offsetLeft: -(labelWidth + pinSize),
      offsetTop: pinSize,
    }
  }
  if (compassBearingDirection === CompassBearingDirection.South) {
    return {
      offsetLeft: -(labelWidth + pinSize),
      offsetTop: -(labelHeight + pinSize),
    }
  }
  if (compassBearingDirection === CompassBearingDirection.West) {
    return {
      offsetLeft: pinSize,
      offsetTop: -(labelHeight + pinSize),
    }
  }
  return {
    offsetLeft: pinSize,
    offsetTop: pinSize,
  }
}

export const getBoundingBox = (region: Region, scale = 1) => {
  const calcMinLatByOffset = (lat: number, offset: number) => {
    const factValue = lat - offset
    if (factValue < -90) {
      return (90 + offset) * -1
    }
    return factValue
  }

  const calcMaxLatByOffset = (lat: number, offset: number) => {
    const factValue = lat + offset
    if (90 < factValue) {
      return (90 - offset) * -1
    }
    return factValue
  }

  const calcMinLngByOffset = (lng: number, offset: number) => {
    const factValue = lng - offset
    if (factValue < -180) {
      return (180 + offset) * -1
    }
    return factValue
  }

  const calcMaxLngByOffset = (lng: number, offset: number) => {
    const factValue = lng + offset
    if (180 < factValue) {
      return (180 - offset) * -1
    }
    return factValue
  }

  const latOffset: number = (region.latitudeDelta / 2) * scale
  const lngD: number = region.longitudeDelta < -180 ? 360 + region.longitudeDelta : region.longitudeDelta
  const lngOffset: number = (lngD / 2) * scale

  return {
    minLng: calcMinLngByOffset(region.longitude, lngOffset), // westLng - min lng
    minLat: calcMinLatByOffset(region.latitude, latOffset), // southLat - min lat
    maxLng: calcMaxLngByOffset(region.longitude, lngOffset), // eastLng - max lng
    maxLat: calcMaxLatByOffset(region.latitude, latOffset), // northLat - max lat
  }
}

export const isPointVisible = (coordinate: GeoJsonCoordinate, region: Region) => {
  const boundingBox = getBoundingBox(region)
  return (
    coordinate[1] <= boundingBox.maxLat &&
    coordinate[1] >= boundingBox.minLat &&
    coordinate[0] <= boundingBox.maxLng &&
    coordinate[0] >= boundingBox.minLng
  )
}
