// This hot fix is required to address this bug
// https://github.com/storybookjs/react-native/issues/159#issuecomment-852422659
// eslint-disable-next-line import/order
import Bluebird from 'bluebird'
;(global.Promise as any) = Bluebird

import Constants from 'expo-constants'
import * as SplashScreen from 'expo-splash-screen'
import React, { useCallback } from 'react'
import { LogBox } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { AppChild } from 'src/AppChild'
import { WebAlert } from 'src/components/webAlert/WebAlert'
import { useFetchUpdate } from 'src/hooks/useFetchUpdate'
import { BuildEnvironment } from 'src/types/constants'

if (Constants.expoConfig?.extra?.BUILD_ENVIRONMENT === BuildEnvironment.TESTING) {
  LogBox.ignoreAllLogs()
}

// Keep the splash screen visible while we fetch resources
void SplashScreen.preventAutoHideAsync()

export const App: React.FC = () => {
  const isUpdateFetched = useFetchUpdate()

  const onLayoutRootView = useCallback(async () => {
    if (isUpdateFetched) {
      await SplashScreen.hideAsync()
    }
  }, [isUpdateFetched])

  if (!isUpdateFetched) {
    return null
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <WebAlert />
      <AppChild />
    </GestureHandlerRootView>
  )
}
