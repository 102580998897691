import { RequestCancellationReason } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { TouchableOpacityProps } from 'react-native'
import { st } from 'src/locales'
import { TagButton } from '../buttons/TagButton/TagButton'

interface IProps extends TouchableOpacityProps {
  onPress?: () => void
  active?: boolean
  reason: RequestCancellationReason
}

const t = st.components.requestCancellationTagButton

export const reasonStringMap: Record<Exclude<RequestCancellationReason, 'riderBan'>, string> = {
  [RequestCancellationReason.AlternativeMethod]: t.alternativeMethod(),
  [RequestCancellationReason.NoShow]: t.noShow(),
  [RequestCancellationReason.LateDriver]: t.lateDriver(),
  [RequestCancellationReason.ScheduleChange]: t.plansChanged(),
  [RequestCancellationReason.Other]: t.other(),
}

export class RequestCancellationTagButton extends Component<IProps> {
  public render() {
    return (
      <TagButton
        onPress={this.props.onPress}
        active={this.props.active}
        text={reasonStringMap[this.props.reason]}
        {...this.props}
      />
    )
  }
}
