import { IPoint } from '@sparelabs/geography'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Platform } from 'react-native'
import { Region } from 'react-native-maps'
import {
  AutoSuggestHelper,
  AutoSuggestion,
  IFavoriteAutosuggestion,
  IPlaceholderAutosuggestion,
  IPlacePredictionAutoSuggestion,
  IPlainAutoSuggestion,
  IStopAutoSuggestion,
} from 'src/helpers/AutoSuggestHelper'
import { OsType } from 'src/util/types'
import { LocationStore } from './LocationStore'
import { ServiceStore } from './ServiceStore'

// Limit max stops shown on list for performance and usability reasons
export const MAX_STOP_LIMIT = 11

export class AutoSuggestStoreClass {
  @observable
  public selectedSuggestion: AutoSuggestion | null = null

  @observable
  public searchTerm: string = ''

  @observable
  public mapRegionSuggestion: IPlainAutoSuggestion | null = null

  public searchIndex: Fuse<IFavoriteAutosuggestion, Fuse.FuseOptions<IFavoriteAutosuggestion>> | null = null

  @observable
  public placeSuggestions: IPlacePredictionAutoSuggestion[] | null = null

  @observable
  public stopSuggestions: IStopAutoSuggestion[] | null = null

  constructor() {
    makeObservable(this)
  }

  public getSuggestions(includeCurrentLocation: boolean): AutoSuggestion[] {
    const searchTerm = this.searchTerm
    const fixedSuggestions = this.getFixedSuggestions(includeCurrentLocation)
    const placeSuggestions = this.placeSuggestions || []
    const favoriteSuggestions = AutoSuggestHelper.getFavoriteSuggestions(this.searchTerm, this.searchIndex)
    const initialStopSuggestions = this.getInitialStopSuggestions()
    const searchStopSuggestions = this.stopSuggestions || []

    // Show SearchItems most relevant to the search term first
    if (searchTerm.length <= 0) {
      return [...fixedSuggestions, ...favoriteSuggestions, ...initialStopSuggestions]
    }
    return [...favoriteSuggestions, ...searchStopSuggestions, ...placeSuggestions, ...fixedSuggestions]
  }

  @action
  public createSearchIndex() {
    this.searchIndex = AutoSuggestHelper.createSearchIndex(
      AutoSuggestHelper.getFavoriteSuggestions(this.searchTerm, this.searchIndex)
    )
  }

  @action
  public selectAutoSuggestion = (autoSuggestion: AutoSuggestion | null) => {
    this.selectedSuggestion = autoSuggestion
  }

  @action
  public setSearchTerm = async (searchTerm: string, referencePoint?: IPoint | null) => {
    this.searchTerm = searchTerm
    if (searchTerm) {
      const placeSuggestions = await AutoSuggestHelper.getPlacesSuggestions(searchTerm, referencePoint)
      runInAction(() => {
        this.placeSuggestions = placeSuggestions ?? null
      })
    } else {
      runInAction(() => {
        this.placeSuggestions = null
      })
    }
    await this.searchStopSuggestions(this.searchTerm)
  }

  @action
  public setSearchTermWithSuggestions = (
    searchTerm: string,
    stopSuggestions: IStopAutoSuggestion[] | null,
    placeSuggestions: IPlacePredictionAutoSuggestion[] | null,
    selectedSuggestion: AutoSuggestion | null
  ) => {
    this.searchTerm = searchTerm
    this.placeSuggestions = placeSuggestions
    this.stopSuggestions = stopSuggestions
    this.selectedSuggestion = selectedSuggestion
  }

  @action
  public clear = () => {
    this.selectedSuggestion = null
    this.searchTerm = ''
    this.mapRegionSuggestion = null
    this.placeSuggestions = null
  }

  public setMapRegionSuggestion = async (mapRegion: Region | null) => {
    this.mapRegionSuggestion = mapRegion ? await AutoSuggestHelper.mapRegionToAutoSuggestion(mapRegion) : null
  }

  private readonly getInitialStopSuggestions = (): IStopAutoSuggestion[] =>
    AutoSuggestHelper.mapStopsToAutoSuggestion(ServiceStore.stops)

  @action
  private readonly searchStopSuggestions = async (searchTerm: string) => {
    const stopSuggestions = await AutoSuggestHelper.getStopSuggestion(searchTerm)
    runInAction(() => {
      this.stopSuggestions = stopSuggestions
    })
  }

  // Stable suggestions that do not change/filter: CurrentLocation, PressOnMap
  public getFixedSuggestions(
    includeCurrentLocation: boolean
  ): Array<IPlaceholderAutosuggestion | IPlainAutoSuggestion> {
    const fixedSuggestions: Array<IPlaceholderAutosuggestion | IPlainAutoSuggestion> = []

    /*
     * TODO: Removing this feature for Rider Web MVP, should be re-enabled when
     * we decide on the best solution for positioning the map w.r.t. the other components on the left side
     * https://sparelabs.atlassian.net/browse/ENDUSER-625
     */
    if (Platform.OS !== OsType.Web) {
      fixedSuggestions.push(AutoSuggestHelper.mapSetOnMapToAutoSuggestion())
    }

    if (includeCurrentLocation) {
      const currentLocation = LocationStore.getCurrentLocationWithPermission()
      fixedSuggestions.push(AutoSuggestHelper.mapPositionToAutoSuggestion(currentLocation))
    }
    return fixedSuggestions
  }
}

export const AutoSuggestStore = new AutoSuggestStoreClass()
