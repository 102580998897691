import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { TextField } from 'src/components/textField/TextField'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { st } from 'src/locales'
import { ParamsListAddPaymentMethod, ScreenName, ScreenPropsAddPaymentMethod } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'

const NIMOCA_PREFIX = 'NR'
const NIMOCA_ID_LENGTH = 15

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.sectionBlue,
    flex: 1,
  },
  cardViewContainer: {
    backgroundColor: 'white',
  },
  cardImage: {
    margin: 20,
    maxWidth: '50%',
  },
  nimocaMascotContainer: {
    position: 'absolute',
    marginTop: 100,
    marginLeft: '60%',
  },
  containerCell: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  textField: {
    fontSize: 17,
  },
  body: {
    fontSize: 24,
    color: colors.selectedBlue,
    padding: 20,
    fontWeight: '600',
    maxWidth: '60%',
  },
  disclaimer: {
    fontSize: 13,
    color: colors.textMedium,
    paddingHorizontal: 20,
  },
  description: {
    fontSize: 17,
    color: colors.darkGrayBlue,
    paddingTop: 25,
    paddingHorizontal: 20,
  },
  buttonContainer: {
    marginTop: 15,
  },
  errorText: {
    color: colors.red,
  },
})

type Props = ParamsListAddPaymentMethod[ScreenName.AddNimoca]

interface IState {
  nimocaId: string
  error?: string
}

@observer
export class AddNimocaView extends Component<Props, IState> {
  private readonly loadingStore = new LoadingStore()

  constructor(props: Props) {
    super(props)
    this.state = {
      nimocaId: '',
    }
  }

  public textChanged(nimocaId: string) {
    this.setState({
      nimocaId,
      error: '',
    })
  }

  public handlePressConfirm = async () => {
    if (this.state.nimocaId.length !== NIMOCA_ID_LENGTH) {
      this.setState({
        error: st.screens.addNimoca.lengthWarning(),
      })
    } else {
      const fullNimocaId = `${NIMOCA_PREFIX}${this.state.nimocaId}`
      try {
        const paymentMethodId = await this.loadingStore.execute(PaymentMethodStore.createNimoca(fullNimocaId))
        if (paymentMethodId) {
          await this.props.onPaymentMethodSuccessfullyAdded(paymentMethodId)
        }
      } catch (e) {
        AlertHelper.alert(st.screens.addNimoca.alertTitle(), st.screens.addNimoca.alertDescription())
      }
    }
  }

  public render() {
    return (
      <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
        <View style={styles.cardViewContainer}>
          <View style={styles.cardImage}>
            <SvgIconWrapper widthFixed={188} heightFixed={124} icon={SvgIcon.NimocaCardBack} />
          </View>
          <View style={styles.nimocaMascotContainer}>
            <SvgIconWrapper widthFixed={111} heightFixed={167} icon={SvgIcon.NimocaMascot} />
          </View>
        </View>
        <Text style={styles.body}>{st.screens.addNimoca.body()}</Text>
        <Text style={styles.description}>{st.screens.addNimoca.creditRewardDescription()}</Text>
        <View style={styles.containerCell}>
          <TextField
            style={styles.textField}
            returnKeyType='done'
            autoCapitalize='characters'
            autoCorrect={false}
            keyboardType='numbers-and-punctuation'
            label={st.screens.addNimoca.placeholder()}
            tintColor={this.state.error ? colors.red : colors.primaryColor}
            value={this.state.nimocaId}
            onChangeText={(nimocaId) => this.textChanged(nimocaId)}
            prefix={NIMOCA_PREFIX}
          />
          <Text style={styles.errorText}>{this.state.error}</Text>
          <View style={styles.buttonContainer}>
            <PrimaryButton
              loading={this.loadingStore.isLoading()}
              title={st.screens.addNimoca.buttonTitle()}
              onPress={this.handlePressConfirm}
            />
          </View>
        </View>
        <Text style={styles.disclaimer}>{st.screens.addNimoca.nimocaArchiveDisclaimer()}</Text>
      </ScrollView>
    )
  }
}

export const AddNimoca = (props: ScreenPropsAddPaymentMethod<ScreenName.AddNimoca>) => (
  <AddNimocaView {...props.route.params} />
)
