import { registerRootComponent } from 'expo'
import { Platform } from 'react-native';
import App from './App'

registerRootComponent(App)

if (Platform.OS === 'web' && process.env.NODE_ENV !== 'production') {
  const React = require('react');
  const ReactDOM = require('react-dom');

  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}
