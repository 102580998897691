import 'react-native-browser-polyfill'
import * as Notifications from 'expo-notifications'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { LogBox, Text, TextInput } from 'react-native'
import { EventName } from 'src/helpers/AnalyticsHelper/AnalyticHelperTypes'
import { AnalyticsHelper } from 'src/helpers/AnalyticsHelper/AnalyticsHelper'
import { loadFontAwesomeIcons } from 'src/helpers/FontAwesomeHelper'
import { setupLongTimeoutWorkaround } from 'src/helpers/LongTimeoutWorkaround'
import { moment } from 'src/helpers/Moment'
import { Sentry } from 'src/helpers/sentry'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { MobxStoreManager } from 'src/stores/MobxStoreManager'
import { AuthenticatorHelper } from '../helpers/AuthenticatorHelper'

const setReactNativeDefaults = () => {
  ;(Text as any).defaultProps = (Text as any).defaultProps || {}
  ;(TextInput as any).defaultProps = (TextInput as any).defaultProps || {}
  ;(Text as any).defaultProps.allowFontScaling = false
  ;(TextInput as any).defaultProps.allowFontScaling = false
}

export enum InitState {
  Loading,
  NotLoggedIn,
  LoggedIn,
  NoConnection,
}

interface IHookData {
  initState: InitState
  isLoading: boolean
  retry: () => void
}

export const useInitHook = (): IHookData => {
  const [initState, setInitState] = useState(InitState.Loading)
  const [retryCount, setRetryCount] = useState<number>(0)
  const retry = () => setRetryCount((count) => count + 1)
  // track app load event
  useEffect(() => {
    AnalyticsHelper.track(EventName.AppLoad)
  }, [])
  // setup notification handler
  useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
      }),
    })
  })
  // run synchronously on startup
  useEffect(() => {
    // Set-up Sentry logging
    // eslint-disable-next-line no-undef
    if (!__DEV__) {
      Sentry.sentryInstall()
    }

    if (__DEV__) {
      // Hide pop-up warning notifications in dev environment as they are disruptive. Warning messages will still be logged to the console.
      // Uncaught errors will still open a fullscreen LogBox.
      LogBox.ignoreAllLogs()
    }

    setReactNativeDefaults()
    loadFontAwesomeIcons()
    moment.locale(LanguageHelper.getMomentLocale())
    setupLongTimeoutWorkaround()
  }, [])

  const hydrateStores = useAsync(async () => {
    await MobxStoreManager.hydrateMobxStores()
  }, [])

  const loadInitialData = useAsync(async () => {
    // when hydrateStore.status is NOT success we want to return false to NOT trigger chooseSuccessState accidentally
    // without this boolean flag loadInitialData.status will be 'success' during awaiting for hydrateStores
    // because 'if (hydrateStores.status === 'success')' will be false and loadInitialData is basically empty function always returning 'success' status
    if (hydrateStores.status === 'success') {
      await AuthenticatorHelper.fetchGlobalApp()
      await AuthenticatorHelper.refreshUserAndOrgData()
      return true
    }
    return false
  }, [retryCount, hydrateStores.status])

  const chooseSuccessState = () => {
    const { user, organization, userOrgToken } = AuthenticatorHelper
    if (user && organization && userOrgToken) {
      // keep sentry in-sync with who is logged in on the device
      Sentry.sentrySetUser(user.id, organization)
      const initState = user.phoneNumber ? InitState.LoggedIn : InitState.NotLoggedIn
      setInitState(initState)
    } else {
      setInitState(InitState.NotLoggedIn)
    }
  }

  useEffect(() => {
    // if wasLoaded is true in useAsync above meaning initial data was fetched
    if (loadInitialData.result) {
      chooseSuccessState()
    } else if (loadInitialData.status === 'error') {
      setInitState(InitState.NoConnection)
    }
  }, [loadInitialData.status, loadInitialData.result, loadInitialData.error?.message])

  return { initState, retry, isLoading: loadInitialData.loading }
}
