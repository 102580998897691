import { IEstimateOutput, IEstimateService } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { AccessibilityRole, Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { calculateDropoffTime, getDropoffTimeString } from 'src/helpers/EstimateHelper'
import { FareRenderer } from 'src/helpers/FareRenderer'
import { getPressInDelay } from 'src/helpers/RideOptionsCardHelper'
import { st } from 'src/locales'
import { IEstimateStore } from 'src/stores/EstimateStore'

const IMAGE_SIZE = 52

const styles = StyleSheet.create({
  bodyContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderColor: colors.borderBlue,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  selected: {
    borderLeftColor: colors.blue50,
    borderLeftWidth: 4,
    backgroundColor: colors.blue10,
  },
  deselected: {
    paddingLeft: 4,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  bodyTitle: {
    fontSize: 16,
    color: colors.gray90,
    fontWeight: '600',
  },
  bodySubtitle: {
    fontSize: 14,
    color: colors.gray70,
  },
  serviceIcon: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    backgroundColor: 'white',
    borderRadius: IMAGE_SIZE / 2,
  },
  dropoffServiceContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 8,
    paddingRight: 8,
  },
  fareContainer: {
    paddingHorizontal: 8,
    paddingTop: 8,
  },
  fareText: {
    fontSize: 16,
    color: colors.gray90,
  },
  disabled: {
    color: colors.gray50,
    fontSize: 16,
  },
})

interface IProps {
  service: IEstimateService
  selected: boolean
  handleSelectedService: (service: IEstimateService) => void
  estimateStore: IEstimateStore
  accessibilityRole?: AccessibilityRole
  accessibilityLabel?: string
  accessibilityHint?: string
}

@observer
export class RideOptionsCardServiceInfo extends Component<IProps> {
  public renderPrice(estimate: IEstimateOutput) {
    return (
      <View style={styles.fareContainer}>
        <Text style={styles.fareText}>{FareRenderer.renderPriceAmount(estimate)}</Text>
      </View>
    )
  }

  public renderServiceAndDropoff(service: IEstimateService, estimate: IEstimateOutput | null | undefined) {
    return (
      <View style={styles.dropoffServiceContainer}>
        <Text style={[styles.bodyTitle, estimate ? null : styles.disabled]}>{service.serviceName}</Text>
        {this.renderServiceSubtitle(service, estimate)}
      </View>
    )
  }

  public renderServiceSubtitle(service: IEstimateService, estimate: IEstimateOutput | null | undefined) {
    if (!estimate) {
      if (this.props.estimateStore.loadingStore.isLoading(service.serviceId)) {
        return <Text style={styles.bodySubtitle}>{st.components.rideOptionsCard.calculatingDropoff()}</Text>
      }
      return this.renderNoRidesAvailable(this.props.estimateStore.estimateErrorMap.get(service.serviceId)?.message)
    }

    const dropoffTimes = calculateDropoffTime(estimate)

    if (!dropoffTimes) {
      return this.renderNoRidesAvailable()
    }
    const { dropoffTimeHigh, dropoffTimeLow, dropoffTime } = dropoffTimes
    return (
      <Text style={styles.bodySubtitle}>
        {getDropoffTimeString(dropoffTimeLow, dropoffTimeHigh, dropoffTime, service.serviceShowPickupDropoffWindows)}
      </Text>
    )
  }

  public renderCardBody() {
    const { service, selected, accessibilityHint, accessibilityLabel, accessibilityRole } = this.props
    const estimate = this.props.estimateStore.estimateResponseMap.get(this.props.service.serviceId)

    return (
      <TouchableListItemWrapper
        onPress={() => this.props.handleSelectedService(service)}
        disabled={!estimate}
        delayPressIn={getPressInDelay()}
        accessibilityHint={accessibilityHint}
        accessibilityLabel={accessibilityLabel}
        accessibilityRole={accessibilityRole}
        accessibilityState={{ selected }}
      >
        <View style={[styles.infoContainer, selected ? styles.selected : styles.deselected]}>
          <View style={styles.bodyContainer}>
            <Image style={styles.serviceIcon} source={{ uri: service.serviceBrand.photoUrl }} />
            <View style={styles.container}>
              {this.renderServiceAndDropoff(service, estimate)}
              {estimate && this.renderPrice(estimate)}
            </View>
          </View>
        </View>
      </TouchableListItemWrapper>
    )
  }

  public render() {
    return this.renderCardBody()
  }

  private renderNoRidesAvailable(message?: string | undefined) {
    return <Text style={styles.disabled}>{message || st.components.rideOptionsCard.noRidesAvailable()}</Text>
  }
}
