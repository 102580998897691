import { IRequestRiderType, RiderType } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { CardIconAction } from 'src/components/cards/CardIconAction'
import { getNumRidersFromRiders } from 'src/helpers/EstimateHelper'
import { st } from 'src/locales'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { IEstimatesUserInputParsed } from 'src/types'

export interface IProps {
  handleNavigateEstimateRiderOptions: (params: ParamsListRoot[ScreenName.EstimateRiderOptions]) => void
  estimatesUserInput: IEstimatesUserInputParsed
  riders: RiderType[]
  maxRiders: number
  estimateInputStore: IEstimateInputStore
  isJourneySelected: boolean
  isDisabled?: boolean
}

const t = st.components.rideOptionsCardBody
const DEFAULT_NUM_RIDERS = 1

@observer
export class RidersControl extends Component<IProps> {
  public render() {
    const serviceRiderTypes: RiderType[] = this.props.riders ?? [RiderType.Adult]
    const defaultRiders: IRequestRiderType[] = [{ type: RiderType.Adult, count: DEFAULT_NUM_RIDERS }]
    return (
      <CardIconAction
        testID='ridersIconAction'
        icon='user-plus'
        accessibilityLabel={t.accessibilityLabelRiders()}
        accessibilityHint={t.accessibilityHintRiders()}
        title={this.getRidersTitle()}
        isDisabled={this.props.isJourneySelected || this.props.isDisabled}
        onPress={() =>
          this.props.handleNavigateEstimateRiderOptions({
            estimateInputStore: this.props.estimateInputStore,
            serviceRiderTypes,
            riders: this.props.estimateInputStore.getEstimateInput().riders || defaultRiders,
            maxRiders: this.props.maxRiders,
          })
        }
      />
    )
  }

  public getRidersTitle = (): string => {
    let numRiders = 1
    if (this.props.isJourneySelected) {
      return t.rider_plural()
    } else if (this.props.estimatesUserInput.riders) {
      numRiders = getNumRidersFromRiders(this.props.estimatesUserInput.riders)
    } else if (this.props.estimatesUserInput.numRiders) {
      numRiders = this.props.estimatesUserInput.numRiders
    }
    if (numRiders > 1) {
      return LanguageHelper.getFormattedCount(t.rider_plural(), numRiders)
    }
    return LanguageHelper.getFormattedCount(t.rider(), numRiders)
  }
}
