import React, { Component, ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

const styles = StyleSheet.create({
  header: {
    width: '100%',
    flex: 1,
    overflow: 'hidden',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
})

interface IProps {
  headerStyle?: StyleProp<ViewStyle>
  children?: ReactNode
}

export class BaseCardHeader extends Component<IProps> {
  public render() {
    return <View style={[styles.header, this.props.headerStyle]}>{this.props.children}</View>
  }
}
