import {
  IServiceResponse,
  IStopResponse,
  IZoneResponse,
  Resources,
  ServiceStatus,
  ServiceType,
} from '@sparelabs/api-client'
import { pick } from 'lodash'
import { action, observable, ObservableMap } from 'mobx'
import { persist } from 'mobx-persist'
import { handleError } from 'src/helpers/ErrorHelpers'
import { PagedDataHelper } from 'src/helpers/PagedDataHelper'

export type PartiallyStoredServiceResponse = Pick<
  IServiceResponse,
  'riderTypes' | 'accessibilityFeatures' | 'maxRiders' | 'showDriverProfileToRiders' | 'tipPolicyId' | 'enableTipping'
>
export interface IServiceStore {
  servicesMap: Map<string, PartiallyStoredServiceResponse>
}
class ServiceStoreClass {
  public name = 'ServiceStore'
  @observable
  @persist('map')
  public servicesMap: ObservableMap<string, PartiallyStoredServiceResponse> = observable.map()

  @observable
  @persist('map')
  public zonesMap: ObservableMap<string, Pick<IZoneResponse, 'area'>> = observable.map()

  @observable
  @persist('object')
  public stops: IStopResponse[] = []

  @observable
  @persist('object')
  public isSchedulingAllowed: boolean = false

  @observable
  @persist('object')
  public isCashEnabled: boolean = false

  @observable
  @persist('object')
  public isPoolingEnabled: boolean = false

  @action
  public update = async () => {
    try {
      await this.retrieveServices()
      await this.retrieveZones()
      await this.retrieveStops()
    } catch (error: any) {
      handleError({ error })
    }
  }

  @action
  public clear() {
    this.servicesMap = observable.map()
    this.zonesMap = observable.map()
    this.stops = []
    this.isSchedulingAllowed = false
    this.isCashEnabled = false
    this.isPoolingEnabled = false
  }

  private areMultipleActiveServices() {
    return Array.from(this.servicesMap.keys()).length > 1
  }

  private async retrieveServices() {
    const resServices = await PagedDataHelper.getDataWithPagination<IServiceResponse>(Resources.Services, {
      type: ServiceType.OnDemand,
      status: ServiceStatus.Enabled,
    })

    const nowDate = new Date().valueOf()

    if (resServices) {
      const newServicesMap = observable.map<
        string,
        Pick<
          IServiceResponse,
          | 'riderTypes'
          | 'accessibilityFeatures'
          | 'maxRiders'
          | 'showDriverProfileToRiders'
          | 'tipPolicyId'
          | 'enableTipping'
        >
      >()

      /**
       * Only add services which are not past their end date, we do this check using Date instead of moment
       * since it is more performant and some organizations have many services.
       *
       * endDate is inclusive, therefore we set the time on the endDate to be 23:59:59 so that the service
       * ends at the end of the day rather than at the start of the day.
       */
      const servicesArray: IServiceResponse[] = resServices.filter(
        (s: IServiceResponse) => nowDate <= new Date(s.endDate).setUTCHours(23, 59, 59).valueOf()
      )

      for (const service of servicesArray) {
        // Important: We are only storing what is relevant to avoid large writes to disk due to mobx-persist!
        newServicesMap.set(
          service.id,
          pick(service, [
            'riderTypes',
            'accessibilityFeatures',
            'maxRiders',
            'showDriverProfileToRiders',
            'tipPolicyId',
            'enableTipping',
          ])
        )
      }

      // Set the map in the store, this is done as an atomic operation to reduce the number of writes and improve performance
      this.servicesMap = newServicesMap

      // Set the booleans for service configuration here rather than when they are used to improve performance
      this.isSchedulingAllowed = servicesArray.some(
        (s: IServiceResponse) => s.allowScheduledLeaveAtBooking || s.allowScheduledArriveByBooking
      )
      this.isCashEnabled = servicesArray.some((s: IServiceResponse) => s.isCashEnabled)
      this.isPoolingEnabled = servicesArray.some((s: IServiceResponse) => s.enablePooling)
    }
  }

  private async retrieveZones() {
    const zonesQuery = {
      serviceType: ServiceType.OnDemand,
      serviceStatus: ServiceStatus.Enabled,
      isVisibleToRiderApp: true,
      ...(this.areMultipleActiveServices()
        ? { serviceIds: Array.from(this.servicesMap.keys()) }
        : { serviceId: this.servicesMap.keys() }),
    }

    const resZones = await PagedDataHelper.getDataWithPagination<IZoneResponse>(Resources.Zones, zonesQuery)

    if (resZones) {
      const newZonesMap = observable.map<string, Pick<IZoneResponse, 'area'>>()

      for (const zone of resZones) {
        // Important: We are only storing what is relevant to avoid large writes to disk due to mobx-persist!
        newZonesMap.set(zone.id, pick(zone, ['area']))
      }
      // Set the map in the store, this is done as an atomic operation to reduce the number of writes and improve performance
      this.zonesMap = newZonesMap
    }
  }

  private async retrieveStops() {
    const resStops = await PagedDataHelper.getDataWithPagination<IStopResponse>(Resources.Stops, {
      serviceType: ServiceType.OnDemand,
      serviceStatus: ServiceStatus.Enabled,
      orderBy: 'code',
      orderDirection: 'ASC',
      isVisibleToRiderApp: true,
      ...(this.areMultipleActiveServices()
        ? { serviceIds: Array.from(this.servicesMap.keys()) }
        : { serviceId: this.servicesMap.keys() }),
    })
    if (resStops) {
      // Only add stops for active zones
      this.stops = resStops.filter((stop: IStopResponse) => this.zonesMap.get(stop.zoneId))
    }
  }
}

export const ServiceStore = new ServiceStoreClass()
