import { IFarePassAllocationResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { FarePassIcon } from 'src/components/farePasses/FarePassIcon'
import { ISettingsListItem, SettingsList, SettingsListItemType } from 'src/components/settings/SettingsList'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { FarePassScreenStateType, ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { FarePassStore } from 'src/stores/FarePassStore'

type Props = ParamsListAccount[ScreenName.FarePassHistoryList] & {
  handleNavigateViewFarePass: (params: ParamsListAccount[ScreenName.ViewFarePass]) => void
}

@observer
export class FarePassHistoryListView extends Component<Props> {
  public componentDidMount = async () => {
    await FarePassStore.updateInactiveFarePassAllocations()
  }

  public historySettingsListData(): ISettingsListItem[] {
    const historySettingsList: ISettingsListItem[] = []
    // Create a settings list for the fare passes
    for (const [, allocation] of FarePassStore.inactiveFarePassAllocations) {
      historySettingsList.push({
        id: allocation.id,
        title: allocation.farePass.name,
        subTitle: this.getValidDates(allocation),
        iconComponent: <FarePassIcon color={allocation.farePass.color} />,
        type: SettingsListItemType.List,
      })
    }

    return historySettingsList
  }

  public getValidDates(allocation: IFarePassAllocationResponse): string {
    return `${moment.unix(allocation.createdAt).format('LL')} • ${st.screens.farePassHistoryList[allocation.status]()}`
  }

  public handleViewPass = async (allocationId: string) => {
    const allocation = FarePassStore.getFarePassAllocation(allocationId)
    this.props.handleNavigateViewFarePass({
      farePassName: allocation.farePass.name,
      state: { type: FarePassScreenStateType.FarePassAllocation, allocationId },
    })
  }

  public render() {
    return <SettingsList data={this.historySettingsListData()} onPressItem={this.handleViewPass} />
  }
}

export const FarePassHistoryList = (props: ScreenPropsAccount<ScreenName.FarePassHistoryList>) => (
  <FarePassHistoryListView
    {...props.route.params}
    handleNavigateViewFarePass={(params) => props.navigation.navigate(ScreenName.ViewFarePass, params)}
  />
)
