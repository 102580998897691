import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Platform } from 'react-native'
import { ISettingsListItem, SettingsList, SettingsListItemType } from 'src/components/settings/SettingsList'
import { VersionFooter } from 'src/components/VersionFooter'
import { ParamsListDeveloperMenu, ScreenName, ScreenPropsDeveloperMenu } from 'src/navigation'
import { OsType } from 'src/util/types'

enum DeveloperMenuSettings {
  HostSelect = 'HostSelect',
  TriggerError = 'TriggerError',
  ReviewerLogin = 'ReviewerLogin',
}

type Props = ParamsListDeveloperMenu[ScreenName.DeveloperMenu] & {
  handleNavigateHostSelect: () => void
  handleNavigateReviewerLogin: () => void
}

@observer
export class DeveloperMenuView extends Component<Props> {
  public handlePressItem = async (id: DeveloperMenuSettings) => {
    switch (id) {
      case DeveloperMenuSettings.HostSelect:
        this.props.handleNavigateHostSelect()
        return true
      case DeveloperMenuSettings.TriggerError:
        this.triggerError()
        return true
      case DeveloperMenuSettings.ReviewerLogin:
        this.props.handleNavigateReviewerLogin()
        return true
      default:
        return false
    }
  }

  public getSettingsListData = (): ISettingsListItem[] => {
    const settingsListData: ISettingsListItem[] = [
      {
        id: DeveloperMenuSettings.HostSelect,
        title: 'Host Settings',
        withHeader: true,
        type: SettingsListItemType.List,
      },
      {
        id: DeveloperMenuSettings.TriggerError,
        title: 'Trigger Unhandled Error',
        withHeader: true,
        type: SettingsListItemType.Action,
      },
    ]

    if (Platform.OS !== OsType.Web) {
      settingsListData.push({
        id: DeveloperMenuSettings.ReviewerLogin,
        title: 'Reviewer Login',
        withHeader: true,
        type: SettingsListItemType.Action,
      })
    }

    return settingsListData
  }

  public render() {
    return (
      <SettingsList<DeveloperMenuSettings>
        data={this.getSettingsListData()}
        onPressItem={this.handlePressItem}
        listFooterComponent={<VersionFooter />}
      />
    )
  }

  private readonly triggerError = () => {
    throw new Error('This is an unhandled test error triggered from Developer Menu')
  }
}

export const DeveloperMenu = (props: ScreenPropsDeveloperMenu<ScreenName.DeveloperMenu>) => (
  <DeveloperMenuView
    {...props.route.params}
    handleNavigateHostSelect={() => props.navigation.navigate(ScreenName.DeveloperHostSelect, {})}
    handleNavigateReviewerLogin={() => props.navigation.navigate(ScreenName.DeveloperReviewerLogin, {})}
  />
)
