import React from 'react'
import { ActivityIndicator, Image, SafeAreaView, StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { WhitelabelHelper } from 'src/helpers/WhitelabelHelper'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loginContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  bottomContainer: {
    flex: 1,
    margin: 16,
    marginBottom: 80,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoContainer: {
    marginTop: 80,
    width: 250,
    height: 200,
  },
  loadingSpinner: {
    width: 27,
    height: 27,
  },
  updateText: {
    marginBottom: 16,
    color: colors.gray90,
    fontSize: 16,
  },
})

export const Loading = () => (
  <SafeAreaView style={styles.container}>
    <View style={styles.loginContainer}>
      <Image resizeMode='contain' style={styles.logoContainer} source={WhitelabelHelper.getLogoSource()} />
      <View style={styles.bottomContainer}>
        <ActivityIndicator style={styles.loadingSpinner} color={colors.black} size='small' />
      </View>
    </View>
  </SafeAreaView>
)
