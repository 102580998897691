import { ILastVehicleLocationResponse, IRequestResponse, SlotType } from '@sparelabs/api-client'
import { IPoint } from '@sparelabs/geography'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ErrorBoundary } from 'src/components/error/ErrorBoundary'
import { DrivingPolyline } from 'src/components/mapMarkers/DrivingPolyline'
import { DropoffLabelMarker } from 'src/components/mapMarkers/DropoffLabelMarker'
import { DropoffPinMarker } from 'src/components/mapMarkers/DropoffPinMarker'
import { EndLabelMarker } from 'src/components/mapMarkers/EndLabelMarker'
import { EndPinMarker } from 'src/components/mapMarkers/EndPinMarker'
import { MapServiceAreas } from 'src/components/mapMarkers/MapServiceAreas'
import { PickupLabelMarker } from 'src/components/mapMarkers/PickupLabelMarker'
import { PickupPinMarker } from 'src/components/mapMarkers/PickupPinMarker'
import { StartPinMarker } from 'src/components/mapMarkers/StartPinMarker'
import { VehicleMarker } from 'src/components/mapMarkers/VehicleMarker'
import { WalkingPolyline } from 'src/components/mapMarkers/WalkingPolyline'
import { WalkingPolylineInfoBuilder } from 'src/components/mapMarkers/WalkingPolylineInfoBuilder'
import { geoJsonToCoords } from 'src/helpers/MapHelper'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { ActiveRequestVehicleLocationStore } from 'src/stores/ActiveRequestVehicleLocationStore'
import { MapStore } from 'src/stores/MapStore'
import { IMarkerCoordinates } from 'src/types'
import { RequestStatus } from 'src/util/types'

interface IProps {
  request: IRequestResponse
}

@observer
export class RequestMap extends Component<IProps> {
  public componentDidMount() {
    this.fitMapToRequestMarkers()
  }

  public componentDidUpdate() {
    const request = this.props.request
    const vehicleLocation = this.getVehicleLocation()
    if (request && vehicleLocation) {
      this.fitMapToRequestMarkers()
      if (request.status === RequestStatus.InProgress && MapStore.showUserLocation) {
        MapStore.setShowUserLocation(false)
      } else if (request.status !== RequestStatus.InProgress && !MapStore.showUserLocation) {
        MapStore.setShowUserLocation(true)
      }
    }
  }

  public fitMapToRequestMarkers = () => {
    const request = this.props.request
    const vehicleLocation = this.getVehicleLocation()
    if (request) {
      const coordinates: IMarkerCoordinates[] = MapMarkerHelper.getPickupMarkerCoordinates(request)
      if (ActiveRequestVehicleLocationStore.shouldBeShowingVL(request) && vehicleLocation && vehicleLocation.location) {
        coordinates.push(geoJsonToCoords(vehicleLocation.location))
      }
      MapStore.fitMapToCoordinates(coordinates, {
        edgePadding: MapMarkerHelper.getEdgePadding(),
        animated: true,
      })
    }
  }

  public render() {
    const request = this.props.request
    const vehicleLocation = this.getVehicleLocation()

    if (!request) {
      return null
    }

    const locations = MapMarkerHelper.getAllCoordinates(request)
    const pickupLocation: IPoint = MapMarkerHelper.getPickupLocation(request)
    const dropoffLocation: IPoint = MapMarkerHelper.getDropoffLocation(request)
    const showEndLocation: boolean = MapMarkerHelper.showEndLocation(request)
    /* On web, the order of polylines and markers determine which gets rendered on top of one another */
    return (
      <>
        <ErrorBoundary>
          <MapServiceAreas hideStops={true} />
        </ErrorBoundary>
        <ErrorBoundary>
          <DrivingPolyline
            pickupLocation={pickupLocation}
            dropoffLocation={dropoffLocation}
            renderPolyline={request.scheduledDropoffLocation !== null || request.scheduledPickupLocation !== null}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <WalkingPolyline walkingInfo={WalkingPolylineInfoBuilder.build(request, SlotType.Pickup, false)} />
        </ErrorBoundary>
        <ErrorBoundary>
          <WalkingPolyline walkingInfo={WalkingPolylineInfoBuilder.build(request, SlotType.Dropoff, false)} />
        </ErrorBoundary>
        <ErrorBoundary>
          {MapMarkerHelper.showStartLocation(request) && <StartPinMarker location={request.requestedPickupLocation} />}
        </ErrorBoundary>
        <ErrorBoundary>{showEndLocation && <EndPinMarker location={request.requestedDropoffLocation} />}</ErrorBoundary>
        <ErrorBoundary>
          <PickupPinMarker location={pickupLocation} />
        </ErrorBoundary>
        <ErrorBoundary>
          <DropoffPinMarker location={dropoffLocation} />
        </ErrorBoundary>
        {vehicleLocation && (
          <ErrorBoundary>
            <VehicleMarker request={request} vehicleLocation={vehicleLocation} />
          </ErrorBoundary>
        )}
        <ErrorBoundary>
          {!showEndLocation && <DropoffLabelMarker coordinates={locations} location={dropoffLocation} />}
        </ErrorBoundary>
        <ErrorBoundary>
          <PickupLabelMarker location={pickupLocation} coordinates={locations} scheduledPickupTime={null} />
        </ErrorBoundary>
        <ErrorBoundary>
          {showEndLocation && (
            <EndLabelMarker
              location={request.requestedDropoffLocation}
              coordinates={MapMarkerHelper.getAllCoordinates(request)}
            />
          )}
        </ErrorBoundary>
      </>
    )
  }

  private getVehicleLocation(): ILastVehicleLocationResponse | null {
    return ActiveRequestVehicleLocationStore.lastVL
  }
}
