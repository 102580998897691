import { IRequestInsightsResponse } from '@sparelabs/api-client'
import { action, makeObservable, observable, ObservableMap } from 'mobx'
import { persist } from 'mobx-persist'
import { getRequestInsights } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'

export class RequestInsightsStoreClass {
  public name = 'RequestInsights'

  @persist('map')
  @observable
  public requestInsightsMap: ObservableMap<string, IRequestInsightsResponse> = observable.map()

  public getRequestInsights = async (requestId: string): Promise<IRequestInsightsResponse | null> => {
    const requestInsightsResponse: IRequestInsightsResponse | undefined = this.requestInsightsMap.get(requestId)
    if (requestInsightsResponse) {
      return requestInsightsResponse
    }
    let requestInsight: IRequestInsightsResponse | null = null
    try {
      if (AuthenticatorHelper.userOrgToken) {
        requestInsight = await getRequestInsights(requestId)
        if (requestInsight) {
          this.requestInsightsMap.set(requestId, requestInsight)
        }
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      // Only handle non-404 errors, 404 errors would mean the request's insights have not been calculated
      if (error && error.status !== 404) {
        handleError({ error, silent: true })
      }
    }
    return requestInsight
  }

  public getInsightsFromMap = (requestId: string) => this.requestInsightsMap.get(requestId)

  constructor() {
    makeObservable(this)
  }

  @action
  public clear() {
    this.requestInsightsMap = observable.map()
  }
}

export const RequestInsightsStore = new RequestInsightsStoreClass()
