import { buildPoint, IPoint } from '@sparelabs/geography'
import * as Location from 'expo-location'
import { LocationObject } from 'expo-location'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { Linking, Platform } from 'react-native'
import { Constants } from 'src/consts/Constants'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { st } from 'src/locales'
import { OsType } from 'src/util/types'

export class LocationStoreClass {
  constructor() {
    makeObservable(this)
  }

  public name: string = 'LocationStore'
  @persist('object')
  @observable
  public currentLocation: Location.LocationObject | null = null

  @persist('object')
  @observable
  private locationPermissionResult: Location.LocationPermissionResponse | null = null

  private watchPromise: { remove: () => void } | null = null
  private locationTimer: NodeJS.Timeout | null = null

  public isGranted = (): boolean => this.locationPermissionResult?.granted ?? false

  public permissionsDenied = async (): Promise<boolean> => {
    await this.getLocationPermission()
    return !this.locationPermissionResult?.granted
  }

  public getCurrentLocationWithPermission = (): Location.LocationObject | null => {
    if (this.currentLocation && this.isGranted()) {
      return this.currentLocation
    }
    return null
  }

  public getCurrentLocationPointWithPermission = (): IPoint | null => {
    const currentLocation = this.getCurrentLocationWithPermission()
    if (currentLocation) {
      return buildPoint(currentLocation.coords.longitude, currentLocation.coords.latitude)
    }
    return null
  }

  @action
  public stopTimers = () => {
    if (this.locationTimer) {
      clearTimeout(this.locationTimer)
    }
    if (this.watchPromise) {
      this.watchPromise.remove()
      this.watchPromise = null
    }
  }

  @action
  public clear() {
    this.stopTimers()
    this.locationPermissionResult = null
    this.currentLocation = null
  }

  @action
  public async getLocationPermission() {
    const permissions = await Location.getForegroundPermissionsAsync()
    runInAction(() => {
      this.locationPermissionResult = permissions
    })
  }

  @action
  public requestLocationPermission = async () => {
    const permissionsResult = await Location.requestForegroundPermissionsAsync()
    runInAction(() => {
      this.locationPermissionResult = permissionsResult
    })
  }

  @action
  private readonly requestPermissionAndLocation = async (): Promise<void> => {
    await this.getLocationPermission()
    await this.getCurrentLocation()
  }

  @action
  public async startLocationAndPermissionWatch(): Promise<void> {
    // Immediately get current location and permissions
    await this.requestPermissionAndLocation()
    // Poll for updates every 5 seconds
    await this.recursiveLocationTimer()
  }

  // We have to implement our own version of setInterval to
  // ensure that execution duration is shorter than interval frequency
  // see comment below for details.
  // https://developer.mozilla.org/en-US/docs/Web/API/setInterval#ensure_that_execution_duration_is_shorter_than_interval_frequency
  @action
  private readonly recursiveLocationTimer = async () => {
    this.locationTimer = setTimeout(async () => {
      await this.requestPermissionAndLocation()
      await this.recursiveLocationTimer()
    }, 5000)
  }

  public readonly tryToDirectToSettings = async () => {
    if (Platform.OS === OsType.iOS) {
      // User is not capable of granting this permission. Edge case. Open settings
      await Linking.openURL('app-settings:')
    }
  }

  public getLocationPoint = async (): Promise<Location.LocationObject> => Location.getCurrentPositionAsync()

  /* 3 Types of Permissions Available:
  - granted: we're allowed to use location
  - denied: we're not currently allowed but we can request again
  - blocked: we're not currently allowed and we can't request
   */
  @action
  public async noLocationPermissionAlert() {
    if (!this.locationPermissionResult?.canAskAgain) {
      if (Platform.OS === OsType.iOS) {
        AlertHelper.alert(
          st.screens.permissions.alertTitle(),
          st.screens.permissions.featurePermissionMessage({ appName: Constants.APP_NAME }),
          [
            { text: st.screens.permissions.openSettings(), onPress: () => this.tryToDirectToSettings() },
            { text: st.common.buttonCancel() },
          ]
        )
      } else {
        // android doesn't have functionality to link to settings
        AlertHelper.alert(
          st.screens.permissions.alertTitle(),
          st.screens.permissions.featurePermissionMessage({ appName: Constants.APP_NAME }),
          [{ text: st.common.alertOk() }]
        )
      }
    } else if (!this.locationPermissionResult.granted) {
      // request permissions
      await this.requestLocationPermission()
    }
  }

  @action
  private setCurrentLocation(position: LocationObject) {
    this.currentLocation = position
  }

  private async getLocation() {
    const locationPoint = await Location.getCurrentPositionAsync()
    this.setCurrentLocation(locationPoint)
  }

  private async startWatchPosition() {
    const watchPromise = await Location.watchPositionAsync(
      {
        accuracy: Location.Accuracy.High,
      },
      this.setCurrentLocation.bind(this)
    )
    runInAction(() => {
      this.watchPromise = watchPromise
    })
  }

  private async getCurrentLocation() {
    if (this.watchPromise === null && this.locationPermissionResult?.granted) {
      try {
        await this.getLocation()
        await this.startWatchPosition()
      } catch (error) {
        handleError({ error: error as Error })
      }
    }
  }
}

export const LocationStore = new LocationStoreClass()
