import { observer } from 'mobx-react-lite'
import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import {
  PaymentMethodHelper,
  PaymentMethodItem,
  VirtualConnectedAccountType,
} from 'src/helpers/payments/PaymentMethodHelper'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    marginBottom: 1,
  },
  bodyContainer: {
    flex: 1,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 70,
    flexBasis: 'auto',
  },
  bodyTitleText: {
    fontSize: 16,
    color: colors.textDark,
  },
  defaultSubtitle: {
    fontSize: 14,
    color: colors.textMedium,
  },
  disabledText: {
    fontSize: 14,
    color: colors.textMedium,
  },
  imageStyle: {
    width: 50,
    height: 30,
    marginRight: 15,
  },
  titleContainer: {
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
  },
})

export interface IPaymentMethodEntryProps {
  paymentMethod: PaymentMethodItem
  disabled: boolean
  selectedPaymentMethodId?: string | null
  onSelectPaymentMethod: (paymentMethodId: string | null) => void
  indexInList: number
  numberOfOptions: number
  showSelected?: boolean
}

export const PaymentMethodEntry = observer(
  ({
    paymentMethod,
    disabled,
    selectedPaymentMethodId,
    onSelectPaymentMethod,
    indexInList,
    numberOfOptions,
    showSelected,
  }: IPaymentMethodEntryProps): JSX.Element => {
    const isDefaultCash =
      !selectedPaymentMethodId && paymentMethod.connectedAccountType === VirtualConnectedAccountType.Cash
    const selected = showSelected && (isDefaultCash || paymentMethod.id === selectedPaymentMethodId) && !disabled

    const renderIcon = (paymentMethod: PaymentMethodItem) => {
      const iconComponent = PaymentMethodHelper.getIconComponentForPaymentMethod(paymentMethod)
      if (iconComponent) {
        return <View style={styles.imageStyle}>{iconComponent}</View>
      }

      const iconImage = PaymentMethodHelper.getIconForPaymentMethod(paymentMethod)
      if (iconImage) {
        return <Image resizeMode='contain' source={iconImage} style={styles.imageStyle} />
      }

      return null
    }

    const renderSelectedIcon = () => (
      <FontAwesomeIconWrapper icon='check' color={colors.selectedBlue} size={17} testID='selectedMethod' />
    )

    const onPaymentMethodPress = (paymentMethod: PaymentMethodItem) => {
      if (!disabled) {
        onSelectPaymentMethod(paymentMethod.id)
      }
    }

    const renderBody = () => {
      const defaultText = paymentMethod.default ? (
        <Text style={styles.defaultSubtitle}>{st.screens.paymentMethods.defaultSubtitle()}</Text>
      ) : null
      const selectedCheckMark = selected ? renderSelectedIcon() : null
      return (
        <View style={styles.bodyContainer}>
          {renderIcon(paymentMethod)}
          <View style={styles.titleContainer}>
            <Text style={styles.bodyTitleText}>{PaymentMethodHelper.getShortPaymentMethodName(paymentMethod)}</Text>
            {defaultText}
          </View>
          {selectedCheckMark}
          {disabled && <Text style={styles.disabledText}>{st.screens.paymentMethods.notAvailable()}</Text>}
        </View>
      )
    }

    return (
      <TouchableListItemWrapper
        style={styles.container}
        onPress={() => onPaymentMethodPress(paymentMethod)}
        accessibilityRole='menuitem'
        accessibilityHint={`${indexInList + 1} ${st.common.outOf()} ${numberOfOptions}`}
        accessibilityState={{ selected }}
      >
        {renderBody()}
      </TouchableListItemWrapper>
    )
  }
)
