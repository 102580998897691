import React from 'react'
import {
  ActivityIndicator,
  Image,
  ImageRequireSource,
  ImageStyle,
  ImageURISource,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    backgroundColor: 'transparent',
  },
  cell: {
    backgroundColor: 'white',
    height: 70,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexBasis: 'auto',
  },
  image: {
    width: 17,
    height: 17,
  },
  titleImage: {
    alignSelf: 'stretch',
    height: 15,
    width: 15,
    marginRight: 16,
  },
  rightArrow: {
    margin: 16,
    marginLeft: 'auto',
    ...(Platform.OS === OsType.Web && { width: 8, height: 13 }),
  },
  title: {
    fontSize: 18,
    color: colors.textDark,
    marginHorizontal: 16,
    whiteSpace: 'wrap',
  },
  subTitle: {
    fontSize: 14,
    color: colors.textMedium,
    marginHorizontal: 16,
  },
  cellRightIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellLeftIcon: {
    marginLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellText: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
})

interface IProps {
  onPressItem: (id: string) => Promise<void>
  loading?: boolean
  selected?: boolean
  image?: ImageRequireSource | ImageURISource
  titleImage?: ImageRequireSource
  iconComponent?: JSX.Element
  id: string
  withHeader?: boolean
  title: string
  subTitle?: string
  titleColor?: string
  imageStyle?: StyleProp<ImageStyle>
  testID?: string
}

export const SettingsListItem = (props: IProps) => {
  const renderLoader = () => {
    if (props.loading && props.selected) {
      return <ActivityIndicator color='black' style={styles.rightArrow} />
    }
    return null
  }

  const renderIcon = () => {
    if (props.iconComponent) {
      return props.iconComponent
    }
    if (props.image) {
      return <Image style={[styles.image, props.imageStyle]} resizeMode='contain' source={props.image} />
    }

    return null
  }

  const renderTitleImage = () => {
    if (props.titleImage) {
      return (
        <View>
          <Image style={styles.titleImage} resizeMode='contain' source={props.titleImage} />
        </View>
      )
    }
    return null
  }

  const renderTitle = () => {
    const titleColorOverride = props.titleColor ? { color: props.titleColor } : {}
    return (
      <View style={{ flex: 1 }}>
        <Text numberOfLines={1} style={[styles.title, titleColorOverride]}>
          {props.title}
        </Text>
        {props.subTitle ? <Text style={styles.subTitle}>{props.subTitle}</Text> : null}
      </View>
    )
  }

  return (
    <View style={props.withHeader ? styles.container : null} accessible={true}>
      <TouchableListItemWrapper
        disabled={props.loading}
        style={styles.cell}
        onPress={async () => {
          await props.onPressItem(props.id)
        }}
      >
        {(props.iconComponent || props.image) && <View style={styles.cellLeftIcon}>{renderIcon()}</View>}
        <View style={styles.cellText}>
          {renderTitleImage()}
          {renderTitle()}
        </View>
        <View style={styles.cellRightIcon}>
          {renderLoader()}
          <Image style={styles.rightArrow} resizeMode='contain' source={Images.rightArrow} />
        </View>
      </TouchableListItemWrapper>
    </View>
  )
}
