import { ICustomFieldChoice } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { ChoiceLabel, ChoiceRow, ChoicesContainer, FieldModalWrapper, ModalHint } from 'src/components/form/ModalShared'
import { RadioButton } from 'src/components/RadioButton'
import { st } from 'src/locales'
import { ScreenName, ScreenPropsRoot } from 'src/navigation'

const getStyles = () =>
  StyleSheet.create({
    safeArea: {
      flex: 1,
    },
    container: {
      flex: 1,
      backgroundColor: colors.white,
    },
    title: {
      paddingTop: 16,
      color: colors.gray90,
      fontSize: 21,
      lineHeight: 24,
      fontWeight: 'bold',
      paddingHorizontal: 16,
    },
  })

interface IProps {
  choices: ICustomFieldChoice[]
  selected: string | undefined
  onSubmit: (selected: string) => void
  hint?: string
  title?: string
  isRequired?: boolean
  addWrapper?: boolean
  handleDismiss?: () => void
}

interface IState {
  selected: string
}

export class SingleChoiceView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { selected: props.selected ?? props.choices[0].value }
  }

  public render() {
    // Only need to wrap with SafeAreaView if the view is its own screen
    if (this.props.addWrapper) {
      return <FieldModalWrapper>{this.renderSingleChoiceView()}</FieldModalWrapper>
    }
    return this.renderSingleChoiceView()
  }

  private readonly renderSingleChoiceView = () => {
    const styles = getStyles()
    const { hint, title, onSubmit, choices, isRequired, handleDismiss } = this.props
    return (
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.container}>
          <ModalHint hint={hint} />
          {title && (
            <Text style={styles.title}>
              {title}
              {isRequired && <Text style={[styles.title, { color: colors.red50 }]}> *</Text>}
            </Text>
          )}
          <ChoicesContainer>
            {choices.map((choice) => (
              <ChoiceRow key={choice.value} onPress={() => this.onPressOption(choice)}>
                <RadioButton selected={this.state.selected === choice.value} />
                <ChoiceLabel>{choice.label}</ChoiceLabel>
              </ChoiceRow>
            ))}
          </ChoicesContainer>
        </View>
        {handleDismiss && (
          <ButtonWrapper>
            <PrimaryButton
              title={st.common.done()}
              onPress={async () => {
                onSubmit(this.state.selected)
                handleDismiss()
              }}
            />
          </ButtonWrapper>
        )}
      </SafeAreaView>
    )
  }

  private readonly onPressOption = (choice: ICustomFieldChoice) => {
    this.setState({ selected: choice.value })
    if (!this.props.handleDismiss) {
      // Submit value each time
      this.props.onSubmit(choice.value)
    }
  }
}

export const SingleChoiceModal = (props: ScreenPropsRoot<ScreenName.SingleChoiceModal>) => (
  <SingleChoiceView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
