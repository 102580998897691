import React, { Component, ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

const styles = StyleSheet.create({
  cardHolder: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
})

interface IProps {
  containerStyle?: StyleProp<ViewStyle>
  testID?: string
  children?: ReactNode
}

export class BottomSheetWrapper extends Component<IProps> {
  public render() {
    return (
      <View style={[styles.cardHolder, this.props.containerStyle]} pointerEvents='box-none' testID={this.props.testID}>
        {this.props.children}
      </View>
    )
  }
}
