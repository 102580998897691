import { PhoneNumberInput } from '@sparelabs/web-ui-components'
import { uniqueId } from 'lodash'
import React from 'react'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'
import styled from 'styled-components'
import { IPhoneInput, IPhoneInputProps } from './PhoneInputTypes'

const StyledLabel = styled.label`
  font-size: 15;
  color: ${colors.blueAlt50};
  margin-bottom: 4;
`

const StyledDiv = styled.div`
  margin-bottom: 20px;
  z-index: 1;
`

export const PhoneInput: IPhoneInput = (props: IPhoneInputProps) => {
  const id = uniqueId()
  return (
    <StyledDiv>
      <StyledLabel htmlFor={id}>{st.screens.setPhoneNumber.phoneNumberLabel()}</StyledLabel>
      <PhoneNumberInput
        value={props.phoneNumber}
        onChange={props.handleChangePhoneNumber}
        autoFocus={props.textProps.autoFocus}
        id={id}
      />
    </StyledDiv>
  )
}
