import { observer } from 'mobx-react'
import React, { Component, ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { ROUTER_CONTENT_MAX_WIDTH } from 'src/screens/HomeRootHelper'
import { UIStateStore } from 'src/stores/UIStore'

const styles = StyleSheet.create({
  cardHolder: {
    flex: 1,
    position: 'absolute',
    width: '100%',
    bottom: 8,
    flexDirection: 'column',
  },
  cardHolderWeb: {
    top: 0,
    maxWidth: ROUTER_CONTENT_MAX_WIDTH,
  },
})

interface IProps {
  containerStyle?: StyleProp<ViewStyle>
  children?: ReactNode
}

@observer
export class CardWrapper extends Component<IProps> {
  public render() {
    return (
      <View
        style={[
          styles.cardHolder,
          this.props.containerStyle,
          UIStateStore.shouldShowLandscapeWeb ? styles.cardHolderWeb : {},
        ]}
        pointerEvents='box-none'
      >
        {this.props.children}
      </View>
    )
  }
}
