import { IRequestResponse } from '@sparelabs/api-client'
import { FeatureFlag } from '@sparelabs/feature-flags'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { StyleSheet } from 'react-native'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { TipHelper } from 'src/helpers/TipHelper'
import { st } from 'src/locales'
import { ParamsListReview, ScreenName } from 'src/navigation'
import { RequestInsightsStore } from 'src/stores/RequestInsightsStore'
import { RouterStore } from 'src/stores/RouterStore'
import { TipStore } from 'src/stores/TipStore'
import { Pathname } from 'src/types/homeRoot'
import { HomeInfoBar } from './HomeInfoBar'

const styles = StyleSheet.create({
  infoBar: {
    marginBottom: 5,
  },
})

interface IProps {
  handleNavigateReview: (params: ParamsListReview[ScreenName.Review]) => void
  handleNavigateTip: (params: ParamsListReview[ScreenName.AddTip]) => void
  completedRequest: IRequestResponse
  isSingleBar: boolean
}

/**
 * This component is responsible for two things:
 * 1. Showing a bar on the home screen to prompt the user to review their last trip
 * 2. Forcing a review/tipping modal to open if tipping is enabled on the service and the user has not yet tipped
 */
export const CompletedRequestInfoBar = observer((props: IProps) => {
  const [isTippingPossible, setIsTippingPossible] = useState(false)
  const isTipInfoLoaded = TipStore.getTipFromMap(props.completedRequest.id) !== undefined
  const hasRiderTipped = Boolean(TipStore.getTipFromMap(props.completedRequest.id))
  const shouldProceedToTipping = isTipInfoLoaded && isTippingPossible && !hasRiderTipped

  const fetchTipAndInsightData = async () => {
    // reset tipping state
    setIsTippingPossible(false)
    const result = await TipHelper.checkRiderCanTip(props.completedRequest)
    // Load request insights before moving on
    // TODO this data should be lazy loaded in the next view
    if (AuthenticatorHelper.organization?.featureFlags.includes(FeatureFlag.RequestInsights)) {
      await RequestInsightsStore.getRequestInsights(props.completedRequest.id)
    }
    // set tipping state
    setIsTippingPossible(result)
  }

  // Effect to reloading data when needed
  useAsync(async () => {
    await fetchTipAndInsightData()
  }, [props.completedRequest])

  // This is used to show a persistent review/tipping modal
  // so that the rider is forced to go through the flow. Only relevant when
  // tipping is enabled on the request
  const showReviewOrTipModal = () => {
    if (props.completedRequest.shouldRiderReview) {
      props.handleNavigateReview({
        request: toJS(props.completedRequest),
        canRiderTip: true,
        title: st.screens.review.title(),
      })
    } else {
      props.handleNavigateTip({
        request: toJS(props.completedRequest),
        title: st.screens.tip.tipLastTrip(),
        canRiderTip: true,
      })
    }
  }

  // Effect for showing the modal
  useEffect(() => {
    if (shouldProceedToTipping) {
      showReviewOrTipModal()
    }
  }, [shouldProceedToTipping])

  // We will render nothing in two cases:
  // 1. until we have loaded the tipping info, we don't want to show anything
  // 2. the request is already reviewed, since it is reviewed we don't need to show anything
  if (!isTipInfoLoaded || !props.completedRequest.shouldRiderReview) {
    return null
  }

  return (
    <HomeInfoBar
      barStyle={props.isSingleBar ? {} : styles.infoBar}
      infoText={st.components.reviewInfoBar.rate()}
      onPress={() => RouterStore.goToScreen({ pathname: Pathname.RequestLastCompleted })}
    />
  )
})
