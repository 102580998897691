import { IPromoAllocationResponse } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  promoContainer: {
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    marginBottom: 10,
    paddingBottom: 10,
  },
  title: {
    fontSize: 16,
    marginLeft: 16,
    color: colors.textDark,
    flexWrap: 'wrap',
    flex: 1,
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.borderGray,
    alignItems: 'center',
    paddingVertical: 15,
    marginHorizontal: 15,
    marginBottom: 15,
  },
  bodyContainer: {
    flex: 1,
    paddingHorizontal: 15,
  },
  promoBodyText: {
    fontSize: 15,
    color: colors.textMedium,
    marginBottom: 3,
  },
})

const t = st.screens.listPromoScreen

interface IHeaderProps {
  promoDescription: string | null
}

export class PromoHeading extends Component<IHeaderProps> {
  public render() {
    const { promoDescription } = this.props
    const title = promoDescription || st.screens.listPromoScreen.referralTitle()
    return (
      <View style={styles.headerContainer}>
        <FontAwesomeIconWrapper icon='badge-dollar' size={40} color={colors.selectedBlue} />
        <Text style={styles.title}>{title}</Text>
      </View>
    )
  }
}

interface IProps {
  promo: IPromoAllocationResponse
}

@observer
export class PromoEntry extends Component<IProps> {
  public renderText(text: string) {
    return <Text style={styles.promoBodyText}>{text}</Text>
  }

  public renderBody(promo: IPromoAllocationResponse) {
    const amountRemaining = CurrencyHelper.format(promo.maxAmount - promo.amountRedeemed, promo.currency)
    const usesRemaining = promo.limitRides ? promo.limitRides - promo.usesRedeemed : null
    const expiry = moment.unix(promo.expiryTs).format('ll LT')
    return (
      <View style={styles.bodyContainer}>
        {this.renderText(t.amountRemaining({ amount: amountRemaining }))}
        {usesRemaining ? this.renderText(t.ridesRemaining({ count: 1 })) : null}
        {this.renderText(t.expiryDate({ expiry }))}
      </View>
    )
  }

  public render() {
    const { promo } = this.props
    return (
      <View style={styles.promoContainer}>
        <PromoHeading promoDescription={promo.promoDescription} />
        {this.renderBody(promo)}
      </View>
    )
  }
}
