import { ArcBuilder, IPoint } from '@sparelabs/geography'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { IDrivingPolylineProps } from 'src/components/mapMarkers/MapMarkerProps'
import { Polyline } from 'src/components/mapMarkers/polyline/Polyline'
import { lineStringToCoords } from 'src/helpers/MapHelper'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { IMarkerCoordinates } from 'src/types'

/**
 * Draws a curved line between the pickup and dropoff locations.
 * If pickup radius is used, use requested pickup location, else use scheduled pickup location.
 * Use scheduled dropoff location always.
 * If scheduled locations do not exist, do not render.
 */
@observer
export class DrivingPolyline extends Component<IDrivingPolylineProps> {
  public render() {
    const { renderPolyline, pickupLocation, dropoffLocation } = this.props
    if (renderPolyline) {
      return (
        <Polyline
          strokeWidth={MapMarkerHelper.getPolylineStrokeWidth()}
          strokeColor={colors.gray90}
          coordinates={this.createPolyline(pickupLocation, dropoffLocation)}
        />
      )
    }
    return null
  }

  private readonly createPolyline = (pickupLocation: IPoint, dropoffLocation: IPoint): IMarkerCoordinates[] =>
    lineStringToCoords(ArcBuilder.buildArc(pickupLocation, dropoffLocation))
}
