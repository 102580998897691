import { IRequestResponse, IVehicleResponse } from '@sparelabs/api-client'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { useAsync } from 'react-async-hook'
import { StyleSheet, View } from 'react-native'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import { BackButton } from 'src/components/buttons/BackButton'
import { BottomSheetWrapper } from 'src/components/cards/BottomSheetWrapper'
import { CardWrapper } from 'src/components/cards/CardWrapper'
import { NetworkErrorPill } from 'src/components/NetworkErrorPill'
import { ActiveRequestCompletionObserver } from 'src/components/request/ActiveRequestCompletionObserver'
import { RequestProcessingCard } from 'src/components/request/processing/RequestProcessingCard'
import { CLOSED_CARD_HEIGHT, RequestCard } from 'src/components/request/RequestCard'
import { RequestCompletionAlerts } from 'src/components/request/RequestCompletionAlerts'
import { VehicleStatusPill } from 'src/components/request/VehicleStatusPill'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { COLLAPSED_ESTIMATE_TOP_PADDING_WEB } from 'src/helpers/RideOptionsCardHelper'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { MODAL_MAX_WIDTH } from 'src/navigation/consts/NavigationHelper'
import { ApiStore } from 'src/stores/ApiStore'
import { IRequestStore } from 'src/stores/RequestStore'
import { RouterStore } from 'src/stores/RouterStore'
import { UIStateStore } from 'src/stores/UIStore'
import { Pathname } from 'src/types/homeRoot'
import { RequestStatus } from 'src/util/types'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backButtonWrapper: {
    position: 'absolute',
    zIndex: 2,
    bottom: CLOSED_CARD_HEIGHT,
    width: '100%',
  },
  pill: {
    alignItems: 'center',
    marginBottom: 45,
    width: UIStateStore.shouldShowLandscapeWeb ? MODAL_MAX_WIDTH : '100%',
    position: 'absolute',
    bottom: UIStateStore.shouldShowLandscapeWeb
      ? UIStateStore.screenHeight - COLLAPSED_ESTIMATE_TOP_PADDING_WEB
      : CLOSED_CARD_HEIGHT,
  },
})

export const executeOnNDA = async (request: IRequestResponse, selectedEstimateId: string | null) => {
  if (selectedEstimateId === request.estimateId) {
    await RouterStore.returnToPreviousScreen()
    RequestCompletionAlerts.showImmediateNDAAlert()
  } else {
    await RouterStore.returnHome()
    RequestCompletionAlerts.showNDAAlert(request.scheduledPickupTs || request.requestedPickupTs)
  }
}

export const executeOnCancelled = async (request: IRequestResponse) => {
  await RouterStore.returnHome()
  if (request.cancellationDetails) {
    RequestCompletionAlerts.showCancelledAlert(
      request.scheduledPickupTs || request.requestedPickupTs,
      request.cancellationDetails.reason
    )
  }
}

export const executeOnCompleted = async () => {
  await RouterStore.goToScreen({ pathname: Pathname.RequestLastCompleted })
}

export interface IRequestScreenProps {
  requestStore: IRequestStore
  selectedEstimateId: string | null
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  handleNavigateRequestCancellation: (params: ParamsListRoot[ScreenName.RequestCancellation]) => void
  handleNavigateRequestRiderOptions: (
    params: Pick<ParamsListRoot[ScreenName.RequestRiderOptions], 'requestId' | 'serviceId'>
  ) => void
  handleNavigateRequestAccessibilityOptions: (
    params: Pick<ParamsListRoot[ScreenName.RequestAccessibilityOptions], 'requestId' | 'serviceId'>
  ) => void
}

export const RequestScreen = observer(
  ({
    requestStore,
    selectedEstimateId,
    handleNavigateDiscountDetails,
    handleNavigateRequestCancellation,
    handleNavigateRequestRiderOptions,
    handleNavigateRequestAccessibilityOptions,
  }: IRequestScreenProps) => {
    const request = requestStore.activeRequest
    const handleNavigateHome = RouterStore.returnHome.bind(RouterStore)

    const isRequestOrVehicleLoading = (request: IRequestResponse, vehicle: IVehicleResponse | undefined) =>
      // if no vehicle and status is not service disruption meaning there wasn't matching duty before
      (!vehicle && request.status !== RequestStatus.ServiceDisruption) ||
      request.status === RequestStatus.Processing ||
      // we want to continue showing the spinner for no drivers available so that
      // we can fetch the updated estimate in the background
      request.status === RequestStatus.NoDriversAvailable

    const vehicle = useAsync(async () => {
      if (requestStore.activeRequest?.vehicleId) {
        const result = await ApiClientBuilder.build().vehicles.get(requestStore.activeRequest.vehicleId)
        return result
      }
    }, [requestStore.activeRequest?.vehicleId])

    const renderCard = () => {
      if (!request || request.status === RequestStatus.Completed || request.status === RequestStatus.Cancelled) {
        return null
      }

      if (isRequestOrVehicleLoading(request, vehicle.result)) {
        return (
          <CardWrapper containerStyle={{ bottom: 0 }}>
            <BackButton onPress={handleNavigateHome} />
            {request && (
              <RequestProcessingCard
                request={request}
                handleNavigateRequestCancellation={handleNavigateRequestCancellation}
              />
            )}
          </CardWrapper>
        )
      }
      // else if request is loaded
      return (
        <>
          <View style={styles.pill} pointerEvents='box-none'>
            {ApiStore.connected ? <VehicleStatusPill request={request} /> : <NetworkErrorPill />}
          </View>
          <BottomSheetWrapper>
            <RequestCard
              organization={AuthenticatorHelper.organization!}
              request={request}
              vehicle={vehicle.result ?? null}
              handleNavigateRequestCancellation={handleNavigateRequestCancellation}
              handleNavigateRequestAccessibilityOptions={() =>
                handleNavigateRequestAccessibilityOptions({
                  requestId: request.id,
                  serviceId: request.serviceId,
                })
              }
              handleNavigateRequestRiderOptions={() =>
                handleNavigateRequestRiderOptions({
                  requestId: request.id,
                  serviceId: request.serviceId,
                })
              }
              handleNavigateDiscountDetails={() =>
                handleNavigateDiscountDetails({
                  fare: toJS(request.fare),
                  fareRedemptions: toJS(request.fareRedemptions),
                })
              }
              handleBackPress={handleNavigateHome}
            />
          </BottomSheetWrapper>
        </>
      )
    }

    return (
      <View key='route-container' style={styles.container} pointerEvents='box-none' testID='requestView'>
        <ActiveRequestCompletionObserver
          effectIfCancelled={executeOnCancelled}
          effectIfNda={(request: IRequestResponse) => executeOnNDA(request, selectedEstimateId)}
          effectIfCompleted={executeOnCompleted}
        />
        {request && renderCard()}
      </View>
    )
  }
)
