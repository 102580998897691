import { Formik, FormikProps } from 'formik'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { FormTextField } from 'src/components/form/FormTextField/FormTextField'
import { FormWrapper } from 'src/components/form/FormWrapper'
import {
  IPhoneConfirmationProps,
  ISetPhoneNumberConfirmFormValues,
} from 'src/components/phoneConfirmation/PhoneConfirmationTypes'
import { st } from 'src/locales'
import yup from 'yup'

const style = StyleSheet.create({
  formTextField: {
    marginBottom: 20,
  },
})

const schema = yup.object().shape({
  confirmCode: yup
    .string()
    .required(st.screens.setPhoneNumberConfirm.verificationCodeWarning())
    .label(st.screens.setPhoneNumberConfirm.verificationCode()),
})

const renderForm = (formProps: FormikProps<ISetPhoneNumberConfirmFormValues>, loading: boolean) => (
  <FormWrapper>
    <View style={style.formTextField}>
      <FormTextField
        fieldKey='confirmCode'
        label={st.screens.setPhoneNumberConfirm.verificationCode()}
        value={formProps.values.confirmCode}
        onChange={(value) => formProps.setFieldValue('confirmCode' as never, value)}
        touched={formProps.touched.confirmCode}
        error={formProps.errors.confirmCode}
        keyboardType='phone-pad'
        autoFocus={true}
      />
    </View>
    <PrimaryButton
      testID='phoneVerifyButton'
      title={st.common.buttonConfirm()}
      loading={loading}
      onPress={formProps.handleSubmit as any}
    />
  </FormWrapper>
)

export const PhoneConfirmation = (props: IPhoneConfirmationProps) => (
  <Formik<ISetPhoneNumberConfirmFormValues>
    validationSchema={schema}
    initialValues={{ confirmCode: '' }}
    onSubmit={(values) => props.handlePressConfirm(values.confirmCode ?? '')}
  >
    {(formProps) => renderForm(formProps, props.isLoading)}
  </Formik>
)
