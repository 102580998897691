import { CustomFieldType, ICustomFieldChoice, ICustomFieldResponse } from '@sparelabs/api-client'
import { dateFormat } from '@sparelabs/time'
import { isFinite, isString } from 'lodash'
import React, { useEffect } from 'react'
import { DateTimePickerMode } from 'src/components/datepicker/DateTimeInputTypes'
import { AddressField } from 'src/components/form/AddressField/AddressField'
import { DatetimeField } from 'src/components/form/DateField/DatetimeField'
import { FileField } from 'src/components/form/FileField/FileField'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { FormTextField } from 'src/components/form/FormTextField/FormTextField'
import { MultipleChoiceField } from 'src/components/form/MultipleChoiceField/MultipleChoiceField'
import { SingleChoiceField } from 'src/components/form/SingleChoiceField/SingleChoiceField'
import { ToggleField } from 'src/components/form/ToggleField'
import { moment, Moment } from 'src/helpers/Moment'

interface ICustomFieldProps extends Omit<IFormFieldProps<any>, 'label' | 'keyboardType'> {
  customField: Pick<ICustomFieldResponse, 'type' | 'label' | 'hint' | 'choices'>
  setFieldTouched?: (field: never, isTouched?: boolean) => void
  displayErrors?: boolean
}

export const CustomField = (props: ICustomFieldProps) => {
  const commonProps = {
    ...props,
    label: props.customField.label,
    hint: props.customField.hint ?? undefined,
  }
  const { displayErrors, setFieldTouched, fieldKey } = props
  useEffect(() => {
    if (displayErrors && setFieldTouched) {
      setFieldTouched(fieldKey as never, true)
    }
  }, [displayErrors, setFieldTouched, fieldKey])

  switch (props.customField.type) {
    case CustomFieldType.Text:
      return <FormTextField {...commonProps} keyboardType='default' />
    case CustomFieldType.Number: {
      const { value, onChange } = props
      const stringValue = isString(value) ? value : isFinite(value) ? value.toString() : ''
      return (
        <FormTextField
          {...commonProps}
          onChange={(newValue) => onChange(parseInt(newValue, 10))}
          value={stringValue}
          keyboardType='numeric'
        />
      )
    }
    case CustomFieldType.SingleChoice:
      return <SingleChoiceField {...commonProps} choices={props.customField.choices as ICustomFieldChoice[]} />
    case CustomFieldType.MultipleChoice:
      return <MultipleChoiceField {...commonProps} choices={props.customField.choices as ICustomFieldChoice[]} />
    case CustomFieldType.Date:
      return (
        <DatetimeField<string>
          {...commonProps}
          mode={DateTimePickerMode.Date}
          formatValue={(value: string) => moment(value, dateFormat)}
          onValueChange={(newMoment: Moment) => commonProps.onChange(newMoment.format(dateFormat))}
          format='ll'
        />
      )
    case CustomFieldType.Datetime:
      return (
        <DatetimeField<number>
          {...commonProps}
          mode={DateTimePickerMode.DateTime}
          formatValue={(value: number) => moment.unix(value)}
          onValueChange={(newMoment: Moment) => commonProps.onChange(newMoment.unix())}
          format='lll'
        />
      )
    case CustomFieldType.Toggle:
      return <ToggleField {...commonProps} />
    case CustomFieldType.Address:
      return <AddressField {...commonProps} />
    case CustomFieldType.File:
      return (
        <FileField
          {...commonProps}
          onClick={() => {
            commonProps.setFieldTouched && commonProps.setFieldTouched(commonProps.fieldKey as never, true)
          }}
        />
      )
    case CustomFieldType.NpsScore:
    case CustomFieldType.Drawing:
    case CustomFieldType.Files:
      return null
  }
}
