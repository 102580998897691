import { IErrorResponse, IMobileImageFile, IRiderMePatchBody } from '@sparelabs/api-client'
import { AxiosError } from 'axios'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleAuthError } from 'src/helpers/ErrorHelpers'
import { Sentry } from 'src/helpers/sentry'
import { st } from 'src/locales'

export class UserMeHelper {
  public static async userMePhoneRequest(phoneNumber: string, reCaptchaToken?: string): Promise<boolean> {
    try {
      await ApiClientBuilder.build().users.postUserMePhoneRequest({ phoneNumber }, reCaptchaToken)
      return true
    } catch (error) {
      handleAuthError(error as AxiosError<IErrorResponse>)
      return false
    }
  }

  public static async userMeVerifyPhone(code: string): Promise<boolean> {
    try {
      await ApiClientBuilder.build().users.postUserMePhoneVerify({ code })

      return true
    } catch (error) {
      Sentry.captureMessage('User me phone verification failed')
      Sentry.setExtra('extra', error)
      AlertHelper.alert(
        st.authentication.verificationAlertTitle(),
        st.authentication.verificationAlertContent(),
        [
          {
            text: st.common.alertOk(),
            onPress: () => undefined,
          },
        ],
        { cancelable: false }
      )
    }
    return false
  }

  public static async updateUserProfile(data: IRiderMePatchBody, photoData?: IMobileImageFile | Blob): Promise<void> {
    if (photoData) {
      const { url } = await ApiClientBuilder.build().upload.photo(photoData)
      data.photoUrl = url
    }
    const updatedRider = await ApiClientBuilder.build().users.patchMeRider(data)
    AuthenticatorHelper.optimisticallyUpdateRider(updatedRider)
  }
}
