import { IAddress } from '@sparelabs/address'
import { CustomFieldType, ICustomFieldChoice, ICustomFieldResponse } from '@sparelabs/api-client'
import { IFileResponse } from '@sparelabs/http-client-utils'
import { KeyboardType } from 'react-native'
import { st } from 'src/locales'
import yup, { BooleanSchema, ObjectSchema } from 'yup'

export interface IFormField {
  label: string
  schema: yup.Schema<unknown>
  keyboardType?: KeyboardType
  disabled?: boolean
  customField?: ICustomFieldResponse
}

type CustomFieldSchema =
  | yup.StringSchema<string | null | undefined>
  | yup.NumberSchema<number | null | undefined>
  | yup.DateSchema<string | Date | null | undefined>
  | yup.ArraySchema<string | null | undefined>
  | yup.NotRequiredArraySchema<string | null | undefined>
  | yup.BooleanSchema<boolean | null | undefined>
  | yup.ObjectSchema<Partial<IAddress> | Partial<IFileResponse> | null | undefined>
  | yup.ObjectSchema

export class CustomFieldHelper {
  public static buildFields(customFields: ICustomFieldResponse[]): Record<string, IFormField> {
    const fields: Record<string, IFormField> = {}
    for (const customField of customFields) {
      fields[customField.key] = {
        label: customField.label,
        schema: this.buildSchemaForField(
          customField.type,
          customField.choices,
          customField.isRequired,
          customField.label
        ),
        customField,
      }
    }
    return fields
  }

  public static buildInitialValues(metadata: object, customFields: ICustomFieldResponse[]): object {
    const initialValues = {}
    for (const customField of customFields) {
      if (metadata[customField.key] !== undefined) {
        initialValues[customField.key] = metadata[customField.key]
      } else {
        switch (customField.type) {
          case CustomFieldType.Text:
          case CustomFieldType.Number:
          case CustomFieldType.NpsScore:
            initialValues[customField.key] = ''
            break
          case CustomFieldType.Date:
          case CustomFieldType.Datetime:
          case CustomFieldType.SingleChoice:
            initialValues[customField.key] = undefined
            break
          case CustomFieldType.MultipleChoice:
            initialValues[customField.key] = []
            break
          case CustomFieldType.Toggle:
            initialValues[customField.key] = false
            break
          case CustomFieldType.Address:
            initialValues[customField.key] = { version: 1, country: 'US' }
            break
          case CustomFieldType.Drawing:
          case CustomFieldType.File:
            initialValues[customField.key] = undefined
            break
        }
      }
    }
    return initialValues
  }

  private static defaultValidation(schema: CustomFieldSchema, isRequired: boolean) {
    return isRequired ? schema.required(st.screens.setProfile.fieldRequired()) : schema
  }

  private static toggleSchemaValidation(toggleSchema: BooleanSchema, isRequired: boolean) {
    return isRequired
      ? toggleSchema.test('toggle-test', st.screens.setProfile.fieldRequired(), (value) => (value ? true : false))
      : toggleSchema
  }

  private static fileSchemaValidation(
    fileSchema: ObjectSchema<{ [key: string]: number | string } | undefined>,
    isRequired: boolean
  ) {
    return isRequired
      ? (fileSchema = fileSchema.test(
          'file-test',
          st.screens.setProfile.fieldRequired(),
          (value) => !value || (!!value && !!value.id)
        ))
      : fileSchema
  }

  private static addressSchemaValidation(
    addressSchema: ObjectSchema<{ [key: string]: number | string } | undefined>,
    isRequired: boolean
  ) {
    return isRequired
      ? (addressSchema = addressSchema.test(
          'address-test',
          st.screens.setProfile.fieldRequired(),
          (value) => !value || (!!value && !!value.firstName && !!value.lastName && !!value.address1)
        ))
      : addressSchema
  }

  private static buildSchemaForField(
    type: CustomFieldType,
    choices: ICustomFieldChoice[] | null,
    isRequired: boolean,
    label: string
  ): CustomFieldSchema {
    let schema: CustomFieldSchema
    switch (type) {
      case CustomFieldType.Text:
        schema = this.defaultValidation(yup.string(), isRequired)
        break
      case CustomFieldType.NpsScore:
      case CustomFieldType.Number:
        schema = this.defaultValidation(yup.number().typeError(st.screens.setProfile.validateNumber()), isRequired)
        break
      case CustomFieldType.Date:
        schema = this.defaultValidation(yup.date(), isRequired)
        break
      case CustomFieldType.Datetime:
        // This uses string validation since yup validation doesn't work for datetime strings prior to 2001
        schema = this.defaultValidation(yup.string(), isRequired)
        break
      case CustomFieldType.Toggle:
        schema = this.toggleSchemaValidation(yup.boolean(), isRequired)
        break
      case CustomFieldType.SingleChoice:
        if (choices === null) {
          throw new Error('CustomFieldType.SingleChoice expected to have choices defined')
        }
        schema = this.defaultValidation(yup.string().oneOf(choices.map((choice) => choice.value)), isRequired)
        break
      case CustomFieldType.MultipleChoice:
        if (choices === null) {
          throw new Error('CustomFieldType.MultipleChoice expected to have choices defined')
        }
        schema = this.defaultValidation(
          yup.array(yup.string().oneOf(choices.map((choice) => choice.value))),
          isRequired
        )
        break
      case CustomFieldType.Address:
        schema = this.addressSchemaValidation(
          yup.object({
            firstName: yup.string(),
            lastName: yup.string(),
            address1: yup.string(),
            address2: yup.string(),
            city: yup.string(),
            province: yup.string(),
            zip: yup.string(),
            country: yup.string(),
          }),
          isRequired
        )
        break
      case CustomFieldType.Drawing:
        schema = this.defaultValidation(yup.array(yup.string()), isRequired)
        break
      case CustomFieldType.File:
        schema = this.fileSchemaValidation(
          yup.object({
            id: yup.string(),
            name: yup.string(),
            format: yup.string(),
            size: yup.number(),
          }),
          isRequired
        )
        break
      case CustomFieldType.Files:
        schema = this.defaultValidation(yup.array(), false)
        break
    }
    return schema.label(label)
  }
}
