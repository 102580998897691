import { observer } from 'mobx-react-lite'
import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { colors } from 'src/assets/colors'
import { PhoneNumberForm } from 'src/components/form/PhoneNumberForm'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { UserMeHelper } from 'src/helpers/UserMeHelper'
import { st } from 'src/locales'
import { ScreenName, ScreenPropsEditPhoneNumber } from 'src/navigation'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  cellContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  description: {
    fontSize: 15,
    color: colors.textDark,
    paddingVertical: 20,
  },
})

interface IEditPhoneNumberViewProps {
  handleValidatePhoneNumber: (parsedPhoneNumber: string, token?: string) => Promise<void>
  handleInvalidPhoneNumber: () => void
}

export const EditPhoneNumberView = observer(
  ({ handleValidatePhoneNumber, handleInvalidPhoneNumber }: IEditPhoneNumberViewProps) => (
    <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
      <PhoneNumberForm
        submitButtonTestId='phoneConfirmButton'
        handleValidatePhoneNumber={handleValidatePhoneNumber}
        handleInvalidPhoneNumber={handleInvalidPhoneNumber}
        submitButtonText={st.common.buttonConfirm()}
        inputDescription={st.screens.setPhoneNumber.phoneNumberDescription()}
      />
    </ScrollView>
  )
)

export const EditPhoneNumber = observer(
  ({ navigation }: ScreenPropsEditPhoneNumber<ScreenName.EditPhoneNumber>): JSX.Element => {
    const handleValidatePhoneNumber = async (parsedPhoneNumber: string, token: string | undefined) => {
      try {
        const success = await UserMeHelper.userMePhoneRequest(parsedPhoneNumber, token ?? undefined)

        if (success) {
          navigation.navigate(ScreenName.EditPhoneNumberConfirm, { phoneNumber: parsedPhoneNumber })
        }
      } catch (error) {
        handleError({ error: error as Error })
      }
    }

    const handleInvalidPhoneNumber = () => {
      AlertHelper.alert(
        st.screens.setPhoneNumber.phoneAlertTitle(),
        st.screens.setPhoneNumber.phoneAlertContent(),
        [{ text: st.common.alertOk() }],
        {
          cancelable: false,
        }
      )
    }

    return (
      <EditPhoneNumberView
        handleValidatePhoneNumber={handleValidatePhoneNumber}
        handleInvalidPhoneNumber={handleInvalidPhoneNumber}
      />
    )
  }
)
