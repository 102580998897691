import { IRequestResponse, ReviewTag } from '@sparelabs/api-client'
import { action, makeObservable, observable } from 'mobx'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { RequestStore } from 'src/stores/RequestStore'

const REVIEW_URL = 'reviews/'

export enum ReviewRating {
  Positive = 'positive',
  Negative = 'negative',
}

class ReviewStoreClass {
  @observable
  public rating: ReviewRating | null = null

  @observable
  public comment: string | null = null

  @observable
  public tags: Map<ReviewTag, boolean> = new Map()

  @action public setComment = (comment: string) => {
    this.comment = comment
  }

  @action public setRating = (rating: ReviewRating) => {
    if (rating !== this.rating) {
      this.tags = new Map()
    }
    this.rating = rating
  }

  @action public setTag = (tag: ReviewTag) => {
    this.tags.set(tag, !this.tags.get(tag))
  }

  @action public submitReview = async (request: IRequestResponse) => {
    if (AuthenticatorHelper.userOrgToken) {
      const tags: ReviewTag[] = []
      for (const [tag, value] of this.tags) {
        if (value) {
          tags.push(tag)
        }
      }
      await LegacyApiClient.post(
        AuthenticatorHelper.userOrgToken,
        REVIEW_URL,
        {
          requestId: request.id,
          reviewerId: request.riderId,
          comment: this.comment,
          rating: this.rating === ReviewRating.Positive ? 1 : 0,
          tags,
        },
        true
      )
      // Since we have already filled in the review, we can optimistically take note of that
      await RequestStore.updateRequestOptimistically(request.id, { shouldRiderReview: false })
      this.clear()
    }
  }

  @action public clear = () => {
    this.rating = null
    this.comment = null
    this.tags = new Map()
  }

  constructor() {
    makeObservable(this)
  }
}

export const ReviewStore = new ReviewStoreClass()
