import { IRequestResponse } from '@sparelabs/api-client'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ActivityIndicator, FlatList, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'
import { ParamsListAccount, ParamsListRoot, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { PastTripsListItem } from 'src/screens/request/PastTrips/PastTripsListItem'
import { PagedDataStore } from 'src/stores/PagedDataStore'
import { PastRequestsStore } from 'src/stores/PastRequestsStore'
import { ITipStore, TipStore } from 'src/stores/TipStore'

const styles = StyleSheet.create({
  list: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  requestTitle: {
    fontSize: 16,
    color: colors.textDark,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
  },
  loadingFooter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25,
    paddingBottom: 25,
  },
  divider: {
    flex: 1,
    borderTopColor: colors.borderGray,
    borderTopWidth: 1,
  },
})

type Props = ParamsListAccount[ScreenName.PastTripsList] & {
  tipStore: ITipStore
  handleNavigatePastTrip: (params: ParamsListAccount[ScreenName.PastTrip]) => void
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
}

interface IState {
  pageStore: PagedDataStore<IRequestResponse>
}

@observer
export class PastTripsView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      pageStore: new PastRequestsStore(),
    }
  }

  public async UNSAFE_componentWillMount() {
    await this.state.pageStore.fetchMore()
  }

  public onEndReached = async () => {
    if (this.state.pageStore.apiHasMoreData && !this.state.pageStore.isLoading()) {
      await this.state.pageStore.fetchMore()
    }
  }

  public renderLoadingFooter() {
    if (this.state.pageStore.isLoading()) {
      return (
        <View style={styles.loadingFooter}>
          <ActivityIndicator color='black' />
        </View>
      )
    }
    return null
  }

  public renderEmptyList() {
    if (this.state.pageStore.isLoading()) {
      return null
    }
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.requestTitle}>{st.screens.pastTrips.noPastTrips()}</Text>
      </View>
    )
  }

  public render() {
    const { tipStore } = this.props
    return (
      <FlatList
        style={styles.list}
        data={this.state.pageStore.data}
        refreshing={this.state.pageStore.isLoading()}
        keyExtractor={(item) => item.id}
        onEndReached={this.onEndReached}
        onEndReachedThreshold={0.7}
        renderItem={({ item }) => (
          <PastTripsListItem
            tipStore={tipStore}
            handleNavigateDiscountDetails={(params) => this.props.handleNavigateDiscountDetails(params)}
            handleNavigatePastTrip={() => this.props.handleNavigatePastTrip({ request: toJS(item) })}
            request={item}
          />
        )}
        ListEmptyComponent={this.renderEmptyList()}
        ListFooterComponent={this.renderLoadingFooter()}
        ItemSeparatorComponent={() => <View style={styles.divider} />}
        testID='pastTripsList'
      />
    )
  }
}

export const PastTrips = (props: ScreenPropsAccount<ScreenName.PastTripsList>) => (
  <PastTripsView
    {...props.route.params}
    tipStore={TipStore}
    handleNavigateDiscountDetails={(params) => props.navigation.navigate(ScreenName.DiscountDetails, params)}
    handleNavigatePastTrip={(params) => props.navigation.navigate(ScreenName.PastTrip, params)}
  />
)
