import React from 'react'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from '../../FontAwesomeIcon'
import { ITagButtonProps } from './TagButtonTypes'

const styles = {
  container: {
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 35,
    cursor: 'pointer',
  },
  title: {
    fontSize: 15,
    fontWeight: '400',
  },
  icon: {
    marginRight: 8,
  },
  iconSelected: {
    color: colors.white,
  },
}

export const TagButton = (props: ITagButtonProps) => (
  <div
    style={{ ...styles.container, backgroundColor: props.active ? colors.selectedBlue : colors.blue10 }}
    onClick={props.onPress}
    data-testid='tag-button'
    role='radio'
    aria-checked={props.active}
    tabIndex={0}
  >
    {props.icon && (
      <FontAwesomeIconWrapper
        icon={props.icon}
        size={16}
        style={props.active ? { ...styles.icon, ...styles.iconSelected } : { ...styles.icon }}
      />
    )}
    <span style={{ ...styles.title, color: props.active ? colors.textLight : colors.gray90 }}>{props.text}</span>
  </div>
)
