import { IPoint } from '@sparelabs/geography'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'
import { Region } from 'react-native-maps'
import { DismissableSearchList } from 'src/components/DismissableSearchList'
import { LoadingShimmer } from 'src/components/LoadingShimmer'
import { Map } from 'src/components/map/Map'
import { MapServiceAreas } from 'src/components/mapMarkers/MapServiceAreas'
import { SetLocationHeader } from 'src/components/SetLocationHeader'
import { AutoSuggestion } from 'src/helpers/AutoSuggestHelper'
import { st } from 'src/locales'
import { LoadingStore } from 'src/stores/LoadingStore'
import { LocationStore } from 'src/stores/LocationStore'
import { MapStoreClass } from 'src/stores/MapStore'
import { SetLocationStore } from 'src/stores/SetLocationStore'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
  },
})

interface IProps {
  setLocationCallback: (location: IPoint, address?: string) => void
  handlePressBack: () => void
}

interface IState {
  isShowingMap: boolean
  setLocationStore: SetLocationStore
  mapStore: MapStoreClass
}

enum LoadingKey {
  GetGeoCode = 'geoCodeLoading',
  SetSearchTerm = 'setSearchTerm',
}

@observer
export class SetLocationView extends Component<IProps, IState> {
  public header: React.Ref<SetLocationHeader>
  public loadingStore = new LoadingStore()

  constructor(props: IProps) {
    super(props)
    this.header = React.createRef()
    this.loadingStore = new LoadingStore()

    this.state = {
      isShowingMap: false,
      setLocationStore: new SetLocationStore(),
      mapStore: new MapStoreClass(),
    }
  }

  public componentDidMount() {
    this.state.setLocationStore.createSearchIndex()
  }

  public setSearchTerm = (text: string) => {
    this.state.setLocationStore.setSearchTerm(text)
  }

  public getSearchTerm() {
    const suggestion = this.state.setLocationStore.autoSuggestion.selectedSuggestion
    if (this.state.isShowingMap) {
      const mapRegionSuggestion = this.state.setLocationStore.autoSuggestion.mapRegionSuggestion
      if (this.loadingStore.isLoading(LoadingKey.GetGeoCode) || !mapRegionSuggestion) {
        return st.screens.search.loading()
      }
      return mapRegionSuggestion.name
    }

    if (suggestion) {
      return suggestion.name
    }

    return this.state.setLocationStore.autoSuggestion.searchTerm
  }

  public onRegionChangeComplete = async (region: Region) => {
    this.state.mapStore.setMapRegion(region)
    await this.loadingStore.execute(this.state.setLocationStore.setMapRegionSuggestion(region), LoadingKey.GetGeoCode)
  }

  public handlePressDoneOnMap = async () => {
    await this.state.setLocationStore.setSearchToMapRegion()
    this.handleCallback()
  }

  public handlePressSuggestion = async (suggestion: AutoSuggestion) => {
    if (!this.loadingStore.isLoading(LoadingKey.SetSearchTerm)) {
      Keyboard.dismiss()
      await this.loadingStore.execute(
        this.state.setLocationStore.setSearchAutoSuggestion(suggestion),
        LoadingKey.SetSearchTerm
      )
      this.handleCallback()
    }
  }

  public handlePressSetLocationOnMap = async () => {
    Keyboard.dismiss()
    this.setState({
      isShowingMap: true,
    })

    await this.loadingStore.execute(
      this.state.setLocationStore.setMapRegionSuggestion(this.state.mapStore.mapRegion),
      LoadingKey.GetGeoCode
    )

    this.handleCallback()
  }

  public handleFocus = () => {
    this.state.setLocationStore.clearAutoSuggestion()
    this.setState({
      isShowingMap: false,
    })
  }

  public handleCallback() {
    const location = this.state.setLocationStore.locationData.location
    const address = this.state.setLocationStore.locationData.address || ''
    if (location) {
      this.props.setLocationCallback(location, address)
    }
  }

  public render() {
    const placeholder = st.components.searchBar.searchPlaceholder()
    const latitude = LocationStore.currentLocation ? LocationStore.currentLocation.coords.latitude : null
    const longitude = LocationStore.currentLocation ? LocationStore.currentLocation.coords.longitude : null
    return (
      <View style={styles.container}>
        <Map
          key='set-location-map'
          latitude={latitude}
          longitude={longitude}
          isLocationPermissionGranted={LocationStore.isGranted()}
          onRegionChangeComplete={this.onRegionChangeComplete}
        >
          <MapServiceAreas mapRegion={this.state.mapStore.mapRegion} hideStops={false} />
        </Map>
        <View pointerEvents='box-none' style={StyleSheet.absoluteFillObject}>
          <SetLocationHeader
            ref={this.header}
            onFocus={this.handleFocus}
            placeholder={placeholder}
            onPressBack={this.props.handlePressBack}
            isShowingMap={this.state.isShowingMap}
            searchTerm={this.getSearchTerm()}
            setSearchTerm={this.setSearchTerm}
          />
          {this.loadingStore.isLoading(LoadingKey.SetSearchTerm) && <LoadingShimmer />}
          <DismissableSearchList
            isOpen={!this.state.isShowingMap}
            searchSuggestions={this.state.setLocationStore.getSearchSuggestions()}
            onConfirmMapRegion={this.handlePressDoneOnMap}
            onPressSearchResult={this.handlePressSuggestion}
            onPressSetLocationOnMap={this.handlePressSetLocationOnMap}
            geoCodeLoading={this.loadingStore.isLoading(LoadingKey.GetGeoCode)}
          />
        </View>
      </View>
    )
  }
}
