import { FavoriteLocationType, IFavoriteLocationResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { ListView } from 'src/components/list/ListView'
import { IListItemIcon, IListViewItem } from 'src/components/list/ListViewItem'
import { mapFavoriteTypeToColor, mapFavoriteTypeToIcon } from 'src/helpers/FavoritesHelper'
import { st } from 'src/locales'
import {
  FavoriteScreenStateType,
  ParamsListAccount,
  ParamsListRoot,
  ScreenName,
  ScreenPropsAccount,
} from 'src/navigation'
import { FavoriteLocationStore, MAX_NUM_FAVORITE_LOCATIONS } from 'src/stores/FavoriteLocationStore'

type Props = ParamsListAccount[ScreenName.FavoritesListScreen] & {
  handleNavigateEditFavoriteScreen: (params: ParamsListRoot[ScreenName.EditFavoriteScreen]) => void
  handleNavigateSetFavoriteLocation: (params: ParamsListRoot[ScreenName.SetFavoriteLocation]) => void
  handleDismiss: () => void
}

@observer
export class FavoritesView extends Component<Props> {
  public render() {
    const listItems: IListViewItem[] = [...this.getHomeWorkAction(), ...this.getFavoriteItems()]
    if (FavoriteLocationStore.favorites.length < MAX_NUM_FAVORITE_LOCATIONS) {
      listItems.push(this.createAddFavorite())
    }
    return <ListView data={listItems} loading={false} />
  }

  private createSetFavoriteAction(type: FavoriteLocationType.Home | FavoriteLocationType.Work): IListViewItem {
    const iconColor = mapFavoriteTypeToColor(type)
    const titleIcon = mapFavoriteTypeToIcon(type)
    let id: string
    let title: string
    let favoriteTitle: string
    if (type === FavoriteLocationType.Home) {
      id = 'setHome'
      title = st.screens.favorites.homePlaceHolder()
      favoriteTitle = st.screens.favorites.homeTitle()
    } else {
      id = 'setWork'
      title = st.screens.favorites.workPlaceHolder()
      favoriteTitle = st.screens.favorites.workTitle()
    }
    return {
      id,
      title,
      iconColor,
      titleIcon,
      onPress: () => this.createOrEditFavorite({ type, name: favoriteTitle }),
    }
  }

  private getHomeWorkAction(): IListViewItem[] {
    const homeWorkItems: IListViewItem[] = []
    if (FavoriteLocationStore.hasHomeSet) {
      const homeItems = FavoriteLocationStore.filterByType(FavoriteLocationType.Home)
      homeWorkItems.push(...this.createFavoritesItems(homeItems))
    } else {
      homeWorkItems.push(this.createSetFavoriteAction(FavoriteLocationType.Home))
    }
    if (FavoriteLocationStore.hasWorkSet) {
      const workItems = FavoriteLocationStore.filterByType(FavoriteLocationType.Work)
      homeWorkItems.push(...this.createFavoritesItems(workItems))
    } else {
      homeWorkItems.push(this.createSetFavoriteAction(FavoriteLocationType.Work))
    }
    return homeWorkItems
  }

  private getFavoriteItems(): IListViewItem[] {
    const favorites: IListViewItem[] = this.createFavoritesItems(FavoriteLocationStore.otherFavorites)
    return favorites
  }

  private readonly createOrEditFavorite = async (favorite?: Partial<IFavoriteLocationResponse>) => {
    if (favorite && favorite.id) {
      this.props.handleNavigateEditFavoriteScreen({
        state: FavoriteScreenStateType.Edit,
        favoriteId: favorite.id,
      })
    } else {
      const type = favorite && favorite.type ? favorite.type : FavoriteLocationType.Other
      this.props.handleNavigateSetFavoriteLocation({ type })
    }
  }

  private createFavoritesItems(favorite: IFavoriteLocationResponse[]): IListViewItem[] {
    return favorite.map((fav): IListViewItem => {
      const iconColor = mapFavoriteTypeToColor(fav.type)
      const titleIcon = mapFavoriteTypeToIcon(fav.type)
      return {
        id: fav.id,
        titleIcon,
        iconColor,
        title: fav.name || fav.address,
        subtitle: fav.name ? fav.address : undefined,
        onPress: () => this.createOrEditFavorite(fav),
      }
    })
  }

  private createAddFavorite(): IListViewItem {
    return {
      id: 'addFavorite',
      titleIcon: IListItemIcon.Plus,
      iconColor: colors.gray5,
      title: st.screens.favorites.addFavorite(),
      onPress: () => this.createOrEditFavorite(),
    }
  }
}

export const Favorites = (props: ScreenPropsAccount<ScreenName.FavoritesListScreen>) => (
  <FavoritesView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateEditFavoriteScreen={(params) => props.navigation.navigate(ScreenName.EditFavoriteScreen, params)}
    handleNavigateSetFavoriteLocation={(params) => props.navigation.navigate(ScreenName.SetFavoriteLocation, params)}
  />
)
