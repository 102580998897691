import { IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import { delay } from 'bluebird'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { LegacyApiClient } from 'src/api'
import { Animations } from 'src/assets/Animations'
import { colors } from 'src/assets/colors'
import { Header } from 'src/components/headers/Header'
import { LoadingShimmer } from 'src/components/LoadingShimmer'
import { LottieAnimation } from 'src/components/lottieAnimation/LottieAnimation'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { ParamsListRoot, ParamsListScheduledTripList, ScreenName, ScreenPropsScheduledTripList } from 'src/navigation'
import { MODAL_MAX_WIDTH } from 'src/navigation/consts/NavigationHelper'
import { RequestBrand } from 'src/screens/request/RequestBrand'
import { RequestCancelButton } from 'src/screens/request/RequestCancelButton'
import { RequestStatusBar } from 'src/screens/request/RequestStatusBar'
import { RequestTimeline } from 'src/screens/request/RequestTimeline'
import { ScheduledRequestPickupText } from 'src/screens/request/ScheduledRequests/ScheduledRequestPickupText'
import { isWithinPollingThreshold } from 'src/screens/request/ScheduledRequests/ScheduledTripHelper'

export const REQUEST_POLLING_INTERVAL_IN_MS = 5000

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.gray4,
  },
  innerContainer: {
    flex: 1,
    padding: 16,
    backgroundColor: 'white',
  },
  divider: {
    borderTopColor: colors.borderGray,
    borderTopWidth: 1,
    marginVertical: 16,
  },
  timelineContainer: {
    flex: 1,
    paddingHorizontal: 8,
    paddingVertical: 12,
  },
  cancelButtonContainer: {
    marginTop: 'auto',
    marginBottom: 5,
  },
  iconContainer: {
    flex: 1,
    maxHeight: '50%',
    backgroundColor: colors.gray4,
    alignItems: 'center',
  },
})

type Props = ParamsListScheduledTripList[ScreenName.ScheduledTrip] & {
  handleDismiss: () => void
  handleNavigateRequestCancellation: (params: ParamsListRoot[ScreenName.RequestCancellation]) => void
}

interface IState {
  confirmationShowing: boolean
  request: IRequestResponse | null
}

/**
 * If a request is in one of these states then we should not
 * show the cancellation button
 */
const NON_CANCELLABLE_STATUSES = [
  RequestStatus.Cancelled,
  RequestStatus.NoDriversAvailable,
  RequestStatus.InProgress,
  RequestStatus.Completed,
]

@observer
export class ScheduledTripView extends Component<Props, IState> {
  public interval: NodeJS.Timeout | null = null

  constructor(props: Props) {
    super(props)
    this.state = { confirmationShowing: false, request: null }
  }

  public async componentDidMount() {
    if (this.props.showConfirmation) {
      // show confirmation screen
      this.setState({ confirmationShowing: true })
      // wait for animation to play
      await delay(2000)
      // hide confirmation screen
      this.setState({ confirmationShowing: false })
    }

    this.setState({ request: this.props.request })
    // Using this.props.request here because there is a possibility that the state has not been set yet, causing a null reference error
    if (isWithinPollingThreshold(this.props.request.matchCutoffTs, moment().unix())) {
      this.pollForRequestChanges()
    }
  }

  public componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  public render() {
    if (this.state.confirmationShowing) {
      return (
        <View style={styles.container}>
          <View style={styles.iconContainer}>
            <LottieAnimation
              source={Animations.scheduledConfirmation}
              autoPlay={true}
              loop={false}
              width='60%'
              maxWidth={MODAL_MAX_WIDTH}
            />
            <Header headerText={st.screens.scheduledTrip.confirmation()} />
          </View>
        </View>
      )
    }
    const { request } = this.state
    if (!request) {
      return null
    }

    return (
      <View style={styles.container}>
        {request.status === RequestStatus.Processing &&
          isWithinPollingThreshold(request.matchCutoffTs, moment().unix()) && <LoadingShimmer />}
        <RequestStatusBar request={request} />
        <View style={styles.innerContainer}>
          <ScheduledRequestPickupText request={request} />
          <View style={styles.divider} />
          <RequestBrand request={request} />
          <View style={styles.timelineContainer}>
            <RequestTimeline request={request} />
          </View>
          {this.renderCancelButton()}
        </View>
      </View>
    )
  }

  private renderCancelButton() {
    const { request } = this.props
    if (NON_CANCELLABLE_STATUSES.includes(request.status)) {
      return null
    }
    return (
      <View style={styles.cancelButtonContainer}>
        <View style={styles.divider} />
        <RequestCancelButton
          handleNavigateRequestCancellation={() =>
            this.props.handleNavigateRequestCancellation({
              requestId: request.id,
              serviceBrandPhotoUrl: request.serviceBrand.photoUrl,
              onSuccessCallback: this.props.handleDismiss,
            })
          }
        />
      </View>
    )
  }

  private readonly pollForRequestChanges = () => {
    const request: IRequestResponse | null = this.state.request
    this.interval = setInterval(async () => {
      if (!request || !AuthenticatorHelper.userOrgToken) {
        return
      }

      if (request.status !== RequestStatus.Processing && this.interval) {
        clearInterval(this.interval)
        return
      }

      const res = await LegacyApiClient.get(AuthenticatorHelper.userOrgToken, `requests/${request.id}`)
      if (res && res.body) {
        this.setState({ request: res.body })
      }
    }, REQUEST_POLLING_INTERVAL_IN_MS)
  }
}

export const ScheduledTrip = (props: ScreenPropsScheduledTripList<ScreenName.ScheduledTrip>) => (
  <ScheduledTripView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateRequestCancellation={(params) => props.navigation.navigate(ScreenName.RequestCancellation, params)}
  />
)
