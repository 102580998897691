import {
  AccessibilityFeature,
  CreatedInterface,
  IEstimatedRange,
  IEstimateOutput,
  IEstimateService,
  IRequestAccessibilityFeature,
  IRequestRiderType,
} from '@sparelabs/api-client'
import { FeatureFlag } from '@sparelabs/feature-flags'
import { IPoint } from '@sparelabs/geography'
import { ObservableMap } from 'mobx'
import { Platform, StatusBar } from 'react-native'
import { EdgeInsets } from 'react-native-safe-area-context'
import { reverseGeocode } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { AccessibilityCountMap } from 'src/screens/rideOptions/EstimateAccessibilityOptions'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { OsType } from 'src/util/types'
import { EstimateScheduleOptions } from '../components/rideOptions/EstimateRadioButtonGroup'
import { IEstimateInputStore } from '../stores/EstimateInputStore'
import { EstimatesUserInputParsed, IEstimateQueryData, IEstimatesUserInput } from '../types'
import { AutoSuggestion, AutoSuggestType, SetAutoSuggestions } from './AutoSuggestHelper'

const ANDROID_WITH_NOTCH_BAR_HEIGHT = 49

const DaysOfWeek = {
  monday: [1],
  tuesday: [2],
  wednesday: [3],
  thursday: [4],
  friday: [5],
  saturday: [6],
  sunday: [7],
  weekends: [6, 7],
  weekdays: [1, 2, 3, 4, 5],
  everyday: [1, 2, 3, 4, 5, 6, 7],
}

export interface ITimeRange {
  startTs: number
  endTs: number
}

export interface IDropoffTimes {
  dropoffTimeLow: string
  dropoffTimeHigh: string
  dropoffTime: string
}

export interface IPickupTimes {
  pickupTimeLow: string
  pickupTimeHigh: string
  pickupTime: string
}

export const convertTimeRuleToUnixRange = (rule, nowTs) => {
  const mappedDays = DaysOfWeek[rule.days]
  const unixRanges: number[][] = []
  for (const day of mappedDays) {
    let dayTs
    if (moment.unix(nowTs).isoWeekday() <= day) {
      dayTs = moment.unix(nowTs).isoWeekday(day).startOf('day').unix()
    } else {
      dayTs = moment.unix(nowTs).add(1, 'weeks').isoWeekday(day).startOf('day').unix()
    }
    const range = [
      moment.unix(dayTs).add(rule.startTs, 'seconds').unix(),
      moment.unix(dayTs).add(rule.endTs, 'seconds').unix(),
    ]
    unixRanges.push(range)
  }
  return unixRanges
}

export const getNextAvailableTimeRange = (timeRules, nowTs): number[] => {
  let timeRanges: number[][] = []
  for (const rule of timeRules) {
    timeRanges = timeRanges.concat(convertTimeRuleToUnixRange(rule, nowTs))
  }
  timeRanges = timeRanges.sort((a, b) => a[0] - b[0])
  for (const timeRange of timeRanges) {
    if (
      moment.unix(timeRange[0]).isAfter(moment.unix(nowTs)) ||
      moment.unix(timeRange[1]).isAfter(moment.unix(nowTs))
    ) {
      return timeRange
    }
  }
  return []
}

export const requestedTsIsWithinNextAvailableServiceHours = (requestedTs: number, service: IEstimateService) => {
  let timeRange
  if (service.serviceTimeRules.length > 0) {
    timeRange = getNextAvailableTimeRange(service.serviceTimeRules, requestedTs)
  }
  if (timeRange) {
    return (
      moment.unix(timeRange[0]).isBefore(moment.unix(requestedTs)) &&
      moment.unix(timeRange[1]).isAfter(moment.unix(requestedTs))
    )
  }
  return false
}

export const calculatePickupTime = ({
  estimatedPickupTime,
}: Pick<IEstimateOutput, 'estimatedPickupTime'>): IPickupTimes => ({
  pickupTimeLow: moment.unix(estimatedPickupTime.minTs).format('LT'),
  pickupTimeHigh: moment.unix(estimatedPickupTime.maxTs).format('LT'),
  pickupTime: moment.unix(estimatedPickupTime.ts).format('LT'),
})

export const calculateDropoffTime = ({
  estimatedDropoffTime,
}: Pick<IEstimateOutput, 'estimatedDropoffTime'>): IDropoffTimes => ({
  dropoffTimeLow: moment.unix(estimatedDropoffTime.minTs).format('LT'),
  dropoffTimeHigh: moment.unix(estimatedDropoffTime.maxTs).format('LT'),
  dropoffTime: moment.unix(estimatedDropoffTime.ts).format('LT'),
})

export const getMinutesUntilPickup = (scheduledPickupTs: number): number =>
  Math.max(moment.unix(scheduledPickupTs).diff(moment(), 'minutes'), 1)

export const getMinutesUntilPickupRange = ({
  minTs,
  maxTs,
  ts,
}: IEstimatedRange): { pickupMinutesLow: number; pickupMinutesHigh: number; pickupMinutes: number } => ({
  pickupMinutesLow: getMinutesUntilPickup(minTs),
  pickupMinutesHigh: getMinutesUntilPickup(maxTs),
  pickupMinutes: getMinutesUntilPickup(ts),
})

export const getCreatedInterface = (): CreatedInterface =>
  Platform.OS === OsType.Web ? CreatedInterface.RiderWeb : CreatedInterface.Rider

export const getEstimateQuery = (data: EstimatesUserInputParsed): IEstimateQueryData => ({
  requestedPickupAddress: data.requestedPickupAddress,
  requestedDropoffAddress: data.requestedDropoffAddress,
  requestedPickupLatitude: data.requestedPickupLocation.coordinates[1],
  requestedPickupLongitude: data.requestedPickupLocation.coordinates[0],
  requestedDropoffLatitude: data.requestedDropoffLocation.coordinates[1],
  requestedDropoffLongitude: data.requestedDropoffLocation.coordinates[0],
  createdInterface: getCreatedInterface(),
  ...(data.riders ? { ['riders[]']: data.riders.map((r) => JSON.stringify(r)) } : { numRiders: 1 }),
  ['accessibilityFeatures[]']: parseAccessibilityFeatureMapToArray(data.accessibilityFeatures).map((f) =>
    JSON.stringify(f)
  ),
})

export const parseAccessibilityFeatureMapToArray = (
  accessibilityMap?: AccessibilityCountMap
): IRequestAccessibilityFeature[] => {
  if (accessibilityMap) {
    return Object.entries(accessibilityMap).reduce(
      (features: IRequestAccessibilityFeature[], [type, count]): IRequestAccessibilityFeature[] => {
        if (type && count) {
          // Object.entries returns [string, any] so we lose our map typing
          const featureType = type as AccessibilityFeature
          features.push({ type: featureType, count })
        }
        return features
      },
      []
    )
  }
  return []
}

export const getNumRidersFromRiders = (riders: IRequestRiderType[]): number =>
  riders.reduce((res, current) => res + current.count, 0)

export const parseSuggestionToEstimateInput = (
  pickupField: SetAutoSuggestions,
  dropoffField: SetAutoSuggestions
): IEstimatesUserInput => {
  const requestedPickupAddress = getAddress(pickupField)
  const requestedDropoffAddress = getAddress(dropoffField)
  const pickupPlaceId = pickupField.type === AutoSuggestType.PlacesPrediction ? pickupField.placeId : undefined
  const dropoffPlaceId = dropoffField.type === AutoSuggestType.PlacesPrediction ? dropoffField.placeId : undefined

  return {
    requestedPickupAddress,
    requestedPickupLocation: pickupField.location,
    pickupPlaceId,
    requestedDropoffAddress,
    requestedDropoffLocation: dropoffField.location,
    dropoffPlaceId,
  }
}

const getAddress = (suggestion: AutoSuggestion): string | null => {
  if (suggestion.type === AutoSuggestType.CurrentLocation || suggestion.type === AutoSuggestType.SetLocationOnMap) {
    return null
  }
  if (suggestion.type === AutoSuggestType.Stop) {
    return suggestion.name
  }
  return suggestion.address || suggestion.name
}

export const hasWalkingDirections = (estimate: IEstimateOutput | null | undefined) =>
  (estimate && MapMarkerHelper.showStartLocation(estimate)) || (estimate && MapMarkerHelper.showPickupRadius(estimate))

export const isNextAvailablePickupOverTwoHours = (scheduledPickupTs: number) =>
  moment.unix(scheduledPickupTs).diff(moment(), 'hour') >= 2

// TODO this is fairly confusing logic and it is not clear by the values
// are set in this way, ideally we can redefine this using safe area view values instead
export const getEstimateCardTopOffset = (insets: EdgeInsets): number => {
  if (Platform.OS === OsType.Android) {
    if (StatusBar.currentHeight && StatusBar.currentHeight < ANDROID_WITH_NOTCH_BAR_HEIGHT) {
      // We check if currentHeight is available to prevent a null value
      return 70
    }
  }
  if (insets.top > 0) {
    return 45
  }
  return 50
}

export const getOriginDestinationPillMargin = (): number => {
  if (Platform.OS === OsType.Android) {
    return 8
  }
  return 0
}

export const getDropoffTimeString = (
  dropoffTimeLow: string,
  dropoffTimeHigh: string,
  dropoffTime: string,
  showTimeRanges: boolean
): string => {
  if (showTimeRanges) {
    return dropoffTimeHigh === dropoffTimeLow
      ? st.components.rideOptionsCardBody.dropoffTimeExact({ dropoffTime })
      : st.components.rideOptionsCardBody.dropoffTimeRange({
          dropoffTimeHigh,
          dropoffTimeLow,
        })
  }

  return st.components.rideOptionsCardBody.dropoffTimeAround({ dropoffTime })
}

export const getPickupTimeString = (
  pickupTimeLow: string,
  pickupTimeHigh: string,
  pickupTime: string,
  showTimeRanges: boolean
): string => {
  if (showTimeRanges) {
    return pickupTimeHigh === pickupTimeLow
      ? st.components.rideOptionsCardBody.pickupTimeExact({ pickupTime })
      : st.components.rideOptionsCardBody.pickupTimeRange({ pickupTimeLow, pickupTimeHigh })
  }

  return st.components.rideOptionsCardBody.pickupTimeAround({ pickupTime })
}

// This check is needed so we can show the service hours when all estimate responses were received and they are all null
export const areAllEstimatesNull = (
  estimateServices: IEstimateService[],
  estimateResponseMap: ObservableMap
): boolean =>
  Boolean(
    estimateResponseMap.size === estimateServices.length &&
      Array.from(estimateResponseMap.values()).every((estimate) => estimate === null)
  )

export const fetchAddress = async (location: IPoint | null) => {
  if (location) {
    const response = await reverseGeocode({
      latitude: location.coordinates[1],
      longitude: location.coordinates[0],
    })
    if (response.address) {
      return response.address
    }
  }

  return st.request.addressUnknown()
}

export const updateEstimateInput = (
  selectedTs: number,
  estimateInputStore: IEstimateInputStore,
  selectedEstimateType: EstimateScheduleOptions,
  dismissCallBack: () => void
): void => {
  const currentInput = estimateInputStore.getEstimateInput()
  let newRequestedPickupTs: number | null = null
  let newRequestedDropoffTs: number | null = null

  if (selectedEstimateType === EstimateScheduleOptions.Leave) {
    newRequestedPickupTs = selectedTs
  } else if (selectedEstimateType === EstimateScheduleOptions.Arrive) {
    newRequestedDropoffTs = selectedTs
  }

  if (
    newRequestedPickupTs !== currentInput.requestedPickupTs ||
    newRequestedDropoffTs !== currentInput.requestedDropoffTs
  ) {
    estimateInputStore.updateEstimateInput({
      ...currentInput,
      requestedPickupTs: newRequestedPickupTs,
      requestedDropoffTs: newRequestedDropoffTs,
    })
  }

  dismissCallBack()
}

export const isLyftPassLinkEnabled = (): boolean =>
  AuthenticatorHelper.getOrganization().featureFlags.includes(FeatureFlag.LyftPassLink) && Platform.OS !== OsType.Web

export const isLyftEstimateAvailable = (estimateStore: IEstimateStore): boolean =>
  estimateStore.lyftEstimates.length > 0
