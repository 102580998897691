import { IPaymentMethodResponse, PaymentMethodBrand } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { PaymentMethodList } from 'src/components/payments/PaymentMethodList'
import { PaymentProviderHelper } from 'src/helpers/payments/PaymentProviderHelper'
import { ParamsListAccount, ParamsListAddPaymentMethod, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { ServiceStore } from 'src/stores/ServiceStore'
import { ConnectedAccountType } from 'src/util/types'

type Props = ParamsListAccount[ScreenName.PaymentMethods] & {
  handleDismiss: () => void
  handleNavigateViewCash: (params: ParamsListAccount[ScreenName.ViewCash]) => void
  handleNavigateViewSepa: (params: ParamsListAccount[ScreenName.ViewSepa]) => void
  handleNavigateViewCreditCard: (params: ParamsListAccount[ScreenName.ViewCreditCard]) => void
  handleNavigateViewNimoca: (params: ParamsListAccount[ScreenName.ViewNimoca]) => void
  handleNavigateAddPaymentMethod: (params: ParamsListAddPaymentMethod[ScreenName.AddPaymentMethod]) => void
}

@observer
export class PaymentMethodsView extends Component<Props> {
  public onPaymentMethodPress = async (paymentMethodId: string | null) => {
    if (!paymentMethodId) {
      this.props.handleNavigateViewCash({})
    } else {
      const paymentMethod: IPaymentMethodResponse | undefined = PaymentMethodStore.getPaymentMethodById(paymentMethodId)
      if (paymentMethod && paymentMethod.connectedAccountType === ConnectedAccountType.Stripe) {
        if (paymentMethod.brand === PaymentMethodBrand.Sepa) {
          this.props.handleNavigateViewSepa({
            paymentMethodId: paymentMethod.id,
          })
        } else {
          this.props.handleNavigateViewCreditCard({
            paymentMethodId: paymentMethod.id,
          })
        }
      } else if (paymentMethod && paymentMethod.connectedAccountType === ConnectedAccountType.Nimoca) {
        this.props.handleNavigateViewNimoca({ paymentMethodId: paymentMethod.id })
      }
    }
  }

  public render() {
    return (
      <PaymentMethodList
        handleAddPaymentMethod={() =>
          this.props.handleNavigateAddPaymentMethod({
            validProviders: PaymentProviderHelper.getValidProviders(),
          })
        }
        onSelectPaymentMethod={this.onPaymentMethodPress}
        showSelected={false}
        isCashEnabled={ServiceStore.isCashEnabled}
      />
    )
  }
}

export const PaymentMethods = (props: ScreenPropsAccount<ScreenName.PaymentMethods>) => (
  <PaymentMethodsView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateViewCash={(params) => props.navigation.navigate(ScreenName.ViewCash, params)}
    handleNavigateViewSepa={(params) => props.navigation.navigate(ScreenName.ViewSepa, params)}
    handleNavigateViewCreditCard={(params) => props.navigation.navigate(ScreenName.ViewCreditCard, params)}
    handleNavigateViewNimoca={(params) => props.navigation.navigate(ScreenName.ViewNimoca, params)}
    handleNavigateAddPaymentMethod={(params) =>
      props.navigation.navigate(ScreenName.GroupAddPaymentMethod, {
        screen: ScreenName.AddPaymentMethod,
        params,
      })
    }
  />
)
