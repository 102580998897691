import { IEstimateOutput, ILeg, LegMode } from '@sparelabs/api-client'
import { Geography, IPoint } from '@sparelabs/geography'
import { last, uniqBy } from 'lodash'
import { Platform, StyleSheet } from 'react-native'
import { EdgePadding } from 'react-native-maps'
import { geoJsonPolygonToCoordList, geoJsonToCoords, lineStringToCoords } from 'src/helpers/MapHelper'
import { COLLAPSED_CARD_HEIGHT } from 'src/helpers/RideOptionsCardHelper'
import { IMarkerCoordinates, Mappable, MarkerTypes } from 'src/types'
import { OsType } from 'src/util/types'

export const START_PIN_SIZE = 12
export const END_PIN_SIZE = 16
export const STOP_PIN_SIZE = 8
export const PICKUP_DROPOFF_PIN_SIZE = 20
export const VEHICLE_PIN_HEIGHT = 40
export const VEHICLE_PIN_WIDTH = 40

// offsets to center the image since markers are positioned at the top left of given position, should be centered
export const PICKUP_DROPOFF_PIN_OFFSET = PICKUP_DROPOFF_PIN_SIZE / 2
export const START_PIN_OFFSET = START_PIN_SIZE / 2
export const END_PIN_OFFSET = END_PIN_SIZE / 2

export const mapMarkerStyles = StyleSheet.create({
  startPin: {
    width: START_PIN_SIZE,
    height: START_PIN_SIZE,
  },
  endPin: {
    width: END_PIN_SIZE,
    height: END_PIN_SIZE,
  },
  stopPin: {
    width: STOP_PIN_SIZE,
    height: STOP_PIN_SIZE,
  },
  pickupDropoffPin: {
    width: PICKUP_DROPOFF_PIN_SIZE,
    height: PICKUP_DROPOFF_PIN_SIZE,
  },
  vehiclePin: {
    height: VEHICLE_PIN_HEIGHT,
    width: VEHICLE_PIN_WIDTH,
  },
  searchLocationPin: {
    height: 32,
    width: 32,
  },
})

// Set android anchor to middle of image
export const ANDROID_ANCHOR = {
  x: 0.5,
  y: 0.5,
}

// We want the pickupAreaPill to be slightly off center on Y axis
export const pickupPillAndroidAnchor = {
  x: 0.5,
  y: 1.5,
}

const MIN_STOP_DISTANCE_M = 5
export const MIN_WALKING_DURATION_S = 10

export class MapMarkerHelper {
  public static getEdgePadding = (mappable?: Mappable): EdgePadding => {
    // We want the pickup radius to be closer to the edge of the map
    const padding: number = mappable && MapMarkerHelper.showPickupRadius(mappable) ? 50 : 130
    return { top: padding, right: padding, bottom: COLLAPSED_CARD_HEIGHT + padding / 2, left: padding }
  }

  // Should we show Requested/Scheduled if they overlap/radius
  public static showStartLocation = (mappable: Mappable): boolean =>
    !MapMarkerHelper.doLocationsOverlap(
      mappable.requestedPickupLocation,
      mappable.scheduledPickupLocation,
      mappable.pickupWalkingDuration
    ) && !MapMarkerHelper.showPickupRadius(mappable)

  // Get the start location that is shown on the map
  public static getPickupLocation = (mappable: Mappable): IPoint =>
    !MapMarkerHelper.showPickupRadius(mappable) && mappable.scheduledPickupLocation !== null
      ? mappable.scheduledPickupLocation
      : mappable.requestedPickupLocation

  // Should we show Requested/Scheduled if they overlap/radius
  public static showEndLocation = (mappable: Mappable): boolean =>
    !MapMarkerHelper.doLocationsOverlap(
      mappable.requestedDropoffLocation,
      mappable.scheduledDropoffLocation,
      mappable.dropoffWalkingDuration
    )

  // Get the end location that is shown on the map
  public static getDropoffLocation = (mappable: Mappable): IPoint =>
    mappable.scheduledDropoffLocation !== null ? mappable.scheduledDropoffLocation : mappable.requestedDropoffLocation

  public static showPickupRadius = (mappable: Pick<IEstimateOutput, 'scheduledPickupRadius'> | {}): boolean => {
    if ((mappable as IEstimateOutput).scheduledPickupRadius) {
      return true
    }
    return false
  }

  public static getAllCoordinates = (mappable: Mappable): IMarkerCoordinates[] => [
    ...MapMarkerHelper.getPickupMarkerCoordinates(mappable),
    ...MapMarkerHelper.getDropoffMarkerCoordinates(mappable),
  ]

  public static getPickupMarkerCoordinates = (mappable: Mappable): IMarkerCoordinates[] => {
    const result: IMarkerCoordinates[] = [geoJsonToCoords(mappable.requestedPickupLocation)]
    if (mappable.scheduledPickupLocation) {
      result.push(geoJsonToCoords(mappable.scheduledPickupLocation))
    }
    // We only want to zoom to the walking polyline if we are showing start location and pickup separately
    if (mappable.pickupWalkingPolyline && MapMarkerHelper.showStartLocation(mappable)) {
      result.push(...lineStringToCoords(mappable.pickupWalkingPolyline))
    }
    const estimate = mappable as IEstimateOutput
    if (estimate.scheduledPickupRadius) {
      const circleCoords: IMarkerCoordinates[] | undefined = geoJsonPolygonToCoordList(
        Geography.circle(estimate.requestedPickupLocation, estimate.scheduledPickupRadius)
      )
      if (circleCoords) {
        result.push(...circleCoords)
      }
    }

    // Remove duplicate co-ordinates
    return uniqBy(result, (res) => res.latitude && res.longitude)
  }

  public static getDropoffMarkerCoordinates = (mappable: Mappable): IMarkerCoordinates[] => {
    const result: IMarkerCoordinates[] = [geoJsonToCoords(mappable.requestedDropoffLocation)]
    if (mappable.scheduledDropoffLocation) {
      result.push(geoJsonToCoords(mappable.scheduledDropoffLocation))
    }
    return result
  }

  // Are the stops close to each other to overlap
  public static doLocationsOverlap(
    requestedLocation: IPoint,
    scheduledLocation: IPoint | null,
    walkingDuration: number | null
  ): boolean {
    if (scheduledLocation === null || walkingDuration === null) {
      // If the scheduled location doesn't exist, the locations are the "same" so they "overlap"
      return true
    }
    return (
      walkingDuration < MIN_WALKING_DURATION_S ||
      Geography.distance(requestedLocation, scheduledLocation) < MIN_STOP_DISTANCE_M
    )
  }

  public static doesLegOverlapLocation(location: IPoint, leg: ILeg, markerType: MarkerTypes): boolean {
    if (leg.mode === LegMode.Walk) {
      return false
    }
    if (leg.mode === LegMode.OnDemand) {
      if (markerType === MarkerTypes.EndPin) {
        return Geography.distance(location, leg.end.location) < MIN_STOP_DISTANCE_M
      }
      return Geography.distance(location, leg.start.location) < MIN_STOP_DISTANCE_M
    }

    if (markerType === MarkerTypes.StartPin) {
      return Geography.distance(location, leg.stops[0].location) < MIN_STOP_DISTANCE_M
    }
    const lastStop = last(leg.stops)
    if (!lastStop) {
      return false
    }
    return Geography.distance(location, lastStop.location) < MIN_STOP_DISTANCE_M
  }

  public static getPickupPillCenterOffSet = (radius: number) => ({ x: 0, y: -radius * MapMarkerHelper.getYAxisValue() })

  public static getYAxisValue = () => 0.2

  public static getStringDashArray = (dashArray: number[]): string =>
    dashArray.reduce((previousValue, currentValue) => `${previousValue} ${currentValue}`, '')

  /* On Android devices, the polyline width looks too thin, so we increase the width based on the platform */
  public static getPolylineStrokeWidth = (): number => (Platform.OS === OsType.Android ? 4 : 3)
}
