import { IJourney } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { JourneyRoute } from 'src/components/journey/JourneyRoute'
import { multimodalEnabled } from 'src/helpers/RideOptionsCardHelper'
import { st } from 'src/locales'
import { IJourneyStore } from 'src/stores/JourneyStore'

const styles = StyleSheet.create({
  sectionTitleContainer: {
    borderBottomWidth: 1,
    borderColor: colors.borderBlue,
    paddingTop: 16,
    paddingBottom: 8,
    paddingHorizontal: 16,
  },
  sectionTitle: {
    fontSize: 14,
    color: colors.gray70,
    lineHeight: 16,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
})

interface IProps {
  selectedRideOptionId: string | null
  setRideOptions: (rideOptionId: string) => void
  journeyStore: IJourneyStore
  showSectionTitles: boolean
}

interface IState {
  showRefreshButton: boolean
}

@observer
export class RideOptionsJourneysList extends Component<IProps, IState> {
  public render() {
    if (multimodalEnabled() && this.props.journeyStore.journeys && this.props.journeyStore.journeys.length > 0) {
      return (
        <View>
          {this.renderMixedOptions()}
          {this.renderTransitOptions()}
          {this.renderOtherOptions()}
        </View>
      )
    }

    return null
  }

  private readonly renderJourneys = (journeys: IJourney[]) =>
    journeys.map((journey, index) => (
      <TouchableOpacity
        key={`${journey.startTime}-${journey.endTime}-${journey.legs.length}-${index}`}
        onPress={() => this.handleOnSelectJourney(journey)}
        accessible={true}
        accessibilityRole='radio'
        accessibilityState={{ selected: this.props.selectedRideOptionId === journey.id }}
      >
        <JourneyRoute journey={journey} key={index} selected={this.props.selectedRideOptionId === journey.id} />
      </TouchableOpacity>
    ))

  private readonly renderMixedOptions = () => {
    if (this.props.journeyStore.mixedJourneys.length) {
      return (
        <View>
          {this.props.showSectionTitles && (
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>{st.components.rideOptionsCard.mixedOptions()}</Text>
            </View>
          )}
          {this.renderJourneys(this.props.journeyStore.mixedJourneys)}
        </View>
      )
    }

    return null
  }

  private readonly renderTransitOptions = () => {
    if (this.props.journeyStore.transitJourneys.length) {
      return (
        <View>
          {this.props.showSectionTitles && (
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>{st.components.rideOptionsCard.transitOptions()}</Text>
            </View>
          )}
          {this.renderJourneys(this.props.journeyStore.transitJourneys)}
        </View>
      )
    }

    return null
  }

  private readonly renderOtherOptions = () => {
    if (this.props.journeyStore.walkingOnlyJourney.length) {
      return (
        <View>
          {this.props.showSectionTitles && (
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>{st.components.rideOptionsCard.otherOptions()}</Text>
            </View>
          )}
          {this.renderJourneys(this.props.journeyStore.walkingOnlyJourney)}
        </View>
      )
    }

    return null
  }

  private readonly handleOnSelectJourney = (journey: IJourney) => {
    this.props.setRideOptions(journey.id)
  }
}
