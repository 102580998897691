import { FavoriteLocationType, IFavoriteLocationResponse, Resources } from '@sparelabs/api-client'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'

export const MAX_NUM_FAVORITE_LOCATIONS = 8

class FavoriteStoreClass {
  public name = 'FavoriteLocationStore'

  @persist('object')
  @observable
  public favorites = observable<IFavoriteLocationResponse>([])

  constructor() {
    makeObservable(this)
  }

  @computed
  public get hasHomeSet() {
    return this.favorites.find((favorite) => favorite.type === FavoriteLocationType.Home)
  }

  @computed
  public get hasWorkSet() {
    return this.favorites.find((favorite) => favorite.type === FavoriteLocationType.Work)
  }

  // Favorites that are not work or home
  @computed
  public get otherFavorites() {
    return this.favorites.filter(
      (fav) => fav.type !== FavoriteLocationType.Work && fav.type !== FavoriteLocationType.Home
    )
  }

  public filterByType(type: FavoriteLocationType): IFavoriteLocationResponse[] {
    return this.favorites.filter((fav) => fav.type === type)
  }

  public findById(id: string): IFavoriteLocationResponse | undefined {
    return this.favorites.find((fav) => fav.id === id)
  }

  @action
  public clear() {
    this.favorites = observable<IFavoriteLocationResponse>([])
  }

  @action
  public updateWithNewFavorite(newFavorite: IFavoriteLocationResponse) {
    const oldFavorite = this.favorites.find((fav) => fav.id === newFavorite.id)
    if (oldFavorite) {
      this.favorites.remove(oldFavorite)
      this.favorites.push(newFavorite)
    } else {
      this.favorites.push(newFavorite)
    }
  }

  @action
  public remove(id: string) {
    const oldFavorite = this.findById(id)
    if (oldFavorite) {
      this.favorites.remove(oldFavorite)
    }
  }

  @action
  public async fetchFavoriteLocations() {
    try {
      const res = await LegacyApiClient.get(AuthenticatorHelper.getUserOrgToken(), Resources.FavoriteLocations)
      if (res) {
        runInAction(() => {
          this.favorites = res.body.data
        })
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    }
  }
}

export const FavoriteLocationStore = new FavoriteStoreClass()
