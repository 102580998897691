import { IUserStub } from '@sparelabs/api-client'
import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { hash } from '../helpers/StringHelper'

const styles = StyleSheet.create({
  profileImage: {
    height: 52,
    width: 52,
    borderRadius: 26,
  },
  profileTintedImage: {
    tintColor: colors.gray1,
  },
  initials: {
    fontSize: 20,
    backgroundColor: 'transparent',
    textAlign: 'center',
    color: colors.gray1,
  },
  initialsWrapper: {
    height: 52,
    width: 52,
    borderRadius: 26,
    backgroundColor: '#DCDCDC',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

interface IProps {
  user: Pick<IUserStub, 'firstName' | 'lastName' | 'photoUrl'>
  icon?: string
  height: number
}

export const ProfileImage = ({ user, icon, height }: IProps): JSX.Element =>
  user.photoUrl && !icon ? (
    <Image
      resizeMode='cover'
      style={[
        styles.profileImage,
        height
          ? {
              height,
              width: height,
              borderRadius: height / 2,
            }
          : null,
      ]}
      source={{ uri: user.photoUrl }}
      key={hash(user.photoUrl)}
    />
  ) : (
    <View
      style={[
        styles.initialsWrapper,
        height
          ? {
              height,
              width: height,
              borderRadius: height / 2,
            }
          : null,
      ]}
    >
      {icon || !user.firstName ? (
        <Image resizeMode='cover' style={styles.profileTintedImage} source={icon ? icon : Images.user} />
      ) : (
        <Text style={[styles.initials, height ? { fontSize: height * 0.35 } : null]}>
          {user.firstName[0]}
          {user.lastName ? user.lastName[0] : null}
        </Text>
      )}
    </View>
  )
