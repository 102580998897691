import React from 'react'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { mapMarkerStyles, STOP_PIN_SIZE } from 'src/helpers/MapMarkerHelper'
import { IMarker, MarkerTypes } from 'src/types'
import { ImageMarker } from '../ImageMarker'
import { IStopMarkersProp } from './StopMarkerType'

export const StopMarkers = ({ stops }: IStopMarkersProp): JSX.Element => (
  <>
    {stops.map((stop) => {
      const marker: IMarker = {
        name: `stop-pin-${stop.id}` as MarkerTypes,
        coordinate: stop.location,
        imageStyle: mapMarkerStyles.stopPin,
        child: <SvgIconWrapper icon={SvgIcon.StopPin} widthFixed={STOP_PIN_SIZE} heightFixed={STOP_PIN_SIZE} />,
      }
      return <ImageMarker key={stop.id} {...marker} />
    })}
  </>
)
