import React from 'react'
import { Animated, StyleSheet, View, ViewStyle } from 'react-native'

interface IProps {
  backgroundColor: string
  barColor: string
  progressPercent: number
  barHeight?: number
}

const styles = StyleSheet.create({
  progressBarContainer: {
    borderRadius: 100,
    overflow: 'hidden',
    height: 8,
    flex: 1,
    width: '100%',
  },
})

export const ProgressBar = ({ backgroundColor, barHeight, barColor, progressPercent }: IProps) => {
  const progressAnimation: ViewStyle = {
    width: `${progressPercent}%`,
    bottom: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  }

  return (
    <View style={[styles.progressBarContainer, { backgroundColor }, { height: barHeight ?? 8 }]}>
      <Animated.View style={[progressAnimation, { backgroundColor: barColor }]} />
    </View>
  )
}
