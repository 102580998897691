import { ActionSheetProps, useActionSheet } from '@expo/react-native-action-sheet'
import { NavigationProp } from '@react-navigation/native'
import React, { createRef } from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'src/assets/colors'
import { RequestCustomFieldsForm } from 'src/components/request/RequestCustomFieldsForm'
import { handleError } from 'src/helpers/ErrorHelpers'
import { IKeyboardSafeScrollView, KeyboardSafeScrollView } from 'src/lib/KeyboardSafeScrollView'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { RouterStore } from 'src/stores/RouterStore'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blue10,
    height: '100%',
    flex: 1,
  },
})

type IRequestCustomFieldCardProps = ParamsListRoot[ScreenName.RequestCustomFields] & {
  navigation: NavigationProp<ParamsListRoot>
  handleDismiss: () => void
} & ActionSheetProps

export const RequestCustomFieldsCard = (props: IRequestCustomFieldCardProps): JSX.Element => {
  const scrollRef = createRef<IKeyboardSafeScrollView>()
  const loadingStore = new LoadingStore()
  const handlePressConfirm = async (metadata: Record<string, unknown> | null): Promise<void> => {
    try {
      if (metadata !== null) {
        props.estimateInputStore.updateEstimateMetadataInput({
          ...props.estimateInputStore.getEstimateMetadataInput(),
          ...metadata,
        })
      }
      await RouterStore.goToScreen({
        pathname: props.confirmServicePathname,
      })
      props.handleDismiss()
    } catch (error) {
      handleError({ error: error as Error })
    }
  }

  const onUserError = (verticalFieldPosition: number) => {
    scrollRef.current?.scrollTo(verticalFieldPosition)
  }

  return (
    <KeyboardSafeScrollView ref={scrollRef} containerStyle={styles.container} verticalOffset={10}>
      <RequestCustomFieldsForm
        navigation={props.navigation}
        onSubmit={handlePressConfirm}
        loadingStore={loadingStore}
        estimateInputStore={props.estimateInputStore}
        displayErrors={props.displayErrors}
        onUserError={onUserError}
      />
    </KeyboardSafeScrollView>
  )
}

export const RequestCustomFields = (props: ScreenPropsRoot<ScreenName.RequestCustomFields>) => {
  const { showActionSheetWithOptions } = useActionSheet()
  return (
    <RequestCustomFieldsCard
      {...props.route.params}
      showActionSheetWithOptions={showActionSheetWithOptions}
      handleDismiss={() => props.navigation.pop()}
      navigation={props.navigation}
      displayErrors={props.route.params.displayErrors}
    />
  )
}
