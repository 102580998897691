import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { assignGroup } from 'src/api'
import { colors } from 'src/assets/colors'
import { TertiaryButton } from 'src/components/buttons/TertiaryButton'
import { TextField } from 'src/components/textField/TextField'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { captureError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'

const ASSIGN_GROUP_KEY = 'assignGroup'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  containerCell: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    paddingTop: 16,
    paddingRight: 20,
    paddingBottom: 20,
  },
  description: {
    fontSize: 16,
    color: colors.textDark,
  },
  buttonContainer: {
    backgroundColor: colors.blue10,
    fontSize: 18,
    height: 56,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
    width: '100%',
  },
  buttonText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.blue50,
  },
})

type Props = ParamsListAccount[ScreenName.JoinGroup] & {
  handleDismiss: () => void
}

interface IState {
  secretCode: string
}

export class JoinGroupView extends Component<Props, IState> {
  private readonly loadingStore: LoadingStore = new LoadingStore()

  constructor(props: Props) {
    super(props)
    this.state = {
      secretCode: '',
    }
  }

  public render() {
    return (
      <View style={styles.container}>
        <View style={styles.containerCell}>
          <Text style={styles.description}>{st.screens.group.groupConnectDescription()}</Text>
          <View style={{ paddingVertical: 24 }}>
            <TextField
              label={st.screens.group.secretCode()}
              onChangeText={(secretCode) => this.setState({ secretCode })}
              tintColor={colors.primaryColor}
              value={this.state.secretCode}
            />
          </View>
          <TertiaryButton
            title={st.screens.group.enterButton()}
            onPress={this.handlePressConfirm}
            loading={this.loadingStore.isLoading(ASSIGN_GROUP_KEY)}
          />
        </View>
      </View>
    )
  }

  private readonly handlePressConfirm = async () => {
    await this.loadingStore.execute(this.assignGroup(this.state.secretCode), ASSIGN_GROUP_KEY)
  }

  private readonly assignGroup = async (secretCode: string) => {
    try {
      if (AuthenticatorHelper.userOrgToken) {
        await assignGroup({
          token: AuthenticatorHelper.userOrgToken,
          secretCode,
        })
      }
      this.props.handleDismiss()
    } catch (e) {
      const error = e as IErrorWithResponse
      captureError(error)
      AlertHelper.alert(st.screens.group.joinErrorTitle(), st.screens.group.joinErrorText())
    }
  }
}

export const JoinGroup = (props: ScreenPropsAccount<ScreenName.JoinGroup>) => (
  <JoinGroupView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
