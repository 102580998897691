import { IRequestResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { Timeline } from 'src/components/request/Timeline'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    flexDirection: 'column',
  },
})

interface IProps {
  request: IRequestResponse
  containerStyle?: ViewStyle
}

@observer
export class RequestTimeline extends Component<IProps> {
  public render() {
    const { request, containerStyle } = this.props
    const isPickupDifferentFromStart = request.scheduledPickupAddress !== request.requestedPickupAddress
    const isDropoffDifferentFromEnd = request.scheduledDropoffAddress !== request.requestedDropoffAddress
    return (
      <View style={[styles.container, containerStyle]}>
        <View style={{ marginBottom: 8 }} />
        <Timeline
          requestedPickupAddress={request.requestedPickupAddress}
          requestedDropoffAddress={request.requestedDropoffAddress}
          scheduledPickupAddress={request.scheduledPickupAddress}
          scheduledDropoffAddress={request.scheduledDropoffAddress}
          isDropoffDifferentFromEnd={isDropoffDifferentFromEnd}
          isPickupDifferentFromStart={isPickupDifferentFromStart}
          pastTripItem={true}
        />
      </View>
    )
  }
}
