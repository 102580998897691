import { checkForUpdateAsync, fetchUpdateAsync, reloadAsync } from 'expo-updates'
import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { IS_DEVELOPMENT } from 'src/consts/Constants'
import { OsType } from 'src/util/types'

export const useFetchUpdate = () => {
  const [isUpdateFetched, setIsUpdateFetched] = useState(false)

  useEffect(() => {
    void prepare()
  }, [])

  const shouldFetchUpdate = async () => {
    let updateCheckResult = false
    if (!IS_DEVELOPMENT && Platform.OS !== OsType.Web) {
      const checkResult = await checkForUpdateAsync()
      updateCheckResult = checkResult.isAvailable
    }
    return updateCheckResult
  }

  const fetchUpdate = async () => {
    await fetchUpdateAsync()
    await reloadAsync()
  }

  const prepare = async () => {
    try {
      const updateCheckResult = await shouldFetchUpdate()
      if (updateCheckResult) {
        await fetchUpdate()
      }
    } catch (e) {
      // eslint-disable-next-line
      console.warn(e)
    } finally {
      setIsUpdateFetched(true)
    }
  }

  return isUpdateFetched
}
