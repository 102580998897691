import { IPromoAllocationResponse, PromoAllocationStatus, Resources } from '@sparelabs/api-client'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { LegacyApiClient, redeemPromos } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'

class PromoStoreClass {
  public name = 'PromoStore'

  @observable
  @persist('object')
  public promotions: IPromoAllocationResponse[] = []

  @observable public loading: boolean = false

  @action
  private setLoading(loading: boolean) {
    this.loading = loading
  }

  @action
  public getPromos = async () => {
    try {
      this.loading = true
      if (AuthenticatorHelper.userOrgToken) {
        const res = await LegacyApiClient.get(AuthenticatorHelper.userOrgToken, Resources.PromoAllocations, {
          orderBy: 'expiryTs',
          orderDirection: 'ASC',
        })
        if (res) {
          const allocations = res.body.data as IPromoAllocationResponse[]
          runInAction(() => {
            this.promotions = allocations.filter((allocation) => allocation.status === PromoAllocationStatus.Unused)
          })
        }
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    } finally {
      this.setLoading(false)
    }
  }

  @action public async redeemPromo(code: string) {
    try {
      this.loading = true
      if (AuthenticatorHelper.userOrgToken) {
        const res = await redeemPromos(AuthenticatorHelper.userOrgToken, AuthenticatorHelper.getUser().id, code)
        if (res) {
          const newPromo = res.body as IPromoAllocationResponse
          this.promotions = [...this.promotions, newPromo].sort((a, b) => a.expiryTs - b.expiryTs)
        }
      }
    } finally {
      this.setLoading(false)
    }
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public clear() {
    this.promotions = []
    this.setLoading(false)
  }
}

export const PromoStore = new PromoStoreClass()
