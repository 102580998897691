import { NavigationProp } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { colors } from 'src/assets/colors'
import { ConfirmPhoneNumberForm } from 'src/components/form/ConfirmPhoneNumberForm'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { UserMeHelper } from 'src/helpers/UserMeHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsEditPhoneNumber } from 'src/navigation'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
})

interface IEditPhoneNumberConfirmViewProps {
  handleSubmit: (confirmationCode: string) => Promise<void>
}

export const EditPhoneNumberConfirmView = ({ handleSubmit }: IEditPhoneNumberConfirmViewProps) => (
  <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
    <ConfirmPhoneNumberForm
      inputDescription={st.screens.setPhoneNumberConfirm.verificationCodeDescription()}
      handleSubmit={handleSubmit}
    />
  </ScrollView>
)

export const EditPhoneNumberConfirm = observer(
  ({ navigation }: ScreenPropsEditPhoneNumber<ScreenName.EditPhoneNumberConfirm>): JSX.Element => {
    const handleSubmit = async (confirmationCode: string) => {
      const result = await UserMeHelper.userMeVerifyPhone(confirmationCode)

      if (result) {
        // if organization is already selected, then prompt user to setup profile if necessary
        // otherwise navigate to our home screen
        if (AuthenticatorHelper.organization) {
          if (AuthenticatorHelper.isFullUser(AuthenticatorHelper.getUser())) {
            navigation
              .getParent<NavigationProp<ParamsListRoot>>()
              .reset({ routes: [{ name: ScreenName.RootHome, params: {} }] })
          } else {
            navigation.navigate(ScreenName.SetProfile, { isOnboarding: true })
          }
        } else {
          navigation
            .getParent<NavigationProp<ParamsListRoot>>()
            .reset({ routes: [{ name: ScreenName.RootHome, params: {} }] })
        }
      }
    }

    return <EditPhoneNumberConfirmView handleSubmit={handleSubmit} />
  }
)
