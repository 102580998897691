import {
  FarePassAllocationStatus,
  FarePassType,
  IChargeResponse,
  IFarePassAllocationResponse,
  IFarePassStub,
  Resources,
} from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { DAY } from '@sparelabs/time'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { LegacyApiClient } from 'src/api'
import { colors } from 'src/assets/colors'
import { TextButton } from 'src/components/buttons/TextButton'
import { FarePassCard } from 'src/components/farePasses/FarePassCard'
import { ProgressBar } from 'src/components/ProgressBar'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { moment } from 'src/helpers/Moment'
import { PaymentMethodHelper } from 'src/helpers/payments/PaymentMethodHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { FarePassStore } from 'src/stores/FarePassStore'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { findBalanceProgress, findExpiryProgress } from './FarePassAllocationProgressHelper'

interface IProps {
  allocationId: string
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
}

interface IState {
  paymentMethodShortText: string
}

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    margin: 16,
  },
  title: {
    marginTop: 16,
    marginBottom: 4,
    fontSize: 24,
    color: colors.gray6,
  },
  titleBold: {
    marginTop: 16,
    marginBottom: 4,
    fontSize: 24,
    color: colors.gray6,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 16,
    lineHeight: 20,
  },
  summary: {
    paddingBottom: 24,
    borderBottomWidth: 1,
    borderBottomColor: colors.borderColor,
  },
  remaining: {
    fontSize: 18,
  },
  label: {
    marginTop: 24,
    fontWeight: 'bold',
    fontSize: 16,
    color: colors.gray6,
  },
  value: {
    fontSize: 16,
    color: colors.gray6,
  },
  terms: {
    fontSize: 12,
    lineHeight: 16,
    color: colors.gray1,
  },
  termsURL: {
    fontSize: 12,
    lineHeight: 16,
    color: colors.selectedBlue,
  },
  progressBarMask: {
    borderRadius: 100,
    overflow: 'hidden',
    height: 12,
    marginVertical: 8,
  },
})

@observer
export class FarePassAllocationView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { paymentMethodShortText: st.screens.viewFarePass.unknownPaymentMethod() }
  }

  public componentDidMount = async () => {
    await this.loadPaymentMethodText()
  }

  public handlePressTerms = async ({ farePass }: IFarePassAllocationResponse) => {
    // quick validation of terms of use url on the client side in case bad data makes into the API
    if (farePass.termsOfUseUrl && farePass.termsOfUseUrl.startsWith('https://')) {
      this.props.handleNavigateWebView({
        uri: farePass.termsOfUseUrl,
      })
    }
  }

  public render() {
    const allocation = FarePassStore.getFarePassAllocation(this.props.allocationId)
    const farePass = allocation.farePass
    const priceWithCurrency = CurrencyHelper.format(farePass.cost, farePass.currency)

    return (
      <ScrollView style={styles.scrollContainer}>
        <View style={styles.container}>
          <View>
            <FarePassCard farePass={farePass} />
          </View>
          <View style={styles.summary}>
            {farePass.type === FarePassType.TimeBased && this.renderExpiry(allocation)}
            {farePass.type === FarePassType.StoredValue && this.renderBalance(allocation)}
            <Text style={styles.description}>{farePass.description}</Text>
          </View>
          <View>
            {farePass.type === FarePassType.TimeBased && this.renderExpiryRow(allocation)}
            {farePass.type === FarePassType.StoredValue && this.renderBalanceRow(allocation)}
            <Text style={styles.label}>{st.screens.viewFarePass.purchaseDateTitle()}</Text>
            <Text style={styles.value}>{moment.unix(allocation.createdAt).format('LL')}</Text>
            <Text style={styles.label}>{st.screens.viewFarePass.purchasePriceTitle()}</Text>
            <Text style={styles.value}>{priceWithCurrency}</Text>
            <Text style={styles.label}>{st.screens.viewFarePass.paymentMethodTitle()}</Text>
            <Text style={styles.value}>{this.state.paymentMethodShortText}</Text>
            <Text style={styles.label}>{st.screens.viewFarePass.termsTitle()}</Text>
            <Text style={styles.terms}>{farePass.simpleTermsOfUse}</Text>
            <TextButton
              textStyle={styles.termsURL}
              onPress={() => this.handlePressTerms(allocation)}
              text={st.components.confirmPurchase.termsLinkText()}
            />
          </View>
        </View>
      </ScrollView>
    )
  }

  private readonly loadPaymentMethodText = async () => {
    const allocation = FarePassStore.getFarePassAllocation(this.props.allocationId)
    const charge = await this.getCharge(allocation.chargeId)
    if (charge) {
      const paymentMethod = PaymentMethodStore.getPaymentMethodById(charge.paymentMethodId)
      if (paymentMethod) {
        this.setState({
          paymentMethodShortText: PaymentMethodHelper.getShortPaymentMethodName(paymentMethod),
        })
      }
    }
  }

  private readonly getCharge = async (chargeId: string) => {
    let charge: IChargeResponse | null = null
    try {
      const res = await LegacyApiClient.get(AuthenticatorHelper.getUserOrgToken(), `${Resources.Charges}/${chargeId}`)
      if (res) {
        charge = res.body
      }
    } catch (error) {
      handleError({ error: error as IErrorWithResponse, silent: true })
    }
    return charge
  }

  private readonly renderExpiry = ({ status, createdAt, farePass }: IFarePassAllocationResponse) => {
    if (farePass.validDaysCount) {
      const currentUnixTime = moment().unix()
      const progress = findExpiryProgress(createdAt, farePass, currentUnixTime)
      return (
        <View>
          <Text style={styles.title}>{this.getExpiryText(status, createdAt, farePass)}</Text>
          <View style={styles.progressBarMask}>
            <ProgressBar
              progressPercent={progress * 100}
              barColor={colors.borderColor}
              backgroundColor={farePass.color}
            />
          </View>
        </View>
      )
    }

    return null
  }

  private readonly renderExpiryRow = ({ status, createdAt, farePass }: IFarePassAllocationResponse) => {
    if (farePass.validDaysCount) {
      return (
        <View>
          <Text style={styles.label}>{st.screens.viewFarePass.expiryDateTitle()}</Text>
          <Text style={styles.value}>
            {status === FarePassAllocationStatus.Deactivated
              ? st.screens.viewFarePass.deactivatedExplanation()
              : this.getExpiryText(status, createdAt, farePass)}
          </Text>
        </View>
      )
    }

    return null
  }

  private readonly getExpiryText = (
    status: FarePassAllocationStatus,
    createdAt: number,
    farePass: IFarePassStub
  ): string => {
    const expiryTimestamp = createdAt + DAY * (farePass.validDaysCount ?? 0)
    let expiryText = ''
    switch (status) {
      case FarePassAllocationStatus.Expired:
        expiryText = st.screens.viewFarePass.expired({
          date: moment.unix(expiryTimestamp).format('ll'),
          time: moment.unix(expiryTimestamp).format('LT'),
        })
        break
      case FarePassAllocationStatus.Deactivated:
        expiryText = st.screens.viewFarePass.deactivated()
        break
      case FarePassAllocationStatus.Active:
      default:
        expiryText = st.screens.viewFarePass.expiry({
          date: moment.unix(expiryTimestamp).format('ll'),
          time: moment.unix(expiryTimestamp).format('LT'),
        })
    }

    return expiryText
  }

  private readonly renderBalanceRow = ({ amountRedeemed, farePass }: IFarePassAllocationResponse) => {
    if (farePass.amount) {
      const creditRemaining = CurrencyHelper.format(farePass.amount - amountRedeemed, farePass.currency)
      return (
        <View>
          <Text style={styles.label}>{st.screens.viewFarePass.creditRemainingTitle()}</Text>
          <Text style={styles.value}>{st.screens.viewFarePass.creditRemaining({ amount: creditRemaining })}</Text>
        </View>
      )
    }
    return null
  }

  private readonly renderBalance = ({ amountRedeemed, farePass }: IFarePassAllocationResponse) => {
    if (farePass.amount) {
      const creditRemaining = CurrencyHelper.format(farePass.amount - amountRedeemed, farePass.currency)
      const progress = findBalanceProgress(amountRedeemed, farePass)
      return (
        <View>
          <Text style={styles.titleBold}>{farePass.name}</Text>
          <Text style={styles.remaining}>{st.screens.viewFarePass.creditRemaining({ amount: creditRemaining })}</Text>
          <View style={styles.progressBarMask}>
            <ProgressBar
              progressPercent={progress * 100}
              barColor={farePass.color}
              backgroundColor={colors.borderColor}
            />
          </View>
        </View>
      )
    }
    return null
  }
}
