import { IRequestInsightsResponse, IRequestResponse } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { IconCircle } from 'src/components/IconCircle'
import { EmissionsDetailsHelper } from 'src/helpers/EmissionsDetailsHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { RequestInsightsStore } from 'src/stores/RequestInsightsStore'

const getStyles = () =>
  StyleSheet.create({
    container: {
      backgroundColor: colors.white,
      flexDirection: 'row',
      paddingHorizontal: 4,
    },
    rightArrow: {
      alignSelf: 'center',
      height: 20,
      width: 12,
      tintColor: colors.gray90,
    },
    icon: {
      position: 'relative',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: 32,
      height: 32,
      marginRight: 8,
    },
    textContainer: {
      flex: 1,
    },
    text: {
      color: colors.gray70,
      fontSize: 16,
      marginRight: 16,
    },
    divider: {
      borderTopColor: colors.borderGray,
      borderTopWidth: 1,
      marginVertical: 16,
    },
  })

interface IProps {
  handleNavigateEmissionDetails: (params: ParamsListRoot[ScreenName.EmissionsDetails]) => void
  request: IRequestResponse
}

@observer
export class TripEmissions extends Component<IProps> {
  private readonly onPress = async (requestInsights: IRequestInsightsResponse) => {
    const { request } = this.props
    this.props.handleNavigateEmissionDetails({ travelDistance: request.travelDistance, requestInsights })
  }

  public render() {
    const styles = getStyles()
    const { request } = this.props
    const requestInsights = RequestInsightsStore.getInsightsFromMap(request.id)
    if (!requestInsights || !EmissionsDetailsHelper.isEmissionsDataValid(requestInsights)) {
      return null
    }
    return (
      <View>
        <TouchableOpacity style={styles.container} onPress={() => this.onPress(requestInsights)}>
          <View style={styles.icon}>
            <IconCircle
              icon='leaf'
              backgroundColor={colors.green50}
              color={colors.white}
              size={32}
              style={styles.icon}
            ></IconCircle>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.text}>
              {st.components.emissions.emissionSavingsMessage({
                savings: EmissionsDetailsHelper.getEmissionsSavingPercentage(requestInsights),
              })}
            </Text>
          </View>
          <Image style={styles.rightArrow} resizeMode='contain' source={Images.rightArrow} />
        </TouchableOpacity>
        <View style={styles.divider} />
      </View>
    )
  }
}
