import { IRequestInsightsResponse, UnitsOfDistance } from '@sparelabs/api-client'
import Distance from '@sparelabs/distance'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { EmissionsDetailsHelper, MAX_GRAPH_HEIGHT } from 'src/helpers/EmissionsDetailsHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'

const getStyles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.white,
      paddingHorizontal: 16,
    },
    graphics: {
      flexDirection: 'row',
      justifyContent: 'center',
      padding: 16,
      marginTop: 40,
      marginBottom: 40,
    },
    riderContainer: {
      alignSelf: 'flex-end',
      alignItems: 'center',
    },
    otherVehicleContainer: {
      alignSelf: 'flex-end',
      alignItems: 'center',
    },
    riderEmissionsContainer: {
      backgroundColor: colors.green50,
      borderRadius: 8,
      marginBottom: 8,
      marginHorizontal: 8,
      width: 115,
    },
    gasVehicleEmissionsContainer: {
      backgroundColor: colors.blueAlt30,
      borderRadius: 8,
      marginBottom: 8,
      marginHorizontal: 8,
      width: 115,
    },
    textContainer: {
      flex: 5,
    },
    otherRider: {
      fontSize: 14,
      color: colors.green70,
      alignSelf: 'center',
    },
    graphicTextHeader: {
      fontWeight: 'bold',
      fontSize: 14,
      color: colors.gray90,
      alignSelf: 'center',
    },
    graphicText: {
      fontSize: 14,
      color: colors.gray70,
      alignSelf: 'center',
    },
    textHeader: {
      fontWeight: 'bold',
      fontSize: 21,
      color: colors.gray90,
    },
    textHeaderColored: {
      fontWeight: 'bold',
      fontSize: 21,
      color: colors.green50,
    },
    textBody: {
      fontSize: 16,
      color: colors.gray70,
      marginTop: 16,
    },
  })

@observer
export class EmissionsDetailsView extends Component<ParamsListRoot[ScreenName.EmissionsDetails]> {
  public renderEmissionsGraphs(requestInsights: IRequestInsightsResponse) {
    const styles = getStyles()
    return (
      <View style={styles.graphics}>
        <View style={styles.riderContainer}>
          <View
            style={[
              styles.riderEmissionsContainer,
              { height: EmissionsDetailsHelper.getRiderGraphHeight(requestInsights) },
            ]}
          ></View>
          <Text style={styles.graphicTextHeader}>
            {st.screens.emissionsDetails.gramsEmitted({ emissions: requestInsights.requestCarbonEmissions })}
          </Text>
          <Text style={styles.graphicText}>{st.screens.emissionsDetails.yourTrip()}</Text>
        </View>
        <View style={styles.otherVehicleContainer}>
          <View style={[styles.gasVehicleEmissionsContainer, { height: MAX_GRAPH_HEIGHT }]}></View>
          <Text style={styles.graphicTextHeader}>
            {st.screens.emissionsDetails.gramsEmitted({ emissions: requestInsights.baselineCarbonEmissions })}
          </Text>
          <Text style={styles.graphicText}>{st.screens.emissionsDetails.gasVehicle()}</Text>
        </View>
      </View>
    )
  }

  public renderEmissionsText(requestInsights: IRequestInsightsResponse, travelDistance: number) {
    const styles = getStyles()
    return (
      <View style={styles.textContainer}>
        <Text style={styles.textHeader}>
          {st.screens.emissionsDetails.emissionsYouProduced()}
          <Text style={styles.textHeaderColored}>
            {st.screens.emissionsDetails.emissionsPercentSaved({
              savings: EmissionsDetailsHelper.getEmissionsSavingPercentage(requestInsights),
            })}
          </Text>
          <Text style={styles.textHeader}>{st.screens.emissionsDetails.emissionsDetailsHeaderExplanation()}</Text>
        </Text>
        <Text style={styles.textBody}>{st.screens.emissionsDetails.emissionsReductionMessage()}</Text>
        <Text style={styles.textBody}>
          {st.screens.emissionsDetails.emissionsReductionValues({
            distance: Distance.buildFromMeters(travelDistance).toRoundedDistance(UnitsOfDistance.Kilometer, 1),
            totalEmissions: requestInsights.totalCarbonEmissions,
            riderEmissions: requestInsights.requestCarbonEmissions,
          })}
        </Text>
      </View>
    )
  }

  public render() {
    const styles = getStyles()
    const { travelDistance, requestInsights } = this.props
    return (
      <View style={styles.container}>
        {this.renderEmissionsGraphs(requestInsights)}
        {this.renderEmissionsText(requestInsights, travelDistance)}
      </View>
    )
  }
}

export const EmissionsDetails = (props: ScreenPropsRoot<ScreenName.EmissionsDetails>) => (
  <EmissionsDetailsView {...props.route.params} />
)
