import { IPoint } from '@sparelabs/geography'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Region } from 'react-native-maps'
import {
  AutoSuggestHelper,
  AutoSuggestion,
  IFavoriteAutosuggestion,
  IPlacePredictionAutoSuggestion,
  IPlainAutoSuggestion,
  IStopAutoSuggestion,
  SetAutoSuggestions,
} from 'src/helpers/AutoSuggestHelper'
import { AutoSuggestStoreClass } from 'src/stores/AutoSuggestStore'
import { ServiceStore } from 'src/stores/ServiceStore'
import { LocationStore } from './LocationStore'

export enum SearchFieldType {
  Origin = 'origin',
  Destination = 'destination',
}

export interface IAutoSearchField {
  searchTerm: string
  selectedSuggestion: SetAutoSuggestions | null
  mapRegionSuggestion: IPlainAutoSuggestion | null
  placeSuggestions: IPlacePredictionAutoSuggestion[] | null
  stopSuggestions: IStopAutoSuggestion[] | null
}

export class SearchScreenStoreClass {
  constructor() {
    makeObservable(this)
  }

  @observable
  public originSearchField: IAutoSearchField = {
    searchTerm: '',
    placeSuggestions: [],
    stopSuggestions: [],
    selectedSuggestion: null,
    mapRegionSuggestion: null,
  }

  @observable
  public destinationSearchField: IAutoSearchField = {
    searchTerm: '',
    placeSuggestions: [],
    stopSuggestions: [],
    selectedSuggestion: null,
    mapRegionSuggestion: null,
  }

  // keep the old searchItems
  private previousOriginSelectedSuggestion: SetAutoSuggestions | null = null
  private previousDestinationSelectedSuggestion: SetAutoSuggestions | null = null

  public searchIndex: Fuse<IFavoriteAutosuggestion, Fuse.FuseOptions<IFavoriteAutosuggestion>> | null = null

  private readonly autoSuggestStore = new AutoSuggestStoreClass()

  @observable
  public areStopsHidden: boolean = true

  @observable
  public currentSearchFieldType: SearchFieldType = SearchFieldType.Origin

  @action
  public setCurrentSearchField = (fieldType: SearchFieldType) => {
    this.currentSearchFieldType = fieldType
  }

  @action
  public handleFocusChange(searchField: SearchFieldType) {
    if (this.getSearchField(searchField).selectedSuggestion) {
      this.clearField(searchField)
    }
    this.currentSearchFieldType = searchField
  }

  @action
  public setStopsHidden(isHidden: boolean): void {
    this.areStopsHidden = isHidden
  }

  public createSearchIndex = () => {
    const favorites = AutoSuggestHelper.getFavoriteSuggestions(this.originSearchField.searchTerm, this.searchIndex)
    this.searchIndex = AutoSuggestHelper.createSearchIndex(favorites)
  }

  public savePreviousOriginDestination = () => {
    this.previousOriginSelectedSuggestion = this.originSearchField.selectedSuggestion
    this.previousDestinationSelectedSuggestion = this.destinationSearchField.selectedSuggestion
  }

  @action
  public populateOriginDestinationFromPrevious = () => {
    this.originSearchField.selectedSuggestion = this.previousOriginSelectedSuggestion
    this.destinationSearchField.selectedSuggestion = this.previousDestinationSelectedSuggestion
  }

  // Return the Store for correct field or the current field is not specified
  public getSearchField = (searchFieldType?: SearchFieldType): IAutoSearchField => {
    const searchField = searchFieldType || this.currentSearchFieldType
    return searchField === SearchFieldType.Origin ? this.originSearchField : this.destinationSearchField
  }

  public getSearchSuggestions(): AutoSuggestion[] {
    if (this.currentSearchFieldType === SearchFieldType.Destination) {
      return this.getSuggestions(SearchFieldType.Destination)
    }
    return this.getSuggestions(SearchFieldType.Origin)
  }

  private readonly getSuggestions = (searchFieldType: SearchFieldType): AutoSuggestion[] => {
    const searchField: IAutoSearchField =
      searchFieldType === SearchFieldType.Origin ? this.originSearchField : this.destinationSearchField

    const searchTerm =
      searchFieldType === SearchFieldType.Origin
        ? this.originSearchField.searchTerm
        : this.destinationSearchField.searchTerm

    // we only want to include the current location if the searchField is the origin
    const includeLocation = searchFieldType === SearchFieldType.Origin

    const fixedSuggestions = this.autoSuggestStore.getFixedSuggestions(includeLocation)
    const placeSuggestions = searchField.placeSuggestions || []
    const favoriteSuggestions = AutoSuggestHelper.getFavoriteSuggestions(searchTerm, this.searchIndex)
    const initialStopSuggestions = AutoSuggestHelper.mapStopsToAutoSuggestion(ServiceStore.stops)
    const searchStopSuggestions = searchField.stopSuggestions || []

    // Show SearchItems most relevant to the search term first
    if (searchTerm.length <= 0) {
      return [...fixedSuggestions, ...favoriteSuggestions, ...initialStopSuggestions]
    }
    return [...favoriteSuggestions, ...searchStopSuggestions, ...placeSuggestions, ...fixedSuggestions]
  }

  @action
  public setAutoSuggestion(searchItem: SetAutoSuggestions | null, searchField?: SearchFieldType) {
    let updatingSearchField: IAutoSearchField
    if (searchField) {
      updatingSearchField =
        searchField === SearchFieldType.Origin ? this.originSearchField : this.destinationSearchField
    } else {
      updatingSearchField = this.getSearchField()
    }
    updatingSearchField.selectedSuggestion = searchItem
  }

  public getSearchTerm = (searchFieldType: SearchFieldType) =>
    searchFieldType === SearchFieldType.Origin
      ? this.originSearchField.searchTerm
      : this.destinationSearchField.searchTerm

  @action
  public setSearchTerm = async (searchTerm: string, searchFieldType?: SearchFieldType) => {
    const searchField = this.getSearchField(searchFieldType)
    searchField.searchTerm = searchTerm
    if (searchTerm) {
      const placeSuggestions = await AutoSuggestHelper.getPlacesSuggestions(searchTerm, this.getReferencePoint())
      runInAction(() => {
        searchField.placeSuggestions = placeSuggestions ?? null
      })
    } else {
      searchField.placeSuggestions = null
    }
    const stopSuggestions = await AutoSuggestHelper.getStopSuggestion(searchTerm)
    runInAction(() => {
      searchField.stopSuggestions = stopSuggestions
    })

    runInAction(() => {
      // if user has started typing again, deselect what was previously selected
      if (searchField.selectedSuggestion) {
        searchField.selectedSuggestion = null
      }
    })
  }

  public setMapRegion = async (mapRegion: Region | null, searchFieldType?: SearchFieldType) => {
    const searchField = this.getSearchField(searchFieldType)
    const mapRegionSuggestion = mapRegion ? await AutoSuggestHelper.mapRegionToAutoSuggestion(mapRegion) : null
    runInAction(() => {
      searchField.mapRegionSuggestion = mapRegionSuggestion
    })
  }

  public getRegionSuggestion = (searchFieldType?: SearchFieldType) =>
    this.getSearchField(searchFieldType).mapRegionSuggestion

  @action
  public clear = () => {
    this.clearField(SearchFieldType.Origin)
    this.clearField(SearchFieldType.Destination)
    this.currentSearchFieldType = SearchFieldType.Origin
  }

  @action
  public clearField = (searchFieldType: SearchFieldType) => {
    const searchField = this.getSearchField(searchFieldType)
    searchField.searchTerm = ''
    searchField.selectedSuggestion = null
    searchField.mapRegionSuggestion = null
    searchField.placeSuggestions = null
    searchField.stopSuggestions = []
  }

  @action
  public swapOriginAndDestinationTerms(isShowingMap: boolean) {
    if (!isShowingMap) {
      this.currentSearchFieldType =
        this.currentSearchFieldType === SearchFieldType.Origin ? SearchFieldType.Destination : SearchFieldType.Origin
    }

    const previousDestinationSearchField: IAutoSearchField = this.destinationSearchField
    this.destinationSearchField = this.originSearchField
    this.originSearchField = previousDestinationSearchField
  }

  private getReferencePoint(): IPoint | null {
    const originLocation =
      this.originSearchField.selectedSuggestion && this.originSearchField.selectedSuggestion.location
    const destinationLocation =
      this.destinationSearchField.selectedSuggestion && this.destinationSearchField.selectedSuggestion.location
    // If we have Pickup/Dropoff filled, use that as the reference point. Otherwise use currentLocation or null.
    return originLocation || destinationLocation || LocationStore.getCurrentLocationPointWithPermission() || null
  }
}

export const SearchScreenStore = new SearchScreenStoreClass()
