import { IWalkingLeg, LegMode } from '@sparelabs/api-client'
import { MINUTE } from '@sparelabs/time'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import { IconCircle } from 'src/components/IconCircle'
import { getModeIcon } from 'src/components/journey/JourneyHelper'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

export const WALKING_CARD_HEIGHT = 120
const ICON_CIRCLE_SIZE = 48

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    height: WALKING_CARD_HEIGHT,
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    shadowColor: colors.darkShadowColor,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 4,
    position: 'absolute',
    bottom: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
    paddingHorizontal: 16,
  },
  textContainer: {
    flexDirection: 'column',
    paddingLeft: 8,
    alignSelf: 'center',
  },
  instructionText: {
    color: colors.gray90,
    lineHeight: 20,
    fontWeight: 'bold',
    fontSize: 16,
    paddingBottom: 4,
  },
  descriptionText: {
    color: colors.gray70,
    lineHeight: 16,
    fontSize: 14,
  },
})

interface IProps {
  walkingLeg: IWalkingLeg
}

@observer
export class JourneyWalkingRoute extends Component<IProps> {
  public render() {
    return (
      <SafeAreaView edges={['bottom']} style={styles.card}>
        <View style={styles.container}>
          <IconCircle
            icon={getModeIcon(LegMode.Walk)}
            backgroundColor={colors.gray30}
            color={colors.gray90}
            size={ICON_CIRCLE_SIZE}
          />
          <View style={styles.textContainer}>
            <Text style={styles.instructionText}>
              {st.components.journey.walkingRouteInstruction({
                minutes: Math.ceil(
                  (this.props.walkingLeg.endTime.ts - this.props.walkingLeg.startTime.ts) / MINUTE
                ).toString(),
              })}
            </Text>
            <Text style={styles.descriptionText}>
              {st.components.journey.walkingRouteArrival({
                time: moment.unix(this.props.walkingLeg.endTime.ts).format('LT').toString(),
              })}
            </Text>
          </View>
        </View>
      </SafeAreaView>
    )
  }
}
