import { RiderType } from '@sparelabs/api-client'
import { isEqual } from 'lodash'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { EstimateOptions } from 'src/screens/rideOptions/EstimateOptions'

type Props = ParamsListRoot[ScreenName.EstimateRiderOptions] & {
  handleDismiss: () => void
}

@observer
export class EstimateRiderOptionsView extends Component<Props> {
  private readonly handleSubmit = async (counterValues: Map<RiderType, number>) => {
    const { estimateInputStore } = this.props
    const riders = [...counterValues.entries()]
      .filter(([, count]) => count > 0)
      .map(([type, count]) => ({ type, count }))

    if (!riders.length) {
      return
    }

    const input = estimateInputStore.getEstimateInput()

    if (!isEqual(riders, input.riders)) {
      estimateInputStore.updateEstimateInput({ ...input, riders })
    }

    this.props.handleDismiss()
  }

  public render(): JSX.Element {
    return (
      <EstimateOptions
        counterValues={
          new Map(
            this.props.serviceRiderTypes.map((type) => [
              type,
              this.props.riders?.find((rider) => rider.type === type)?.count ?? 0,
            ])
          )
        }
        buttonTitle={st.components.editRiders.buttonTitle()}
        sectionTitle={st.components.editRiders.subtitle()}
        translationFunction={st.enums.riderType}
        onSubmit={this.handleSubmit}
        allowZeroTotal={false}
        maxTotal={this.props.maxRiders}
        estimateInputStore={this.props.estimateInputStore}
      />
    )
  }
}

export const EstimateRiderOptions = ({
  route,
  navigation,
}: ScreenPropsRoot<ScreenName.EstimateRiderOptions>): JSX.Element => (
  <EstimateRiderOptionsView {...route.params} handleDismiss={() => navigation.goBack()} />
)
