import {
  CustomFieldStatus,
  CustomFieldVisibility,
  IEstimateOutput,
  IEstimateScheduledQueryParams,
  IEstimateService,
  IJourney,
  IOnDemandLeg,
  LegMode,
  NativeObjectType,
} from '@sparelabs/api-client'
import { FeatureFlag } from '@sparelabs/feature-flags'
import { ObservableMap } from 'mobx'
import { Platform } from 'react-native'
import { EdgeInsets } from 'react-native-safe-area-context'
import { estimateScheduled } from 'src/api'
import { getEstimateQueryForLeg, IOnDemandItemEstimateInfo } from 'src/components/journey/JourneyHelper'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { areAllEstimatesNull, getEstimateQuery } from 'src/helpers/EstimateHelper'
import { ParseEstimateHelper } from 'src/helpers/ParseEstimateHelper'
import { st } from 'src/locales'
import { RouterStore } from 'src/stores/RouterStore'
import { UIStateStore } from 'src/stores/UIStore'
import { EstimatesUserInputParsed } from 'src/types'
import { Pathname } from 'src/types/homeRoot'
import { OsType } from 'src/util/types'
import { ApiClientBuilder } from '../api/ApiClientBuilders'

// minimum button padding from bottom of screen, devices with edge insets may need more padding
const MIN_BUTTON_PADDING = 16

const RIDE_OPTIONS_SCREEN_PADDING = 10

// heights do not include the bottom button because it is variable between devices
export const COLLAPSED_CARD_HEIGHT = 368
export const NO_SERVICES_CARD_HEIGHT = 462
export const FLOATING_COMPONENT_HEIGHT = 204
const SECTION_TITLE_OFFSET = 28

export const SERVICE_OFFSET = 72
export const SCROLL_ITEMS_THRESHOLD = 4

export const CARD_DURATION = 280
export const CARD_DAMPING = 15

export const COLLAPSED_ESTIMATE_TOP_PADDING_WEB = 120
export const SCREEN_READER_CHANGED_LISTENER = 'screenReaderChanged'

// TODO: multimodal removed for Rider Web MVP
// https://sparelabs.atlassian.net/browse/ENDUSER-611
export const multimodalEnabled = () =>
  AuthenticatorHelper.organization?.featureFlags.includes(FeatureFlag.Multimodal) && Platform.OS !== OsType.Web

export const getPressInDelay = () => (Platform.OS === OsType.Android ? 0.85 : 35)

export const getNumberOfRideOptions = (
  estimateServices: IEstimateService[] | null,
  estimateResponseMap: ObservableMap,
  journeys: IJourney[] | null
) => {
  const numberOfEstimates =
    estimateServices && !areAllEstimatesNull(estimateServices, estimateResponseMap) ? estimateServices.length : 0
  const numberOfJourneys = multimodalEnabled() && journeys ? journeys.length : 0
  return numberOfEstimates + numberOfJourneys
}

export const getButtonPadding = (insets: EdgeInsets) => Math.max(MIN_BUTTON_PADDING, insets.bottom)

export const getEstimateCollapsedHeight = (
  numberOfRideOptions: number,
  insets: EdgeInsets,
  showSectionTitles: boolean
) => {
  // If using Rider Web in landscape view, then we want the modal to be fully opened,
  // which is achieved by setting the collapsed height to the full screen height
  if (UIStateStore.shouldShowLandscapeWeb) {
    return UIStateStore.screenHeight - COLLAPSED_ESTIMATE_TOP_PADDING_WEB
  }

  const buttonPadding = getButtonPadding(insets)
  switch (numberOfRideOptions) {
    case undefined:
    case 0:
      return NO_SERVICES_CARD_HEIGHT + buttonPadding
    case 1:
      return COLLAPSED_CARD_HEIGHT - SERVICE_OFFSET + buttonPadding
    case 2:
    default:
      // We don't show titles when there are < 2 number of ride options, because that would imply that there is only one ride type,
      // therefore we don't need to do the additional check
      return buttonPadding + COLLAPSED_CARD_HEIGHT + (showSectionTitles ? SECTION_TITLE_OFFSET : 0)
  }
}

export const triggerConfirmServiceScreen = async (
  service: IEstimateService,
  estimateResponseMap: ObservableMap<string, IEstimateOutput | null>,
  estimateInput: Pick<EstimatesUserInputParsed, 'paymentMethodId'>,
  confirmServicePathname: string
) => {
  const validPayment = triggerPaymentMethodValidation(service, estimateResponseMap, estimateInput)
  if (validPayment) {
    await RouterStore.goToScreen({
      pathname: confirmServicePathname,
    })
  }
}

export const triggerPaymentMethodValidation = (
  service: IEstimateService,
  estimateResponseMap: ObservableMap<string, IEstimateOutput | null>,
  estimateInput: Pick<EstimatesUserInputParsed, 'paymentMethodId'>
): boolean => {
  // TODO - Remove this feature flag once we have a "mobile wallet" feature available
  const allowBookingWithoutPaymentMethod = AuthenticatorHelper.getOrganization().featureFlags.includes(
    FeatureFlag.AllowBookingWithoutPaymentMethod
  )

  if (!estimateInput.paymentMethodId && !service.serviceIsCashEnabled && !allowBookingWithoutPaymentMethod) {
    // Take the fare from the estimate rather than the service, to account for any updates to the estimate since the initial fetch of estimate services
    const estimate = estimateResponseMap.get(service?.serviceId)

    if (estimate && estimate.fare.cost !== 0 && estimate.fare.total === 0) {
      AlertHelper.alert(
        st.screens.estimates.selectPaymentMethodDiscountedFare(),
        st.screens.estimates.selectPaymentMethodDiscountedFareBody(),
        [
          {
            text: st.common.alertOk(),
          },
        ],
        { cancelable: false }
      )
    } else {
      AlertHelper.alert(st.screens.estimates.selectPaymentMethod(), '', [{ text: st.common.alertOk() }], {
        cancelable: false,
      })
    }
    return false
  }
  return true
}

export const shouldShowRequestCustomFields = async (): Promise<boolean> => {
  const nativeObjectExtensionMap = await ApiClientBuilder.build().customSchemas.getNativeObjectExtensions()
  return (
    nativeObjectExtensionMap[NativeObjectType.Request] &&
    nativeObjectExtensionMap[NativeObjectType.Request].fields.filter(
      (field) =>
        field.status === CustomFieldStatus.Active && field.riderInterfaceVisibility !== CustomFieldVisibility.Hidden
    ).length !== 0
  )
}

export const triggerJourneyScreen = async (journey: IJourney, destinationAddress: string) => {
  // Determines if this is a mixed journey
  const onDemandLegIndex = journey.legs.findIndex((leg) => leg.mode === LegMode.OnDemand)
  const onDemandLeg = journey.legs[onDemandLegIndex]
  if (onDemandLeg && onDemandLeg.mode === LegMode.OnDemand) {
    const estimateInfo = await getEstimateInfoForOnDemandLeg(onDemandLeg, onDemandLegIndex)
    await RouterStore.goToScreen({
      pathname: Pathname.Journey,
      state: { journey, destinationAddress, estimateInfo },
    })
  } else {
    // Handle for other journeys
    await RouterStore.goToScreen({
      pathname: Pathname.Journey,
      state: { journey, destinationAddress },
    })
  }
}

export const triggerLyftPassScreen = async () => {
  await RouterStore.goToScreen({
    pathname: Pathname.LyftPassLink,
  })
}

export const getEstimateInfoForOnDemandLeg = async (
  onDemandLeg: IOnDemandLeg,
  legIndex: number
): Promise<IOnDemandItemEstimateInfo | undefined> => {
  const estimateInput = await ParseEstimateHelper.parseEstimate({
    requestedPickupAddress: onDemandLeg.start.name,
    requestedDropoffAddress: onDemandLeg.end.name,
    requestedPickupLocation: onDemandLeg.start.location,
    requestedDropoffLocation: onDemandLeg.end.location,
  })
  if (estimateInput) {
    // Use requestedPickupTs or requestedDropoffTs based on the constraint of when the user needs to arrive to the next leg,
    // or when the user can leave from the previous leg
    const requestedPickupTs = legIndex === 0 ? null : onDemandLeg.startTime.ts
    const requestedDropoffTs = requestedPickupTs ? null : onDemandLeg.endTime.ts
    const estimate: IEstimateOutput = await getEstimateForJourneyLeg(onDemandLeg, {
      ...estimateInput,
      requestedPickupTs,
      requestedDropoffTs,
    })
    if (estimate) {
      const estimateInfo: IOnDemandItemEstimateInfo = {
        serviceId: estimate.serviceId,
        organizationName: AuthenticatorHelper.getOrganization().name,
        pickupLocationName: estimate.scheduledPickupAddress,
        dropoffLocationName: estimate.scheduledDropoffAddress,
        pickupLocation: estimate.scheduledPickupLocation,
        dropoffLocation: estimate.scheduledDropoffLocation,
        pickupTs: estimate.estimatedPickupTime.ts,
        dropoffTs: estimate.estimatedDropoffTime.ts,
        latestDropoffTs: estimate.estimatedDropoffTime.maxTs,
      }
      return estimateInfo
    }
  }
}

export const getEstimateForJourneyLeg = async (leg: IOnDemandLeg, estimateInput: EstimatesUserInputParsed) => {
  const estimateUserInput = getEstimateQueryForLeg(leg, estimateInput)
  const scheduledQuery: IEstimateScheduledQueryParams = {
    ...getEstimateQuery(estimateUserInput),
    serviceId: leg.serviceId,
    riderId: AuthenticatorHelper.getUser().id,
    requestedPickupTs: leg.startTime.ts,
    deepSearch: true,
  }

  try {
    const res = await estimateScheduled(AuthenticatorHelper.getUserOrgToken(), scheduledQuery)
    return res.body
  } catch (e) {
    const error = e as IErrorWithResponse
    // eslint-disable-next-line no-console
    console.log(error)
    handleError({ error: error as Error, silent: true })
    throw new Error('Cannot find estimate for mixed journey')
  }
}

// Show the section titles if multimodal is enabled, and at least two of the four sections have ride options
export const getShowSectionTitles = (
  estimateServices: IEstimateService[] | null,
  estimateResponseMap: ObservableMap<string, IEstimateOutput | null>,
  mixedJourneys: IJourney[],
  transitJourneys: IJourney[],
  walkingOnlyJourney: IJourney[]
) =>
  Boolean(
    multimodalEnabled() &&
      numberOfRideOptionTypes(
        estimateServices,
        estimateResponseMap,
        mixedJourneys,
        transitJourneys,
        walkingOnlyJourney
      ) > 1
  )

export const numberOfRideOptionTypes = (
  estimateServices: IEstimateService[] | null,
  estimateResponseMap: ObservableMap<string, IEstimateOutput | null>,
  mixedJourneys: IJourney[],
  transitJourneys: IJourney[],
  walkingOnlyJourney: IJourney[]
): number => {
  let rideOptionTypes = 0
  rideOptionTypes += estimateServices && !areAllEstimatesNull(estimateServices, estimateResponseMap) ? 1 : 0
  if (multimodalEnabled()) {
    rideOptionTypes += mixedJourneys.length > 0 ? 1 : 0
    rideOptionTypes += transitJourneys.length > 0 ? 1 : 0
    rideOptionTypes += walkingOnlyJourney.length > 0 ? 1 : 0
  }
  return rideOptionTypes
}

/**
 * Calculates the height of the container with ride options for the web browser version.
 * @param relativeBottomSheetHeight - relative Top height of the bottom sheet set for AccessibleBottomSheetView snapPoints
 * @param delta - number which covers the height of the RideOptionControls padding or margin
 * @returns the height for the container in pixels. This height is expected to be used as a property for AccessibleBottomSheetWrapper
 */
export const calculateListContainerHeight = (relativeBottomSheetHeight: number, delta: number): number =>
  topBottomSheetHeight(relativeBottomSheetHeight) -
  // padding of the RideOptionsScreen from the top and from the bottom
  2 * RIDE_OPTIONS_SCREEN_PADDING -
  delta

const topBottomSheetHeight = (relativeBottomSheetHeight: number): number =>
  UIStateStore.screenHeight * relativeBottomSheetHeight

/**
 * converts string percent appearance into numeric value. For example '75%' becomes 0.75
 */
export const toNumberPercent = (relativeBottomSheetHeight: string): number => {
  const numberString = relativeBottomSheetHeight.replace('%', '')
  return parseInt(numberString) / 100
}
