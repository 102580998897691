import React from 'react'
import { FieldContainer, FieldError } from 'src/components/form/FieldShared'
import { IFormTextField, IFormTextFieldProps } from 'src/components/form/FormTextField/FormTextFieldProps'
import { TextField } from 'src/components/textField/TextField'

export const FormTextField: IFormTextField = ({
  onChange,
  value,
  label,
  disabled,
  touched,
  error,
  autoFocus,
}: IFormTextFieldProps) => (
  <FieldContainer>
    <TextField
      onChangeText={onChange}
      value={value}
      label={label}
      disabled={disabled}
      accessibilityLabel={label}
      autoFocus={autoFocus}
    />
    <FieldError touched={touched} error={error} />
  </FieldContainer>
)
