import { observer } from 'mobx-react-lite'
import React from 'react'
import { ActivityIndicator } from 'react-native'
import { colors } from 'src/assets/colors'
import { ICounterProps } from 'src/components/Counter/CounterTypes'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { st } from 'src/locales'
import { LoadingStore } from 'src/stores/LoadingStore'

const styles = {
  counterRow: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonCircle: {
    border: 'none',
    padding: 10,
    borderRadius: 36,
    height: 48,
    width: 48,
    backgroundColor: colors.blue10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  textCounter: {
    fontWeight: '400',
    fontSize: 24,
    color: colors.gray90,
    marginHorizontal: 10,
  },
}

enum CounterLoadingKeys {
  Increment = 'increment',
  Decrement = 'decrement',
}

export const Counter = observer(
  ({ config, value, onChange, disabled, disableIncrement, disableDecrement, itemLabel }: ICounterProps) => {
    const loadingStore: LoadingStore = new LoadingStore()

    const decrement = async () => {
      await loadingStore.execute(onChange(Math.max(value - 1, config.min)), CounterLoadingKeys.Decrement)
    }

    const increment = async () => {
      await loadingStore.execute(onChange(Math.min(value + 1, config.max)), CounterLoadingKeys.Increment)
    }

    return (
      <section
        style={styles.counterRow}
        role='group'
        aria-label={
          itemLabel ? st.components.counter.accessibilityLabel({ itemLabel, value }) : st.components.counter.counter()
        }
        aria-live='polite'
      >
        <button
          style={{ ...styles.buttonCircle, pointerEvents: disabled || disableDecrement ? 'none' : 'auto' }}
          onClick={decrement}
          aria-disabled={disabled || disableDecrement}
          disabled={disabled || disableDecrement}
          aria-label='decrease button'
          data-testid='decreaseButton'
        >
          {loadingStore.isLoading(CounterLoadingKeys.Decrement) ? (
            <ActivityIndicator color={colors.blue50} />
          ) : (
            <FontAwesomeIconWrapper icon='minus' color={disableDecrement ? colors.blue30 : colors.blue50} size={16} />
          )}
        </button>
        <div style={{ textAlign: 'center', width: 48 }}>
          <span style={styles.textCounter} tabIndex={-1}>
            {value}
          </span>
        </div>
        <button
          style={{ ...styles.buttonCircle, pointerEvents: disabled || disableIncrement ? 'none' : 'auto' }}
          onClick={increment}
          aria-disabled={disabled || disableIncrement}
          disabled={disabled || disableIncrement}
          data-testid='increaseButton'
          aria-label='increase button'
        >
          {loadingStore.isLoading(CounterLoadingKeys.Increment) ? (
            <ActivityIndicator color={colors.blue50} />
          ) : (
            <FontAwesomeIconWrapper icon='plus' color={disableIncrement ? colors.blue30 : colors.blue50} size={16} />
          )}
        </button>
      </section>
    )
  }
)
