import { buildPoint, IPoint } from '@sparelabs/geography'
import { action, makeObservable, observable } from 'mobx'
import { Region } from 'react-native-maps'
import { fetchPlaceDetails, reverseGeocode } from 'src/api'
import {
  AutoSuggestHelper,
  AutoSuggestion,
  AutoSuggestType,
  IPlainAutoSuggestion,
  SetAutoSuggestions,
} from 'src/helpers/AutoSuggestHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { st } from 'src/locales'
import { AutoSuggestStoreClass } from 'src/stores/AutoSuggestStore'
import { LocationStore } from 'src/stores/LocationStore'

export interface ISetLocationResponse {
  address: string | null
  location: IPoint | null
}

export class SetLocationStore {
  @observable
  public isSaving: boolean = false

  @observable
  public autoSuggestion: AutoSuggestStoreClass = new AutoSuggestStoreClass()

  @observable
  public locationData: ISetLocationResponse

  constructor() {
    makeObservable(this)
    this.locationData = this.getDefaultData()
  }

  @action
  public init = () => {
    this.clear()
    this.locationData = this.getDefaultData()
  }

  @action
  public clear = () => {
    this.locationData = this.getDefaultData()
    this.autoSuggestion.clear()
  }

  @action
  public set = async (data: Partial<ISetLocationResponse>) => {
    this.locationData = { ...this.locationData, ...data }
  }

  public createSearchIndex() {
    this.autoSuggestion.createSearchIndex()
  }

  public getSearchSuggestions(): AutoSuggestion[] {
    return this.autoSuggestion.getSuggestions(true)
  }

  @action
  public setMapRegionSuggestion = async (mapRegion: Region) => {
    await this.autoSuggestion.setMapRegionSuggestion(mapRegion)
  }

  @action
  public setSearchToMapRegion = async () => {
    const mapRegionSuggestion = this.autoSuggestion.mapRegionSuggestion
    if (mapRegionSuggestion) {
      this.autoSuggestion.selectAutoSuggestion(mapRegionSuggestion)
    }
    await this.mapSuggestionToSearchArea()
  }

  @action
  public setSearchAutoSuggestion = async (suggestion: AutoSuggestion) => {
    const permissionsDenied = await LocationStore.permissionsDenied()
    if (suggestion.type === AutoSuggestType.CurrentLocation && permissionsDenied) {
      await LocationStore.noLocationPermissionAlert()
    } else {
      // when we have granted location but have not gotten location, grab location before selecting the autosuggestion
      if (suggestion.type === AutoSuggestType.CurrentLocation && !suggestion.location) {
        const location = await LocationStore.getLocationPoint()
        const locationPoint = buildPoint(location.coords.longitude, location.coords.latitude)
        suggestion.location = locationPoint
      }
      this.autoSuggestion.selectAutoSuggestion(suggestion)
      await this.mapSuggestionToSearchArea()
    }
  }

  public setSearchTerm(searchTerm: string) {
    const currentPoint = LocationStore.getCurrentLocationPointWithPermission()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.autoSuggestion.setSearchTerm(searchTerm, currentPoint)
    this.autoSuggestion.selectedSuggestion = null
  }

  public clearAutoSuggestion() {
    this.autoSuggestion.clear()
  }

  @action
  public resetSearch() {
    this.locationData = this.getDefaultData()
  }

  private async mapSuggestionToSearchArea() {
    const suggestion = this.autoSuggestion.selectedSuggestion
    if (suggestion && suggestion.type !== AutoSuggestType.SetLocationOnMap) {
      const locationData: ISetLocationResponse | null = await this.getLocationAndAddress(suggestion)
      if (locationData) {
        await this.set({ location: locationData.location, address: locationData.address })
      }
    }
  }

  private async getLocationAndAddress(suggestion: SetAutoSuggestions): Promise<ISetLocationResponse | null> {
    if (suggestion.type === AutoSuggestType.PlacesPrediction) {
      const placeDetails = await this.fetchSetPlaceDetails(suggestion.placeId)
      return {
        location: placeDetails.location,
        address: placeDetails.address,
      }
    } else if (suggestion.type === AutoSuggestType.Geocode || suggestion.type === AutoSuggestType.CurrentLocation) {
      if (suggestion.location) {
        return {
          address: await this.fetchAddressForLocation(suggestion),
          location: suggestion.location,
        }
      }
    } else if (suggestion.type === AutoSuggestType.Stop) {
      return {
        address: suggestion.name,
        location: suggestion.location,
      }
    }
    return {
      address: suggestion.address,
      location: suggestion.location,
    }
  }

  private readonly fetchAddressForLocation = async (suggestion: IPlainAutoSuggestion): Promise<string> => {
    const address = await this.getAddressFromGeocode(suggestion)
    // If fetching failed, set it to address or name
    if (!address) {
      return suggestion.address || suggestion.name
    }
    return address
  }

  private async getAddressFromGeocode(suggestion: IPlainAutoSuggestion): Promise<string | null> {
    try {
      // Fetch address to get place_id from coordinates
      if (suggestion.location) {
        const addressRes = await reverseGeocode({
          latitude: suggestion.location.coordinates[1],
          longitude: suggestion.location.coordinates[0],
        })
        if (addressRes.address) {
          return addressRes.address
        }
        return st.request.addressUnknown()
      }
    } catch (error) {
      handleError({ error: error as IErrorWithResponse, silent: true })
    }
    return null
  }

  private async fetchSetPlaceDetails(placeId: string) {
    const res = await fetchPlaceDetails({ placeId })
    return AutoSuggestHelper.mapPlaceDetail(res)
  }

  private readonly getDefaultData = (): ISetLocationResponse => ({
    location: null,
    address: null,
  })
}
