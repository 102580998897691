import * as Location from 'expo-location'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { Constants } from 'src/consts/Constants'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { LocationStore } from 'src/stores/LocationStore'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
    maxWidth: '100%',
  },
  enableCell: {
    flex: 1,
    padding: 16,
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
  },
  textContainer: {
    flex: 1,
    maxWidth: '100%',
  },
  title: {
    fontSize: 17,
    color: colors.textDark,
    paddingVertical: 8,
  },
  description: {
    fontSize: 14,
    color: colors.textMedium,
    paddingVertical: 8,
    flexShrink: 1,
  },
  loadingFooter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25,
    paddingBottom: 25,
  },
  grayedOut: {
    color: colors.gray1,
  },
  grayedOutPng: {
    tintColor: colors.gray1,
  },
})

type Props = ParamsListRoot[ScreenName.Permissions] & {
  handleNavigateRootHome: () => void
}

interface IState {
  locationPermissionResult: Location.LocationPermissionResponse | null
}

@observer
class PermissionsView extends Component<Props, IState> {
  private readonly loadingStore: LoadingStore = new LoadingStore()

  constructor(props: Props) {
    super(props)
    this.state = {
      locationPermissionResult: null,
    }
  }

  public handlePressLocation = async () => {
    if (!this.loadingStore.isLoading()) {
      const response: Location.LocationPermissionResponse = await Location.requestForegroundPermissionsAsync()

      this.setState({ locationPermissionResult: response })
      if (!response.granted) {
        AlertHelper.alert(
          st.screens.permissions.alertTitle(),
          st.screens.permissions.deniedAlertMessage({ appName: Constants.APP_NAME }),
          [{ text: st.common.alertOk() }]
        )
      }
      // if user blocked location permissions, should still be allowed to continue
      await this.loadingStore.execute(LocationStore.startLocationAndPermissionWatch())
      this.props.handleNavigateRootHome()
    }
  }

  public getAuthorizedStyle = () => {
    if (this.state.locationPermissionResult?.granted) {
      return styles.grayedOut
    }
    return null
  }

  public render() {
    return (
      <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
        <SafeAreaView>
          <TouchableOpacity onPress={this.handlePressLocation} style={styles.enableCell}>
            <View style={styles.textContainer}>
              <Text style={[styles.title, this.getAuthorizedStyle()]}>
                {st.screens.permissions.authorizedTitle({ appName: Constants.APP_NAME })}
              </Text>
              <Text style={[styles.description, this.getAuthorizedStyle()]}>
                {st.screens.permissions.authorizedContent()}
              </Text>
            </View>
          </TouchableOpacity>
          <View style={{ paddingHorizontal: 16 }}>
            <PrimaryButton
              title={st.screens.permissions.authorizedButton()}
              onPress={this.handlePressLocation}
              loading={this.loadingStore.isLoading()}
              testID='enableLocationBtn'
            />
          </View>
        </SafeAreaView>
      </ScrollView>
    )
  }
}

export const Permissions = ({ navigation }: ScreenPropsRoot<ScreenName.Permissions>) => (
  <PermissionsView handleNavigateRootHome={() => navigation.navigate(ScreenName.RootHome, {})} />
)
