import { RequestStatus } from '@sparelabs/domain-logic'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Dash from 'react-native-dash'
import { ScrollView } from 'react-native-gesture-handler'
import { colors } from 'src/assets/colors'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { st } from 'src/locales'

const fontSize = 16

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 'auto',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 1,
  },
  pinContainer: {
    zIndex: -1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  pinImage: {
    marginTop: 1,
    height: fontSize,
    width: fontSize,
    zIndex: 2,
  },
  pin: {
    width: 19,
    height: 19,
    flexDirection: 'row',
    alignItems: 'center',
  },
  stopPin: {
    height: 8,
    width: 8,
    marginTop: 4,
    left: -12,
  },
  org: {
    color: colors.gray70,
    fontSize: 14,
    marginLeft: 4,
  },
  text: {
    flex: 1,
    flexGrow: 2,
    flexWrap: 'wrap',
    fontSize,
    color: colors.gray90,
    marginLeft: 20,
  },
  addressText: {
    fontSize,
    color: colors.gray90,
    marginLeft: 40,
    width: '65%',
    marginBottom: 10,
  },
  latest: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: colors.gray70,
    fontSize: 14,
  },
  time: {
    flex: 1,
    flexGrow: 1,
    flexWrap: 'wrap',
    fontSize,
    color: colors.gray90,
    textAlign: 'right',
  },
  grayLine: {
    borderColor: colors.gray30,
    borderLeftWidth: 4,
    height: '100%',
    top: 10,
    position: 'absolute',
    marginLeft: 6,
  },
  dottedLine: {
    marginLeft: 7,
    height: '100%',
    flexDirection: 'column',
    top: 10,
    position: 'absolute',
  },
})

export enum LineType {
  Dotted = 'dotted',
  GrayLine = 'gray',
}

export interface IProps {
  requestedPickupAddress: string
  requestedDropoffAddress: string
  scheduledPickupAddress?: string | null
  scheduledDropoffAddress?: string | null
  orgName?: string
  isPickupDifferentFromStart: boolean
  isDropoffDifferentFromEnd: boolean
  startEta?: string | null
  endEta?: string | null
  pickupEta?: string | null
  dropoffEta?: string | null
  latestDropoffEta?: string | null
  renderCurrentLocationDot?: boolean
  pastTripItem?: boolean
  addressMaxNumOfLines?: number
  requestStatus?: RequestStatus
}

@observer
export class Timeline extends Component<IProps> {
  public render() {
    const {
      isPickupDifferentFromStart,
      isDropoffDifferentFromEnd,
      requestedPickupAddress,
      requestedDropoffAddress,
      scheduledPickupAddress,
      scheduledDropoffAddress,
      startEta,
      endEta,
      pickupEta,
      dropoffEta,
      latestDropoffEta,
    } = this.props
    return (
      <ScrollView style={styles.container} focusable={true}>
        {scheduledPickupAddress && isPickupDifferentFromStart ? (
          <>
            <View>
              {this.renderRow(this.renderStartLocationPin(), requestedPickupAddress, LineType.Dotted, startEta)}
            </View>
            <View>
              {this.renderRow(
                this.renderPickupPin(),
                `${st.components.timeline.pickup()}`,
                LineType.GrayLine,
                pickupEta
              )}
              {this.renderAddressLine(scheduledPickupAddress)}
            </View>
          </>
        ) : (
          <View>
            {this.renderRow(this.renderPickupPin(), `${st.components.timeline.pickup()}`, LineType.GrayLine, pickupEta)}
            {this.renderAddressLine(requestedPickupAddress)}
          </View>
        )}
        {scheduledDropoffAddress && isDropoffDifferentFromEnd ? (
          <>
            <View>
              {this.renderRow(
                this.renderDropoffPin(),
                `${st.components.timeline.dropoff()}`,
                LineType.Dotted,
                dropoffEta
              )}
              {this.renderAddressLine(scheduledDropoffAddress)}
            </View>
            <View>
              {this.renderRow(this.renderEndLocationPin(), requestedDropoffAddress, null, endEta, latestDropoffEta)}
            </View>
          </>
        ) : (
          <>
            {this.renderRow(
              this.renderDropoffPin(),
              `${st.components.timeline.dropoff()}`,
              null,
              dropoffEta,
              latestDropoffEta
            )}
            {this.renderAddressLine(requestedDropoffAddress)}
          </>
        )}
      </ScrollView>
    )
  }

  private renderRow(
    pinElement: JSX.Element,
    text: string,
    lineType?: LineType | null,
    time?: string | null,
    latestTime?: string | null
  ) {
    return (
      <>
        <View style={styles.row}>
          <View style={styles.pinContainer}>{pinElement}</View>
          <Text
            style={[styles.text, this.props.pastTripItem ? { color: colors.gray70 } : null]}
            numberOfLines={this.props.addressMaxNumOfLines}
          >
            {text}
          </Text>
          <View>{time && <Text style={styles.time}>{time}</Text>}</View>
        </View>
        {latestTime && (
          <View>
            <Text style={styles.latest}>{latestTime}</Text>
          </View>
        )}
        {lineType && this.renderLine(lineType)}
      </>
    )
  }

  private renderAddressLine(address: string) {
    return (
      <Text
        style={[styles.text, this.props.pastTripItem ? { color: colors.gray70 } : null, styles.addressText]}
        numberOfLines={this.props.addressMaxNumOfLines}
      >
        {address}
      </Text>
    )
  }

  private renderStartLocationPin() {
    const { requestStatus, isPickupDifferentFromStart } = this.props
    return (
      <View style={styles.pin}>
        <SvgIconWrapper
          icon={
            requestStatus === RequestStatus.Accepted && isPickupDifferentFromStart
              ? SvgIcon.CurrentAddressPin
              : SvgIcon.StartPin
          }
          widthFixed={fontSize}
          heightFixed={fontSize}
        />
      </View>
    )
  }

  private renderEndLocationPin() {
    return (
      <View style={styles.pin}>
        <SvgIconWrapper icon={SvgIcon.EndPin} widthFixed={fontSize} heightFixed={fontSize} />
      </View>
    )
  }

  private renderPickupPin() {
    const { requestStatus, isPickupDifferentFromStart } = this.props
    return (
      <View style={styles.pin}>
        <SvgIconWrapper
          icon={
            requestStatus === RequestStatus.Arriving && isPickupDifferentFromStart
              ? SvgIcon.CurrentAddressPin
              : SvgIcon.PickupPin
          }
          widthFixed={fontSize}
          heightFixed={fontSize}
        />
      </View>
    )
  }

  private renderDropoffPin() {
    const { requestStatus, isPickupDifferentFromStart } = this.props
    return (
      <View style={styles.pin}>
        <SvgIconWrapper
          icon={
            requestStatus === RequestStatus.InProgress && isPickupDifferentFromStart
              ? SvgIcon.CurrentAddressPin
              : SvgIcon.DropoffPin
          }
          widthFixed={fontSize}
          heightFixed={fontSize}
        />
      </View>
    )
  }

  private renderGrayLine(orgName?: string) {
    return (
      <View style={[styles.grayLine, this.props.pastTripItem ? { paddingVertical: 8 } : null]}>
        {orgName && (
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.stopPin}>
              <SvgIconWrapper icon={SvgIcon.StopPin} widthFixed={8} heightFixed={8} />
            </View>
            <Text style={styles.org}>{st.components.timeline.rideWith({ orgName })}</Text>
          </View>
        )}
      </View>
    )
  }

  private renderDottedLine() {
    return <Dash dashGap={3} dashThickness={2} dashLength={2} dashColor={colors.gray50} style={[styles.dottedLine]} />
  }

  private renderLine(type: string) {
    switch (type) {
      case LineType.GrayLine:
        return this.renderGrayLine(this.props.orgName)
      case LineType.Dotted:
        return this.renderDottedLine()
      default:
        return null
    }
  }
}
