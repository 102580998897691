import {
  Currency,
  FarePassAllocationStatus,
  FarePassStatus,
  FarePassType,
  IFarePassAllocationResponse,
  IFarePassResponse,
  IPaymentMethodResponse,
  Resources,
} from '@sparelabs/api-client'
import { SuccessOrFailure } from '@sparelabs/core'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { getAuthedApi, LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { IPaymentFailureDetails, PaymentFlowHelper } from 'src/helpers/payments/PaymentFlowHelper'

export class FarePassStoreClass {
  public name = 'FarePassStore'

  @observable
  @persist('map')
  public farePasses: Map<string, IFarePassResponse> = new Map()

  @observable
  @persist('map')
  public activeFarePassAllocations: Map<string, IFarePassAllocationResponse> = new Map()

  @observable
  @persist('map')
  public inactiveFarePassAllocations: Map<string, IFarePassAllocationResponse> = new Map()

  public getFarePass = (farePassId: string): IFarePassResponse => {
    const farePass = this.farePasses.get(farePassId)

    if (!farePass) {
      throw new Error('Could not find fare pass with ID ' + farePassId)
    } else {
      return farePass
    }
  }

  @observable
  public getFarePassAllocation = (allocationId: string): IFarePassAllocationResponse => {
    const allAllocations = new Map([
      ...Array.from(this.activeFarePassAllocations.entries()),
      ...Array.from(this.inactiveFarePassAllocations.entries()),
    ])
    const farePass = allAllocations.get(allocationId)

    if (!farePass) {
      throw new Error('Could not find fare pass allocation with ID ' + allocationId)
    } else {
      return farePass
    }
  }

  /**
   * Returns true if this fare pass is purchaseable - stored value passes are always purchaseable but
   * time based passes can only be purchased if you don't already have a time based fare pass
   */
  public isFarePassPurchasable = (farePass: IFarePassResponse): boolean => {
    let purchasable = true

    if (farePass.type === FarePassType.TimeBased) {
      for (const allocation of this.activeFarePassAllocations.values()) {
        if (allocation.farePass.type === FarePassType.TimeBased) {
          purchasable = false
        }
      }
    }

    return purchasable
  }

  @action
  /**
   * Returns a promise which either returns the new allocation ID (on success) or false (on failure)
   */
  public buyFarePass = async (
    farePassId: string,
    farePassCost: number,
    farePassCurrency: Currency,
    paymentMethod: Pick<IPaymentMethodResponse, 'id' | 'supportedPaymentFlows'>
  ): Promise<SuccessOrFailure<IFarePassAllocationResponse, IPaymentFailureDetails>> => {
    const result = await PaymentFlowHelper.purchase(
      paymentMethod,
      {
        amount: farePassCost,
        currency: farePassCurrency,
      },
      async (purchaseInput) =>
        getAuthedApi().farePassAllocations.post({
          farePassId,
          userId: AuthenticatorHelper.getUser().id,
          ...purchaseInput,
        })
    )
    if (result.success) {
      runInAction(() => {
        this.activeFarePassAllocations.set(result.value.id, result.value)
      })
    }
    return result
  }

  @action
  public updateFarePasses = async () => {
    try {
      const userOrgToken = AuthenticatorHelper.getUserOrgToken()
      const farePassResponse = await LegacyApiClient.get(userOrgToken, Resources.FarePasses, {
        orderBy: 'cost',
        orderDirection: 'ASC',
        status: FarePassStatus.Launched,
      })
      if (farePassResponse) {
        const farePasses = farePassResponse.body.data as IFarePassResponse[]

        // Replace the map with a new one once we have a response
        runInAction(() => {
          this.farePasses = new Map(farePasses.map((farePass) => [farePass.id, farePass]))
        })
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    }
  }

  @action
  public updateActiveFarePassAllocations = async () => {
    try {
      const userOrgToken = AuthenticatorHelper.getUserOrgToken()
      const allocationsResponse = await LegacyApiClient.get(userOrgToken, Resources.FarePassAllocations, {
        status: FarePassAllocationStatus.Active,
        userId: AuthenticatorHelper.getUser().id,
      })
      if (allocationsResponse) {
        const farePassAllocations = allocationsResponse.body.data as IFarePassAllocationResponse[]

        runInAction(() => {
          // Replace the map with a new one once we have a response
          this.activeFarePassAllocations = new Map(farePassAllocations.map((allocation) => [allocation.id, allocation]))
        })
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    }
  }

  @action
  public updateInactiveFarePassAllocations = async () => {
    try {
      const userOrgToken = AuthenticatorHelper.getUserOrgToken()
      const allocationsResponse = await LegacyApiClient.get(userOrgToken, Resources.FarePassAllocations, {
        status: `${FarePassAllocationStatus.Expired}|${FarePassAllocationStatus.Depleted}|${FarePassAllocationStatus.Deactivated}`,
        userId: AuthenticatorHelper.getUser().id,
      })
      if (allocationsResponse) {
        const farePassAllocations = allocationsResponse.body.data as IFarePassAllocationResponse[]
        runInAction(() => {
          this.inactiveFarePassAllocations = new Map(
            farePassAllocations.map((allocation) => [allocation.id, allocation])
          )
        })
      }
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    }
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public clear() {
    this.farePasses = new Map()
    this.activeFarePassAllocations = new Map()
  }
}

export const FarePassStore = new FarePassStoreClass()
