import { useFocusEffect } from '@react-navigation/native'
import {
  CustomFieldStatus,
  CustomFieldVisibility,
  IJourney,
  IRequestResponse,
  NativeObjectType,
} from '@sparelabs/api-client'
import { buildPoint } from '@sparelabs/geography'
import { createMemoryHistory, History } from 'history'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { syncHistoryWithStore } from 'mobx-react-router'
import React, { Component } from 'react'
import { BackHandler, StyleSheet, View } from 'react-native'
import { Region } from 'react-native-maps'
import { Route, Router, Switch } from 'react-router'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { ErrorBoundary } from 'src/components/error/ErrorBoundary'
import { IOnDemandItemEstimateInfo } from 'src/components/journey/JourneyHelper'
import { Map } from 'src/components/map/Map'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { EstimateConfirmServiceCard } from 'src/components/rideOptions/EstimateConfirmServiceCard'
import { LyftPassConfirmServiceCard } from 'src/components/rideOptions/LyftPassConfirmServiceCard'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { EventName } from 'src/helpers/AnalyticsHelper/AnalyticHelperTypes'
import { AnalyticsHelper } from 'src/helpers/AnalyticsHelper/AnalyticsHelper'
import { AppVersionHelper } from 'src/helpers/AppVersionHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { moment } from 'src/helpers/Moment'
import { NotificationsHelper } from 'src/helpers/NotificationsHelper'
import { ParseEstimateHelper } from 'src/helpers/ParseEstimateHelper'
import { multimodalEnabled } from 'src/helpers/RideOptionsCardHelper'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import {
  ParamsListReview,
  ParamsListRoot,
  ParamsListScheduledTripList,
  ScreenName,
  ScreenPropsRoot,
} from 'src/navigation'
import { ROUTER_CONTENT_PADDING_WEB } from 'src/screens/HomeRootHelper'
import { JourneyScreen } from 'src/screens/journey/JourneyScreen'
import { ConfirmOriginScreen } from 'src/screens/rideOptions/ConfirmOriginScreen'
import { EstimateConfirmServiceMap } from 'src/screens/rideOptions/EstimateConfirmServiceMap'
import { EstimateMap } from 'src/screens/rideOptions/EstimateMap'
import { JourneyMap } from 'src/screens/rideOptions/JourneyMap'
import { RideOptions } from 'src/screens/rideOptions/RideOptionsScreen'
import { SearchMap } from 'src/screens/search/SearchMap'
import { EstimateInputStore } from 'src/stores/EstimateInputStore'
import { EstimateServicesLoading, EstimateStore } from 'src/stores/EstimateStore'
import { JourneyStore } from 'src/stores/JourneyStore'
import { LocationStore } from 'src/stores/LocationStore'
import { MapStore } from 'src/stores/MapStore'
import { RequestStore } from 'src/stores/RequestStore'
import { RouterStore } from 'src/stores/RouterStore'
import { UIStateStore } from 'src/stores/UIStore'
import { UserFleetAgreementStore } from 'src/stores/UserFleetAgreementStore'
import { EstimatesUserInputParsed, IEstimatesUserInput, IMarker, MarkerTypes } from 'src/types'
import { Pathname } from 'src/types/homeRoot'
import { ExternalRideOptions } from 'src/types/rideOptions'
import { ApiClientBuilder } from '../api/ApiClientBuilders'
import { HomeMap } from './home/HomeMap'
import { HomeScreen } from './home/HomeScreen'
import { LastCompletedRequestScreen } from './request/LastCompletedRequestScreen'
import { RequestMap } from './request/RequestMap'
import { RequestScreen } from './request/RequestScreen'
import { SearchScreen } from './search/SearchScreen'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
})

function CustomBackButtonBehaviour() {
  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (RouterStore.location.pathname === Pathname.Home) {
          return false
        }
        void RouterStore.returnToPreviousScreen()
        return true
      }

      const subscription = BackHandler.addEventListener('hardwareBackPress', onBackPress)

      return () => subscription.remove()
    }, [])
  )

  return null
}

export type IHomeRootViewProps = ParamsListRoot[ScreenName.RootHome] & {
  history: History
  handleNavigateHome: () => void
  handleNavigateAccount: () => void
  handleNavigateListAnnouncements: () => void
  handleNavigateAcknowledgeableAnnouncement: (
    params: ParamsListRoot[ScreenName.AcknowledgeableAnnouncementModal]
  ) => void
  handleNavigateScheduledTrips: (params: ParamsListScheduledTripList[ScreenName.ScheduledTripsList]) => void
  handleNavigateSetFavoriteLocation: (params: ParamsListRoot[ScreenName.SetFavoriteLocation]) => void
  handleNavigateReview: (params: ParamsListReview[ScreenName.Review]) => void
  handleNavigateTip: (params: ParamsListReview[ScreenName.AddTip]) => void
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
  handleNavigateSetProfile: (params: ParamsListRoot[ScreenName.SetProfile]) => void
  handleNavigateRequestCustomFields: (params: ParamsListRoot[ScreenName.RequestCustomFields]) => void
  handleNavigatePermissions: () => void
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  handleNavigateRequestCancellation: (params: ParamsListRoot[ScreenName.RequestCancellation]) => void
  handleNavigateRequestRiderOptions: (
    params: Pick<ParamsListRoot[ScreenName.RequestRiderOptions], 'requestId' | 'serviceId'>
  ) => void
  handleNavigateRequestAccessibilityOptions: (
    params: Pick<ParamsListRoot[ScreenName.RequestAccessibilityOptions], 'requestId' | 'serviceId'>
  ) => void
  handleNavigateSurvey: (params: ParamsListRoot[ScreenName.Survey]) => void
  handleNavigateEmissionsDetails: (params: ParamsListRoot[ScreenName.EmissionsDetails]) => void
  handleNavigateScheduleTrip: (params: ParamsListScheduledTripList[ScreenName.ScheduledTrip]) => void
  handleNavigateEstimateAccessibilityOptions: (params: ParamsListRoot[ScreenName.EstimateAccessibilityOptions]) => void
  handleNavigateEstimateRiderOptions: (params: ParamsListRoot[ScreenName.EstimateRiderOptions]) => void
  handleNavigateSelectPaymentMethod: (props: ParamsListRoot[ScreenName.SelectPaymentMethod]) => void
  handleNavigateScheduleEstimate: (params: ParamsListRoot[ScreenName.ScheduleEstimate]) => void
  handleNavigateOnboarding: (params: ParamsListRoot[ScreenName.Onboarding]) => void
  handleNavigateConfirmFleetAgreements: (params: ParamsListRoot[ScreenName.FleetAgreementModal]) => void
}

interface IHomeRootViewInitialState {
  initialRideOptionId: string | null
  initialJourneyStore: JourneyStore
  initialEstimateStore: EstimateStore
  initialEstimateInputStore: EstimateInputStore
  userFleetAgreementStore: UserFleetAgreementStore
}

// Used for multimodal trip booking flow
interface IHomeRootViewJourneyLegState {
  journeyRideOptionId: string | null
  journeyLegEstimateStore: EstimateStore
  journeyLegEstimateInputStore: EstimateInputStore
}

export const homeRootViewOnMount = async (
  props: Pick<IHomeRootViewProps, 'handleNavigatePermissions' | 'handleNavigateSetProfile'>
) => {
  await AppVersionHelper.checkIsAppOutdated()
  if (AuthenticatorHelper.userOrgToken) {
    await NotificationsHelper.requestPushAndUpdateDeviceInfo()
  }

  // Check location permission to see if we need to show the permissions screen
  await LocationStore.getLocationPermission()

  const getMissingCustomFields = async (): Promise<boolean> => {
    let missingFieldExists = false
    const nativeObjectExtensionMap = await ApiClientBuilder.build().customSchemas.getNativeObjectExtensions()
    if (nativeObjectExtensionMap[NativeObjectType.Rider]) {
      const riderMetaData = AuthenticatorHelper.getRider().metadata
      const customFields = nativeObjectExtensionMap[NativeObjectType.Rider].fields

      for (const customField of customFields) {
        if (
          customField.isRequired &&
          customField.status === CustomFieldStatus.Active &&
          customField.riderInterfaceVisibility === CustomFieldVisibility.Edit &&
          riderMetaData &&
          riderMetaData[customField.key] === undefined
        ) {
          missingFieldExists = true
          break
        }
      }
    }
    return missingFieldExists
  }

  if (AuthenticatorHelper.organization && AuthenticatorHelper.user) {
    const isUserExist = AuthenticatorHelper.isFullUser(AuthenticatorHelper.getUser())
    if (!isUserExist) {
      props.handleNavigateSetProfile({
        isOnboarding: true,
        onDone: async () => {
          await RouterStore.goToScreen({ pathname: Pathname.Home })
        },
      })
    } else {
      // If the user is not in the creation process and there is a missing custom field, force the user to fill those fields.
      const missingCustomFields = await getMissingCustomFields()
      if (missingCustomFields) {
        props.handleNavigateSetProfile({
          isOnboarding: true,
          displayErrors: true,
        })
      }
    }
  }

  if (!LocationStore.isGranted()) {
    props.handleNavigatePermissions()
  } else {
    await LocationStore.startLocationAndPermissionWatch()
  }
}

@observer
export class HomeRootView extends Component<
  IHomeRootViewProps,
  IHomeRootViewInitialState & IHomeRootViewJourneyLegState & { region: Region | undefined }
> {
  constructor(props) {
    super(props)
    this.state = {
      initialRideOptionId: '',
      initialJourneyStore: new JourneyStore(),
      initialEstimateStore: new EstimateStore(),
      initialEstimateInputStore: new EstimateInputStore(),

      journeyRideOptionId: '',
      journeyLegEstimateStore: new EstimateStore(),
      journeyLegEstimateInputStore: new EstimateInputStore(),

      userFleetAgreementStore: new UserFleetAgreementStore(),
      region: undefined,
    }
  }

  public async componentDidMount() {
    await homeRootViewOnMount(this.props)
  }

  // Workaround for Android not able to load markers on first try
  // when markers are loaded after the map is mounted. We preload
  // markers so that they're available in other places
  public preloadMapMarkers() {
    const mapMarkerImages: SvgIcon[] = [
      SvgIcon.DropoffPin,
      SvgIcon.PickupPin,
      SvgIcon.StartPin,
      SvgIcon.EndPin,
      SvgIcon.Vehicle,
    ]

    const markers = mapMarkerImages.map((markerImage, index) => {
      const marker: IMarker = {
        name: MarkerTypes.Preload,
        coordinate: buildPoint(0, 0),
        style: { height: 0, width: 0, opacity: 0 },
        imageStyle: { height: 0, width: 0, opacity: 0 },
        child: <SvgIconWrapper widthFixed={0} heightFixed={0} icon={markerImage} />,
      }
      return <ImageMarker key={index.toString()} {...marker} />
    })

    const searchLocationMarker: IMarker = {
      name: MarkerTypes.Preload,
      coordinate: buildPoint(0, 0),
      style: { height: 0, width: 0, opacity: 0 },
      imageStyle: { height: 0, width: 0, opacity: 0 },
      image: Images.searchLocationMarker,
    }

    markers.push(<ImageMarker key='searchLocation' {...searchLocationMarker} />)

    return markers
  }

  public onRegionChangeComplete = (region: Region) => {
    MapStore.setIsPanning(false)
    MapStore.setMapRegion(region)
    this.setState({
      region,
    })
  }

  public onRegionChange = () => {
    MapStore.setIsPanning(true)
  }

  public renderRequestMap(request: IRequestResponse | null) {
    if (!request) {
      return null
    }

    return (
      <ErrorBoundary>
        <RequestMap request={request} />
      </ErrorBoundary>
    )
  }

  public renderConfirmOriginScreen(estimateInput: IEstimatesUserInput) {
    return (
      <ConfirmOriginScreen
        estimateUserInput={estimateInput}
        estimateInputStore={this.state.initialEstimateInputStore}
        handleClearEstimateServices={() => this.state.initialEstimateStore.clear()}
      />
    )
  }

  public renderJourneyScreen = (
    journey: IJourney,
    destinationAddress: string,
    estimateInfo?: IOnDemandItemEstimateInfo
  ) => (
    <JourneyScreen
      journey={journey}
      destinationAddress={destinationAddress}
      estimateInfo={estimateInfo}
      handleMakeJourneyEstimate={() => this.handleMakeJourneyEstimate(estimateInfo)}
    />
  )

  public handleNavigateEstimate = async (
    estimateInput: EstimatesUserInputParsed | null,
    serviceId: string | null
  ): Promise<void> => {
    // Parse as set the estimate input
    if (estimateInput) {
      this.state.initialEstimateInputStore.updateEstimateInput(estimateInput)

      // Clear existing estimates data to reset for new estimate input
      this.state.initialEstimateStore.clear()

      // If a serviceId is provided, then handle setting it as the selected ride option if available
      if (serviceId) {
        await RouterStore.goToScreen({ pathname: Pathname.RideOptions, state: { serviceId } })
      } else {
        await RouterStore.goToScreen({ pathname: Pathname.RideOptions })
      }
    }
  }

  public handleMakeJourneyEstimate = async (estimateInfo?: IOnDemandItemEstimateInfo) => {
    if (estimateInfo) {
      AnalyticsHelper.track(EventName.JourneyRequestRide)

      const baseEstimateInput = await ParseEstimateHelper.parseEstimate({
        requestedPickupAddress: estimateInfo.pickupLocationName,
        requestedDropoffAddress: estimateInfo.dropoffLocationName,
        requestedPickupLocation: estimateInfo.pickupLocation,
        requestedDropoffLocation: estimateInfo.dropoffLocation,
      })

      // Update the estimate input with request details specific to the journey
      const now = moment().unix()
      if (baseEstimateInput) {
        this.state.journeyLegEstimateInputStore.updateEstimateInput({
          ...baseEstimateInput,
          requestedPickupTs: Math.max(estimateInfo.pickupTs, now),
          requestedDropoffTs: null,
        })

        await RouterStore.goToScreen({
          pathname: Pathname.JourneyRideOptions,
          state: { serviceId: estimateInfo.serviceId },
        })
      }
    }
  }

  public setInitialRideOption = (rideOptionId: string): void => {
    this.setState({ initialRideOptionId: rideOptionId })
  }

  public setJourneyRideOption = (rideOptionId: string): void => {
    this.setState({ journeyRideOptionId: rideOptionId })
  }

  public clearInitialRideOption = (): void => {
    this.setState({ initialRideOptionId: null })
  }

  public clearJourneyRideOption = (): void => {
    this.setState({ journeyRideOptionId: null })
  }

  public render() {
    const latitude = LocationStore.currentLocation ? LocationStore.currentLocation.coords.latitude : null
    const longitude = LocationStore.currentLocation ? LocationStore.currentLocation.coords.longitude : null
    return (
      <Router history={this.props.history}>
        <CustomBackButtonBehaviour />
        <View style={styles.container}>
          <View
            style={styles.container}
            accessibilityElementsHidden={true}
            importantForAccessibility='no-hide-descendants'
          >
            <Map
              key='home-map'
              isLocationPermissionGranted={LocationStore.isGranted()}
              showUserLocation={MapStore.showUserLocation}
              mapState={toJS(MapStore.mapState)}
              onRegionChange={this.onRegionChange}
              onRegionChangeComplete={this.onRegionChangeComplete}
              latitude={latitude}
              longitude={longitude}
              latitudeDelta={MapStore.latitudeDeltaValue}
              mapPadding={toJS(MapStore.mapPadding)}
              disableMovement={MapStore.disableMovement}
            >
              {this.preloadMapMarkers()}
              <Switch>
                <Route
                  exact={true}
                  path={Pathname.Home}
                  render={() => (
                    <ErrorBoundary>
                      <HomeMap />
                    </ErrorBoundary>
                  )}
                />
                <Route exact={true} path={Pathname.Search} render={() => <SearchMap region={this.state.region} />} />
                <Route
                  exact={true}
                  path={Pathname.RideOptions}
                  render={() => (
                    <ErrorBoundary>
                      <EstimateMap
                        isLoading={Boolean(
                          this.state.initialEstimateStore.loadingStore.isLoading(EstimateServicesLoading.Estimates)
                        )}
                        selectedEstimate={this.state.initialEstimateStore.getSelectedEstimate(
                          this.state.initialRideOptionId
                        )}
                        estimateInput={this.state.initialEstimateInputStore.estimateInput}
                        forceRenderPolyLine={this.state.initialRideOptionId === ExternalRideOptions.Lyft} // force render for Lyft as we don't have Pickup Estimates
                      />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  exact={true}
                  path={Pathname.RideOptionsConfirmService}
                  render={() => (
                    <ErrorBoundary>
                      <EstimateConfirmServiceMap
                        isLoading={Boolean(
                          this.state.initialEstimateStore.loadingStore.isLoading(EstimateServicesLoading.Estimates)
                        )}
                        selectedEstimate={this.state.initialEstimateStore.getSelectedEstimate(
                          this.state.initialRideOptionId
                        )}
                        estimateInput={this.state.initialEstimateInputStore.estimateInput}
                      />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  exact={true}
                  path={Pathname.LyftPassLink}
                  render={() => (
                    <ErrorBoundary>
                      <EstimateConfirmServiceMap
                        isLoading={Boolean(
                          this.state.initialEstimateStore.loadingStore.isLoading(EstimateServicesLoading.Estimates)
                        )}
                        selectedEstimate={this.state.initialEstimateStore.getSelectedEstimate(
                          this.state.initialRideOptionId
                        )}
                        estimateInput={this.state.initialEstimateInputStore.estimateInput}
                        forceRenderPolyLine
                      />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  exact={true}
                  path={Pathname.Journey}
                  render={() => (
                    <ErrorBoundary>
                      <JourneyMap
                        selectedJourney={this.state.initialJourneyStore.getJourneyFromId(
                          this.state.initialRideOptionId
                        )}
                        journeyInput={this.state.initialJourneyStore.journeyInput}
                        isLoading={Boolean(this.state.initialJourneyStore.loadingStore.isLoading())}
                      />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  exact={true}
                  path={Pathname.JourneyRideOptions}
                  render={() => (
                    <ErrorBoundary>
                      <EstimateMap
                        isLoading={Boolean(
                          this.state.journeyLegEstimateStore.loadingStore.isLoading(EstimateServicesLoading.Estimates)
                        )}
                        selectedEstimate={this.state.journeyLegEstimateStore.getSelectedEstimate(
                          this.state.journeyRideOptionId
                        )}
                        estimateInput={this.state.journeyLegEstimateInputStore.estimateInput}
                      />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  exact={true}
                  path={Pathname.JourneyConfirmService}
                  render={() => (
                    <ErrorBoundary>
                      <EstimateConfirmServiceMap
                        isLoading={Boolean(
                          this.state.journeyLegEstimateStore.loadingStore.isLoading(EstimateServicesLoading.Estimates)
                        )}
                        selectedEstimate={this.state.journeyLegEstimateStore.getSelectedEstimate(
                          this.state.journeyRideOptionId
                        )}
                        estimateInput={this.state.journeyLegEstimateInputStore.estimateInput}
                      />
                    </ErrorBoundary>
                  )}
                />

                <Route
                  exact={true}
                  path={Pathname.RequestActive}
                  render={() => this.renderRequestMap(RequestStore.activeRequest)}
                />
                <Route
                  exact={true}
                  path={Pathname.RequestLastCompleted}
                  render={() => this.renderRequestMap(RequestStore.lastCompletedRequest)}
                />
              </Switch>
            </Map>
          </View>
          <View
            pointerEvents='box-none'
            style={[
              StyleSheet.absoluteFillObject,
              UIStateStore.shouldShowLandscapeWeb ? { padding: ROUTER_CONTENT_PADDING_WEB } : {},
            ]}
          >
            <Switch>
              <Route
                exact={true}
                path={Pathname.Home}
                render={() =>
                  AuthenticatorHelper.userOrgToken && AuthenticatorHelper.isFullUser(AuthenticatorHelper.getUser()) ? (
                    <HomeScreen
                      handleNavigateSurvey={this.props.handleNavigateSurvey}
                      handleNavigateAccount={this.props.handleNavigateAccount}
                      handleNavigateWebView={this.props.handleNavigateWebView}
                      handleNavigateScheduledTrips={this.props.handleNavigateScheduledTrips}
                      handleNavigateOnboarding={this.props.handleNavigateOnboarding}
                      handleNavigateSetFavoriteLocation={this.props.handleNavigateSetFavoriteLocation}
                      handleNavigateListAnnouncements={this.props.handleNavigateListAnnouncements}
                      handleNavigateReview={this.props.handleNavigateReview}
                      handleNavigateTip={this.props.handleNavigateTip}
                      handleNavigateAcknowledgeableAnnouncement={this.props.handleNavigateAcknowledgeableAnnouncement}
                      handleNavigateEstimate={this.handleNavigateEstimate}
                    />
                  ) : null
                }
              />
              <Route
                exact={true}
                path={Pathname.LyftPassLink}
                render={() => (
                  <LyftPassConfirmServiceCard
                    lyftEstimates={this.state.initialEstimateStore.lyftEstimates}
                    estimateInputStore={this.state.initialEstimateInputStore}
                  />
                )}
              />

              <Route
                exact={true}
                path={Pathname.RideOptionsConfirmService}
                render={() => (
                  <EstimateConfirmServiceCard
                    estimateStore={this.state.initialEstimateStore}
                    estimateInputStore={this.state.initialEstimateInputStore}
                    confirmServicePathname={Pathname.RideOptionsConfirmService}
                    handleNavigateDiscountDetails={this.props.handleNavigateDiscountDetails}
                    handleNavigateScheduleTrip={this.props.handleNavigateScheduleTrip}
                    selectedRideOptionId={this.state.initialRideOptionId}
                    estimateInput={this.state.initialEstimateInputStore.getEstimateInput()}
                    handleNavigateRequestCustomFields={this.props.handleNavigateRequestCustomFields}
                  />
                )}
              />
              <Route exact={true} path={Pathname.Search} render={() => <SearchScreen />} />
              <Route
                exact={true}
                path={Pathname.RideOptions}
                render={(props) => (
                  <RideOptions
                    journeyStore={this.state.initialJourneyStore}
                    estimateStore={this.state.initialEstimateStore}
                    estimateInputStore={this.state.initialEstimateInputStore}
                    userFleetAgreementStore={this.state.userFleetAgreementStore}
                    preselectedRideOptionId={props.location.state?.serviceId}
                    clearRideOption={this.clearInitialRideOption}
                    selectedRideOptionId={this.state.initialRideOptionId}
                    setRideOption={this.setInitialRideOption}
                    shouldFetchJourneys={Boolean(multimodalEnabled())}
                    showOriginDestinationPill={true}
                    confirmServicePathname={Pathname.RideOptionsConfirmService}
                    handleNavigateScheduleTrip={this.props.handleNavigateScheduleTrip}
                    handleNavigateEstimateAccessibilityOptions={this.props.handleNavigateEstimateAccessibilityOptions}
                    handleNavigateEstimateRiderOptions={this.props.handleNavigateEstimateRiderOptions}
                    handleNavigateSelectPaymentMethod={this.props.handleNavigateSelectPaymentMethod}
                    handleNavigateScheduleEstimate={this.props.handleNavigateScheduleEstimate}
                    handleNavigateRequestCustomFields={this.props.handleNavigateRequestCustomFields}
                    handleNavigateOnboarding={this.props.handleNavigateOnboarding}
                    handleNavigateConfirmFleetAgreements={this.props.handleNavigateConfirmFleetAgreements}
                  />
                )}
              />

              <Route
                exact={true}
                path={Pathname.Journey}
                render={(props) =>
                  this.renderJourneyScreen(
                    props.location.state.journey,
                    props.location.state.destinationAddress,
                    props.location.state.estimateInfo
                  )
                }
              />
              <Route
                exact={true}
                path={Pathname.JourneyConfirmService}
                render={() => (
                  <EstimateConfirmServiceCard
                    estimateStore={this.state.journeyLegEstimateStore}
                    estimateInputStore={this.state.journeyLegEstimateInputStore}
                    confirmServicePathname={Pathname.JourneyConfirmService}
                    handleNavigateDiscountDetails={this.props.handleNavigateDiscountDetails}
                    handleNavigateScheduleTrip={this.props.handleNavigateScheduleTrip}
                    selectedRideOptionId={this.state.journeyRideOptionId}
                    estimateInput={this.state.journeyLegEstimateInputStore.getEstimateInput()}
                    handleNavigateRequestCustomFields={this.props.handleNavigateRequestCustomFields}
                  />
                )}
              />
              <Route
                exact={true}
                path={Pathname.JourneyRideOptions}
                render={(props) => (
                  <RideOptions
                    journeyStore={null}
                    estimateStore={this.state.journeyLegEstimateStore}
                    estimateInputStore={this.state.journeyLegEstimateInputStore}
                    userFleetAgreementStore={this.state.userFleetAgreementStore}
                    // We do not want to refetch journey estimates when coming from the journey itinerary flow
                    shouldFetchJourneys={false}
                    showOriginDestinationPill={false}
                    confirmServicePathname={Pathname.JourneyConfirmService}
                    preselectedRideOptionId={props.location.state?.serviceId}
                    clearRideOption={this.clearJourneyRideOption}
                    selectedRideOptionId={this.state.journeyRideOptionId}
                    setRideOption={this.setJourneyRideOption}
                    handleNavigateScheduleTrip={this.props.handleNavigateScheduleTrip}
                    handleNavigateEstimateAccessibilityOptions={this.props.handleNavigateEstimateAccessibilityOptions}
                    handleNavigateEstimateRiderOptions={this.props.handleNavigateEstimateRiderOptions}
                    handleNavigateSelectPaymentMethod={this.props.handleNavigateSelectPaymentMethod}
                    handleNavigateScheduleEstimate={this.props.handleNavigateScheduleEstimate}
                    handleNavigateRequestCustomFields={this.props.handleNavigateRequestCustomFields}
                    handleNavigateOnboarding={this.props.handleNavigateOnboarding}
                    handleNavigateConfirmFleetAgreements={this.props.handleNavigateConfirmFleetAgreements}
                  />
                )}
              />

              <Route
                exact={true}
                path={Pathname.RequestActive}
                render={(props) => (
                  <RequestScreen
                    requestStore={RequestStore}
                    selectedEstimateId={props.location.state?.selectedEstimateId ?? null}
                    handleNavigateDiscountDetails={this.props.handleNavigateDiscountDetails}
                    handleNavigateRequestCancellation={this.props.handleNavigateRequestCancellation}
                    handleNavigateRequestRiderOptions={this.props.handleNavigateRequestRiderOptions}
                    handleNavigateRequestAccessibilityOptions={this.props.handleNavigateRequestAccessibilityOptions}
                  />
                )}
              />
              <Route
                exact={true}
                path={Pathname.RequestLastCompleted}
                render={() => (
                  <LastCompletedRequestScreen
                    handleNavigateReview={this.props.handleNavigateReview}
                    handleNavigateEmissionsDetails={this.props.handleNavigateEmissionsDetails}
                    journey={this.state.initialJourneyStore.getJourneyFromId(this.state.initialRideOptionId)}
                    journeyEstimateInput={this.state.journeyLegEstimateInputStore.estimateInput}
                    initialEstimateInputStore={this.state.initialEstimateInputStore}
                  />
                )}
              />
            </Switch>
          </View>
          {/*
           * TODO: Refactor this to be treated as a screen using React Native Navigation rather than another route
           * https://sparelabs.atlassian.net/browse/ENDUSER-626
           */}
          <View pointerEvents='box-none' style={StyleSheet.absoluteFillObject}>
            <Switch>
              <Route
                exact={true}
                path={Pathname.RideOptionConfirmOrigin}
                render={(props) => this.renderConfirmOriginScreen(props.location.state.estimateInput)}
              />
            </Switch>
          </View>
        </View>
      </Router>
    )
  }
}

const history = syncHistoryWithStore(createMemoryHistory(), RouterStore)

// TODO explore if we need to a hooks equivalent of error boundaries
export const HomeRoot = (props: ScreenPropsRoot<ScreenName.RootHome>) => (
  <HomeRootView
    {...props.route.params}
    history={history}
    handleNavigateAccount={() => {
      props.navigation.navigate(ScreenName.GroupAccount, { screen: ScreenName.Account, params: {} })
    }}
    handleNavigateWebView={(params) => {
      WebViewHelper.handleLink(params, props.navigation.navigate.bind(HomeRoot))
    }}
    handleNavigatePermissions={() => props.navigation.navigate(ScreenName.Permissions, {})}
    handleNavigateEmissionsDetails={(params) => {
      props.navigation.navigate(ScreenName.EmissionsDetails, params)
    }}
    handleNavigateReview={(params) => {
      props.navigation.navigate(ScreenName.GroupReview, { screen: ScreenName.Review, params })
    }}
    handleNavigateDiscountDetails={(params) => props.navigation.navigate(ScreenName.DiscountDetails, params)}
    handleNavigateHome={() => {
      props.navigation.navigate(ScreenName.RootHome, {})
    }}
    handleNavigateRequestCancellation={(params) => {
      props.navigation.navigate(ScreenName.RequestCancellation, params)
    }}
    handleNavigateRequestRiderOptions={(params) => {
      props.navigation.navigate(ScreenName.RequestRiderOptions, params)
    }}
    handleNavigateRequestAccessibilityOptions={(params) => {
      props.navigation.navigate(ScreenName.RequestAccessibilityOptions, params)
    }}
    handleNavigateScheduleTrip={(params) =>
      props.navigation.navigate(ScreenName.GroupScheduledTripsList, {
        screen: ScreenName.ScheduledTrip,
        params,
      })
    }
    handleNavigateSetFavoriteLocation={(params) => props.navigation.navigate(ScreenName.SetFavoriteLocation, params)}
    handleNavigateEstimateAccessibilityOptions={(params) =>
      props.navigation.navigate(ScreenName.EstimateAccessibilityOptions, params)
    }
    handleNavigateTip={(params) => {
      props.navigation.navigate(ScreenName.GroupReview, { screen: ScreenName.AddTip, params })
    }}
    handleNavigateScheduledTrips={(params) =>
      props.navigation.navigate(ScreenName.GroupScheduledTripsList, {
        screen: ScreenName.ScheduledTripsList,
        params,
      })
    }
    handleNavigateSurvey={(params) => {
      props.navigation.navigate(ScreenName.Survey, params)
    }}
    handleNavigateAcknowledgeableAnnouncement={(params) => {
      props.navigation.navigate(ScreenName.AcknowledgeableAnnouncementModal, params)
    }}
    handleNavigateListAnnouncements={() => props.navigation.navigate(ScreenName.ListAnnouncements, {})}
    handleNavigateEstimateRiderOptions={(params) => props.navigation.navigate(ScreenName.EstimateRiderOptions, params)}
    handleNavigateSelectPaymentMethod={(params) => props.navigation.navigate(ScreenName.SelectPaymentMethod, params)}
    handleNavigateScheduleEstimate={(params) => props.navigation.navigate(ScreenName.ScheduleEstimate, params)}
    handleNavigateOnboarding={(params) => props.navigation.navigate(ScreenName.Onboarding, params)}
    handleNavigateSetProfile={(params) => props.navigation.navigate(ScreenName.SetProfile, params)}
    handleNavigateRequestCustomFields={(params) => props.navigation.navigate(ScreenName.RequestCustomFields, params)}
    handleNavigateConfirmFleetAgreements={(params) => props.navigation.navigate(ScreenName.FleetAgreementModal, params)}
  />
)
