import { sum } from 'lodash'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { Counter } from 'src/components/Counter/Counter'
import { ICounterConfig } from 'src/components/Counter/CounterTypes'
import { SimpleVirtualizedList } from 'src/components/list/SimpleVirtualizedList'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { LoadingStore } from 'src/stores/LoadingStore'

const getStyles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    scrollView: {
      flex: 1,
      backgroundColor: colors.blue10,
      flexDirection: 'column',
    },
    titleWrapper: {
      flex: 1,
      padding: 16,
    },
    optionsWrapper: {
      paddingHorizontal: 16,
      paddingVertical: 8,
      backgroundColor: colors.white,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: colors.borderBlue,
      marginBottom: 32,
    },
    row: {
      flexDirection: 'row',
      marginVertical: 8,
    },
    title: {
      fontWeight: 'bold',
      fontSize: 21,
      lineHeight: 24,
      color: colors.gray90,
      marginBottom: 4,
    },
    subtitle: {
      fontSize: 16,
      lineHeight: 20,
      color: colors.gray70,
    },
    label: {
      alignSelf: 'flex-start',
      fontSize: 16,
      color: colors.gray90,
      paddingRight: 8,
    },
    emptyText: {
      color: colors.gray70,
    },
    leftContainer: {
      flex: 1,
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    rightContainer: {
      flex: 1,
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  })

interface IProps<T extends string> {
  estimateInputStore: IEstimateInputStore
  counterValues: Map<T, number>
  buttonTitle: string
  sectionTitle: string
  sectionDescription?: string
  translationFunction: Record<T, () => string>
  onSubmit: (values: Map<T, number>) => Promise<void>
  allowZeroTotal: boolean
  maxTotal?: number
}

interface IState<T> {
  counterValues: Map<T, number>
}

const COUNTER_MINIMUM = 0
// Set to a large number, the API will disallow increments before this
const COUNTER_MAXIMUM = 99

@observer
export class EstimateOptions<T extends string> extends Component<IProps<T>, IState<T>> {
  private readonly loadingStore: LoadingStore = new LoadingStore()

  constructor(props: IProps<T>) {
    super(props)
    this.state = { counterValues: this.props.counterValues }
  }

  public handlePress = async (key: T, value: number) => {
    const newValues: Map<T, number> = new Map()
    for (const [type, count] of this.state.counterValues) {
      const newCount = type === key ? value : count
      newValues.set(type, newCount)
    }
    this.setState({ counterValues: newValues })
  }

  public handleSubmit = async () => {
    await this.loadingStore.execute(this.props.onSubmit(this.state.counterValues))
  }

  public renderCounter(key: T) {
    const styles = getStyles()
    const counterLabel = this.props.translationFunction[key]?.() ?? key
    const counterValue = this.state.counterValues.get(key) ?? 0
    const counterConfig: ICounterConfig = {
      min: COUNTER_MINIMUM,
      /**
       * If maxTotal is specified then only allow this to be incremented up to the maximum.
       * Otherwise set to a large number, the API will disallow increments before this
       */
      max: this.props.maxTotal ? this.props.maxTotal - this.getTotalCount() + counterValue : COUNTER_MAXIMUM,
    }
    return (
      <View key={key} style={styles.row}>
        <View style={styles.leftContainer} accessibilityElementsHidden={true}>
          <Text style={styles.label}>{counterLabel}</Text>
        </View>
        <View style={styles.rightContainer}>
          <Counter
            config={counterConfig}
            value={counterValue}
            onChange={(value) => this.handlePress(key, value)}
            disableDecrement={
              counterValue <= counterConfig.min || (!this.props.allowZeroTotal && this.getTotalCount() <= 1)
            }
            disableIncrement={counterValue >= counterConfig.max}
            itemLabel={counterLabel}
          />
        </View>
      </View>
    )
  }

  public renderEmptyText = (text: string) => {
    const styles = getStyles()
    return (
      <View style={styles.row}>
        <Text style={styles.emptyText}>{text}</Text>
      </View>
    )
  }

  public getTotalCount = () => sum(Array.from(this.state.counterValues.values()))

  public render() {
    const styles = getStyles()
    return (
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <SimpleVirtualizedList style={styles.scrollView}>
          {this.state.counterValues.size > 0 && this.renderAccessibilityRequirements()}
        </SimpleVirtualizedList>
        <ButtonWrapper>
          <PrimaryButton
            title={this.props.buttonTitle}
            onPress={this.handleSubmit}
            disabled={this.loadingStore.isLoading() || (!this.props.allowZeroTotal && this.getTotalCount() === 0)}
            loading={this.loadingStore.isLoading()}
          />
        </ButtonWrapper>
      </SafeAreaView>
    )
  }

  private readonly renderAccessibilityRequirements = () => {
    const styles = getStyles()
    return (
      <>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{this.props.sectionTitle}</Text>
          {this.props.sectionDescription && <Text style={styles.subtitle}>{this.props.sectionDescription}</Text>}
        </View>
        <View style={styles.optionsWrapper}>
          {Array.from(this.state.counterValues.keys()).map((key) => this.renderCounter(key))}
        </View>
      </>
    )
  }
}
