import { observer } from 'mobx-react'
import React, { Component, ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'
import { ROUTER_CONTENT_MAX_WIDTH } from 'src/screens/HomeRootHelper'
import { UIStateStore } from 'src/stores/UIStore'

const styles = StyleSheet.create({
  cardHolder: {
    flex: 1,
    position: 'absolute',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
  },
  cardContent: {
    flex: 1,
  },
  cardHolderMobile: {
    bottom: 8,
  },
  cardHolderWeb: {
    alignSelf: 'flex-start',
    top: 80,
    maxWidth: ROUTER_CONTENT_MAX_WIDTH,
  },
})

interface IProps {
  testID?: string
  children?: ReactNode
}

@observer
export class HomeCardWrapper extends Component<IProps> {
  public render() {
    return (
      <View style={[styles.cardHolder, this.getCardPosition()]} pointerEvents='box-none'>
        <View style={styles.cardContent}>{this.props.children}</View>
      </View>
    )
  }

  private readonly getCardPosition = () =>
    UIStateStore.shouldShowLandscapeWeb ? styles.cardHolderWeb : styles.cardHolderMobile
}
