import React, { FunctionComponent, ReactNode } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

const styles = StyleSheet.create({
  containerCell: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const FormWrapper: FunctionComponent<{ style?: ViewStyle; children?: ReactNode }> = (props: {
  style?: ViewStyle
  children?: ReactNode
}) => <View style={[styles.containerCell, props.style ?? {}]}>{props.children}</View>
