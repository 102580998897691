import { IRequestResponse } from '@sparelabs/api-client'
import { IListResponse } from '@sparelabs/http-client-utils'
import { LegacyApiClient } from 'src/api'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { PagedDataStore } from 'src/stores/PagedDataStore'
import { RequestStore } from 'src/stores/RequestStore'

export class ScheduledRequestsStore extends PagedDataStore<IRequestResponse> {
  public static fetchScheduledTrips = async (skip: number, limit: number): Promise<IListResponse<IRequestResponse>> => {
    if (AuthenticatorHelper.userOrgToken) {
      const res = await LegacyApiClient.get(AuthenticatorHelper.getUserOrgToken(), 'requests', {
        ...RequestStore.getOngoingRequestQueryParams(),
        skip,
        limit,
      })
      if (res) {
        return res.body
      }
    }
    throw new Error('Failed to fetch scheduled trips')
  }

  constructor() {
    super(ScheduledRequestsStore.fetchScheduledTrips)
  }
}
