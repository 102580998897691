import { IErrorResponse } from '@sparelabs/api-client'
import { action, makeObservable, observable } from 'mobx'
import { EstimatesUserInputParsed } from 'src/types'

export interface IEstimateInputStore {
  estimateInput: EstimatesUserInputParsed | null
  estimateMetadataInput: Record<string, unknown> | null
  updateEstimateInput: (estimateUserInput: EstimatesUserInputParsed) => void
  updateEstimateMetadataInput: (estimateMetadataInput: Record<string, unknown>) => void
  getEstimateInput: () => EstimatesUserInputParsed
  getEstimateMetadataInput: () => Record<string, unknown> | null
  clearMetadata: () => void
  setEstimateInput: (estimateInput: EstimatesUserInputParsed) => void
}

export class EstimateInputStore implements IEstimateInputStore {
  @observable
  public estimateInput: EstimatesUserInputParsed | null = null

  @observable
  public estimateMetadataInput: Record<string, unknown> | null = null

  @observable
  public estimateError: IErrorResponse | null = null

  constructor() {
    makeObservable(this)
  }

  public getEstimateInput() {
    if (!this.estimateInput) {
      throw new Error('Estimate Input was not found')
    }
    return this.estimateInput
  }

  public getEstimateMetadataInput(): Record<string, unknown> | null {
    if (!this.estimateMetadataInput) {
      return null
    }
    return this.estimateMetadataInput
  }

  @action
  public setEstimateInput(estimateInput: EstimatesUserInputParsed) {
    this.estimateInput = estimateInput
  }

  // Update with newer input
  @action
  public updateEstimateInput = (estimateUserInput: EstimatesUserInputParsed) => {
    this.estimateInput = estimateUserInput
  }

  @action
  public updateEstimateMetadataInput = (estimateMetadataInput: Record<string, unknown>): void => {
    this.estimateMetadataInput = estimateMetadataInput
  }

  @action
  public clearMetadata(): void {
    if (this.estimateMetadataInput) {
      this.estimateMetadataInput = null
    }
  }
}
