import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    height: 56,
    borderRadius: 8,
  },
  button: {
    paddingVertical: 16,
    paddingHorizontal: 22,
    backgroundColor: colors.blue10,
    color: colors.gray90,
    justifyContent: 'center',
    alignItems: 'center',
    borderRightColor: colors.borderBlue,
    borderRightWidth: 1,
  },
  firstButton: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  lastButton: {
    borderRightWidth: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  subtitle: {
    color: colors.gray70,
    fontSize: 12,
  },
  title: {
    color: colors.gray90,
    fontSize: 16,
  },
  selected: {
    backgroundColor: colors.blue50,
  },
  custom: {
    color: colors.blue50,
    fontSize: 16,
  },
})

export interface IOptionButtonProps {
  title: string
  subtitle?: string
  onHandleSelected: () => void
  position: OptionButtonPosition
  buttonKey: string
  selected: boolean
}

export enum OptionButtonPosition {
  First = 'first',
  Middle = 'middle',
  Last = 'last',
}

@observer
export class OptionButton extends Component<IOptionButtonProps> {
  public render() {
    const { position, title, subtitle, buttonKey, selected } = this.props
    const buttonStyles = [
      styles.button,
      selected ? styles.selected : null,
      position === OptionButtonPosition.First ? styles.firstButton : null,
      position === OptionButtonPosition.Last ? styles.lastButton : null,
    ]

    return (
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={this.props.onHandleSelected} style={buttonStyles} key={buttonKey}>
          <Text style={[styles.title, selected ? { color: 'white' } : null]}>{title}</Text>
          {subtitle && <Text style={[styles.subtitle, selected ? { color: 'white' } : null]}>{subtitle}</Text>}
        </TouchableOpacity>
      </View>
    )
  }
}
