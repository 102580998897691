import { IEstimateOutput, IEstimateService } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { calculatePickupTime, getMinutesUntilPickupRange } from 'src/helpers/EstimateHelper'
import { getDateFormatted, getMonthDateFromString } from 'src/helpers/TimeHelper'
import { st } from 'src/locales'
import { LoadingStore } from 'src/stores/LoadingStore'
import { EstimatesUserInputParsed } from 'src/types'

interface IProps {
  handleOnPressRequest: () => Promise<void>
  service: IEstimateService | null
  selectedEstimate: IEstimateOutput | null
  isEstimateUpdating: boolean
  estimateInput: EstimatesUserInputParsed | null
}

@observer
export class CreateRequestButton extends Component<IProps> {
  private readonly loadingStore: LoadingStore = new LoadingStore()
  public render() {
    return (
      <PrimaryButton
        testID='createRequestButton'
        title={this.getTitle()}
        subtitle={this.getPickupSubtitle(this.props.estimateInput, this.props.selectedEstimate)}
        onPress={this.onPress}
        loading={this.loadingStore.isLoading()}
        disabled={this.isDisabled()}
      />
    )
  }

  private readonly onPress = async () => {
    await this.loadingStore.execute(this.props.handleOnPressRequest())
  }

  private readonly isDisabled = () => this.props.isEstimateUpdating || !this.props.selectedEstimate

  private readonly getTitle = (): string => {
    if (this.props.isEstimateUpdating) {
      return st.common.updating()
    }
    if (this.props.estimateInput && this.props.estimateInput.requestedPickupTs) {
      return st.components.rideOptionsCard.scheduleButtonTitle()
    }
    return st.components.rideOptionsCard.requestButtonTitle()
  }

  private readonly getPickupSubtitle = (
    estimateInput: EstimatesUserInputParsed | null,
    rideEstimate: IEstimateOutput | null
  ): string => {
    if (this.props.isEstimateUpdating) {
      return ''
    }
    if (rideEstimate) {
      const showPickupDropoffWindow = this.props.service?.serviceShowPickupDropoffWindows ?? false

      // Get subtitle for scheduled estimate
      if (estimateInput && (estimateInput.requestedPickupTs || estimateInput.requestedDropoffTs)) {
        return this.getScheduledPickupSubtitle(rideEstimate, showPickupDropoffWindow)
      }

      return this.getNextAvailablePickupSubtitle(rideEstimate, showPickupDropoffWindow)
    }

    return ''
  }

  private getScheduledPickupSubtitle(rideEstimate: IEstimateOutput, showPickupDropoffWindow: boolean) {
    const pickupDate = getMonthDateFromString(getDateFormatted(rideEstimate.estimatedPickupTime.ts))
    const { pickupTimeLow, pickupTimeHigh, pickupTime } = calculatePickupTime(rideEstimate)
    return showPickupDropoffWindow
      ? st.components.rideOptionsCard.scheduledButtonSubtitleRange({ pickupDate, pickupTimeHigh, pickupTimeLow })
      : st.components.rideOptionsCard.scheduledButtonSubtitleAround({ pickupDate, pickupTime })
  }

  private getNextAvailablePickupSubtitle(rideEstimate: IEstimateOutput, showPickupDropoffWindow: boolean) {
    const pickupDate = getMonthDateFromString(getDateFormatted(rideEstimate.estimatedPickupTime.ts))
    const { pickupMinutesLow, pickupMinutesHigh, pickupMinutes } = getMinutesUntilPickupRange(
      rideEstimate.estimatedPickupTime
    )
    return showPickupDropoffWindow && pickupMinutesLow !== pickupMinutesHigh
      ? st.components.rideOptionsCard.nextAvailableButtonSubtitleRange({
          pickupDate,
          pickupMinutesLow,
          pickupMinutesHigh,
        })
      : st.components.rideOptionsCard.nextAvailableButtonSubtitleAround({ pickupDate, pickupMinutes })
  }
}
