import { Currency, IRequestAccessibilityFeature, IRequestResponse, IRequestRiderType } from '@sparelabs/api-client'
import { CurrencyHelper } from '@sparelabs/currency'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { RequestStatus } from '../util/types'

const REQUEST_STATUSES = [
  RequestStatus.Accepted,
  RequestStatus.Arriving,
  RequestStatus.InProgress,
  RequestStatus.Completed,
]

export class RequestHelper {
  public static tripHasStarted = (request) => moment.unix(request.requestedPickupTs).isBefore(moment())

  public static beforeCutoffTime = (request) => {
    // !!!! Should be isAfter
    if (!request.matchCutoffTs) {
      return false
    }
    return moment.unix(request.matchCutoffTs).isAfter(moment())
  }

  public static requestHasPickupTime = (request) => REQUEST_STATUSES.includes(request.status)

  public static onDemandRequestWasMatched = (request) => request !== null && RequestHelper.requestHasPickupTime(request)

  public static getEstimatedArrival = (request: IRequestResponse): string | null => {
    if (request.dropoffEta) {
      if (moment().unix() >= request.dropoffEta) {
        return st.components.requestCard.dropoffEtaNow()
      }
      return moment.unix(request.dropoffEta).format('LT')
    }
    return null
  }

  public static getLatestArrival = (request: IRequestResponse): string | null => {
    if (request.dropoffEtaLatest) {
      return moment.unix(request.dropoffEtaLatest).format('LT')
    }
    return null
  }

  public static getAcceptedStatusText = (request: IRequestResponse): string => {
    const { pickupEta } = request

    if (pickupEta && moment.unix(pickupEta).diff(moment(), 'hour') < 1) {
      return st.components.requestCard.driverArrives({
        minutes: RequestHelper.getMinutesUntilPickup(pickupEta).toString(),
      })
    } else if (request.scheduledPickupTs) {
      return st.components.requestCard.driverArrives({
        minutes: RequestHelper.getMinutesUntilPickup(request.scheduledPickupTs).toString(),
      })
    }
    return st.components.requestCard.driverArriving()
  }

  public static getMinutesUntilPickup = (pickupTs: number): number => {
    const minutesUntilPickup = moment.unix(pickupTs).diff(moment(), 'minutes')
    // Minimum pickupEta is 1 minute
    return minutesUntilPickup > 1 ? minutesUntilPickup : 1
  }

  public static getInProgressStatusText(request: IRequestResponse): string {
    if (request.dropoffEta) {
      const minutes = moment.unix(request.dropoffEta).diff(moment(), 'minutes')
      return st.components.requestCard.dropoffEtaMin({ minutes: minutes.toString() })
    }
    return st.components.requestCard.onYourWay()
  }

  public static getPickupEta = (request: IRequestResponse): string | null => {
    if (request.pickupEta) {
      return moment.unix(request.pickupEta).format('LT')
    }
    return null
  }

  public static getDropoffEta = (request: IRequestResponse): string | null => {
    if (request.dropoffEta) {
      return moment.unix(request.dropoffEta).format('LT')
    }
    return null
  }

  // Returns the timestamp for start of ride, if requestPickupAddress !== scheduledPickupAddress
  public static getOriginEta = (request: IRequestResponse): string | null => {
    if (request.pickupEta) {
      return moment.unix(request.pickupEta - request.pickupWalkingDuration).format('LT')
    }
    return null
  }

  // Returns the timestamp for end of ride, if requestDropoffAddress !== scheduledDropoffAddress
  public static getDestinationEta = (request: IRequestResponse): string | null => {
    if (request.dropoffEta) {
      return moment.unix(request.dropoffEta + request.dropoffWalkingDuration).format('LT')
    }
    return null
  }

  public static getLatestDropoff = (request: IRequestResponse) => {
    if (request.dropoffEtaLatest) {
      const eta = moment.unix(request.dropoffEtaLatest + request.dropoffWalkingDuration).format('LT')
      return st.components.timeline.latest({ time: eta })
    }
    return null
  }

  public static riderTypeToString(riderType: IRequestRiderType): string {
    const translatedString =
      riderType.count > 1 ? st.enums.riderTypePlural[riderType.type] : st.enums.riderType[riderType.type]
    // double check that function exists because new API versions may
    // send new rider types to older app releases and record may not be defined
    return translatedString ? translatedString() : riderType.type
  }

  public static accessibilityTypeToString(feature: IRequestAccessibilityFeature): string {
    const translatedString =
      feature.count > 1
        ? st.enums.accessibilityFeaturePlural[feature.type]
        : st.enums.accessibilityFeature[feature.type]
    // double check that function exists because new API versions may
    // send new accessibility features to older app releases and record may not be defined
    return translatedString ? translatedString() : feature.type
  }

  public static getFareChange = (fareChange: number, currency: Currency): string => {
    if (fareChange > 0) {
      return st.components.editRideOptions.fareIncrease({ fare: CurrencyHelper.format(fareChange, currency) })
    } else if (fareChange < 0) {
      return st.components.editRideOptions.fareDecrease({ fare: CurrencyHelper.format(Math.abs(fareChange), currency) })
    }
    return st.components.editRideOptions.fareUnchanged()
  }

  public static isLyftRequest(request: IRequestResponse): boolean {
    return request.externalUrl ? request.externalUrl.includes('lyft') : false
  }

  public static enforceUrlScheme(supportUrl: string): string {
    // when opening web URLs with Linking.openURL(), the protocol ("http://", "https://") must be set
    const HTTPS_URL_PROTOCOL = 'https://'
    const VALID_WEB_URL_PROTOCOL = new RegExp('^(http|https)://', 'i')
    return VALID_WEB_URL_PROTOCOL.test(supportUrl) ? supportUrl : HTTPS_URL_PROTOCOL + supportUrl
  }
}
