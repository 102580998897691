import { ActionSheetProps, useActionSheet } from '@expo/react-native-action-sheet'
import { NavigationProp } from '@react-navigation/native'
import { IMobileImageFile, IRiderMePatchBody } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component, createRef } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TextButton } from 'src/components/buttons/TextButton'
import { AuthenticatorHelper, AuthenticatorLoadingKeys } from 'src/helpers/AuthenticatorHelper'
import { handleError } from 'src/helpers/ErrorHelpers'
import { ImagePickerHelper } from 'src/helpers/ImagePickerHelper'
import { UserMeHelper } from 'src/helpers/UserMeHelper'
import { useNavigationPreventBackButton } from 'src/hooks/useNavigationPreventBackButton'
import { IKeyboardSafeScrollView, KeyboardSafeScrollView } from 'src/lib/KeyboardSafeScrollView'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { UserForm } from 'src/screens/profile/UserForm'
import { LoadingStore } from 'src/stores/LoadingStore'
import { OsType } from 'src/util/types'
import { ProfileImage } from '../components/ProfileImage'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blue10,
    flex: 1,
  },
  headerContainer: {
    alignItems: 'center',
    padding: 20,
  },
  changeButton: {
    fontSize: 15,
    padding: 15,
    color: colors.primaryColor,
  },
})

interface IState {
  image: string | null
}

type Props = ParamsListRoot[ScreenName.SetProfile] & {
  // only provided to be used with forms, other navigation needs should be explicitly defined
  navigation: NavigationProp<ParamsListRoot>
  handleDismiss: () => void
  handleNavigateReplaceRootHome: () => void
  displayErrors?: boolean
} & ActionSheetProps

enum PickerActionOptions {
  Cancel,
  Camera,
  Library,
}

@observer
export class SetProfileView extends Component<Props, IState> {
  public loadingStore = new LoadingStore()
  private readonly scrollRef = createRef<IKeyboardSafeScrollView>()

  constructor(props: Props) {
    super(props)
    this.state = {
      image: null,
    }
  }

  public handlePressConfirm = async (data: IRiderMePatchBody): Promise<void> => {
    try {
      const photoData = await this.getPhotoData()
      await this.loadingStore.execute(
        UserMeHelper.updateUserProfile(data, photoData),
        AuthenticatorLoadingKeys.SetProfile
      )

      // If an organization is already selected (ie: in the case of a single-org app),
      // then we handle navigation differently because Home Root is not mounted yet and
      // we need to handle mounting it first
      if (this.props.isOnboarding) {
        this.props.handleNavigateReplaceRootHome()
      } else {
        this.props.handleDismiss()
      }

      if (this.props.onDone) {
        this.props.onDone()
      }
    } catch (error) {
      handleError({ error: error as Error })
    }
  }

  public async getPhotoData(): Promise<IMobileImageFile | Blob | undefined> {
    const image = this.state.image

    if (!image) {
      return undefined
    }

    /**
     * On web we need to pass binary data rather than an object
     * Convert base64 encoded image to a Blob using browser's native fetch function
     */
    if (Platform.OS === OsType.Web) {
      const response = await fetch(image)
      const blob = await response.blob()
      return blob
    }

    return {
      uri: image,
      path: image,
      name: 'photo.jpg',
      type: 'multipart/form-data',
    }
  }

  public handlePressProfileImage = async () => {
    this.props.showActionSheetWithOptions(
      {
        title: st.screens.setProfile.actionSheetTitle(),
        options: [
          st.screens.setProfile.actionCancel(),
          st.screens.setProfile.actionPhoto(),
          st.screens.setProfile.actionLibrary(),
        ],
        cancelButtonIndex: 0,
      },
      this.handlePressActionSheet
    )
  }

  public handlePressActionSheet = async (i: number | undefined) => {
    switch (i) {
      case PickerActionOptions.Library: {
        const libraryResult = await ImagePickerHelper.pickMediaLibrary()
        if (libraryResult) {
          this.setState({ image: libraryResult.uri })
        }
        break
      }
      case PickerActionOptions.Camera: {
        const cameraResult = await ImagePickerHelper.pickCamera()
        if (cameraResult) {
          this.setState({ image: cameraResult.uri })
        }
        break
      }
      case PickerActionOptions.Cancel:
      default:
    }
  }

  public renderUserImage() {
    const user = AuthenticatorHelper.getUser()
    return this.state.image ? { ...user, photoUrl: this.state.image } : user
  }

  public getActionOptions() {
    return [
      st.screens.setProfile.actionCancel(),
      st.screens.setProfile.actionPhoto(),
      st.screens.setProfile.actionLibrary(),
    ]
  }

  private readonly onUserError = (verticalFieldPosition: number) => {
    this.scrollRef.current?.scrollTo(verticalFieldPosition)
  }

  public render() {
    return (
      <KeyboardSafeScrollView ref={this.scrollRef} containerStyle={styles.container} verticalOffset={10}>
        <View style={styles.headerContainer} testID='profileView'>
          <ProfileImage height={80} user={this.renderUserImage()} />
          <TextButton
            onPress={this.handlePressProfileImage}
            textStyle={styles.changeButton}
            text={st.screens.setProfile.buttonText()}
          />
        </View>
        <UserForm
          navigation={this.props.navigation}
          onSubmit={this.handlePressConfirm}
          loadingStore={this.loadingStore}
          loadingKey={AuthenticatorLoadingKeys.SetProfile}
          displayErrors={this.props.displayErrors}
          onUserError={this.onUserError}
        />
      </KeyboardSafeScrollView>
    )
  }
}

export const SetProfile = (props: ScreenPropsRoot<ScreenName.SetProfile>) => {
  const { showActionSheetWithOptions } = useActionSheet()
  useNavigationPreventBackButton(() => props.route.params.isOnboarding, props.navigation, [])
  return (
    <SetProfileView
      {...props.route.params}
      showActionSheetWithOptions={showActionSheetWithOptions}
      handleDismiss={() => props.navigation.pop()}
      handleNavigateReplaceRootHome={() => {
        props.navigation.reset({ routes: [{ name: ScreenName.RootHome, params: {} }] })
      }}
      navigation={props.navigation}
      displayErrors={props.route.params.displayErrors}
    />
  )
}
