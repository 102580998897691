import { ApiClient, IErrorResponse } from '@sparelabs/api-client'
import { AxiosError } from 'axios'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { colors } from 'src/assets/colors'
import { PhoneNumberForm } from 'src/components/form/PhoneNumberForm'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { handleAuthError } from 'src/helpers/ErrorHelpers'
import { st } from 'src/locales'
import { ScreenName, ScreenPropsSetPhoneNumber } from 'src/navigation'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  cellContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  description: {
    fontSize: 15,
    color: colors.textDark,
    paddingVertical: 20,
  },
})

interface ISetPhoneNumberViewProps {
  handleValidatePhoneNumber: (parsedPhoneNumber: string, reCaptchaToken?: string) => Promise<void>
  handleInvalidPhoneNumber: () => void
}

export const SetPhoneNumberView = ({
  handleValidatePhoneNumber,
  handleInvalidPhoneNumber,
}: ISetPhoneNumberViewProps): JSX.Element => (
  <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
    <PhoneNumberForm
      submitButtonTestId='phoneConfirmButton'
      handleValidatePhoneNumber={handleValidatePhoneNumber}
      handleInvalidPhoneNumber={handleInvalidPhoneNumber}
      submitButtonText={st.common.buttonConfirm()}
      inputDescription={st.screens.setPhoneNumber.phoneNumberDescription()}
    />
  </ScrollView>
)

export const SetPhoneNumber = observer(
  ({ navigation, route }: ScreenPropsSetPhoneNumber<ScreenName.SetPhoneNumber>): JSX.Element => {
    const { authOrganization } = route.params
    const loginApiClient = new ApiClient({ host: authOrganization.apiHost })

    const handleValidatePhoneNumber = async (phoneNumber: string, reCaptchaToken?: string) => {
      try {
        await loginApiClient.auth.phoneLoginRequest(
          { phoneNumber, organizationId: authOrganization.id },
          reCaptchaToken
        )
        navigation.navigate(ScreenName.SetPhoneNumberConfirm, { phoneNumber, authOrganization })
      } catch (error) {
        handleAuthError(error as AxiosError<IErrorResponse>)
      }
    }

    const handleInvalidPhoneNumber = () => {
      AlertHelper.alert(
        st.screens.setPhoneNumber.phoneAlertTitle(),
        st.screens.setPhoneNumber.phoneAlertContent(),
        [{ text: st.common.alertOk() }],
        {
          cancelable: false,
        }
      )
    }

    return (
      <SetPhoneNumberView
        handleValidatePhoneNumber={handleValidatePhoneNumber}
        handleInvalidPhoneNumber={handleInvalidPhoneNumber}
      />
    )
  }
)
