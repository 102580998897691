import {
  ApiClient,
  IGlobalMobileAppResponse,
  IOrganizationListQueryParams,
  IOrganizationResponse,
  IRiderMeResponse,
  IUserOrgToken,
  PermissionType,
} from '@sparelabs/api-client'
import { Time } from '@sparelabs/time'
import { Buffer } from 'buffer'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { HostStore } from 'src/api/HostStore'
import { Constants } from 'src/consts/Constants'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { NotificationsHelper } from 'src/helpers/NotificationsHelper'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { AnnouncementStore } from 'src/stores/AnnouncementStore'
import { FavoriteLocationStore } from 'src/stores/FavoriteLocationStore'
import { LocationStore } from 'src/stores/LocationStore'
import { MobxStoreManager } from 'src/stores/MobxStoreManager'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { RequestStore } from 'src/stores/RequestStore'
import { ServiceStore } from 'src/stores/ServiceStore'

export enum AuthenticatorLoadingKeys {
  SelectOrganization = 'selectOrganization',
  LoginWithPhone = 'loginWithPhone',
  SetProfile = 'setProfile',
}

export interface IOrgLoginData {
  userOrgToken: string
  expires: number | null
  organizationId: string
}

interface IUserData {
  id: string
  firstName: string | null
  lastName: string | null
  photoUrl: string | null
  phoneNumber: string | null
  email: string | null
}

/**
 * Only filter by mobileAppId if we are not using the base app. This means Spare Rider will show all organizations
 */
export const organizationParams: IOrganizationListQueryParams =
  Constants.MOBILE_APP_ID && !Constants.SHOW_ALL_ORGANIZATIONS
    ? { isVisibleToRiderApp: true, mobileAppId: Constants.MOBILE_APP_ID }
    : { isVisibleToRiderApp: true }

class RiderAuthenticatorStoreClass {
  public readonly name = 'AuthenticatorStore'
  @persist @observable public userId: string | null = null
  @persist @observable public userOrgToken: string | null = null
  @observable public globalApp: IGlobalMobileAppResponse | null = null
  @persist('object') @observable public organization: IOrganizationResponse | null = null

  /**
   * TODO: the user property is the exact same as the rider property (see where it is set).
   * We should remove this property and change all references to use `getRider`/`rider`
   *
   *
   * @deprecated Use `rider` instead, they store the same value
   */
  @persist('object') @observable public user: IUserData | null = null
  @persist('object') @observable public permissions: PermissionType[] | null = null
  @persist('object') @observable public rider: IRiderMeResponse | null = null

  @persist('list') @observable private orgLoginDataList: IOrgLoginData[] = []

  private readonly locale: string = LanguageHelper.getCurrentLanguageCodeFull()
  @persist @observable public regionalHost: string | null = null

  constructor() {
    makeObservable(this)
  }

  public isPartOfOrganization(organizationId: string): boolean {
    if (this.globalApp) {
      return Boolean(this.globalApp.linkedOrganizations.find((organization) => organization.id === organizationId))
    }
    return false
  }

  public isFullUser = (user: IUserData | null): user is IUserData =>
    Boolean(user && user.firstName && user.lastName && user.email && user.id)

  public getUser(): IUserData {
    if (!this.user) {
      throw new Error('User is not currently defined!')
    }
    return this.user
  }

  public getRider(): IRiderMeResponse {
    if (!this.rider) {
      throw new Error('User rider profile is not currently defined!')
    }
    return this.rider
  }

  private getRegionalApi(token?: string): ApiClient {
    if (!this.regionalHost) {
      throw new Error('API connection is not initialized')
    }
    return new ApiClient({ host: this.regionalHost, token, locale: this.locale })
  }

  public getPermissions(): PermissionType[] {
    if (this.permissions) {
      return this.permissions
    }
    throw new Error('Permissions are not currently defined!')
  }

  public getOrganization(): IOrganizationResponse {
    if (!this.organization) {
      throw new Error('Organization is not currently defined!')
    }
    return this.organization
  }

  public getUserOrgToken(): string {
    if (!this.userOrgToken) {
      throw new Error('User organization token is not currently defined!')
    }
    return this.userOrgToken
  }

  public getRegionalHost(): string {
    if (!this.regionalHost) {
      throw new Error('Regional host is not currently defined!`')
    }
    return this.regionalHost
  }

  public getOrgLoginDataList() {
    return this.orgLoginDataList
  }

  @action
  public setRegionalHost(regionalHost: string) {
    this.regionalHost = regionalHost
  }

  @action
  public setUserId(userId: string) {
    this.userId = userId
  }

  @action
  public setUserOrgToken(token: string) {
    this.userOrgToken = token
  }

  @action
  public addToLoginList(userOrgToken: string) {
    const { organizationId, expires } = this.parseJwt(userOrgToken)
    this.orgLoginDataList.push({ organizationId, expires, userOrgToken })
  }

  @action
  public removeCurrentOrgLoginData() {
    this.orgLoginDataList = this.orgLoginDataList.filter(({ userOrgToken }) => userOrgToken !== this.userOrgToken)
  }

  private parseJwt(userOrgToken: string) {
    const parts = userOrgToken
      .split('.')
      .map((part) => Buffer.from(part.replace(/-/g, '+').replace(/_/g, '/'), 'base64').toString())
    return JSON.parse(parts[1]) as IUserOrgToken
  }

  private removeExpiredTokens() {
    for (const orgLoginData of this.orgLoginDataList) {
      if (orgLoginData.expires && orgLoginData.expires <= Time.getNowTs()) {
        this.orgLoginDataList = this.orgLoginDataList.filter(
          ({ organizationId }) => organizationId !== orgLoginData.organizationId
        )
      }
    }
  }

  @action
  public optimisticallyUpdateRider(rider: IRiderMeResponse): void {
    this.rider = rider
    this.user = rider
  }

  @action
  public clear(): void {
    this.userId = null
    this.userOrgToken = null
    this.clearOrganization()
  }

  @action
  public clearOrganization(): void {
    this.user = null
    this.userOrgToken = null
    this.organization = null
    this.permissions = null
    this.rider = null
  }

  @action
  public clearOrLoginDataList() {
    this.orgLoginDataList = []
  }

  @action
  public async logout() {
    if (this.userOrgToken) {
      await NotificationsHelper.archiveDevice()
    }
    MobxStoreManager.clearMobxStores()
  }

  @action
  public async fetchGlobalApp(): Promise<void> {
    const globalApp = await new ApiClient({ host: HostStore.getHost(), locale: this.locale }).global.getGlobalApp(
      Constants.MOBILE_APP_ID
    )
    runInAction(() => {
      this.globalApp = globalApp
    })
  }

  @action
  public async fetchUserData(): Promise<void> {
    if (!this.userOrgToken) {
      throw new Error('UserOrgToken not set, login first and select organization')
    }
    let user: IUserData | null = null
    let rider: IRiderMeResponse | null = null
    const api = this.getRegionalApi(this.userOrgToken)
    const { permissions } = await api.users.getMePermissions()
    rider = await api.users.getMeRider()
    user = rider
    runInAction(() => {
      this.rider = rider
      this.permissions = permissions
      this.user = user
      this.userId = user ? user.id : null
    })
  }

  @action
  public async fetchOrganizationData(organizationId: string): Promise<void> {
    if (!this.userOrgToken) {
      throw new Error('UserOrgToken or globalAuthOrganization not set, login first')
    }
    const organization = await this.getRegionalApi(this.userOrgToken).organizations.get(organizationId)
    runInAction(() => {
      this.organization = organization
    })
  }

  @action
  public async refreshUserAndOrgData(): Promise<void> {
    this.removeExpiredTokens()
    // if user logged in previously
    if (this.organization && this.userOrgToken && this.globalApp) {
      const globalAppEntry = this.globalApp.linkedOrganizations.find(
        (organization) => organization.id === this.organization?.id
      )
      // if the org is still linked to the app
      if (globalAppEntry) {
        this.regionalHost = globalAppEntry.apiHost
        await this.fetchOrganizationData(this.organization.id)
      }
    }
  }

  @action
  public async refreshStoreData() {
    try {
      if (this.userOrgToken) {
        await this.fetchGlobalApp()
        await this.refreshUserAndOrgData()
        await ServiceStore.update()
        await RequestStore.refreshData()
        await AnnouncementStore.getAnnouncements(LocationStore.currentLocation)
        await FavoriteLocationStore.fetchFavoriteLocations()
        await PaymentMethodStore.getPaymentMethods()
      }
    } catch (error) {
      handleError({ error: error as IErrorWithResponse })
    }
  }
}

export const AuthenticatorHelper = new RiderAuthenticatorStoreClass()
