import { fetchPlaceDetails } from 'src/api'
import { AutoSuggestHelper } from 'src/helpers/AutoSuggestHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { fetchAddress } from 'src/helpers/EstimateHelper'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { EstimatesUserInputParsed, IEstimatesUserInput } from 'src/types'

export class ParseEstimateHelper {
  public static parseEstimate = async (
    estimateUserInput: IEstimatesUserInput
  ): Promise<EstimatesUserInputParsed | null> => {
    let { requestedPickupAddress, requestedPickupLocation, requestedDropoffAddress, requestedDropoffLocation } =
      estimateUserInput
    const { pickupPlaceId, dropoffPlaceId } = estimateUserInput

    try {
      // Fetch details when a PlacesPrediction was selected
      if (!requestedPickupLocation) {
        const { location, address } = await ParseEstimateHelper.fetchPlaceDetails(pickupPlaceId)
        requestedPickupLocation = location
        requestedPickupAddress = address
      }
      if (!requestedDropoffLocation) {
        const { location, address } = await ParseEstimateHelper.fetchPlaceDetails(dropoffPlaceId)
        requestedDropoffLocation = location
        requestedDropoffAddress = address
      }
      if (requestedDropoffLocation && requestedPickupLocation) {
        return {
          requestedPickupTs: null,
          requestedDropoffTs: null,
          requestedPickupAddress: requestedPickupAddress || (await fetchAddress(requestedPickupLocation)),
          requestedPickupLocation,
          requestedDropoffAddress: requestedDropoffAddress || (await fetchAddress(requestedDropoffLocation)),
          requestedDropoffLocation,
          paymentMethodId: PaymentMethodStore.defaultPayment && PaymentMethodStore.defaultPayment.id,
          scheduledPickupLocation: null,
          scheduledDropoffLocation: null,
          pickupWalkingDuration: null,
          dropoffWalkingDuration: null,
          pickupWalkingPolyline: null,
          dropoffWalkingPolyline: null,
        }
      }

      return null
    } catch (e) {
      const error = e as IErrorWithResponse
      // eslint-disable-next-line no-console
      console.log(error)
      handleError({ error, silent: true })
      return null
    }
  }

  public static getCompleteAddress = (name: string | null, address: string | null): string => {
    if (address && name) {
      if (address.includes(name)) {
        return address
      }
      return `${name} - ${address}`
    }

    if (address) {
      return address
    }

    if (name) {
      return name
    }

    return ''
  }

  public static fetchPlaceDetails = async (placeId: string | undefined) => {
    if (placeId) {
      const response = await fetchPlaceDetails({ placeId })
      const details = AutoSuggestHelper.mapPlaceDetail(response)
      return {
        location: details.location,
        address: ParseEstimateHelper.getCompleteAddress(details.name, details.address),
      }
    }

    throw new Error('Undefined place ID. Unable to fetch place details.')
  }
}
