import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import * as Linking from 'expo-linking'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { StatusBar } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import { AddressModal } from 'src/components/form/AddressField/AddressModal'
import { DatetimeModal } from 'src/components/form/DateField/DatetimeModal'
import { MultipleChoiceModal } from 'src/components/form/MultipleChoiceField/MultipleChoiceModal'
import { SingleChoiceModal } from 'src/components/form/SingleChoiceField/SingleChoiceModal'
import { FleetAgreementModal } from 'src/components/rideOptions/FleetAgreementModal'
import { Constants } from 'src/consts/Constants'
import { AnalyticsHelper } from 'src/helpers/AnalyticsHelper/AnalyticsHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { RootNavigation } from 'src/navigation/actions/RootNavigation'
import { NavigationModalCloseButtonOptions } from 'src/navigation/components/NavigationModalCloseButton'
import {
  getNonDismissibleScreenOptions,
  getScreenCardStyle,
  getScreenOptionsWithCloseButton,
  getScreenOptionsWithCloseButtonNoOverlay,
  getStackOptions,
} from 'src/navigation/consts/NavigationHelper'
import { NavigationStateHelper } from 'src/navigation/consts/NavigationStateHelper'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { NavigationStackAccount } from 'src/navigation/stacks/NavigationStackAccount'
import { NavigationStackAddPaymentMethod } from 'src/navigation/stacks/NavigationStackAddPaymentMethod'
import { NavigationStackDeveloperMenu } from 'src/navigation/stacks/NavigationStackDeveloperMenu'
import { NavigationStackEditPhoneNumber } from 'src/navigation/stacks/NavigationStackEditPhoneNumber'
import { NavigationStackReview } from 'src/navigation/stacks/NavigationStackReview'
import { NavigationStackScheduledTrips } from 'src/navigation/stacks/NavigationStackScheduledTripList'
import { NavigationStackSetPhoneNumber } from 'src/navigation/stacks/NavigationStackSetPhoneNumber'
import { AcknowledgeableAnnouncementModal } from 'src/screens/announcements/AcknowledgeableAnnouncementModal'
import { ListAnnouncements } from 'src/screens/announcements/ListAnnouncements'
import { EditFavoriteScreen } from 'src/screens/favorites/EditFavoriteScreen'
import { HomeRoot } from 'src/screens/HomeRoot'
import { Login } from 'src/screens/login/Login'
import { SetOrganization } from 'src/screens/login/SetOrganization'
import { Onboarding } from 'src/screens/Onboarding'
import { ConfirmPurchase } from 'src/screens/payments/ConfirmPurchase'
import { ConfirmStripePayment } from 'src/screens/payments/ConfirmStripePayment'
import { Permissions } from 'src/screens/Permissions'
import { DiscountDetails } from 'src/screens/request/DiscountDetails'
import { EmissionsDetails } from 'src/screens/request/EmissionsDetails'
import { RequestAccessibilityOptions } from 'src/screens/request/RequestAccessibilityOptions'
import { RequestCancellation } from 'src/screens/request/RequestCancellation'
import { RequestCustomFields } from 'src/screens/request/RequestCustomFields'
import { RequestRiderOptions } from 'src/screens/request/RequestRiderOptions'
import { DstFallBackChoice } from 'src/screens/rideOptions/DstFallBackChoice'
import { EstimateAccessibilityOptions } from 'src/screens/rideOptions/EstimateAccessibilityOptions'
import { EstimateRiderOptions } from 'src/screens/rideOptions/EstimateRiderOptions'
import { ScheduleEstimate } from 'src/screens/rideOptions/ScheduleEstimate'
import { SelectPaymentMethod } from 'src/screens/SelectPaymentMethod'
import { SetFavoriteLocation } from 'src/screens/SetFavoriteLocation'
import { SetProfile } from 'src/screens/SetProfile'
import { Survey } from 'src/screens/survey/Survey'
import { WebViewModal } from 'src/screens/WebView'
import { UIStateStore } from 'src/stores/UIStore'

const Stack = createStackNavigator<ParamsListRoot>()
const headerTitleAlign = NavigatorSettings.headerTitleAlign
const prefixes = [Linking.createURL('/')]

export const NavigationStackRoot = observer(({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const navigationRef = RootNavigation.navigationRef
  const routeNameRef = useRef<string | undefined>()
  return (
    <SafeAreaProvider>
      <ActionSheetProvider>
        <NavigationContainer
          // this is necessary to make global navigation work for edge cases
          ref={RootNavigation.navigationRef}
          theme={
            UIStateStore.shouldShowLandscapeWeb
              ? { dark: false, colors: { ...DefaultTheme.colors, background: 'transparent' } }
              : { ...DefaultTheme, colors: { ...DefaultTheme.colors, background: colors.white } }
          }
          documentTitle={{
            formatter: () => `${Constants.APP_NAME}`,
          }}
          linking={{ prefixes }}
          initialState={NavigationStateHelper.getInitialState(isLoggedIn)}
          onReady={() => {
            if (navigationRef.isReady()) {
              const currentRoute = navigationRef.getCurrentRoute()
              if (currentRoute) {
                routeNameRef.current = currentRoute.name
              }
            }
          }}
          onStateChange={async () => {
            if (navigationRef.isReady()) {
              const previousRouteName = routeNameRef.current
              const currentRouteName = navigationRef.getCurrentRoute()?.name ?? ''
              if (previousRouteName !== currentRouteName) {
                await AnalyticsHelper.setScreen(currentRouteName)
              }
              routeNameRef.current = currentRouteName
            }
          }}
        >
          <StatusBar barStyle='dark-content' backgroundColor='transparent' translucent={true} />
          <Stack.Navigator
            screenOptions={
              UIStateStore.shouldShowLandscapeWeb
                ? {
                    cardStyle: {
                      backgroundColor: 'transparent',
                    },
                    presentation: 'transparentModal',
                  }
                : { presentation: 'modal' }
            }
          >
            <Stack.Group screenOptions={{ headerShown: false }}>
              <Stack.Screen name={ScreenName.RootSetOrganization} component={SetOrganization} />
              <Stack.Screen name={ScreenName.RootLogin} component={Login} />
              <Stack.Screen name={ScreenName.RootHome} component={HomeRoot} />
            </Stack.Group>
            <Stack.Group
              screenOptions={{
                headerShown: false,
                detachPreviousScreen: false,
              }}
            >
              <Stack.Screen name={ScreenName.GroupDeveloperMenu} component={NavigationStackDeveloperMenu} />
              <Stack.Screen name={ScreenName.GroupSetPhoneNumber} component={NavigationStackSetPhoneNumber} />
              <Stack.Screen name={ScreenName.GroupEditPhoneNumber} component={NavigationStackEditPhoneNumber} />
              <Stack.Screen
                name={ScreenName.GroupAccount}
                component={NavigationStackAccount}
                options={getStackOptions(UIStateStore.shouldShowLandscapeWeb)}
              />
              <Stack.Screen
                name={ScreenName.GroupAddPaymentMethod}
                component={NavigationStackAddPaymentMethod}
                options={getStackOptions(UIStateStore.shouldShowLandscapeWeb)}
              />
              <Stack.Screen
                name={ScreenName.GroupScheduledTripsList}
                component={NavigationStackScheduledTrips}
                options={getStackOptions(UIStateStore.shouldShowLandscapeWeb)}
              />
              <Stack.Screen
                name={ScreenName.GroupReview}
                component={NavigationStackReview}
                options={({ route }) => ({
                  ...getStackOptions(UIStateStore.shouldShowLandscapeWeb),
                  gestureEnabled: !route.params.params?.canRiderTip,
                })}
              />
            </Stack.Group>
            <Stack.Group>
              <Stack.Screen
                name={ScreenName.WebViewModal}
                component={WebViewModal}
                options={({ navigation }) => getScreenOptionsWithCloseButton(navigation, '')}
              />

              <Stack.Screen
                name={ScreenName.ConfirmPurchase}
                component={ConfirmPurchase}
                options={() => ({
                  headerShown: false,
                  cardStyle: {
                    ...getScreenCardStyle(UIStateStore.shouldShowLandscapeWeb),
                    backgroundColor: 'transparent',
                  },
                })}
              />
              <Stack.Screen
                name={ScreenName.SelectPaymentMethod}
                component={SelectPaymentMethod}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.screens.selectPaymentMethod.title())
                }
              />
              <Stack.Screen
                name={ScreenName.Permissions}
                component={Permissions}
                options={() => getNonDismissibleScreenOptions(st.screens.permissions.title())}
              />
              <Stack.Screen
                name={ScreenName.SetProfile}
                component={SetProfile}
                options={({ navigation, route }) => ({
                  // we disable header button when user is onboarding because this is a mandatory step
                  ...(route.params.isOnboarding
                    ? { headerLeft: () => null }
                    : NavigationModalCloseButtonOptions(navigation, st.screens.setProfile.title())),
                  title: st.screens.setProfile.title(),
                  headerTitleAlign,
                  // we disable the gestures for swiping when user is onboarding because this is a mandatory step
                  gestureEnabled: !route.params.isOnboarding,
                  cardStyle: getScreenCardStyle(UIStateStore.shouldShowLandscapeWeb),
                })}
              />
              <Stack.Screen
                name={ScreenName.RequestCustomFields}
                component={RequestCustomFields}
                options={() => ({
                  title: st.request.additionalRideDetails(),
                  headerTitleAlign,
                  presentation: 'transparentModal',
                  cardStyle: getScreenCardStyle(UIStateStore.shouldShowLandscapeWeb),
                })}
              />
              <Stack.Screen
                name={ScreenName.FleetAgreementModal}
                component={FleetAgreementModal}
                options={({ route }) => {
                  const fleetIds = Object.keys(route.params.fleets)
                  const fleetName = route.params.fleets[fleetIds[route.params.progress.index]].name
                  return getNonDismissibleScreenOptions(st.screens.fleetAgreementModal.title({ fleetName }))
                }}
              />
              <Stack.Screen
                name={ScreenName.Address}
                component={AddressModal}
                options={({ navigation, route }) => getScreenOptionsWithCloseButton(navigation, route.params.title)}
              />
              <Stack.Screen
                name={ScreenName.SingleChoiceModal}
                component={SingleChoiceModal}
                options={({ navigation, route }) =>
                  getScreenOptionsWithCloseButton(navigation, route.params.title ?? '')
                }
              />
              <Stack.Screen
                name={ScreenName.DatetimeModal}
                component={DatetimeModal}
                options={({ navigation, route }) => getScreenOptionsWithCloseButton(navigation, route.params.title)}
              />
              <Stack.Screen
                name={ScreenName.MultipleChoiceModal}
                component={MultipleChoiceModal}
                options={({ navigation, route }) => getScreenOptionsWithCloseButton(navigation, route.params.title)}
              />
              <Stack.Screen
                name={ScreenName.SetFavoriteLocation}
                component={SetFavoriteLocation}
                options={() => ({
                  headerShown: false,
                  cardStyle: getScreenCardStyle(UIStateStore.shouldShowLandscapeWeb),
                })}
              />
              <Stack.Screen
                name={ScreenName.EditFavoriteScreen}
                component={EditFavoriteScreen}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButtonNoOverlay(navigation, st.screens.favorites.title())
                }
              />
              <Stack.Screen
                name={ScreenName.DiscountDetails}
                component={DiscountDetails}
                options={({ navigation }) => getScreenOptionsWithCloseButton(navigation, '')}
              />
              <Stack.Screen
                name={ScreenName.EmissionsDetails}
                component={EmissionsDetails}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.screens.emissionsDetails.emissionsDetailsTitle())
                }
              />
              <Stack.Screen
                name={ScreenName.Survey}
                component={Survey}
                options={({ navigation }) => getScreenOptionsWithCloseButton(navigation, st.screens.surveys.title())}
              />
              <Stack.Screen
                name={ScreenName.AcknowledgeableAnnouncementModal}
                component={AcknowledgeableAnnouncementModal}
                options={() => getNonDismissibleScreenOptions(st.screens.listAnnouncements.title())}
              />
              <Stack.Screen
                name={ScreenName.ScheduleEstimate}
                component={ScheduleEstimate}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.components.scheduleEstimate.title())
                }
              />
              <Stack.Screen
                name={ScreenName.EstimateAccessibilityOptions}
                component={EstimateAccessibilityOptions}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.components.editAccessibility.title())
                }
              />
              <Stack.Screen
                name={ScreenName.EstimateRiderOptions}
                component={EstimateRiderOptions}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.components.editRiders.title())
                }
              />
              <Stack.Screen
                name={ScreenName.RequestAccessibilityOptions}
                component={RequestAccessibilityOptions}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.components.editAccessibility.title())
                }
              />
              <Stack.Screen
                name={ScreenName.RequestRiderOptions}
                component={RequestRiderOptions}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.components.editRiders.title())
                }
              />
              <Stack.Screen
                name={ScreenName.RequestCancellation}
                component={RequestCancellation}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.screens.requestCancellation.title())
                }
              />
              <Stack.Screen
                name={ScreenName.ListAnnouncements}
                component={ListAnnouncements}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.screens.listAnnouncements.title())
                }
              />
              <Stack.Screen
                name={ScreenName.Onboarding}
                component={Onboarding}
                options={({ navigation }) => getScreenOptionsWithCloseButton(navigation, st.screens.onBoarding.title())}
              />
              <Stack.Screen
                name={ScreenName.ConfirmStripePayment}
                component={ConfirmStripePayment}
                options={({ navigation }) =>
                  getScreenOptionsWithCloseButton(navigation, st.screens.confirmPayment.title())
                }
              />
              <Stack.Screen
                name={ScreenName.DstFallBackChoice}
                component={DstFallBackChoice}
                options={() => getNonDismissibleScreenOptions(st.components.scheduleEstimate.dst.fallBack.title())}
              />
            </Stack.Group>
          </Stack.Navigator>
        </NavigationContainer>
      </ActionSheetProvider>
    </SafeAreaProvider>
  )
})
