export const Images = {
  /* eslint-disable @typescript-eslint/no-require-imports */
  // images that can't be swapped to SVG's
  creditCardFront: require('../../assets/img/credit-card-front.png'),
  creditCardBack: require('../../assets/img/credit-card-back.png'),
  searchingRides: require('../../assets/img/searching-rides.png'),
  wallet: require('../../assets/img/wallet.png'),

  // TODO: remove once we swap these for FontAwesomeIcons
  // https://sparelabs.atlassian.net/browse/ENDUSER-25
  user: require('../../assets/img/user.png'),
  searchLocationMarker: require('../../assets/img/map-marker-alt-solid.png'),
  globeAmericas: require('../../assets/img/fa-globe-americas.png'),
  close: require('../../assets/img/close.png'),
  payments: require('../../assets/img/fa-dollar.png'),
  rightArrow: require('../../assets/img/right_arrow.png'),
  help: require('../../assets/img/fa-question.png'),
  legal: require('../../assets/img/fa-gavel.png'),
  back: require('../../assets/img/back.png'),
  floatingLabelArrow: require('../../assets/img/floatingLabelArrow.png'),
  question: require('../../assets/img/question.png'),
  thumbsUp: require('../../assets/img/thumbs-up.png'),
  thumbsDown: require('../../assets/img/thumbs-down.png'),
  creditCard: require('../../assets/img/credit-card.png'),
  magnifyingGlass: require('../../assets/img/fa-search.png'),
  homeUser: require('../../assets/img/fa-user.png'),
  pastTrips: require('../../assets/img/fa-past.png'),
  groupMemberships: require('../../assets/img/fa-users.png'),
  arrowRight: require('../../assets/img/fa-white-arrow-right.png'),
  promoIcon: require('../../assets/img/badge-percent.png'),
  iconChevronLeftScaled: require('../../assets/img/icon-chevron-left-custom.png'),
  lyftLogo: require('../../assets/img/lyftLogo.png'),
  lyftLogoNoBg: require('../../assets/img/lyftLogoNoBg.png'),
  externalLink: require('../../assets/img/externalLink.png'),
  lyftLogoPink: require('../../assets/img/lyftLogoPink.png'),

  // TODO: Remove brand logos for brands that do not have a white-label app when logoUrl gets merged
  loginLogo: require('../../assets/img/loginLogo.png'),
  noOrgLogo: require('../../assets/img/noOrgLogo.png'),
  /* eslint-enable @typescript-eslint/no-require-imports */
}

export const pinImageStyle = { width: 20, height: 20 }
export const pinImageAnchor = { x: 0.5, y: 1 }
export const pinImageCenterOffset = { x: 0, y: -19 }

export const circlePinImageStyle = { width: 20, height: 20 }
export const circlePinCenterOffset = { x: 0, y: -6 }
export const circlePinAnchor = { x: 0.5, y: 0.5 }
