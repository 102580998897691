import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { FloatingLabel } from 'src/components/FloatingLabel'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { ILabelPolylineMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { getAnchorForMarker, getCenterOffsetForMarker, getWebCenterOffset } from 'src/helpers/MapHelper'
import { st } from 'src/locales'
import { IMarker, MarkerTypes } from 'src/types'

/**
 * Marks the scheduled dropoff location if possible, else the requested dropoff location.
 * When the EndMarker will be drawn for the final destination, DropoffMarker has no label. Otherwise labeled "Destination".
 */

const LABEL_WIDTH_ENGLISH = 64

@observer
export class DropoffLabelMarker extends Component<ILabelPolylineMarkerProps> {
  public render() {
    const dropoffLabelMarker = this.createLabel()
    return <ImageMarker key={dropoffLabelMarker.name} {...dropoffLabelMarker} />
  }

  private readonly createLabel = (): IMarker => {
    const { location, coordinates } = this.props

    return {
      name: MarkerTypes.DropoffLabel,
      coordinate: location,
      child: <FloatingLabel title={st.helpers.mapMarkerHelpers.dropoff()} />,
      anchor: getAnchorForMarker(coordinates, location, LABEL_WIDTH_ENGLISH),
      centerOffset: getCenterOffsetForMarker(coordinates, location, LABEL_WIDTH_ENGLISH),
      webCenterOffset: getWebCenterOffset({ markerLocations: coordinates, location, labelWidth: LABEL_WIDTH_ENGLISH }),
      pointerEvents: 'none',
      style: { zIndex: 1 },
    }
  }
}
