import { FeatureFlag } from '@sparelabs/feature-flags'
import { makeObservable, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { RequestStore } from 'src/stores/RequestStore'

class OnboardingStoreClass {
  public name = 'OnboardingStore'

  constructor() {
    makeObservable(this)
  }

  @observable
  @persist('object')
  private showFirstOnboarding: boolean = true

  public shouldShowOnboardingBeforeTrip = () => {
    /**
     * Some organizations like to explicitly disable the onboarding
     */
    if (AuthenticatorHelper.getOrganization().featureFlags.includes(FeatureFlag.DisableRiderOnboarding)) {
      return false
    }
    /**
     * Show onboarding to those that have not seeing it yet, but only if we know they have not completed a trip yet
     */
    return this.showFirstOnboarding && RequestStore.isLoaded && !RequestStore.lastCompletedRequest
  }

  public setOnboardingAsCompleted = () => {
    this.showFirstOnboarding = false
  }
}

export const OnboardingStore = new OnboardingStoreClass()
