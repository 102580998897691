import { IJourney } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { BottomSheetWrapper } from 'src/components/cards/BottomSheetWrapper'
import { JourneyCard } from 'src/components/journey/JourneyCard'
import { IOnDemandItemEstimateInfo } from 'src/components/journey/JourneyHelper'
import { RouterStore } from 'src/stores/RouterStore'

interface IProps {
  journey: IJourney
  destinationAddress: string
  estimateInfo?: IOnDemandItemEstimateInfo
  handleMakeJourneyEstimate: () => Promise<void>
}

@observer
export class JourneyScreen extends Component<IProps> {
  public render() {
    const { journey, destinationAddress } = this.props

    return (
      <BottomSheetWrapper containerStyle={{ bottom: 0 }}>
        <JourneyCard
          journey={journey}
          destinationAddress={destinationAddress}
          estimateInfo={this.props.estimateInfo}
          handleMakeJourneyEstimate={this.props.handleMakeJourneyEstimate}
          handleBackPress={this.goBackFromJourney}
        />
      </BottomSheetWrapper>
    )
  }

  private readonly goBackFromJourney = async () => {
    await RouterStore.goToScreenIndex(-1)
  }
}
