import { FontAwesomeIconStyle, IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import { FeatureFlag } from '@sparelabs/feature-flags'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { PastTripText } from 'src/screens/request/PastTrips/PastTripText'
import { RequestInsightsStore } from 'src/stores/RequestInsightsStore'
import { ITipStore } from 'src/stores/TipStore'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    flexBasis: 'auto',
  },
  divider: {
    height: 8,
  },
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  statusContainer: {
    backgroundColor: colors.red10,
    borderRadius: 8,
    padding: 8,
    flexDirection: 'row',
    marginTop: 16,
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  statusText: {
    fontSize: 16,
    lineHeight: 20,
    color: colors.red70,
    marginLeft: 8,
  },
})

interface IProps {
  tipStore: ITipStore
  handleNavigatePastTrip: () => void
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
  request: IRequestResponse
}

export class PastTripsListItem extends Component<IProps> {
  public render() {
    const { tipStore, request, handleNavigateDiscountDetails } = this.props
    const statusText = this.getRequestStatusText(request.status)
    return (
      <TouchableListItemWrapper style={styles.container} onPress={this.navigateToSingleTripView}>
        <View style={styles.textContainer}>
          <PastTripText
            tipStore={tipStore}
            request={request}
            showFare={false}
            handleNavigateDiscountDetails={handleNavigateDiscountDetails}
          />
          <FontAwesomeIconWrapper icon={[FontAwesomeIconStyle.Solid, 'angle-right']} size={22} color={colors.gray90} />
        </View>
        {statusText ? (
          <View style={styles.statusContainer}>
            <FontAwesomeIconWrapper
              icon={[FontAwesomeIconStyle.Solid, 'exclamation-triangle']}
              size={16}
              color={colors.red70}
            />
            <Text style={styles.statusText}>{statusText}</Text>
          </View>
        ) : null}
      </TouchableListItemWrapper>
    )
  }

  public getRequestStatusText(status: RequestStatus): string | null {
    switch (status) {
      case RequestStatus.Cancelled: {
        return st.screens.pastTrips.cancelled()
      }
      case RequestStatus.NoDriversAvailable: {
        return st.screens.pastTrips.noDriversAvailable()
      }
      default: {
        return null
      }
    }
  }

  private readonly navigateToSingleTripView = async () => {
    const { request } = this.props
    if (AuthenticatorHelper.organization?.featureFlags.includes(FeatureFlag.RequestInsights)) {
      await RequestInsightsStore.getRequestInsights(request.id)
    }
    this.props.handleNavigatePastTrip()
  }
}
