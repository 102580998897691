import { ReviewTag as Tag } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { st } from 'src/locales'
import { TagButton } from '../buttons/TagButton/TagButton'

interface IProps {
  onPress?: () => void
  active?: boolean
  tagType: Tag
}

const t = st.enums

export class ReviewTagButton extends Component<IProps> {
  public render() {
    return <TagButton text={t.tagType[this.props.tagType]()} onPress={this.props.onPress} active={this.props.active} />
  }
}
