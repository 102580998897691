import { NavigationProp } from '@react-navigation/native'
import { ApiClient } from '@sparelabs/api-client'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { colors } from 'src/assets/colors'
import { ConfirmPhoneNumberForm } from 'src/components/form/ConfirmPhoneNumberForm'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { Sentry } from 'src/helpers/sentry'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsSetPhoneNumber } from 'src/navigation'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
})

interface ISetPhoneNumberConfirmViewProps {
  handleSubmit: (confirmationCode: string) => Promise<void>
}

export const SetPhoneNumberConfirmView = ({ handleSubmit }: ISetPhoneNumberConfirmViewProps): JSX.Element => (
  <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
    <ConfirmPhoneNumberForm
      inputDescription={st.screens.setPhoneNumberConfirm.verificationCodeDescription()}
      handleSubmit={handleSubmit}
    />
  </ScrollView>
)

export const SetPhoneNumberConfirm = observer(
  ({ route, navigation }: ScreenPropsSetPhoneNumber<ScreenName.SetPhoneNumberConfirm>): JSX.Element => {
    const { phoneNumber, authOrganization } = route.params
    const loginApiClient = new ApiClient({ host: authOrganization.apiHost })

    const phoneVerify = async (phoneNumber: string, organizationId: string, code: string) => {
      const { userId, token } = await loginApiClient.auth.phoneLoginVerify({
        phoneNumber,
        code,
        organizationId,
      })
      AuthenticatorHelper.setUserId(userId)
      AuthenticatorHelper.setRegionalHost(authOrganization.apiHost)
      AuthenticatorHelper.setUserOrgToken(token)
      AuthenticatorHelper.addToLoginList(token)
    }

    const tryPhoneVerify = async (phoneNumber: string, organizationId: string, code: string) => {
      try {
        await phoneVerify(phoneNumber, organizationId, code)
        return true
      } catch (error) {
        Sentry.captureMessage('Phone verification failed')
        Sentry.setExtra('extra', error)
        AlertHelper.alert(
          st.authentication.verificationAlertTitle(),
          st.authentication.verificationAlertContent(),
          [
            {
              text: st.common.alertOk(),
              onPress: () => undefined,
            },
          ],
          { cancelable: false }
        )
        return false
      }
    }

    const tryFetchUserAndOrgData = async () => {
      try {
        await AuthenticatorHelper.fetchUserData()
        await AuthenticatorHelper.fetchOrganizationData(authOrganization.id)
        return true
      } catch (error) {
        handleError({ error: error as IErrorWithResponse })
        return false
      }
    }

    const redirectToNextScreen = () => {
      if (AuthenticatorHelper.isFullUser(AuthenticatorHelper.getUser())) {
        navigation
          .getParent<NavigationProp<ParamsListRoot>>()
          .reset({ routes: [{ name: ScreenName.RootHome, params: {} }] })
      } else {
        navigation.navigate(ScreenName.SetProfile, { isOnboarding: true })
      }
    }

    const handleSubmit = async (confirmationCode: string) => {
      const isVerified = await tryPhoneVerify(phoneNumber, authOrganization.id, confirmationCode)
      if (isVerified) {
        const isDataFetched = await tryFetchUserAndOrgData()
        if (isDataFetched) {
          redirectToNextScreen()
        }
      }
    }

    return <SetPhoneNumberConfirmView handleSubmit={handleSubmit} />
  }
)
