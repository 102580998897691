import React, { useState } from 'react'
import { Dimensions, Keyboard, Platform, StyleSheet, View } from 'react-native'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { SetLocationPin } from 'src/components/SetLocationPin'
import { SlidingView } from 'src/components/SlidingView'
import { AutoSuggestion, SetAutoSuggestions } from 'src/helpers/AutoSuggestHelper'
import { st } from 'src/locales'
import { OsType } from 'src/util/types'
import { AutoSuggestList } from './AutoSuggestList'

const Screen = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    zIndex: 1,
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    marginBottom: 30,
    width: Screen.width,
  },
  webOverride: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    overflow: 'hidden',
  },
})

interface IProps {
  isOpen: boolean
  onConfirmMapRegion: () => void
  geoCodeLoading: boolean | undefined
  searchSuggestions: AutoSuggestion[]
  onPressSearchResult: (suggestion: SetAutoSuggestions) => void
  onPressSetLocationOnMap: () => void
}

export const DismissableSearchList = (props: IProps) => {
  const [height, setHeight] = useState<number | null>(null)

  const renderConfirmMapRegionButton = () => {
    if (!props.geoCodeLoading) {
      return (
        <View style={styles.buttonContainer}>
          <PrimaryButton
            title={st.common.buttonConfirm()}
            onPress={props.onConfirmMapRegion}
            paddingHorizontal={true}
          />
        </View>
      )
    }
    return null
  }

  return (
    <>
      {/*
       * TODO: Removing this feature for Rider Web MVP, should be re-enabled when
       * we decide on the best solution for positioning the map w.r.t. the other components on the left side
       * https://sparelabs.atlassian.net/browse/ENDUSER-625
       */}
      {Platform.OS !== OsType.Web && (
        <>
          <SetLocationPin />
          {renderConfirmMapRegionButton()}
        </>
      )}
      <View
        pointerEvents='box-none'
        style={[styles.container, styles.webOverride]}
        onLayout={(event) => {
          setHeight(event.nativeEvent.layout.height)
        }}
        onTouchStart={() => {
          /**
           * List does not scroll to end on iOS if keyboard is open, but works fine on Android.
           * Including Keyboard.dismiss() on Android causes rendering issues (component flickers on keyboard dismiss)
           */
          if (Platform.OS === OsType.iOS) {
            Keyboard.dismiss()
          }
        }}
      >
        {height !== null ? (
          <SlidingView isOpen={props.isOpen} height={height}>
            {props.isOpen ? (
              <AutoSuggestList
                searchSuggestions={props.searchSuggestions}
                style={styles.list}
                onPressSearchResult={(searchItem) => {
                  props.onPressSearchResult(searchItem)
                }}
                onPressSetLocationOnMap={props.onPressSetLocationOnMap}
              />
            ) : null}
          </SlidingView>
        ) : null}
      </View>
    </>
  )
}
