import { IFileResponse } from '@sparelabs/http-client-utils'
import { FieldLabel, FileInput } from '@sparelabs/web-ui-components'
import React from 'react'
import { Pressable } from 'react-native'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import { FieldContainer, FieldError } from 'src/components/form/FieldShared'
import { handleError } from 'src/helpers/ErrorHelpers'
import { IFileField, IFileFieldProps } from './FileFieldTypes'

export const FileField: IFileField = (props: IFileFieldProps) => (
  <FieldContainer>
    <FieldLabel>{props.label}</FieldLabel>
    <Pressable onPress={() => props.onClick && props.onClick()}>
      <FileInput<IFileResponse>
        accept={[]}
        label={props.label}
        onChange={props.onChange ?? undefined}
        value={props.value ?? null}
        upload={(file) => ApiClientBuilder.build().upload.file(file)}
        tempUrl={(id) => ApiClientBuilder.build().files.tempUrl(id)}
        onError={(error) => handleError({ error })}
      />
    </Pressable>

    <FieldError touched={props.touched} error={props.error} />
  </FieldContainer>
)
