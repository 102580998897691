import React from 'react'
import { colors } from 'src/assets/colors'
import { IRadioButtonGroup } from './RadioButtonGroupTypes'

export const RadioButtonGroup = ({ selected, options, onHandleSelected }: IRadioButtonGroup): React.ReactElement => {
  const renderRadioButtons = (selected) =>
    options.map((option, index) => {
      const checked = selected === option.key
      const lastItem = index === options.length - 1
      return (
        <div
          key={option.key}
          style={{
            padding: 16,
            borderTopWidth: 1,
            borderColor: colors.borderBlue,
            borderBottomWidth: lastItem ? 1 : 0,
          }}
        >
          <input type='radio' id={option.label} onClick={() => onHandleSelected(option.key)} checked={checked} />
          <label htmlFor={option.label} style={{ marginLeft: 16, fontSize: 16, color: colors.gray90 }}>
            {option.label}
          </label>
        </div>
      )
    })

  return (
    <div style={{ flexDirection: 'column' }} role='radiogroup'>
      {renderRadioButtons(selected)}
    </div>
  )
}
