import { getAnalytics, logEvent } from 'firebase/analytics'
import { getApps, initializeApp } from 'firebase/app'
import { Constants } from 'src/consts/Constants'
import { EventName } from 'src/helpers/AnalyticsHelper/AnalyticHelperTypes'
import { Mixpanel } from 'src/helpers/Mixpanel'
import { IEventProps } from 'src/lib/ExpoMixpanelAnalytics'
import { BuildEnvironment } from 'src/types/constants'

// configure and initialize Firebase for web
const firebaseConfig =
  Constants.BUILD_ENVIRONMENT === BuildEnvironment.PRODUCTION
    ? {
        apiKey: 'AIzaSyD9XmO9L7LbJILiE8bn7Axoef3oTr8unpM',
        authDomain: 'spare-rider.firebaseapp.com',
        databaseURL: 'https://spare-rider.firebaseio.com',
        projectId: 'spare-rider',
        storageBucket: 'spare-rider.appspot.com',
        messagingSenderId: '561936584047',
        appId: '1:561936584047:web:76459f8eaf7a39305d6c9a',
        measurementId: 'G-JS1MNRX6MH',
      }
    : {
        apiKey: 'AIzaSyAeX24I1rq_EGOve0pnrr0sSZqLhBouK18',
        authDomain: 'spare-rider-testing.firebaseapp.com',
        databaseURL: 'https://spare-rider-testing.firebaseio.com',
        projectId: 'spare-rider-testing',
        storageBucket: 'spare-rider-testing.appspot.com',
        messagingSenderId: '535514145951',
        appId: '1:535514145951:web:de65e28365856ffdf0aeaa',
        measurementId: 'G-04SM8KBH37',
      }

const app = initializeApp(firebaseConfig)
const webAnalytics = getAnalytics(app)

export class AnalyticsHelper {
  public static track = (name: EventName, props: IEventProps = {}): void => {
    Mixpanel.track(name, props)
  }

  public static setScreen = async (screen: string): Promise<void> => {
    if (getApps().length) {
      logEvent(webAnalytics, EventName.ScreenView, {
        firebase_screen: screen,
        firebase_screen_class: screen,
        screen_name: screen,
        page_title: screen,
      })
    }
    AnalyticsHelper.track(EventName.ScreenView, { screen })
  }
}
