import { observer } from 'mobx-react-lite'
import React from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Images } from 'src/assets/Images'
import { TimelineDash } from 'src/components/journey/TimelineDash'
import { st } from 'src/locales'
import { SearchFieldType, SearchScreenStore } from 'src/stores/SearchScreenStore'
import { colors } from '../assets/colors'
import { AutoSuggestInput } from './AutoSuggestInput'
import { FontAwesomeIconWrapper } from './FontAwesomeIcon'

const styles = StyleSheet.create({
  container: {
    zIndex: 3,
    backgroundColor: 'white',
    elevation: 3,
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomColor: colors.gray50,
    borderBottomWidth: 0.3,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  backContainer: {
    height: 44,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  searchHeaderContainer: {
    flexDirection: 'row',
  },
  swapContainer: {
    height: 48,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchContainer: {
    flex: 1,
  },
  iconContainer: {
    alignItems: 'center',
    width: 8,
    marginRight: 8,
  },
  searchSwapContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  chevron: {
    width: 12,
    height: 21,
    marginHorizontal: 18,
  },
})

interface IProps {
  handleFocus: (searchFied: SearchFieldType) => void
  onPressBack: () => Promise<void>
  setSearchTerm: (text: string) => void
  mapShowingOnSwap: () => void
  geoCodeLoading: boolean | undefined
  isShowingMap: boolean
}

export const SearchHeader = observer(
  ({ handleFocus, onPressBack, setSearchTerm, mapShowingOnSwap, geoCodeLoading, isShowingMap }: IProps) => {
    const getFieldValue = (searchFieldType: SearchFieldType): string => {
      const searchField = SearchScreenStore.getSearchField(searchFieldType)
      if (isShowingMap && SearchScreenStore.currentSearchFieldType === searchFieldType) {
        const regionSearchItem = SearchScreenStore.getRegionSuggestion(searchFieldType)
        if (geoCodeLoading || !regionSearchItem) {
          return st.screens.search.loading()
        }
        return regionSearchItem.name
      }
      return searchField.selectedSuggestion
        ? searchField.selectedSuggestion.name
        : SearchScreenStore.getSearchTerm(searchFieldType)
    }

    const handleSwap = async (isShowingMap: boolean) => {
      if (isShowingMap) {
        mapShowingOnSwap()
      }
      SearchScreenStore.swapOriginAndDestinationTerms(isShowingMap)
    }

    const currentSearchFieldType = SearchScreenStore.currentSearchFieldType
    return (
      <SafeAreaView style={styles.container} edges={['top']}>
        <View style={styles.searchHeaderContainer}>
          <TouchableOpacity onPress={onPressBack} style={styles.backContainer}>
            <Image resizeMode='contain' style={styles.chevron} source={Images.back} />
          </TouchableOpacity>
          <View style={styles.searchSwapContainer}>
            <View style={styles.iconContainer}>
              <FontAwesomeIconWrapper icon='circle' size={8} color={colors.textDark} />
              <TimelineDash dottedLineHeight={34} />
              <FontAwesomeIconWrapper icon='square' size={8} color={colors.textDark} />
            </View>
            <View style={styles.searchContainer}>
              <AutoSuggestInput
                value={getFieldValue(SearchFieldType.Origin)}
                onChangeText={(text) => setSearchTerm(text)}
                selected={currentSearchFieldType === SearchFieldType.Origin}
                onFocus={() => handleFocus(SearchFieldType.Origin)}
                inputStyle={{ borderRadius: 8, marginBottom: 4 }}
              />
              <AutoSuggestInput
                value={getFieldValue(SearchFieldType.Destination)}
                onChangeText={(text) => setSearchTerm(text)}
                selected={currentSearchFieldType === SearchFieldType.Destination}
                onFocus={() => handleFocus(SearchFieldType.Destination)}
                isDropoff={true}
                inputStyle={{ borderRadius: 8, marginTop: 4 }}
              />
            </View>
            <TouchableOpacity onPress={() => handleSwap(isShowingMap)} style={styles.swapContainer}>
              <FontAwesomeIconWrapper icon='sort-alt' size={20} color={colors.textDark} />
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    )
  }
)
