import { IEstimateService } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { AccessibilityControl } from 'src/components/rideOptions/AccessibilityControl'
import { PaymentControl } from 'src/components/rideOptions/PaymentControl'
import { RidersControl } from 'src/components/rideOptions/RidersControl'
import { ScheduleControl } from 'src/components/rideOptions/ScheduleControl'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { IEstimatesUserInputParsed } from 'src/types'

const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 4,
    paddingBottom: 8,
    justifyContent: 'space-around',
  },
  row: {
    flexDirection: 'row',
    flex: 1,
    paddingBottom: 8,
    width: '100%',
  },
})

export interface IProps {
  estimatesUserInput: IEstimatesUserInputParsed
  selectedService: IEstimateService | undefined
  isJourneySelected: boolean
  estimateStore: IEstimateStore
  estimateInputStore: IEstimateInputStore
  isDisabled?: boolean
  paymentMethodTitle?: string
  handleNavigateSelectPaymentMethod: (props: ParamsListRoot[ScreenName.SelectPaymentMethod]) => void
  handleNavigateEstimateAccessibilityOptions: (params: ParamsListRoot[ScreenName.EstimateAccessibilityOptions]) => void
  handleNavigateEstimateRiderOptions: (params: ParamsListRoot[ScreenName.EstimateRiderOptions]) => void
  handleNavigateScheduleEstimate: (params: ParamsListRoot[ScreenName.ScheduleEstimate]) => void
}

@observer
export class RideOptionsControls extends Component<IProps> {
  public render() {
    return (
      <View style={styles.containerStyle}>
        <View style={styles.row}>
          <RidersControl
            estimatesUserInput={this.props.estimatesUserInput}
            estimateInputStore={this.props.estimateInputStore}
            riders={this.props.estimateStore.servicesRiderTypes}
            maxRiders={this.props.estimateStore.servicesMaxRiders}
            handleNavigateEstimateRiderOptions={this.props.handleNavigateEstimateRiderOptions}
            isJourneySelected={this.props.isJourneySelected}
            isDisabled={this.props.isDisabled}
          />
          {this.shouldRenderAccessibilityControl() ? (
            <AccessibilityControl
              estimateStore={this.props.estimateStore}
              estimateInputStore={this.props.estimateInputStore}
              estimatesUserInput={this.props.estimatesUserInput}
              features={this.props.estimateStore.servicesAccessibilityFeatures}
              handleNavigateEstimateAccessibilityOptions={this.props.handleNavigateEstimateAccessibilityOptions}
              isJourneySelected={this.props.isJourneySelected}
              isDisabled={this.props.isDisabled}
            />
          ) : null}
        </View>
        <View style={styles.row}>
          <ScheduleControl
            estimateStore={this.props.estimateStore}
            estimateInputStore={this.props.estimateInputStore}
            handleNavigateScheduleEstimate={this.props.handleNavigateScheduleEstimate}
            estimatesUserInput={this.props.estimatesUserInput}
            canScheduleEstimate={this.props.estimateStore.servicesAllowScheduling}
            isDisabled={this.props.isDisabled}
          />
          <PaymentControl
            estimateInputStore={this.props.estimateInputStore}
            handleNavigateSelectPaymentMethod={this.props.handleNavigateSelectPaymentMethod}
            estimatesUserInput={this.props.estimatesUserInput}
            selectedService={this.props.selectedService}
            isJourneySelected={this.props.isJourneySelected}
            estimateResponseMap={this.props.estimateStore.estimateResponseMap}
            isDisabled={this.props.isDisabled}
            title={this.props.paymentMethodTitle}
          />
        </View>
      </View>
    )
  }

  private readonly shouldRenderAccessibilityControl = () =>
    this.props.estimateStore.servicesAccessibilityFeatures.length > 0
}
