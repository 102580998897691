import { IStripeClientSafeInfo, StripePaymentMethodType } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { Images } from 'src/assets/Images'
import { ISettingsListItem, SettingsList, SettingsListItemType } from 'src/components/settings/SettingsList'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { PaymentMethodType } from 'src/helpers/payments/PaymentMethodHelper'
import { PaymentProviderHelper } from 'src/helpers/payments/PaymentProviderHelper'
import { st } from 'src/locales'
import { ScreenName, ScreenPropsAddPaymentMethod, SuccessfulPaymentMethodAddHook } from 'src/navigation'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { AlertButtonStyle, ConnectedAccountType } from 'src/util/types'

enum AddPaymentMethodScreenOptions {
  StripeCreditCard = 'StripeCreditCard',
  StripeSEPA = 'StripeSEPA',
  Nimoca = 'Nimoca',
}

interface IAddPaymentMethodViewProps {
  paymentMethodTypeRequired?: PaymentMethodType[]
  onPaymentMethodSuccessfullyAdded?: SuccessfulPaymentMethodAddHook
  handlePressItem: (id: AddPaymentMethodScreenOptions) => void
}

export class AddPaymentMethodView extends Component<IAddPaymentMethodViewProps> {
  public getSettingsList(): ISettingsListItem[] {
    const imageStyle = { width: 50, height: 20 }
    const settingsListItems: ISettingsListItem[] = []
    for (const provider of PaymentProviderHelper.getValidProviders(this.props.paymentMethodTypeRequired)) {
      if (provider.connectedAccountType === ConnectedAccountType.Stripe) {
        // If `enabledMethods` is not supplied from the client, we will default
        // to enabling Card as the only option
        const enabledMethods: StripePaymentMethodType[] = (provider.clientSafe as IStripeClientSafeInfo)
          ?.enabledMethods ?? [StripePaymentMethodType.Card]
        for (const method of enabledMethods) {
          if (method === StripePaymentMethodType.Card) {
            settingsListItems.push({
              id: AddPaymentMethodScreenOptions.StripeCreditCard,
              imageStyle,
              title: st.screens.addPaymentMethod.creditCard(),
              image: Images.creditCard,
              type: SettingsListItemType.List,
            })
          } else if (method === StripePaymentMethodType.SepaDebit) {
            settingsListItems.push({
              id: AddPaymentMethodScreenOptions.StripeSEPA,
              title: st.screens.addPaymentMethod.sepaDebit(),
              type: SettingsListItemType.List,
              iconComponent: <SvgIconWrapper widthFixed={50} heightFixed={20} icon={SvgIcon.SepaIcon} />,
            })
          }
        }
      } else if (provider.connectedAccountType === ConnectedAccountType.Nimoca) {
        settingsListItems.push({
          id: AddPaymentMethodScreenOptions.Nimoca,
          title: st.screens.addPaymentMethod.nimoca(),
          iconComponent: <SvgIconWrapper widthFixed={50} heightFixed={20} icon={SvgIcon.NimocaIcon} />,
          type: SettingsListItemType.List,
        })
      }
    }
    return settingsListItems
  }

  public render() {
    return (
      <SettingsList<AddPaymentMethodScreenOptions>
        data={this.getSettingsList()}
        onPressItem={this.props.handlePressItem}
      />
    )
  }
}

export class AddPaymentMethod extends Component<ScreenPropsAddPaymentMethod<ScreenName.AddPaymentMethod>> {
  public handlePressItem = (id: AddPaymentMethodScreenOptions): void => {
    switch (id) {
      case AddPaymentMethodScreenOptions.Nimoca:
        this.pushScreenWithCallbackHandlerNimoca()
        break
      case AddPaymentMethodScreenOptions.StripeCreditCard:
        this.pushScreenWithCallbackHandlerStripe({
          stripeMethodType: StripePaymentMethodType.Card,
        })
        break
      case AddPaymentMethodScreenOptions.StripeSEPA:
        this.pushScreenWithCallbackHandlerStripe({
          stripeMethodType: StripePaymentMethodType.SepaDebit,
        })
        break
    }
  }

  private readonly handlePaymentMethodSuccessfullyAdded = async (paymentMethodId: string) => {
    await this.showSetAsDefaultAlert(paymentMethodId, async () => {
      // We are popping two screens here because we first will be popping the payment method specific screen
      // and then we will be popping the general add payment method screen
      this.props.navigation.goBack()
      this.props.navigation.goBack()
      if (this.props.route.params.onPaymentMethodSuccessfullyAdded) {
        await this.props.route.params.onPaymentMethodSuccessfullyAdded(paymentMethodId)
      }
    })
  }

  /**
   * Used when a rider wants to add a specific payment method:
   *  1. Push user to screen
   *  2. Payment method screen receives a SuccessfulPaymentMethodAddHook
   *  3. Rider enters details successfully, screen calls SuccessfulPaymentMethodAddHook
   *  4. Take user through flow of setting default payment method
   *  5. Dismiss new screen and this screen & forward information to parent
   */
  private readonly pushScreenWithCallbackHandlerNimoca = () => {
    this.props.navigation.navigate(ScreenName.AddNimoca, {
      onPaymentMethodSuccessfullyAdded: this.handlePaymentMethodSuccessfullyAdded,
    })
  }

  private readonly pushScreenWithCallbackHandlerStripe = (stripeSessionProps: {
    stripeMethodType: StripePaymentMethodType
  }) => {
    this.props.navigation.navigate(ScreenName.AddStripePaymentMethodSession, {
      onPaymentMethodSuccessfullyAdded: this.handlePaymentMethodSuccessfullyAdded,
      ...stripeSessionProps,
    })
  }

  private readonly showSetAsDefaultAlert = async (paymentMethodId: string, done: SuccessfulPaymentMethodAddHook) => {
    /**
     * If this is the only payment method then set it as the default,
     * otherwise prompt the user to see if they want to change the default
     */
    if (PaymentMethodStore.paymentMethods.length === 1) {
      await PaymentMethodStore.setDefault(paymentMethodId)
      await done(paymentMethodId)
    } else {
      AlertHelper.alert(
        st.screens.paymentMethods.setDefaultTitle(),
        st.screens.paymentMethods.setDefaultText(),
        [
          {
            text: st.screens.paymentMethods.setDefaultOk(),
            onPress: async () => {
              await PaymentMethodStore.setDefault(paymentMethodId)
              await done(paymentMethodId)
            },
          },
          {
            text: st.screens.paymentMethods.setDefaultCancel(),
            onPress: async () => {
              await done(paymentMethodId)
            },
            style: AlertButtonStyle.Cancel,
          },
        ],
        { cancelable: true }
      )
    }
  }

  public render() {
    return (
      <AddPaymentMethodView
        handlePressItem={this.handlePressItem}
        onPaymentMethodSuccessfullyAdded={this.props.route.params.onPaymentMethodSuccessfullyAdded}
        paymentMethodTypeRequired={this.props.route.params.paymentMethodTypeRequired}
      />
    )
  }
}
