import { IEstimateService } from '@sparelabs/api-client'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { multimodalEnabled } from 'src/helpers/RideOptionsCardHelper'
import { st } from 'src/locales'
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup'
import { IRadioOption } from '../RadioButtonGroup/RadioButtonGroupTypes'

export enum EstimateScheduleOptions {
  Now = 'now',
  Leave = 'leave',
  Arrive = 'arrive',
}

interface IProps {
  onHandleChange: (selected: EstimateScheduleOptions) => void
  selected: EstimateScheduleOptions
  scheduledLeaveEnabledServices: IEstimateService[] | null
  scheduledArriveEnabledServices: IEstimateService[] | null
}
@observer
export class EstimateRadioButtonGroup extends Component<IProps> {
  public getEstimateOptions(): IRadioOption[] {
    const { scheduledLeaveEnabledServices, scheduledArriveEnabledServices } = this.props
    const options = [{ key: EstimateScheduleOptions.Now, label: st.components.scheduleEstimate.now() }]

    if ((scheduledLeaveEnabledServices && scheduledLeaveEnabledServices.length >= 1) || multimodalEnabled()) {
      options.push({ key: EstimateScheduleOptions.Leave, label: st.components.scheduleEstimate.leave() })
    }

    if ((scheduledArriveEnabledServices && scheduledArriveEnabledServices.length >= 1) || multimodalEnabled()) {
      options.push({ key: EstimateScheduleOptions.Arrive, label: st.components.scheduleEstimate.arrive() })
    }

    return options
  }

  public onHandleSelected = (selected) => {
    this.props.onHandleChange(selected)
  }

  public render() {
    return (
      <RadioButtonGroup
        selected={this.props.selected}
        onHandleSelected={this.onHandleSelected}
        options={this.getEstimateOptions()}
      />
    )
  }
}
