import { RequestStatus } from '@sparelabs/api-client'
import { FeatureFlag } from '@sparelabs/feature-flags'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Image, Linking, Platform, ScrollView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { LyftConstants } from 'src/consts/LyftConstants'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'
import { ParamsListAccount, ParamsListRoot, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { AboutRide } from 'src/screens/request/AboutRide'
import { PastTripText } from 'src/screens/request/PastTrips/PastTripText'
import { TripEmissions } from 'src/screens/request/PastTrips/TripEmissions'
import { RequestBrand } from 'src/screens/request/RequestBrand'
import { RequestStatusBar } from 'src/screens/request/RequestStatusBar'
import { RequestTimeline } from 'src/screens/request/RequestTimeline'
import { ITipStore, TipStore } from 'src/stores/TipStore'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    padding: 16,
    backgroundColor: 'white',
  },
  divider: {
    borderTopColor: colors.borderGray,
    borderTopWidth: 1,
    marginVertical: 16,
  },
  timelineContainer: {
    marginLeft: 8,
  },
  timeline: {
    height: 'auto',
    flex: 0,
  },
  accountHistoryMsg: {
    color: colors.gray70,
    fontSize: 14,
    marginVertical: 8,
  },
  rightArrow: {
    margin: 16,
    marginLeft: 'auto',
    ...(Platform.OS === OsType.Web && { width: 8, height: 13 }),
  },
  cell: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexBasis: 'auto',
    alignItems: 'center',
  },
})

type Props = ParamsListAccount[ScreenName.PastTrip] & {
  tipStore: ITipStore
  handleNavigateEmissionDetails: (params: ParamsListRoot[ScreenName.EmissionsDetails]) => void
  handleNavigateDiscountDetails: (params: ParamsListRoot[ScreenName.DiscountDetails]) => void
}

@observer
export class PastTripView extends Component<Props> {
  public renderEmissionsData() {
    const { request, handleNavigateEmissionDetails } = this.props
    const requestInsightsEnabled = AuthenticatorHelper.organization?.featureFlags.includes(FeatureFlag.RequestInsights)
    if (requestInsightsEnabled && request.status === RequestStatus.Completed) {
      return <TripEmissions handleNavigateEmissionDetails={handleNavigateEmissionDetails} request={request} />
    }
    return null
  }

  private renderAboutOpenFleetRide(): JSX.Element {
    return (
      <>
        <View style={styles.divider} />
        <View style={{ paddingVertical: 8 }}>
          <AboutRide provider='Lyft' serviceName={this.props.request.serviceBrand.name} />
        </View>
      </>
    )
  }

  private renderOpenFleetHistory(): JSX.Element {
    return (
      <>
        <TouchableListItemWrapper style={styles.cell} onPress={() => Linking.openURL(LyftConstants.LYFT_RIDE_LINK)}>
          <View>
            <Text style={{ fontSize: 16 }}>{st.components.requestCard.lyftRide.btnTitle()}</Text>
          </View>
          <Image style={styles.rightArrow} resizeMode='contain' source={Images.rightArrow} />
        </TouchableListItemWrapper>
        <Text style={styles.accountHistoryMsg}>{st.components.requestCard.lyftRide.accountHistoryMsg()}</Text>
      </>
    )
  }

  private renderContactSupport(supportUrl: string): JSX.Element {
    const { request } = this.props
    return (
      <>
        {!RequestHelper.isLyftRequest(request) && <View style={styles.divider} />}
        <TouchableListItemWrapper style={styles.cell} onPress={() => Linking.openURL(supportUrl)}>
          <View>
            <Text style={{ fontSize: 16 }}>{st.components.requestCard.contactSupport()}</Text>
          </View>
          <Image style={styles.rightArrow} resizeMode='contain' source={Images.rightArrow} />
        </TouchableListItemWrapper>
      </>
    )
  }

  public render() {
    const { tipStore, request, handleNavigateDiscountDetails } = this.props
    const supportUrl = request.fleet?.supportUrl ? RequestHelper.enforceUrlScheme(request.fleet?.supportUrl) : null
    return (
      <View style={styles.container}>
        <ScrollView>
          <RequestStatusBar request={request} />
          <View style={styles.innerContainer}>
            <PastTripText
              tipStore={tipStore}
              request={request}
              showFare={true}
              handleNavigateDiscountDetails={handleNavigateDiscountDetails}
            />
            <View style={styles.divider} />
            {this.renderEmissionsData()}
            <RequestBrand request={request} />
            <View style={styles.timelineContainer}>
              <RequestTimeline request={request} containerStyle={styles.timeline} />
            </View>
            {RequestHelper.isLyftRequest(request) && this.renderAboutOpenFleetRide()}
            {supportUrl && this.renderContactSupport(supportUrl)}
            {RequestHelper.isLyftRequest(request) && this.renderOpenFleetHistory()}
          </View>
        </ScrollView>
      </View>
    )
  }
}

export const PastTrip = (props: ScreenPropsAccount<ScreenName.PastTrip>) => (
  <PastTripView
    {...props.route.params}
    tipStore={TipStore}
    handleNavigateEmissionDetails={(params) => props.navigation.navigate(ScreenName.EmissionsDetails, params)}
    handleNavigateDiscountDetails={(params) => props.navigation.navigate(ScreenName.DiscountDetails, params)}
  />
)
