import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import {
  PaymentMethodHelper,
  PaymentMethodItem,
  PaymentMethodType,
  VirtualConnectedAccountType,
} from 'src/helpers/payments/PaymentMethodHelper'
import { st } from 'src/locales'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { PrimaryButton } from '../buttons/PrimaryButton'
import { PaymentMethodEntry } from './PaymentMethodEntry'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  list: {
    flexGrow: 0,
  },
  buttonWrapper: {
    paddingTop: 16,
  },
})

export interface IPaymentMethodListProps {
  showSelected?: boolean
  isCashEnabled: boolean
  selectedPaymentMethodId?: string | null
  onSelectPaymentMethod: (paymentMethodId: string | null) => Promise<void>
  paymentMethodTypeRequired?: PaymentMethodType[]
  selectAfterAdd?: boolean
  handleAddPaymentMethod: () => void
}

@observer
export class PaymentMethodList extends Component<IPaymentMethodListProps> {
  public async componentDidMount() {
    await PaymentMethodStore.getPaymentMethods()
  }

  public renderPaymentMethod = ({ item, index }: { item: PaymentMethodItem; index: number }) => (
    <PaymentMethodEntry
      paymentMethod={item}
      showSelected={this.props.showSelected}
      indexInList={index}
      numberOfOptions={this.getAllPaymentMethodItems().length}
      disabled={item.connectedAccountType === VirtualConnectedAccountType.Cash && !this.props.isCashEnabled}
      selectedPaymentMethodId={this.props.selectedPaymentMethodId}
      onSelectPaymentMethod={this.props.onSelectPaymentMethod}
    />
  )

  public getValidProviders() {
    const { paymentMethodTypeRequired } = this.props
    // Make sure that we are only checking the valid providers for this operation
    // as sometimes we want to restrict payment method types
    return AuthenticatorHelper.getOrganization().paymentProviders.filter((p) => {
      if (paymentMethodTypeRequired) {
        return paymentMethodTypeRequired.includes(p.connectedAccountType)
      }
      return true
    })
  }

  public renderAddPaymentMethod = () =>
    this.getValidProviders().length ? (
      <View style={styles.buttonWrapper}>
        <PrimaryButton
          onPress={this.props.handleAddPaymentMethod}
          title={st.screens.selectPaymentMethod.addPaymentMethod()}
          paddingHorizontal={true}
        />
      </View>
    ) : null

  public getAllPaymentMethodItems = (): PaymentMethodItem[] => {
    const { paymentMethodTypeRequired } = this.props

    const allPaymentMethods: PaymentMethodItem[] = [
      PaymentMethodHelper.buildCashPaymentMethod(!PaymentMethodStore.defaultPayment),
      ...PaymentMethodStore.paymentMethods,
    ]

    if (paymentMethodTypeRequired) {
      return allPaymentMethods.filter((p) => paymentMethodTypeRequired.includes(p.connectedAccountType))
    }
    return allPaymentMethods
  }

  public render() {
    return (
      <View style={styles.container}>
        <FlatList
          style={styles.list}
          data={this.getAllPaymentMethodItems()}
          refreshing={PaymentMethodStore.isLoading}
          keyExtractor={(item) => item.id || item.connectedAccountType}
          renderItem={this.renderPaymentMethod}
          accessibilityRole='menu'
        />
        {this.renderAddPaymentMethod()}
      </View>
    )
  }
}
