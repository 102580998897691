import { CustomFieldType, ICustomFieldChoice, ICustomFieldResponse } from '@sparelabs/api-client'
import { isFinite, isString } from 'lodash'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { FormLongTextField } from 'src/components/form/FormLongTextField'
import { SingleChoiceView } from 'src/components/form/SingleChoiceField/SingleChoiceModal'
import { SliderField } from 'src/components/form/SliderField'
import { ToggleField } from 'src/components/form/ToggleField'

const styles = StyleSheet.create({
  fieldWrapper: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: colors.white,
    marginBottom: 16,
  },
  toggleLabel: {
    flex: 1,
    paddingVertical: 20,
    color: colors.gray90,
    fontSize: 21,
    lineHeight: 24,
    fontWeight: 'bold',
    paddingRight: 16,
  },
})

interface ICustomSurveyFieldProps extends Omit<IFormFieldProps<any>, 'label' | 'keyboardType'> {
  customField: Pick<ICustomFieldResponse, 'type' | 'label' | 'hint' | 'choices' | 'isRequired'>
}

export class CustomSurveyField extends Component<ICustomSurveyFieldProps> {
  public render() {
    const commonProps = {
      ...this.props,
      label: this.props.customField.label,
      hint: this.props.customField.hint ?? undefined,
      isRequired: this.props.customField.isRequired ?? false,
    }
    switch (this.props.customField.type) {
      case CustomFieldType.Text:
        return (
          <View style={styles.fieldWrapper}>
            <FormLongTextField {...commonProps} />
          </View>
        )
      case CustomFieldType.Number: {
        const { value, onChange } = this.props
        const stringValue = isString(value) ? value : isFinite(value) ? value.toString() : ''
        return (
          <View style={styles.fieldWrapper}>
            <FormLongTextField
              {...commonProps}
              onChange={(newValue) => onChange(parseInt(newValue, 10))}
              value={stringValue}
              keyboardType='numeric'
            />
          </View>
        )
      }
      case CustomFieldType.SingleChoice:
        return (
          <View style={{ marginBottom: 16 }}>
            <SingleChoiceView
              choices={this.props.customField.choices as ICustomFieldChoice[]}
              selected={commonProps.value}
              onSubmit={commonProps.onChange}
              hint={commonProps.hint}
              title={commonProps.label}
              isRequired={commonProps.isRequired}
              addWrapper={true}
            />
          </View>
        )
      case CustomFieldType.Toggle:
        return (
          <View style={styles.fieldWrapper}>
            <ToggleField {...commonProps} labelStyle={styles.toggleLabel} />
          </View>
        )
      case CustomFieldType.NpsScore:
        return <SliderField {...commonProps} />
      case CustomFieldType.Date:
      case CustomFieldType.Datetime:
      case CustomFieldType.Address:
      case CustomFieldType.File:
      case CustomFieldType.Files:
      case CustomFieldType.MultipleChoice:
      case CustomFieldType.Drawing:
        return null
    }
  }
}
