import { IRequestResponse } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FloatingCard } from 'src/components/cards/FloatingCard'
import { RequestWaitText } from 'src/components/request/processing/RequestWaitText'
import { st } from 'src/locales'
import { ScreenName } from 'src/navigation/types/ScreenNameTypes'
import { ParamsListRoot } from 'src/navigation/types/ScreenParamsListTypes'
import { RequestCancelButton } from 'src/screens/request/RequestCancelButton'
import { RouterStore } from 'src/stores/RouterStore'
import { LoadingShimmer } from '../../LoadingShimmer'

const imageDiameter = 64

const styles = StyleSheet.create({
  card: {
    paddingTop: 0,
    paddingBottom: 24,
    paddingHorizontal: 8,
  },
  loadingImage: {
    width: imageDiameter,
    height: imageDiameter,
    borderRadius: imageDiameter / 2,
  },
  rotatingText: {
    flexWrap: 'wrap',
    paddingTop: 4,
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray70,
    paddingRight: 16,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    padding: 16,
    paddingBottom: 0,
  },
  buttonContainer: {
    padding: 8,
  },
  webOverride: {
    borderRadius: 8,
  },
})

interface IProps {
  request: IRequestResponse
  handleNavigateRequestCancellation: (params: ParamsListRoot[ScreenName.RequestCancellation]) => void
}

export class RequestProcessingCard extends Component<IProps> {
  public render() {
    const { request } = this.props
    return (
      <FloatingCard customStyle={styles.card}>
        <LoadingShimmer />
        <View style={styles.container} testID='requestProcessingCard'>
          <View style={styles.row}>
            <View style={styles.textContainer}>
              <RequestWaitText request={request} />
              <Text style={styles.rotatingText}>{st.request.processingText()}</Text>
            </View>
            <Image source={{ uri: request.serviceBrand.photoUrl }} style={styles.loadingImage} />
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <RequestCancelButton
            handleNavigateRequestCancellation={() =>
              this.props.handleNavigateRequestCancellation({
                requestId: request.id,
                serviceBrandPhotoUrl: request.serviceBrand.photoUrl,
                onSuccessCallback: RouterStore.returnHome.bind(RouterStore),
              })
            }
          />
        </View>
      </FloatingCard>
    )
  }
}
