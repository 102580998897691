import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { SetupIntentResult } from '@stripe/stripe-js'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { st } from 'src/locales'
import { RedirectParams, RedirectValues } from 'src/navigation/consts/NavigationStateHelper'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  form: {
    width: '100%',
    paddingVertical: 24,
    paddingLeft: 16,
    paddingRight: 20,
  },
  buttonContainer: {
    paddingTop: 16,
  },
})

export const CheckoutFormWeb = (props: { onReady: () => void; onSuccess: () => Promise<void> }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsLoading(true)
    const result: SetupIntentResult = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        /**
         * If payment method requires a redirect it will take the customer out of the flow
         * see getInitialState in NavigationHelper.ts on how nav state is recreated
         */
        return_url: `${window.location.origin}/redirect?${RedirectParams.Stripe}=${RedirectValues.CardSetup}`,
      },
      /**
       * test redirects by setting this to 'always', since this is determined by the card issuer
       * and all stripe test cards do not require a redirect.
       */
      redirect: 'if_required',
    })
    if (result.error) {
      AlertHelper.alert(result.error.message ?? '')
    } else {
      await props.onSuccess()
    }
    setIsLoading(false)
  }

  return (
    <View style={styles.container}>
      <View style={styles.form}>
        <PaymentElement onReady={props.onReady} />
        <View style={styles.buttonContainer}>
          <PrimaryButton
            loading={isLoading}
            onPress={handleSubmit}
            title={st.screens.selectPaymentMethod.addPaymentMethod()}
          />
        </View>
      </View>
    </View>
  )
}
