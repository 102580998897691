import React, { Component, ReactNode } from 'react'
import { LayoutChangeEvent, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native'

const styles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'column',
  },
  tapToDismissContainer: {
    backgroundColor: 'transparent',
    flex: 1,
  },
})

interface IProps {
  onExit: () => void
  children?: ReactNode
}

interface IState {
  width: number
}

export class Lightbox extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      width: 0,
    }
  }

  public onLayout = (event: LayoutChangeEvent) => {
    this.setState({ width: event.nativeEvent.layout.width })
  }

  public render() {
    return (
      <View onLayout={(event) => this.onLayout(event)} style={styles.background}>
        <TouchableOpacity style={styles.tapToDismissContainer} onPress={() => this.props.onExit()} />
        <SafeAreaView>{this.props.children}</SafeAreaView>
      </View>
    )
  }
}
