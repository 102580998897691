import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { faBadgeDollar } from '@fortawesome/pro-solid-svg-icons/faBadgeDollar'
import { faBadgePercent } from '@fortawesome/pro-solid-svg-icons/faBadgePercent'
import { faBallot } from '@fortawesome/pro-solid-svg-icons/faBallot'
import { faBicycle } from '@fortawesome/pro-solid-svg-icons/faBicycle'
import { faBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase'
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn'
import { faBus } from '@fortawesome/pro-solid-svg-icons/faBus'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar'
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt'
import { faCar } from '@fortawesome/pro-solid-svg-icons/faCar'
import { faCarBus } from '@fortawesome/pro-solid-svg-icons/faCarBus'
import { faCarCrash } from '@fortawesome/pro-solid-svg-icons/faCarCrash'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChild } from '@fortawesome/pro-solid-svg-icons/faChild'
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faDotCircle } from '@fortawesome/pro-solid-svg-icons/faDotCircle'
import { faDumbbell } from '@fortawesome/pro-solid-svg-icons/faDumbbell'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faFlag } from '@fortawesome/pro-solid-svg-icons/faFlag'
import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap'
import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart'
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faLeaf } from '@fortawesome/pro-solid-svg-icons/faLeaf'
import { faLocation } from '@fortawesome/pro-solid-svg-icons/faLocation'
import { faLocationPin } from '@fortawesome/pro-solid-svg-icons/faLocationPin'
import { faMapPin } from '@fortawesome/pro-solid-svg-icons/faMapPin'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faPrayingHands } from '@fortawesome/pro-solid-svg-icons/faPrayingHands'
import { faRaindrops } from '@fortawesome/pro-solid-svg-icons/faRaindrops'
import { faScrubber } from '@fortawesome/pro-solid-svg-icons/faScrubber'
import { faShip } from '@fortawesome/pro-solid-svg-icons/faShip'
import { faSnowflake } from '@fortawesome/pro-solid-svg-icons/faSnowflake'
import { faSortAlt } from '@fortawesome/pro-solid-svg-icons/faSortAlt'
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare'
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faSubway } from '@fortawesome/pro-solid-svg-icons/faSubway'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faTaxi } from '@fortawesome/pro-solid-svg-icons/faTaxi'
import { faTicket } from '@fortawesome/pro-solid-svg-icons/faTicket'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faTrafficLight } from '@fortawesome/pro-solid-svg-icons/faTrafficLight'
import { faTrain } from '@fortawesome/pro-solid-svg-icons/faTrain'
import { faTrees } from '@fortawesome/pro-solid-svg-icons/faTrees'
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils'
import { faWalking } from '@fortawesome/pro-solid-svg-icons/faWalking'
import { faWheelchair } from '@fortawesome/pro-solid-svg-icons/faWheelchair'

export const loadFontAwesomeIcons = (): void => {
  library.add(
    faLeaf,
    faWheelchair,
    faBullhorn,
    faBadgePercent,
    faTag,
    faTrafficLight,
    faTrees,
    faBicycle,
    faTaxi,
    faShip,
    faCarCrash,
    faExclamationCircle,
    faExclamationTriangle,
    faRaindrops,
    faSnowflake,
    faUserPlus,
    faCreditCard,
    faClock,
    faFlag,
    faLocationPin,
    faDumbbell,
    faUtensils,
    faChild,
    faGraduationCap,
    faPrayingHands,
    faStar,
    faMapPin,
    faLocation,
    faEdit,
    faBriefcase,
    faHome,
    faAngleRight,
    faBadgeDollar,
    faCalendar,
    faTicket,
    faHeart,
    faCarBus,
    faMinus,
    faPlus,
    faDotCircle,
    faCircle,
    faSquare,
    faSortAlt,
    faScrubber,
    faCheck,
    faSubway,
    faTrain,
    faCar,
    faBus,
    faWalking,
    faChevronRight,
    faInfoCircle,
    faChevronLeft,
    faCalendarAlt,
    faExclamationTriangle,
    faBallot,
    faTimes,
    faTicket
  )
}
