import { ApiClient } from '@sparelabs/api-client'
import { IApiClientConfiguration } from '@sparelabs/http-client-utils'
import { SurveyClient } from '@sparelabs/survey-client'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { LanguageHelper } from 'src/locales/LanguageHelper'

export class ClientBuilderHelper {
  public static getClientConfiguration(): Required<Pick<IApiClientConfiguration, 'host' | 'token' | 'locale'>> {
    return {
      host: AuthenticatorHelper.getRegionalHost(),
      token: AuthenticatorHelper.getUserOrgToken(),
      locale: LanguageHelper.getCurrentLanguageCodeFull(),
    }
  }
}

export class ApiClientBuilder {
  public static build(paramInput?: Partial<IApiClientConfiguration>): ApiClient {
    return new ApiClient({
      ...ClientBuilderHelper.getClientConfiguration(),
      ...paramInput,
    })
  }
}

export class SurveyClientBuilder {
  public static build(paramInput?: Partial<IApiClientConfiguration>): SurveyClient {
    return new SurveyClient({
      ...ClientBuilderHelper.getClientConfiguration(),
      ...paramInput,
    })
  }
}
