import React from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'

interface IRadioButtonProps {
  selected: boolean
}

const radioDiameter = 14

const radioStyles = StyleSheet.create({
  radioUnfilled: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.radioBorder,
    width: radioDiameter + 6,
    height: radioDiameter + 6,
    borderRadius: (radioDiameter + 6) / 2,
    borderWidth: 1,
  },
  radioFilled: {
    backgroundColor: colors.selectedBlue,
    width: radioDiameter,
    height: radioDiameter,
    borderRadius: radioDiameter / 2,
  },
})

export const RadioButton = ({ selected }: IRadioButtonProps) => (
  <View style={[radioStyles.radioUnfilled, selected && { borderColor: colors.blue30 }]}>
    {selected && <View style={radioStyles.radioFilled} />}
  </View>
)
