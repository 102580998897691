import { IJourney, IWalkingLeg } from '@sparelabs/api-client'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import {
  AccessibleBottomSheetPosition,
  AccessibleBottomSheetView,
} from 'src/components/accessibleBottomSheet/AccessibleBottomSheetView'
import { BottomSheetScrollView } from 'src/components/BottomSheetScrollView/BottomSheetScrollView'
import { BackButton } from 'src/components/buttons/BackButton'
import { JourneyCardContent } from 'src/components/journey/JourneyCardContent'
import { IOnDemandItemEstimateInfo, isWalkingOnlyJourney } from 'src/components/journey/JourneyHelper'
import { JourneyRoute } from 'src/components/journey/JourneyRoute'
import { JourneyWalkingRoute, WALKING_CARD_HEIGHT } from 'src/components/journey/JourneyWalkingRoute'

export const TOP_OFFSET = 16
export const JOURNEY_CARD_HEIGHT = 200

const getStyles = () =>
  StyleSheet.create({
    card: {
      backgroundColor: colors.white,
      borderRadius: 8,
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 3,
      shadowOpacity: 1,
      elevation: 3,
    },
    handleIndicatorStyle: {
      width: 50,
    },
    backButtonWrapper: {
      position: 'absolute',
      width: '100%',
      marginBottom: -8,
    },
  })

enum JourneyCardSnapPoints {
  Initial = '25%',
  Top = '85%',
}

interface IProps {
  journey: IJourney
  destinationAddress: string
  estimateInfo?: IOnDemandItemEstimateInfo
  handleBackPress: () => void
  handleMakeJourneyEstimate: () => Promise<void>
}

export const JourneyCard = observer((props: IProps) => {
  const { journey, destinationAddress, estimateInfo } = props
  const [backButtonHeight, setBackButtonHeight] = useState<string>(JourneyCardSnapPoints.Initial)

  const styles = getStyles()

  const renderHeader = () => (
    <View style={{ marginTop: 12 }}>
      <JourneyRoute journey={journey} />
    </View>
  )

  if (isWalkingOnlyJourney(journey)) {
    return (
      <>
        <View
          style={[
            styles.backButtonWrapper,
            {
              bottom: WALKING_CARD_HEIGHT,
            },
          ]}
          pointerEvents='box-none'
        >
          <BackButton onPress={props.handleBackPress} />
        </View>
        <JourneyWalkingRoute walkingLeg={journey.legs[0] as IWalkingLeg} />
      </>
    )
  }

  const handleOnChangePosition = (position: AccessibleBottomSheetPosition) => {
    setBackButtonHeight(
      position === AccessibleBottomSheetPosition.Top ? JourneyCardSnapPoints.Top : JourneyCardSnapPoints.Initial
    )
  }

  return (
    <>
      <View
        style={[
          styles.backButtonWrapper,
          {
            bottom: backButtonHeight,
          },
        ]}
        pointerEvents='box-none'
      >
        <BackButton onPress={props.handleBackPress} />
      </View>
      <AccessibleBottomSheetView
        style={styles.card}
        handleIndicatorStyle={[styles.handleIndicatorStyle]}
        enableContentPanningGesture={true}
        enableOverDrag={true}
        onChangePosition={handleOnChangePosition}
        snapPoints={[JourneyCardSnapPoints.Initial, JourneyCardSnapPoints.Top]}
      >
        {renderHeader()}
        <BottomSheetScrollView>
          <JourneyCardContent
            journey={journey}
            walkingDestinationAddress={destinationAddress}
            estimateInfo={estimateInfo}
            handleMakeJourneyEstimate={props.handleMakeJourneyEstimate}
          />
        </BottomSheetScrollView>
      </AccessibleBottomSheetView>
    </>
  )
})
