import React from 'react'
import { Image, SafeAreaView, StyleSheet, TouchableOpacity } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { st } from 'src/locales'

const ButtonDiameter = 40

const styles = StyleSheet.create({
  container: {
    paddingBottom: 15,
    width: ButtonDiameter,
    marginLeft: 8,
    marginTop: 30,
  },
  touchable: {
    shadowColor: colors.darkShadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    elevation: 2, // For android shadow
    width: ButtonDiameter,
    height: ButtonDiameter,
    backgroundColor: 'white',
    borderRadius: ButtonDiameter / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 15,
    width: 15,
  },
})

interface IProps {
  onPress: () => void
}

export const ProfileButton = ({ onPress }: IProps) => (
  <SafeAreaView style={styles.container}>
    <TouchableOpacity
      accessibilityRole='button'
      style={styles.touchable}
      onPress={onPress}
      accessibilityLabel={st.screens.account.buttonLabel()}
      testID='homeUserButton'
    >
      <Image resizeMode='contain' style={styles.image} source={Images.homeUser} />
    </TouchableOpacity>
  </SafeAreaView>
)
