import { IEstimateOutput, SlotType } from '@sparelabs/api-client'
import { IPoint } from '@sparelabs/geography'
import { autorun, IReactionDisposer } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ErrorBoundary } from 'src/components/error/ErrorBoundary'
import { DrivingPolyline } from 'src/components/mapMarkers/DrivingPolyline'
import { DropoffLabelMarker } from 'src/components/mapMarkers/DropoffLabelMarker'
import { DropoffPinMarker } from 'src/components/mapMarkers/DropoffPinMarker'
import { EndLabelMarker } from 'src/components/mapMarkers/EndLabelMarker'
import { EndPinMarker } from 'src/components/mapMarkers/EndPinMarker'
import { MapServiceAreas } from 'src/components/mapMarkers/MapServiceAreas'
import { PickupLabelMarker } from 'src/components/mapMarkers/PickupLabelMarker'
import { PickupPinMarker } from 'src/components/mapMarkers/PickupPinMarker'
import { StartPinMarker } from 'src/components/mapMarkers/StartPinMarker'
import { WalkingPolyline } from 'src/components/mapMarkers/WalkingPolyline'
import { WalkingPolylineInfoBuilder } from 'src/components/mapMarkers/WalkingPolylineInfoBuilder'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { MapStore } from 'src/stores/MapStore'
import { EstimatesUserInputParsed } from 'src/types'

interface IProps {
  isLoading: boolean
  selectedEstimate: IEstimateOutput | null
  estimateInput: EstimatesUserInputParsed | null
  forceRenderPolyLine?: boolean
}

@observer
export class EstimateMap extends Component<IProps> {
  private estimateAutorun: IReactionDisposer | null = null
  public componentDidMount() {
    this.estimateAutorun = autorun(() => {
      if (!this.props.isLoading) {
        const estimate = this.getActiveEstimate()
        if (estimate) {
          this.fitMapToMarkers(estimate)
        }
      }
    })
  }

  public componentWillUnmount() {
    if (this.estimateAutorun) {
      this.estimateAutorun()
    }
  }

  public getActiveEstimate(): IEstimateOutput | EstimatesUserInputParsed | null {
    return this.props.selectedEstimate ?? this.props.estimateInput
  }

  public getScheduledPickupTime(): number | null {
    if (this.props.selectedEstimate) {
      return this.props.selectedEstimate.estimatedPickupTime.ts
    }
    return null
  }

  public renderMapPolylineAndMarkers() {
    const estimate = this.getActiveEstimate()
    if (estimate === null) {
      return null
    }

    const locations = MapMarkerHelper.getAllCoordinates(estimate)
    const scheduledPickupTime = this.getScheduledPickupTime()
    const dropoffLocation: IPoint = MapMarkerHelper.getDropoffLocation(estimate)
    const pickupLocation: IPoint = MapMarkerHelper.getPickupLocation(estimate)
    const showEndLocation: boolean = MapMarkerHelper.showEndLocation(estimate)
    /* On web, the order of polylines and markers determine which gets rendered on top of one another
     *
     * Components at the top of the list are rendered below the components at the bottom of the list
     */
    const forceRenderPolyLine = this.props.forceRenderPolyLine || false
    return (
      <>
        <ErrorBoundary>
          <DrivingPolyline
            pickupLocation={pickupLocation}
            dropoffLocation={dropoffLocation}
            renderPolyline={
              estimate.scheduledDropoffLocation !== null ||
              estimate.scheduledPickupLocation !== null ||
              forceRenderPolyLine
            }
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <WalkingPolyline walkingInfo={WalkingPolylineInfoBuilder.build(estimate, SlotType.Pickup, false)} />
        </ErrorBoundary>
        <ErrorBoundary>
          <WalkingPolyline walkingInfo={WalkingPolylineInfoBuilder.build(estimate, SlotType.Dropoff, false)} />
        </ErrorBoundary>
        <ErrorBoundary>
          {MapMarkerHelper.showStartLocation(estimate) && (
            <StartPinMarker location={estimate.requestedPickupLocation} />
          )}
        </ErrorBoundary>
        <ErrorBoundary>
          {showEndLocation && <EndPinMarker location={estimate.requestedDropoffLocation} />}
        </ErrorBoundary>
        <ErrorBoundary>
          <PickupPinMarker location={pickupLocation} />
        </ErrorBoundary>
        <ErrorBoundary>
          <DropoffPinMarker location={dropoffLocation} />
        </ErrorBoundary>
        <ErrorBoundary>
          <PickupLabelMarker
            location={pickupLocation}
            coordinates={locations}
            scheduledPickupTime={scheduledPickupTime}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          {!showEndLocation && <DropoffLabelMarker coordinates={locations} location={dropoffLocation} />}
        </ErrorBoundary>
        <ErrorBoundary>
          {showEndLocation && (
            <EndLabelMarker
              location={estimate.requestedDropoffLocation}
              coordinates={MapMarkerHelper.getAllCoordinates(estimate)}
            />
          )}
        </ErrorBoundary>
      </>
    )
  }

  public render() {
    return (
      <>
        <ErrorBoundary>
          <MapServiceAreas hideStops={true} />
        </ErrorBoundary>
        {this.renderMapPolylineAndMarkers()}
      </>
    )
  }

  private fitMapToMarkers(estimate: IEstimateOutput | EstimatesUserInputParsed) {
    MapStore.fitMapToCoordinates(MapMarkerHelper.getAllCoordinates(estimate), {
      edgePadding: MapMarkerHelper.getEdgePadding(estimate),
      animated: true,
    })
  }
}
