import { IJourney, IJourneyEstimateQueryParams } from '@sparelabs/api-client'
import { autorun, IReactionDisposer } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ErrorBoundary } from 'src/components/error/ErrorBoundary'
import { MapServiceAreas } from 'src/components/mapMarkers/MapServiceAreas'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { JourneyLegsMap } from 'src/screens/rideOptions/JourneyLegsMap'
import { buildSimpleJourneyRoute } from 'src/screens/rideOptions/JourneyMapHelper'
import { MapStore } from 'src/stores/MapStore'
import { ISimpleJourneyRoute } from 'src/types/journey'

interface IProps {
  selectedJourney: IJourney | null
  journeyInput: IJourneyEstimateQueryParams | null
  isLoading: boolean
}

@observer
export class JourneyMap extends Component<IProps> {
  private journeyAutorun: IReactionDisposer | null = null
  public componentDidMount() {
    // Refocus map when done loading
    this.journeyAutorun = autorun(() => {
      // When loading is set to false
      if (this.props.selectedJourney && this.props.journeyInput) {
        const simpleJourneyRoute = buildSimpleJourneyRoute(this.props.selectedJourney, this.props.journeyInput)
        if (!this.props.isLoading && simpleJourneyRoute) {
          this.fitMapToMarkers(simpleJourneyRoute)
        }
      }
    })
  }

  public componentWillUnmount() {
    if (this.journeyAutorun) {
      this.journeyAutorun()
    }
  }

  public render() {
    if (!this.props.selectedJourney || !this.props.journeyInput) {
      return null
    }

    const simpleJourneyRoute = buildSimpleJourneyRoute(this.props.selectedJourney, this.props.journeyInput)
    if (!simpleJourneyRoute) {
      return null
    }

    return (
      <>
        <ErrorBoundary>
          <MapServiceAreas hideStops={true} />
        </ErrorBoundary>
        <JourneyLegsMap simpleJourneyRoute={simpleJourneyRoute} selectedJourney={this.props.selectedJourney} />
      </>
    )
  }

  private fitMapToMarkers(journeyRoute: ISimpleJourneyRoute) {
    MapStore.fitMapToCoordinates(MapMarkerHelper.getAllCoordinates(journeyRoute), {
      edgePadding: MapMarkerHelper.getEdgePadding(journeyRoute),
      animated: true,
    })
  }
}
