import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'

export class Poller {
  private timer: NodeJS.Timeout | null
  private readonly callback: () => Promise<void>
  private timeoutMs: number | null

  constructor(callback: () => Promise<void>) {
    this.callback = callback
    this.timer = null
    this.timeoutMs = null
  }

  public isRunning = (): boolean => this.timer !== null
  public start = (timeoutMs: number, runImmediately: boolean = false) => {
    if (this.isRunning()) {
      this.stop()
    }
    this.timeoutMs = timeoutMs
    this.timer = setTimeout(this.poll, runImmediately ? 0 : timeoutMs)
  }

  public resume = async () => {
    await this.callback()
    if (this.timeoutMs) {
      this.start(this.timeoutMs)
    }
  }

  public stop = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  private readonly poll = async () => {
    try {
      await this.callback()
    } catch (e) {
      const error = e as IErrorWithResponse
      handleError({ error, silent: true })
    }
    if (this.timer !== null && this.timeoutMs) {
      this.start(this.timeoutMs)
    }
  }
}
