import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Platform } from 'react-native'
import { InitState, useInitHook } from 'src/hooks/useInitHook'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { NavigationStackRoot } from 'src/navigation/stacks/NavigationStackRoot'
import { NoConnection } from 'src/screens/login/NoConnection'
import { OsType } from 'src/util/types'
import { Loading } from './screens/Loading'
import 'src/styles/bootstrap/bootstrap'

export const AppChild: FC = observer(() => {
  const { initState, retry, isLoading } = useInitHook()

  if (initState === InitState.Loading) {
    return <Loading />
  }

  if (initState === InitState.NoConnection) {
    return <NoConnection handleRetryPress={retry} isLoading={isLoading} />
  }

  if (Platform.OS === OsType.Web) {
    // Set the language of the app in the html document. Helps a11y
    document.documentElement.lang = LanguageHelper.getCurrentLanguageCode()
  }

  return <NavigationStackRoot isLoggedIn={initState === InitState.LoggedIn} />
})
