import { observer } from 'mobx-react-lite'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TextButton } from 'src/components/buttons/TextButton'
import { IFontAwesomeWrapperProps } from 'src/components/FontAwesomeIcon'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { st } from 'src/locales'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { ProfileImage } from './ProfileImage'

const styles = StyleSheet.create({
  container: {
    height: 140,
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    flexBasis: 'auto',
  },
  name: {
    fontSize: 22,
    color: colors.textDark,
  },
  editText: {
    marginLeft: 10,
    fontSize: 17,
    color: colors.textDark,
  },
  editButton: {
    marginTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftContainer: {
    flexDirection: 'column',
    marginLeft: 20,
    flex: 1,
  },
  rightContainer: {
    marginRight: 12,
  },
  riderId: {
    color: colors.blueAlt50,
    marginBottom: 5,
    fontSize: 16,
  },
})

interface IProps {
  onPress: () => void
}

export const AccountHeader = observer(({ onPress }: IProps): JSX.Element | null => {
  const { rider, organization } = AuthenticatorHelper

  const shouldRenderExternalNumericId = organization?.isExternalNumericRiderIdEnabled && rider?.externalNumericId

  const iconProps: IFontAwesomeWrapperProps = {
    icon: 'edit',
    size: 17,
    color: colors.textDark,
  }

  if (!(rider && rider.firstName && rider.lastName)) {
    return null
  }

  return (
    <View style={styles.container} testID='editProfileView'>
      <View style={styles.leftContainer}>
        <Text style={styles.name}>{LanguageHelper.getFormattedName(rider.firstName, rider.lastName)}</Text>
        {shouldRenderExternalNumericId && <Text style={styles.riderId}>{rider.externalNumericId}</Text>}
        <TextButton
          textStyle={styles.editText}
          containerStyle={styles.editButton}
          onPress={onPress}
          text={st.screens.account.header()}
          testPropertyKey='editProfileBtn'
          leftIconProps={iconProps}
        />
      </View>
      <View style={styles.rightContainer}>
        <ProfileImage height={82} user={rider} />
      </View>
    </View>
  )
})
