import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { ViewTextInput } from 'src/components/ViewTextInput'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { PaymentMethodControls } from 'src/screens/payments/PaymentMethodControls'
import { IPaymentMethodStore, PaymentMethodStore } from 'src/stores/PaymentMethodStore'

const styles = StyleSheet.create({
  viewContainer: {
    padding: 16,
    backgroundColor: colors.sectionBlue,
    flex: 1,
  },
  viewContentContainer: {
    marginTop: 24,
    marginBottom: 16,
  },
  description: {
    textAlign: 'left',
    fontSize: 17,
    color: colors.darkGrayBlue,
    marginTop: 20,
  },
  cardImage: {
    width: '100%',
    height: undefined,
    aspectRatio: 120 / 79,
  },
})

type Props = ParamsListAccount[ScreenName.ViewNimoca] & {
  paymentMethodStore: IPaymentMethodStore
  handleDismiss: () => void
}

@observer
export class ViewNimocaView extends Component<Props> {
  public render() {
    const { paymentMethodId } = this.props
    const paymentMethod = this.props.paymentMethodStore.getPaymentMethodById(paymentMethodId)

    if (paymentMethod) {
      return (
        <View style={styles.viewContainer}>
          <View style={styles.cardImage}>
            <SvgIconWrapper widthPercentage={100} heightPercentage={100} icon={SvgIcon.NimocaCardFront} />
          </View>
          <Text style={styles.description}>{st.screens.viewNimoca.description()}</Text>
          <View style={styles.viewContentContainer}>
            <ViewTextInput title={st.screens.addNimoca.placeholder()} value={paymentMethod.externalId} />
          </View>
          <PaymentMethodControls handlePopScreen={() => this.props.handleDismiss()} paymentMethodId={paymentMethodId} />
        </View>
      )
    }
    return null
  }
}

export const ViewNimoca = (props: ScreenPropsAccount<ScreenName.ViewNimoca>) => (
  <ViewNimocaView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    paymentMethodStore={PaymentMethodStore}
  />
)
