import { InputLabel, TextInput } from '@sparelabs/web-ui-components'
import { uniqueId } from 'lodash'
import React from 'react'
import { ITextField, ITextFieldProps } from 'src/components/textField/TextFieldTypes'

export const TextField: ITextField = ({
  value,
  onChangeText,
  placeholder,
  label,
  disabled,
  autoFocus,
}: ITextFieldProps) => {
  const id = uniqueId()
  return (
    <>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <TextInput
        disabled={disabled}
        label={label ?? ''}
        onChange={(value: string | undefined) => {
          onChangeText && onChangeText(value ?? '')
        }}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        id={id}
      />
    </>
  )
}
