import { IJourney, IRequestInsightsResponse, IRequestResponse } from '@sparelabs/api-client'
import { FeatureFlag } from '@sparelabs/feature-flags'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { colors } from 'src/assets/colors'
import { BaseCard } from 'src/components/cards/BaseCard'
import { ContinueJourneyButton, shouldShowContinueJourneyButton } from 'src/components/journey/ContinueJourneyButton'
import { RequestCardHeader } from 'src/components/request/RequestCardHeader'
import { WalkingDirections } from 'src/components/request/WalkingDirections'
import { ThumbButton } from 'src/components/review/ThumbButton'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { EmissionsDetailsHelper } from 'src/helpers/EmissionsDetailsHelper'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { ParamsListReview, ParamsListRoot, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { RequestInsightsStore } from 'src/stores/RequestInsightsStore'
import { RequestStore } from 'src/stores/RequestStore'
import { ReviewRating, ReviewStore } from 'src/stores/ReviewStore'
import { EstimatesUserInputParsed } from 'src/types'

const getStyles = () =>
  StyleSheet.create({
    card: {
      marginHorizontal: 0,
      backgroundColor: colors.white,
      borderRadius: 16,
      shadowColor: colors.black,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.34,
      shadowRadius: 6.27,
      elevation: 10,
      height: '100%',
    },
    container: {
      paddingHorizontal: 16,
      flex: 1,
      flexBasis: 'auto',
    },
    title: {
      color: colors.gray90,
      fontSize: 21,
      fontWeight: 'bold',
      paddingTop: 16,
    },
    subtitle: {
      color: colors.gray70,
      fontSize: 16,
      marginTop: 4,
      marginBottom: 16,
    },
    buttons: {
      flexDirection: 'row',
    },
    walkingDirectionsContainer: {
      borderBottomWidth: 1,
      borderBottomColor: colors.borderGray,
    },
    emissionsContainer: {
      paddingTop: 16,
      paddingHorizontal: 16,
    },
    emissionsHeader: {
      color: colors.gray70,
      fontWeight: 'bold',
      fontSize: 14,
    },
    emissionsTextContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 8,
    },
    emissionsText: {
      fontSize: 16,
    },
    continueJourneyContainer: {
      padding: 16,
    },
    touchableText: {
      alignSelf: 'center',
      marginRight: 'auto',
      color: colors.blue50,
      fontSize: 16,
    },
    divider: {
      borderTopColor: colors.borderGray,
      borderTopWidth: 1,
      marginTop: 16,
    },
  })

interface IProps {
  request: IRequestResponse
  journey: IJourney | null
  initialEstimateInputStore: IEstimateInputStore
  journeyEstimateInput: EstimatesUserInputParsed | null
  handleNavigateReview: (params: Pick<ParamsListReview[ScreenName.Review], 'title'>) => void
  handleNavigateEmissionsDetails: (params: ParamsListRoot[ScreenName.EmissionsDetails]) => void
}

@observer
export class LastCompletedRequestCard extends Component<IProps> {
  public render(): JSX.Element {
    const styles = getStyles()
    const { request } = this.props

    return (
      <BaseCard containerStyle={styles.card}>
        <RequestCardHeader request={request} />
        {this.renderWalkingDirections()}
        {this.renderEmissionsData()}
        {this.renderContinueJourneyButton()}
        {request.shouldRiderReview && this.renderReviewOptions()}
      </BaseCard>
    )
  }

  private readonly handleThumbPress = async (thumbsUp) => {
    const rating = thumbsUp ? ReviewRating.Positive : ReviewRating.Negative
    ReviewStore.setRating(rating)

    if (RequestStore.lastCompletedRequest) {
      const date = RequestStore.lastCompletedRequest.scheduledPickupTs
        ? moment.unix(RequestStore.lastCompletedRequest.scheduledPickupTs).format('ll')
        : null
      this.props.handleNavigateReview({
        title: date ?? st.screens.review.title(),
      })
    }
  }

  private readonly onPress = async () => {
    const { request } = this.props
    const requestInsights: IRequestInsightsResponse | undefined = RequestInsightsStore.getInsightsFromMap(request.id)
    if (requestInsights) {
      this.props.handleNavigateEmissionsDetails({ travelDistance: request.travelDistance, requestInsights })
    }
  }

  private renderEmissionsData() {
    const styles = getStyles()
    const { request } = this.props
    const requestInsights: IRequestInsightsResponse | undefined = RequestInsightsStore.getInsightsFromMap(request.id)
    const requestInsightsEnabled = AuthenticatorHelper.organization?.featureFlags.includes(FeatureFlag.RequestInsights)
    if (requestInsightsEnabled && EmissionsDetailsHelper.isEmissionsDataValid(requestInsights)) {
      return (
        <View style={styles.emissionsContainer}>
          <Text style={styles.emissionsHeader}>{st.components.emissions.emissionsHeader().toUpperCase()}</Text>
          <View style={styles.emissionsTextContainer}>
            <Text style={styles.emissionsText}>
              {st.components.emissions.emissionsSavings({
                savings: EmissionsDetailsHelper.getEmissionsSavingPercentage(requestInsights),
              })}
            </Text>
            <TouchableOpacity onPress={this.onPress}>
              <Text style={styles.touchableText}>{st.components.emissions.learnMore()}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.divider} />
        </View>
      )
    }
    return null
  }

  private renderReviewOptions() {
    const styles = getStyles()
    const { request } = this.props
    const date = request.scheduledPickupTs ? moment.unix(request.scheduledPickupTs).format('LL') : null
    const address = request.requestedDropoffAddress || request.scheduledDropoffAddress

    return (
      <View style={styles.container}>
        <Text style={styles.title}>{st.screens.review.reviewTitle()}</Text>
        {address && date && (
          <Text style={styles.subtitle}>
            {st.screens.review.rideDateAndAddress({
              date,
              address,
            })}
          </Text>
        )}
        <View style={styles.buttons}>
          <View style={{ flex: 1, marginRight: 8 }}>
            <ThumbButton
              thumbsUp={true}
              selected={ReviewStore.rating === ReviewRating.Positive}
              onPress={this.handleThumbPress}
            />
          </View>
          <View style={{ flex: 1 }}>
            <ThumbButton
              thumbsUp={false}
              selected={ReviewStore.rating === ReviewRating.Negative}
              onPress={this.handleThumbPress}
            />
          </View>
        </View>
      </View>
    )
  }

  private renderWalkingDirections() {
    const styles = getStyles()
    const { request } = this.props
    const isDropoffDifferentFromEnd = request.scheduledDropoffAddress !== request.requestedDropoffAddress

    if (isDropoffDifferentFromEnd) {
      return (
        <View style={styles.walkingDirectionsContainer}>
          <WalkingDirections request={request} />
        </View>
      )
    }

    return null
  }

  private renderContinueJourneyButton() {
    const styles = getStyles()
    const { initialEstimateInputStore, journeyEstimateInput, journey } = this.props

    if (
      !journeyEstimateInput ||
      !shouldShowContinueJourneyButton(journey, initialEstimateInputStore.getEstimateInput(), journeyEstimateInput)
    ) {
      return null
    }

    return (
      <View style={styles.continueJourneyContainer}>
        <ContinueJourneyButton
          journey={journey}
          initialEstimateInputStore={initialEstimateInputStore}
          journeyEstimateInput={journeyEstimateInput}
        />
      </View>
    )
  }
}
