import { action, makeObservable } from 'mobx'
import { RouterStore as RouterStoreClass } from 'mobx-react-router'
import { AnalyticsHelper } from 'src/helpers/AnalyticsHelper/AnalyticsHelper'
import { Pathname } from 'src/types/homeRoot'

class RiderRouterStore extends RouterStoreClass {
  public name = 'RiderRouterStore'

  @action
  public async returnHome() {
    await AnalyticsHelper.setScreen(Pathname.Home)
    this.go(-10)
  }

  @action
  public async goToScreenIndex(index: number) {
    this.go(index)
    const pathname = this.history.location.pathname
    await AnalyticsHelper.setScreen(pathname)
  }

  @action
  public async replaceScreen({ pathname, state }: { pathname: string; state?: any }) {
    if (state) {
      this.replace({ pathname, state })
    } else {
      this.replace(pathname)
    }
    await AnalyticsHelper.setScreen(pathname)
  }

  @action
  public async goToScreen({ pathname, state }: { pathname: string; state?: any }) {
    if (state) {
      this.push({ pathname, state })
    } else {
      this.push(pathname)
    }
    await AnalyticsHelper.setScreen(pathname)
  }

  @action
  public async returnToPreviousScreen() {
    this.goBack()
    const pathname = this.history.location.pathname
    await AnalyticsHelper.setScreen(pathname)
  }

  constructor() {
    super()
    makeObservable(this)
  }
}

export const RouterStore = new RiderRouterStore()
