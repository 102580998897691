import { IRequestResponse, ITipPolicyResponse } from '@sparelabs/api-client'
import { action, makeObservable, observable, ObservableMap, runInAction } from 'mobx'
import { getTipPolicy } from 'src/api'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { ServiceStore } from 'src/stores/ServiceStore'

export interface ITipPolicyStore {
  getTipPolicyResponse: (request: IRequestResponse) => Promise<ITipPolicyResponse | null>
}

class TipPolicyStoreClass implements ITipPolicyStore {
  public name = 'TipPolicyStore'

  // Map request IDs to tip policies
  private tipPolicyMap: ObservableMap<string, ITipPolicyResponse> = observable.map()

  public getTipPolicyResponse = async (request: IRequestResponse): Promise<ITipPolicyResponse | null> => {
    const tipPolicyResponse = this.tipPolicyMap.get(request.id)

    if (tipPolicyResponse) {
      return tipPolicyResponse
    }

    const service = ServiceStore.servicesMap.get(request.serviceId)

    if (!service || !service.enableTipping) {
      return null
    }

    const tipPolicyId = service.tipPolicyId
    if (!tipPolicyId) {
      return null
    }

    try {
      const tipPolicy: ITipPolicyResponse = await getTipPolicy(tipPolicyId)
      if (tipPolicy) {
        runInAction(() => {
          this.tipPolicyMap.set(request.id, tipPolicy)
        })
        return tipPolicy
      }
    } catch (error) {
      handleError({ error: error as IErrorWithResponse, silent: true })
    }

    return null
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public clear() {
    this.tipPolicyMap = observable.map()
  }
}

export const TipPolicyStore = new TipPolicyStoreClass()
