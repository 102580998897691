import { ConnectedAccountType } from '@sparelabs/api-client'
import { delay } from 'bluebird'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { TextButton } from 'src/components/buttons/TextButton'
import { Lightbox } from 'src/components/Lightbox'
import { PurchaseConfirmedView } from 'src/components/payments/PurchaseConfirmedView'
import { PaymentMethodHelper } from 'src/helpers/payments/PaymentMethodHelper'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { LoadingStore } from 'src/stores/LoadingStore'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  card: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
    paddingTop: 24,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    ...(Platform.OS === OsType.Web && { height: '100vh', padding: 16 }),
  },
  image: {
    marginTop: 4,
    height: 21,
    width: 21,
  },
  header: {
    marginBottom: 24,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    color: colors.gray6,
    maxWidth: '80%',
  },
  titleCloseIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subtitle: {
    paddingTop: 3,
    fontSize: 24,
    color: colors.gray6,
  },
  termsWrapper: {
    marginVertical: 16,
  },
  terms: {
    fontSize: 12,
    lineHeight: 16,
    color: colors.gray1,
  },
  termsUrl: {
    fontSize: 12,
    color: colors.selectedBlue,
  },
  paymentMethod: {
    borderTopColor: colors.borderColor,
    borderBottomColor: colors.borderColor,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  paymentMethodRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 16,
  },
  paymentMethodLink: {
    fontSize: 16,
    color: colors.selectedBlue,
  },
  button: {
    paddingBottom: 16,
    ...(Platform.OS === OsType.Web && { marginTop: 'auto', paddingBottom: 0 }),
  },
  purchaseContainer: {
    flex: 1,
  },
})

type Props = ParamsListRoot[ScreenName.ConfirmPurchase] & {
  handleDismiss: () => void
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
  handleNavigateSelectPaymentMethod: (params: ParamsListRoot[ScreenName.SelectPaymentMethod]) => void
}

interface IState {
  paymentMethodId: string | null
  confirmed: boolean
}

@observer
export class ConfirmPurchaseView extends Component<Props, IState> {
  private readonly loadingStore = new LoadingStore()

  constructor(props: Props) {
    super(props)

    // This makes sure that we only select a payment method to be valid if its a stripe card
    if (
      PaymentMethodStore.defaultPayment &&
      PaymentMethodStore.defaultPayment.connectedAccountType === ConnectedAccountType.Stripe
    ) {
      this.state = { paymentMethodId: PaymentMethodStore.defaultPayment.id, confirmed: false }
    } else {
      this.state = { paymentMethodId: null, confirmed: false }
    }
  }

  public handlePressTerms = async () => {
    if (this.props.termsUrl) {
      this.props.handleNavigateWebView({
        uri: this.props.termsUrl,
      })
    }
  }

  public handleChangePaymentMethod = async () => {
    this.props.handleNavigateSelectPaymentMethod({
      isCashEnabled: false,
      selectedPaymentMethodId: this.state.paymentMethodId,
      onPaymentMethodPress: this.onPaymentMethodPress,
      paymentMethodTypeRequired: [ConnectedAccountType.Stripe],
    })
  }

  public onPaymentMethodPress = async (paymentMethodId: string | null) => {
    this.setState({ paymentMethodId })
  }

  public handlePressBuy = async () => {
    if (this.state.paymentMethodId) {
      const isSuccess = await this.loadingStore.execute(this.props.onPressPurchase(this.state.paymentMethodId))
      if (isSuccess) {
        // show confirmation screen
        this.setState({ confirmed: true })
        // wait for animation to play
        await delay(1000)
        // dismiss the modal
        this.props.handleDismiss()
      }
    }
  }

  public render() {
    return (
      <Lightbox onExit={this.props.handleDismiss}>
        <View style={styles.modal}>
          <View style={styles.card}>{this.state.confirmed ? this.renderConfirmed() : this.renderPurchase()}</View>
        </View>
      </Lightbox>
    )
  }

  private renderPurchase() {
    const { title, terms, getPriceSubtitle } = this.props
    return (
      <SafeAreaView style={styles.purchaseContainer} edges={['bottom']}>
        <View style={styles.header}>
          <View style={styles.titleCloseIcon}>
            <Text style={styles.title}>{title}</Text>
            <TouchableOpacity onPress={this.props.handleDismiss}>
              <Image style={styles.image} source={Images.close} />
            </TouchableOpacity>
          </View>
          <Text style={styles.subtitle}>{getPriceSubtitle()}</Text>
        </View>
        <View style={styles.paymentMethod}>{this.renderPaymentMethod()}</View>
        <View style={styles.termsWrapper}>
          <Text style={styles.terms}>{terms}</Text>
          <TextButton
            text={st.components.confirmPurchase.termsLinkText()}
            textStyle={styles.termsUrl}
            onPress={this.handlePressTerms}
          />
        </View>
        <View style={styles.button}>
          <PrimaryButton
            testID='confirmPurchase'
            title={st.components.confirmPurchase.buy()}
            onPress={this.handlePressBuy}
            disabled={this.loadingStore.isLoading() || !this.state.paymentMethodId}
            loading={this.loadingStore.isLoading()}
          />
        </View>
      </SafeAreaView>
    )
  }

  private renderConfirmed() {
    return <PurchaseConfirmedView />
  }

  private renderPaymentMethod() {
    if (this.state.paymentMethodId) {
      const paymentMethod = PaymentMethodStore.getPaymentMethodById(this.state.paymentMethodId)
      return (
        <View style={styles.paymentMethodRow}>
          <Text style={{ color: colors.gray90 }}>
            {paymentMethod && PaymentMethodHelper.getShortPaymentMethodName(paymentMethod)}
          </Text>
          <TextButton
            text={st.components.confirmPurchase.changePaymentMethod()}
            textStyle={styles.paymentMethodLink}
            onPress={this.handleChangePaymentMethod}
          />
        </View>
      )
    }
    return (
      <TextButton
        containerStyle={styles.paymentMethodRow}
        onPress={this.handleChangePaymentMethod}
        textStyle={styles.paymentMethodLink}
        text={st.screens.addPaymentMethod.title()}
      />
    )
  }
}

export const ConfirmPurchase = (props: ScreenPropsRoot<ScreenName.ConfirmPurchase>) => (
  <ConfirmPurchaseView
    {...props.route.params}
    handleDismiss={() => props.navigation.goBack()}
    handleNavigateSelectPaymentMethod={(params) => props.navigation.navigate(ScreenName.SelectPaymentMethod, params)}
    handleNavigateWebView={(params) =>
      WebViewHelper.handleLink(params, props.navigation.navigate.bind(ConfirmPurchase))
    }
  />
)
